import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import CircleIcon from "@mui/icons-material/Circle";

import {
  CellValue,
  Column,
  CustomTable,
  DynamicDialogProps,
  TableSettings,
} from "components/Table/Table";
import ContentLayout from "components/layouts/ContentLayout";
import Promotion from "models/resources/promotion.model";
import { getLocalizedDate } from "utils/lozalizeDateTime";

import usePriceAdjustment from "./usePriceAdjustment";

const PriceAdjustment = () => {
  const { priceAdjustmentProducts, setPriceAdjustmentProducts, setData } =
    usePriceAdjustment();
  const { t } = useTranslation();

  const tableOptions: TableSettings = {
    pagination: true,
    page: 0,
    rowsPerPage: 25,
    field: "id",
    order: "asc",
  };

  const columns: Column<Promotion>[] = [
    {
      label: t("Name"),
      name: "name",
      getData: (data): CellValue => data.name,
      show: () => {
        return true;
      },
    },
    {
      label: t("Display name"),
      name: "display_name",
      getData: (data): CellValue => data.display_name,
      show: () => true,
    },
    {
      label: t("Public"),
      name: "active",
      getData: (data): CellValue => (
        <CircleIcon sx={{ color: data.active ? "#4CAF50" : "#FF6347" }} />
      ),
      show: () => true,
    },
    {
      label: t("Validity period"),
      name: "period",
      getData: (data): CellValue => {
        return (
          <div>
            {getLocalizedDate(data.valid_from) +
              " / " +
              getLocalizedDate(data.valid_to)}
          </div>
        );
      },
      show: () => true,
    },
    {
      label: t("Price difference"),
      name: "price_difference",
      getData: (data): CellValue =>
        data.gift_card.discount_type === "amount"
          ? Number(data.gift_card.discount_value).toFixed(2)
          : `${data.gift_card.discount_value}%`,
      show: () => true,
    },
    {
      label: t("Company"),
      name: "company",
      getData: (data): CellValue => (
        <Link to={{ pathname: `/account/companies/${data.company_id}` }}>
          {data.company.display_name || data.company.name}
        </Link>
      ),
      show: () => true,
    },
    {
      label: t("Edit"),
      name: "edit",
      getData: (data): CellValue => data.id,
      show: () => true,

      align: "center",
      width: "5%",
    },
  ];

  const dynamicDialogProps: DynamicDialogProps = {
    title: "Price Adjustment",
    resource: "price adjustment",
    call: "price_adjustments",
    styles: () => "flexHalfForm",
  };

  return (
    <ContentLayout title={t("Price Adjustment")}>
      <CustomTable
        title={t("Price Adjustment")}
        resource="price adjustment"
        call="price_adjustments"
        tableData={priceAdjustmentProducts}
        fetchPaginatedData={setData}
        setTableData={setPriceAdjustmentProducts}
        columns={columns}
        tableOptions={tableOptions}
        notCSV
        dynamicDialogProps={dynamicDialogProps}
        isDynamic={true}
      />
    </ContentLayout>
  );
};

export default PriceAdjustment;
