import { useTranslation } from "react-i18next";

import Search from "@mui/icons-material/Search";
import {
  InputBase,
  InputBaseProps,
  IconButton,
  Paper,
  Tooltip,
} from "@mui/material";

import useTableSearch from "components/CustomSearch/useCustomSearch";

interface CustomSearchProps extends InputBaseProps {
  showAlways?: boolean /* Default behaviour hides the search input field and shows only the search button until it gets clicked. */;
  value?: string /* Sets a defaultValue for the input field. */;
  onCustomChange?(
    searchInput: string,
  ): void /* Custom onChange that uses the inputed value. Not using the onChange function from InputBaseProps. */;
}

const CustomSearch = ({
  showAlways = false,
  value = "",
  onCustomChange = () => {},
  onChange, // Remove the default onChange from InputBaseProps to not cause conflicts with the custom one that uses debouncer.
  ...props
}: CustomSearchProps) => {
  const { t } = useTranslation();
  const {
    debounceResults,
    handleSearchInputOnBlur,
    showSearchBar,
    handleSearchOnClick,
    searchInputRef,
  } = useTableSearch(showAlways, value, onCustomChange);

  if (!showSearchBar) searchInputRef.current?.blur();

  return (
    <Paper elevation={showSearchBar ? 2 : 0} sx={{ paddingBottom: "5px" }}>
      <InputBase
        sx={{ transition: "all 0.5s", paddingLeft: "10px" }}
        style={{
          width: showSearchBar ? 300 : 0,
        }}
        placeholder={t("Search")}
        defaultValue={value}
        inputRef={searchInputRef}
        onChange={debounceResults}
        onBlur={handleSearchInputOnBlur}
        {...props}
      />
      <Tooltip title={t("Search")}>
        <IconButton aria-label={t("Search")} onClick={handleSearchOnClick}>
          <Search />
        </IconButton>
      </Tooltip>
    </Paper>
  );
};

export default CustomSearch;
