const localizeDateTime = (date: string): Date => {
  let newDate: Date = new Date(date);
  newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());
  return newDate;
};

export const getLocalizedDate = (date: string | undefined): string => {
  const localizedDate: Date = localizeDateTime(date as string);
  return formatDate(localizedDate);
};
export const formatDate = (date: Date | undefined) => {
  if (date) {
    const year: number = date.getFullYear();
    const month: string = ("0" + (date.getMonth() + 1)).slice(-2);
    const day: string = ("0" + date.getDate()).slice(-2);

    return day + "." + month + "." + year;
  }

  return "";
};
export const getLocalizedTime = (date: string): string =>
  localizeDateTime(date).toString().slice(16, 24);
