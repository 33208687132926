import { Dispatch, SetStateAction } from "react";

import { Box, Button } from "@mui/material";
import Typography from "@mui/material/Typography";

import { changeStatus } from "components/DetailsPage/requests";
import { FieldDescriptor } from "models/fieldsEntities.model";
import Company from "models/resources/company.model";

const ChangeCompanyStatus = ({
  e,
  data,
  t,
  newData,
  handleClose,
}: {
  e: FieldDescriptor;
  data: Company;
  t: (key: string | string[]) => string;
  newData: Dispatch<SetStateAction<Company>>;
  handleClose?: () => void;
}) => {
  return (
    <Box>
      <Typography
        sx={{
          color: "rgba(0, 0, 0, 0.38)",
        }}
      >
        {e.text}
      </Typography>
      <Button
        onClick={() => changeStatus(data, t, newData, handleClose)}
        key={`${e.value} - ${e.type}`}
      >
        {t("Confirm")}
      </Button>
    </Box>
  );
};

export default ChangeCompanyStatus;
