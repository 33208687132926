import { useTranslation } from "react-i18next";

import ReplayIcon from "@mui/icons-material/Replay";
import { Button, ButtonGroup, Tooltip } from "@mui/material";

import SideBarFilters from "components/HeaderFilters/SideBarFilters";
import useDataContext from "context/DataContext";

const HeaderFiltersActions = ({ filters }: { filters?: boolean }) => {
  const { t } = useTranslation();
  const { getPagePropsData } = useDataContext();

  return (
    <ButtonGroup variant="text" aria-label="text button group">
      {((getPagePropsData && getPagePropsData.pageFilters) || []).length > 0 &&
        filters !== false && <SideBarFilters />}
      <Tooltip title={t("Refresh")}>
        <Button onClick={() => window.location.reload()}>
          <ReplayIcon />
        </Button>
      </Tooltip>
    </ButtonGroup>
  );
};

export default HeaderFiltersActions;
