import { useState } from "react";
import { toast } from "react-toastify";

import QueryParams, { RequestPagination } from "models/queryParams.model";
import EntityCategory from "models/resources/entityCategory.model";
import { ResponseDataTable } from "models/responseData.model";
import apiClient from "services/api";
import { getData } from "services/requests/listData";

const useEntityTable = () => {
  const [entityData, setEntityData] = useState<
    ResponseDataTable<EntityCategory[]>
  >({ data: [] });

  const defaultQueryParams = {
    sort: {
      field: "name",
      order: "ASC",
    },
  };

  const handleEntityOnDelete = async (id: number) => {
    await apiClient({
      url: `entity-categories/${id}`,
      method: "delete",
    })
      .then(() =>
        setEntityData((oldData) => {
          return {
            ...entityData,
            data: oldData.data.filter((a) => a.id !== id),
          };
        }),
      )
      .catch((error) => toast.error(error.message));
  };

  const fetchPaginatedData = async (
    queryParams: QueryParams,
    setDataLoading: any,
  ) =>
    getData(
      setEntityData,
      "entity-categories",
      { ...defaultQueryParams, ...queryParams },
      setDataLoading,
    );

  return {
    entityData,
    fetchPaginatedData,
    setEntityData,
    handleEntityOnDelete,
  };
};

export default useEntityTable;
