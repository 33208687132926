import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import { Box, Typography } from "@mui/material";

import { CustomDetailsPage } from "components/DetailsPage/DetailsPage";
import IngredientForm from "components/IngredientForm";
import DynamicDialog from "components/layouts/Dialog";
import { FieldDescriptor } from "models/fieldsEntities.model";
import { IngredientBase } from "models/resources/ingredient.model";
import Machine from "models/resources/machine.model";
import { ProductBase } from "models/resources/product.model";
import constants from "styles/scss/constants.module.scss";

import IngredientCard from "./IngredientCard";

interface IngredientBoardProps {
  machineData: Machine | undefined;
  setMachineData: Dispatch<SetStateAction<Machine | undefined>>;
  productType: string | null;
  selectedEditIngredient: IngredientBase | null;
  machineProducts: ProductBase[] | null;
  setMachineProducts: Dispatch<SetStateAction<ProductBase[] | null>>;
  capacityEditDialog: any;
  machineIngredients: IngredientBase[] | null;
  setMachineIngredients: Dispatch<SetStateAction<IngredientBase[] | null>>;
  handleEditIngredientChange: (i: IngredientBase | null) => void;
  attachIngredient: (i: {
    id: number;
    quantity: number;
    recharge_quantity: number;
    capacity?: number;
  }) => Promise<boolean>;
  setRefechtFlag: Dispatch<SetStateAction<boolean>>;
}

const IngredientBoard = ({
  machineData,
  productType,
  selectedEditIngredient,
  machineProducts,
  setMachineIngredients,
  capacityEditDialog,
  machineIngredients,
  handleEditIngredientChange,
  attachIngredient,
  setMachineData,
  setRefechtFlag,
}: IngredientBoardProps) => {
  const { t } = useTranslation();

  const ingredientFields: FieldDescriptor[] = [
    {
      type: "Wrap",
      show: true,
      label: "",
      value: (machineIngredients ?? [])
        .sort((ingredientA, ingredientB) =>
          ingredientA.name.localeCompare(ingredientB.name),
        )
        .map((ingredient, idx) => {
          return (
            <IngredientCard
              id={ingredient.id}
              key={idx}
              machineIngredients={machineIngredients}
              setMachineIngredients={setMachineIngredients}
              name={ingredient.name}
              unitName={ingredient.unit_name}
              product_type={ingredient.product_type}
              capacity={ingredient.capacity}
              rechargeQuantity={ingredient.recharge_quantity}
              quantity={ingredient.quantity}
            />
          );
        }),
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h3"
          component="header"
          sx={{
            fontFamily: constants.semiBold,
            fontSize: "24px",
            lineHeight: "36px",
          }}
        >
          {t("Ingredients")}
        </Typography>
        <DynamicDialog
          buttonSx={{
            margin: "20px 0 20px 0",
            display: "flex",
          }}
          title={t(
            productType === "packaged"
              ? `${
                  selectedEditIngredient === null ? "Specify" : "Edit"
                } product capacity`
              : `${
                  selectedEditIngredient === null ? "Specify" : "Edit"
                } ingredient capacity`,
          )}
          openMessage={t(
            productType === "packaged"
              ? "Add product capacity"
              : "Add ingredient capacity",
          )}
          isOpen={capacityEditDialog.show}
          hideActions
          disabled={machineProducts?.length === 0}
          onClick={() => {
            capacityEditDialog.handleShowStateChange();
            handleEditIngredientChange(null);
          }}
          onCancel={() => {
            capacityEditDialog.handleShowStateChange();
            handleEditIngredientChange(null);
          }}
          component={
            <IngredientForm
              hasCapacity
              defaultValues={
                selectedEditIngredient === null
                  ? undefined
                  : {
                      id: selectedEditIngredient.id,
                      name: selectedEditIngredient.name,
                      measurement_unit: selectedEditIngredient.unit_name,
                      quantity:
                        typeof selectedEditIngredient.quantity === "undefined"
                          ? 0
                          : selectedEditIngredient.quantity,
                      recharge_quantity:
                        selectedEditIngredient.recharge_quantity,
                      capacity: selectedEditIngredient.capacity,
                    }
              }
              defaultOptions={machineProducts
                ?.map((p) => p.ingredients)
                .flat(1)
                .filter(
                  (value, index, self) =>
                    index === self.findIndex((t) => t.id === value.id),
                )}
              submitBtnLabel="Update"
              onSubmit={(i) => {
                capacityEditDialog.handleShowStateChange();
                handleEditIngredientChange(null);

                attachIngredient(i);
              }}
            />
          }
        />
      </div>
      {machineIngredients?.length != 0 ? (
        <CustomDetailsPage
          data={machineData}
          setData={(v) => {
            setMachineData(v);
            setRefechtFlag((old) => !old);
          }}
          resource={""}
          call={""}
          fields={ingredientFields}
          isEditableAndDeletable={false}
        />
      ) : (
        <Box
          sx={{
            backgroundColor: "white",
            boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
            padding: "50px",
          }}
        >
          <h1>{t("There aren't any ingredients")}</h1>
        </Box>
      )}
    </>
  );
};

export default IngredientBoard;
