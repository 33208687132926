import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  CustomTable,
  Column,
  TableSettings,
  DynamicDialogProps,
  CellValue,
} from "components/Table/Table";
import ContentLayout from "components/layouts/ContentLayout";
import Ingredient from "models/resources/ingredient.model";

import useIngredientTable from "./useIngredientTable";

const IngredientTable = () => {
  const { t } = useTranslation();
  const { ingredientsData, setData, setIngredientsData } = useIngredientTable();

  const columns: Column<Ingredient>[] = [
    {
      label: t("Name"),
      name: "name",
      getData: (data): CellValue => data.name,
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "name" },
    },

    {
      label: t("Company"),
      name: "company",
      getData: (data): CellValue => (
        <Link
          to={{
            pathname: `/account/companies/${data.company_id}`,
          }}
        >
          {data.company.display_name || data.company.name}
        </Link>
      ),
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "company_id" },
    },
    {
      label: t("Measurement"),
      name: "measurement",
      getData: (data): CellValue => t(data.unit_name.toLowerCase()),
      show: () => {
        return true;
      },
    },
    {
      label: t("Edit"),
      name: "edit",
      getData: (data): CellValue => data.id,
      show: () => {
        return true;
      },
      width: "5%",
      align: "center",
    },
  ];

  const tableOptions: TableSettings = {
    pagination: true,
    page: 0,
    rowsPerPage: 25,
    field: "id",
    order: "asc",
  };

  const dynamicDialogProps: DynamicDialogProps = {
    title: "Add ingredient",
    resource: "ingredient",
    call: "ingredients",
    styles: () => "flexHalfForm",
  };

  return (
    <ContentLayout title={`${t("Products")} > ${t("Ingredients")}`}>
      <CustomTable
        title={t("Ingredients")}
        tableData={ingredientsData}
        fetchPaginatedData={setData}
        setTableData={setIngredientsData}
        columns={columns}
        resource="ingredient"
        call="ingredients"
        tableOptions={tableOptions}
        exportProp={"ingredients"}
        dynamicDialogProps={dynamicDialogProps}
        isDynamic={true}
        notCSV={true}
        clickableRow={true}
      />
    </ContentLayout>
  );
};

export default IngredientTable;
