import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Box } from "@mui/material";

import constants from "styles/scss/constants.module.scss";

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box className="four_zero_four_bg">
        <h1>
          {t("404: Page not found")}
          {". "}
          <Link
            to={{ pathname: "/account/dashboard" }}
            style={{ color: constants.primary }}
          >
            {t("Go back to dashboard")}
          </Link>
        </h1>
      </Box>
    </Box>
  );
};

export default NotFound;
