import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { isEmpty } from "lodash";

import {
  Button,
  FilledInput,
  FormControl,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";

import apiClient from "services/api";

interface ResetFormProps {
  token: string;
}

const ResetForm = ({ token }: ResetFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [password, setPassword] = useState<string>("");
  const [retypePassword, setRetypePassword] = useState<string>("");

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleRetypePasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRetypePassword(event.target.value);
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (isEmpty(password) || isEmpty(retypePassword)) {
      return toast.error(t("Password field can't be blank!").toString());
    }

    if (password !== retypePassword) {
      return toast.error(t("Password missmatch!").toString());
    }

    apiClient({
      url: "password-reset",
      method: "put",
      bodyData: {
        reset_code: token,
        password,
      },
    })
      .then(() => {
        navigate("/login");
        return toast.success(t("Password changed! Please, log in.").toString());
      })
      .catch((error) => {
        switch (error.statusCode) {
          case 404:
            return toast.error(
              t("Token does not exist! Please, check the link in your email!"),
            );
          case 422:
            if (
              error.message === "password should be at least 6 characters long"
            ) {
              return toast.error(
                t("Password must be at least 6 characters long!"),
              );
            }

            return toast.error(error.message);
          // No break for other Server errors on status 422 and show default client error message.
          default:
            console.log(
              "Password reset response error message: ".concat(error.message),
            );

            return toast.error(t("System error!"));
        }
      });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography component="span" variant="caption" mb={2}>
          {t("Please, enter a new password")}
        </Typography>
        <FormControl
          fullWidth
          sx={{ m: 1, margin: "0px", marginTop: "20px" }}
          variant="filled"
        >
          <FilledInput
            id="filled-adornment-weight-5"
            type="password"
            onChange={handlePasswordChange}
            disableUnderline={true}
            autoFocus={false}
            placeholder={t("Type a new password")}
            endAdornment={<InputAdornment position="end" />}
            aria-describedby="filled-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
          />
        </FormControl>
        <Typography component="span" variant="caption" mb={2}>
          {t("Please, confirm the new password")}
        </Typography>
        <FormControl
          fullWidth
          sx={{ m: 1, margin: "0px", marginTop: "20px" }}
          variant="filled"
        >
          <FilledInput
            id="filled-adornment-weight-6"
            type="password"
            onChange={handleRetypePasswordChange}
            disableUnderline={true}
            autoFocus={false}
            placeholder={t("Retype the password")}
            endAdornment={<InputAdornment position="end" />}
            aria-describedby="filled-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{ marginTop: "20px" }}
            >
              {t("Save")}
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ResetForm;
