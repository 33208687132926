import { useEffect, useState } from "react";

const useTabs = (
  defaultTabIndex: number,
  onChange: (tabIndex: number) => void,
) => {
  const [tabIndex, setTabIndex] = useState<number>(0);

  const handleTabIndexOnChange = (
    event: React.SyntheticEvent,
    value: number,
  ) => {
    setTabIndex(value);

    onChange(value);
  };

  useEffect(() => {
    // Using effect hook for setting defaultIndex after dynamic deletion of the tabs.
    setTabIndex(defaultTabIndex);
  }, [defaultTabIndex]);

  return { tabIndex, handleTabIndexOnChange };
};

export default useTabs;
