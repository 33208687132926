import { useParams } from "react-router-dom";

import ResetForm from "components/Forms/custom/ResetForm";
import InfoLayout from "components/InfoLayout/InfoLayout";

const PasswordReset = () => {
  const { token } = useParams();

  return <InfoLayout component={<ResetForm token={token || ""} />} />;
};

export default PasswordReset;
