import { useTranslation } from "react-i18next";

import DynamicChart from "components/Chart/DynamicChart";

interface machineStatus {
  up: number;
  down: number;
}

function MachinePowerChart({ up, down }: machineStatus) {
  const { t } = useTranslation();
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "right" as const,
        align: "end",
        padding: {
          top: 10,
          bottom: 70,
          //right: 30
        },
      },
      title: {
        display: true,
        text: t("Machines module states"),
        align: "end",
        padding: {
          top: 0,
          bottom: 70,
          //right: 30
        },
      },
    },
    font: { family: "Poppins-Bold" },
    maintainAspectRatio: false,
  };
  const dataPie = {
    labels: [t("Down"), t("Up")],
    datasets: [
      {
        type: "pie" as const,
        label: "Dataset 1",
        borderColor: "transparent",
        backgroundColor: ["#FF6347", "#4CAF50"],
        borderWidth: 2,
        fill: true,
        data: [down, up],
      },
    ],
  };
  return <DynamicChart options={options} type="pie" data={dataPie} />;
}
export default MachinePowerChart;
