import { useTranslation } from "react-i18next";

import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  FormControl,
  TextField,
} from "@mui/material";

import Product, { ProductBase } from "models/resources/product.model";
import getVariableName from "utils/getVariableName";

import useProductAtachForm from "./useProductAtachForm";

interface ProductAttachFormProps {
  productType: string;
  companyId: number;
  attachedList: ProductBase[];
  attachProducts(productIds: number[]): void;
  onSubmit?(): void;
}

const ProductAttachForm = ({
  productType,
  companyId,
  attachedList,
  attachProducts,
  onSubmit = () => {},
}: ProductAttachFormProps) => {
  const { t } = useTranslation();
  const { productList, multiSelectValues, handleMultiSelectOnChange } =
    useProductAtachForm(productType, companyId);

  return (
    <Box>
      <FormControl fullWidth sx={{ m: 1 }}>
        <Autocomplete
          multiple
          options={productList.filter(
            (p) => !attachedList.map((d) => d.name).includes(p.name),
          )}
          renderOption={(props, value) => (
            <li {...props} key={value.id}>
              {value.name}
            </li>
          )}
          getOptionLabel={(option) => option.name}
          value={multiSelectValues.products}
          onChange={(e, products) =>
            handleMultiSelectOnChange(getVariableName({ products }), products)
          }
          inputValue={multiSelectValues.productNames}
          onInputChange={(e, productNames) =>
            handleMultiSelectOnChange(
              getVariableName({ productNames }),
              productNames,
            )
          }
          // Remove 'InputLabelProps' that causes error in TextField component.
          renderInput={({ InputLabelProps, ...params }) => (
            <TextField {...params} label={t("Products")} variant="outlined" />
          )}
        />
      </FormControl>

      <DialogActions>
        <Button
          sx={{
            boxShadow: "none",
          }}
          variant="contained"
          type="submit"
          disabled={multiSelectValues.products.length === 0}
          onClick={() => {
            attachProducts(multiSelectValues.products.map((p) => p.id));
            onSubmit();
          }}
        >
          {t("Add")}
        </Button>
      </DialogActions>

      {/* {attachedList.attached && (
        <TextField
          fullWidth
          required
          type="number"
          label={t("Quantity")}
          value={quantity || ""}
          onChange={handleQuantityOnChange}
        />
      )} */}
    </Box>
  );
};

export default ProductAttachForm;
