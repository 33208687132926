import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { Box } from "@mui/material";

import {
  loadSumUpWidget,
  sumUpMountingCallback,
} from "components/SumUp/sumupHelpers";
import useSumupForm from "components/SumUp/useSumupForm";
import useDataContext from "context/DataContext";

interface SumUpFormProps {
  checkoutId: string;
}

const SumUpForm = ({ checkoutId }: SumUpFormProps) => {
  const { t } = useTranslation();
  // When 'getPagePropsData' is undefined then we are on the QR payment page.
  const { getPagePropsData } = useDataContext();
  const {
    loading,
    handleSumupMounterChange,
    responseStatus,
    handleResponseStatusChange,
  } = useSumupForm();

  if (typeof getPagePropsData !== "undefined" && loading) {
    // Don't show loading on vm payment page because sumup form opens in the same dialog window there and it stays empty.
    // TODO: refactor logic in the future.
    return (
      <div style={{ textAlign: "center" }}>{t("Loading payment form")}</div>
    );
  }

  return (
    <Box>
      {responseStatus !== null ? (
        <div style={{ textAlign: "center" }}>
          {responseStatus.success
            ? t(
                "Payment made! Amount paid will be credited to vending machine in a few seconds",
              )
            : t("Payment failed!").concat(` ${responseStatus.message}`)}
        </div>
      ) : (
        <Fragment>
          <div id="sumup-card" />
          <>
            {loadSumUpWidget(() =>
              setTimeout(
                sumUpMountingCallback(
                  checkoutId,
                  handleSumupMounterChange,
                  handleResponseStatusChange,
                ),
                0,
              ),
            )}
          </>
        </Fragment>
      )}
    </Box>
  );
};

export default SumUpForm;
