export const coOwnerRoles = [
  { id: "owner", name: "Company Owner", num_id: 2 },
  { id: "operator", name: "Operator", num_id: 3 },
  { id: "technician", name: "Technician", num_id: 4 },
];

export const adminRoles = [
  { id: "admin", name: "Administrator", num_id: 1 },
  ...coOwnerRoles,
];

export const userRoles = [{ id: "end_user", name: "End User", num_id: 49 }];

export function roleName(role: string) {
  for (let key in adminRoles) {
    if (role && adminRoles[key].id === role.toString())
      return adminRoles[key].name;
  }

  for (let key in userRoles) {
    if (role && userRoles[key].id === role.toString())
      return userRoles[key].name;
  }
}
