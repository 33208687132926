import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  DialogActions,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import usePaymentCredentialsForm from "components/CompanyPaymentCredentials/usePaymentCredentialsForm";
import {
  PaymentCredentialsTab,
  PaymentCredentialsTabForm,
  PaymentProvider,
  PaymentProviderCredentials,
  fields,
  equalPaymentCredentials,
} from "components/CompanyPaymentCredentials/utils";
import constants from "styles/scss/constants.module.scss";

interface PaymentCredentialsFormProps {
  companyId: number /* Company Id used in the requests for the current payment credentials form. */;
  paymentProvider: PaymentProvider /* Used in payment credentials management requests. */;
  credentialsTabForm: PaymentCredentialsTabForm /* Generates the input fields for the current selected tab form and stores their values. */;
  selectCurrencyNames: string[] /* Generates the select menu items in the `Add` tab form of the payment credentials. */;
  handleTabsUpdate(
    credentials: PaymentProviderCredentials,
  ): void /* Handler for adding new currency tabs, after successfully creating payment credentials. */;
  handleCurrentTabRemove(): void /* Handler for removing a currency tab, after successfully deleting a selected payment credentials from the form tab. */;
}

const PaymentCredentialsForm = ({
  companyId,
  paymentProvider,
  credentialsTabForm,
  selectCurrencyNames,
  handleTabsUpdate,
  handleCurrentTabRemove,
}: PaymentCredentialsFormProps) => {
  const { t } = useTranslation();
  const {
    formValues,
    handleFormValuesOnChange,
    errorMessages,
    handleSubmitOnClick,
    handleDeleteOnClick,
  } = usePaymentCredentialsForm(
    companyId,
    paymentProvider,
    credentialsTabForm,
    handleTabsUpdate,
    handleCurrentTabRemove,
  );

  return (
    <Box>
      {
        // Generate from selected payment provider input fields.
        Object.keys(credentialsTabForm.paymentCredentialsForm).map((field) =>
          field === "currency" ? (
            <FormControl
              key={field}
              fullWidth
              error={errorMessages.currency.length !== 0}
            >
              <InputLabel>{t("Currency name").concat("*")}</InputLabel>
              <Select
                name="currency"
                value={formValues.currency}
                onChange={handleFormValuesOnChange}
              >
                {selectCurrencyNames.map((currency) => (
                  <MenuItem key={currency} value={currency}>
                    {t(currency)}
                  </MenuItem>
                ))}
              </Select>

              {errorMessages.currency.length !== 0 && (
                <FormHelperText>{t(errorMessages.currency)}</FormHelperText>
              )}
            </FormControl>
          ) : (
            <TextField
              key={field}
              name={field}
              label={t(
                fields.find((f) => f.name === field)?.label ?? "",
              ).concat("*")}
              fullWidth
              error={
                errorMessages[field as keyof typeof errorMessages].length !== 0
              }
              helperText={t(errorMessages[field as keyof typeof errorMessages])}
              value={
                formValues[
                  field as keyof typeof credentialsTabForm.paymentCredentialsForm
                ]
              }
              onChange={handleFormValuesOnChange}
            />
          ),
        )
      }
      <DialogActions>
        {credentialsTabForm.tabIndex !== PaymentCredentialsTab.ADD && (
          <Button
            sx={{
              boxShadow: "none",
              backgroundColor: constants.bgRed,
              color: constants.bgColor,
            }}
            variant="contained"
            onClick={handleDeleteOnClick}
          >
            {t("Delete")}
          </Button>
        )}

        <Button
          sx={{
            boxShadow: "none",
          }}
          variant="contained"
          disabled={equalPaymentCredentials(
            credentialsTabForm.paymentCredentialsForm,
            formValues,
          )}
          onClick={handleSubmitOnClick}
        >
          {t("Submit")}
        </Button>
      </DialogActions>
    </Box>
  );
};

export default PaymentCredentialsForm;
