import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
} from "@mui/material";

import IngredientRow from "./IngredientRow";
import { ProductIngredient } from "./utils";

interface IngredientsTableProps {
  ingredients: ProductIngredient[];
  editIngredient(ingredient: ProductIngredient): void;
  deleteIngredient(ingredient: ProductIngredient): void;
}

const IngredientsTable = ({
  ingredients,
  editIngredient,
  deleteIngredient,
}: IngredientsTableProps) => {
  const ingredientsHeaders = [
    "name",
    "quantity",
    "measurement",
    "allergens",
    "action",
  ];

  const { t } = useTranslation();

  return (
    <Fragment>
      <Table sx={{ marginTop: "10px", marginBottom: "10px" }}>
        <TableHead>
          <TableRow>
            {ingredientsHeaders?.map((header: string, index: number) => (
              <TableCell
                key={index}
                sx={{
                  padding: "5px 0 5px 5px",
                  textAlign: "center",
                }}
              >
                {t(header)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {ingredients?.map((i: ProductIngredient) => (
            <IngredientRow
              key={i.id}
              ingredient={i}
              editIngredient={editIngredient}
              deleteIngredient={deleteIngredient}
            />
          ))}
        </TableBody>
      </Table>
    </Fragment>
  );
};

export default IngredientsTable;
