import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import ReactCrop from "react-image-crop";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, Button } from "@mui/material";

import DynamicDialog from "components/layouts/Dialog";

import useProductImageUpload from "./useImageUpload";

interface ImageUploadProps {
  setValue: Function;
  isCropSquared: boolean;
}
const ImageUpload = ({ setValue, isCropSquared }: ImageUploadProps) => {
  const { t } = useTranslation();
  const {
    show,
    handleDialogShowChange,
    productImage,
    setProductImage,
    imageSrc,
    handleOnChange,
    crop,
    handleCropOnChange,
    imgRef,
    previewCanvasRef,
    cropImage,
  } = useProductImageUpload(isCropSquared);

  return (
    <Box sx={{ margin: 0 }}>
      <Button
        sx={{
          boxShadow: "none",
        }}
        variant="outlined"
        startIcon={<CloudUploadIcon />}
        component="label"
      >
        {t("Upload image")}
        <input
          type="file"
          accept="image/png, image/jpeg, image/webp"
          hidden
          onChange={(e) => {
            handleOnChange(e);
            handleDialogShowChange();
          }}
          //@ts-ignore
          // Reset target value to be able to reopen the dialog window for image crop on selecting the same picture more than 1 time in a row.
          onClick={(e) => (e.target.value = null)}
        />
      </Button>
      {productImage !== null ? (
        <Fragment> {productImage.name}</Fragment>
      ) : (
        <Fragment> {t("No image selected")}</Fragment>
      )}

      <DynamicDialog
        isOpen={show}
        title="Crop"
        hide
        onConfirm={() => {
          cropImage(setValue);
          handleCropOnChange({
            unit: "%", // Can be 'px' or '%'
            x: 0,
            y: 0,
            width: 0,
            height: 0,
          });
        }}
        onCancel={() => {
          handleDialogShowChange();
          handleCropOnChange({
            unit: "%", // Can be 'px' or '%'
            x: 0,
            y: 0,
            width: 0,
            height: 0,
          });
        }}
        onCustomCancel={() => {
          setProductImage(null);
          setValue("product_image", undefined);
        }}
        component={
          <Box alignItems="center" justifyContent="center" display="flex">
            <ReactCrop crop={crop} onChange={handleCropOnChange}>
              <img ref={imgRef} src={imageSrc} />
            </ReactCrop>
          </Box>
        }
      />

      <canvas
        hidden
        ref={previewCanvasRef}
        style={{
          border: "1px solid black",
          objectFit: "contain",
          width: crop.width,
          height: crop.height,
        }}
      />
    </Box>
  );
};

export default ImageUpload;
