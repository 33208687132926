import { useTranslation } from "react-i18next";

import DynamicChart from "components/Chart/DynamicChart";
import { getTransactionBarChart } from "components/Chart/chartHelper";
import Transaction from "models/resources/transaction.model";

interface TransactionsBarChartProps {
  transactions: Transaction[];
  dateRange?: {
    startDate: string;
    endDate: string;
  };
}

const TransactionsBarChart = ({
  transactions,
  dateRange,
}: TransactionsBarChartProps) => {
  const { t } = useTranslation();
  const { chartData, totalPaid, totalFailed } = getTransactionBarChart(
    transactions,
    dateRange,
  );

  const options = {
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom" as const,
      },
      title: {
        display: true,
        align: "end",
        padding: {
          top: 0,
          bottom: 70,
          //right: 30
        },
      },
    },
    font: { family: "Poppins-Bold" },
    maintainAspectRatio: false,
  };

  const dataLine = {
    labels: chartData.map((d) => d.date),
    datasets: [
      {
        type: "bar",
        label: t("Failed"),
        data: chartData.map((d) => d.failed),
        backgroundColor: "#FF6347",
      },
      {
        type: "bar",
        label: t("Paid"),
        data: chartData.map((d) => d.paid),
        backgroundColor: "#4CAF50",
      },
    ],
  };

  return (
    <DynamicChart
      mainHeader={true}
      options={options}
      data={dataLine}
      totalPaid={totalPaid}
      totalFailed={totalFailed}
    />
  );
};

export default TransactionsBarChart;
