import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Ingredient from "models/resources/ingredient.model";
import { getDataById } from "services/requests/listData";

const useIngredeientDetails = () => {
  const { id } = useParams();
  const [ingredientData, setIngredientData] = useState<Ingredient | null>(null);

  useEffect(() => {
    if (id) {
      getDataById(setIngredientData, `ingredients/${id}`);
    }
  }, [id]);

  return { ingredientData, setIngredientData };
};

export default useIngredeientDetails;
