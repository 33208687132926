import { useEffect, useRef, useState } from "react";

import useDataContext from "context/DataContext";
import Ingredient from "models/resources/ingredient.model";

import { ProductIngredient, fetchRecipeIngredients } from "./utils";

const useRecipeProductIngredients = (
  companyId: number,
  defaultIngredients: ProductIngredient[] | null,
) => {
  const { setProductIngredients } = useDataContext(); // Used for setting ingredientsQuantity in request body for recipe product creation.
  const [ingredientData, setIngredients] = useState<ProductIngredient[]>([]);
  const [ingredientList, setIngredientList] = useState<Ingredient[]>([]);
  const editValuesParseRef = useRef<boolean>(false); // Prevents field values override from response data triggered by rerenders.

  const addIngredient = (newIngredient: ProductIngredient) => {
    setIngredients((oldIngredients) => [newIngredient, ...oldIngredients]);
    setProductIngredients(
      (oldState: { ingredient_id: number; quantity: number }[]) => [
        ...oldState,
        {
          ingredient_id: newIngredient.id,
          quantity: newIngredient.quantity,
        },
      ],
    );
  };

  const updateIngredient = (selectedIngredient: ProductIngredient) => {
    setIngredients((oldIngredients) =>
      oldIngredients.map((i) =>
        i.name === selectedIngredient.name ? selectedIngredient : i,
      ),
    );

    setProductIngredients(
      (oldState: { ingredient_id: number; quantity: number }[]) =>
        oldState.map((i) =>
          i.ingredient_id === selectedIngredient.id
            ? { ...i, quantity: selectedIngredient.quantity }
            : i,
        ),
    );
  };

  const removeIngredient = (selectedIngredient: ProductIngredient) => {
    setIngredients((oldIngredients) =>
      oldIngredients.filter((i) => i.name !== selectedIngredient.name),
    );

    setProductIngredients(
      (oldState: { ingredient_id: number; quantity: number }[]) =>
        oldState.filter((i) => i.ingredient_id !== selectedIngredient.id),
    );
  };

  useEffect(() => {
    if (companyId === undefined) return; // While there is not selected company in the form.

    (async () => {
      await fetchRecipeIngredients(companyId).then((ingredients) =>
        setIngredientList(
          ingredients.filter(
            (i) => !ingredientData.map((i) => i.id).includes(i.id),
          ),
        ),
      );
    })();
  }, [companyId, ingredientData]);

  // Parse the ingredients response data.
  useEffect(() => {
    if (defaultIngredients === null) return;

    if (editValuesParseRef.current === true) return;

    setIngredients(defaultIngredients);
    setProductIngredients(
      defaultIngredients.map((i) => ({
        ingredient_id: i.id,
        quantity: +i.quantity,
      })),
    );
    editValuesParseRef.current = true;
  }, [defaultIngredients]);

  return {
    ingredientData,
    ingredientList,
    addIngredient,
    updateIngredient,
    removeIngredient,
  };
};

export default useRecipeProductIngredients;
