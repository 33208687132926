import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";

import GetAppIcon from "@mui/icons-material/GetApp";
import { Tooltip, Typography } from "@mui/material";

import Fields from "components/CsvExport/fields";
import { DatabaseEntity } from "models/responseData.model";
import constants from "styles/scss/constants.module.scss";
import { getFormattedTime } from "utils/FormatDateAndHour";
import NoSsr from "utils/NoSsr";

interface Props {
  rowData: DatabaseEntity[];
  type: string;
  typeInvoice?: string;
}

function CSVExport({ rowData, type, typeInvoice }: Props) {
  const { t } = useTranslation();
  const {
    Companies,
    OrderInvoices,
    Invoices,
    Locations,
    Machines,
    Modules,
    Orders,
    POS,
    Promotions,
    QR,
    Transactions,
    Users,
  } = Fields();
  const types: any = {
    companies: Companies,
    invoices: Invoices,
    orderInvoice: OrderInvoices,
    locations: Locations,
    machines: Machines,
    modules: Modules,
    orders: Orders,
    pos: POS,
    promotions: Promotions,
    qr: QR,
    transactions: Transactions,
    users: Users,
  };

  return (
    <NoSsr>
      <CSVLink
        data={types[type](rowData, typeInvoice).body}
        headers={types[type](rowData, typeInvoice).headers}
        filename={`${type}_${getFormattedTime(new Date())}.csv`}
        style={{ textDecoration: "none" }}
      >
        <Tooltip title={t("Export as CSV")}>
          <Typography
            sx={{
              color: constants.grey,
              textShadow: constants.boxShadowLight,
              fontSize: "24px",
              display: "flex",
            }}
            component="p"
          >
            <GetAppIcon />
            <Typography
              sx={{
                color: constants.textColor,
                fontSize: "12px",
                lineHeight: "21px",
              }}
              component="span"
            >
              CSV
            </Typography>
          </Typography>
        </Tooltip>
      </CSVLink>
    </NoSsr>
  );
}

export default CSVExport;
