import { CompanyBase } from "models/resources/company.model";

export const CompaniesForExport = (users_companies: CompanyBase[]) => {
  return !users_companies || users_companies?.length === 0
    ? "None"
    : users_companies
        ?.reduce((acc: string, value: CompanyBase) => {
          acc += value.display_name + ", ";
          return acc;
        }, "")
        .slice(0, -2);
};
