import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Crop } from "react-image-crop";
import { toast } from "react-toastify";

const useProductImageUpload = (isCropSquared: boolean) => {
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(false);
  const [productImage, setProductImage] = useState<any>(null);
  const [imageSrc, setImageSrc] = useState<string>("");
  const [crop, setCrop] = useState<Crop>({
    unit: "%",
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);

  const handleDialogShowChange = () => setShow((oldState) => !oldState);

  const handleOnChange = (event: any) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      if (!["image/png", "image/jpeg", "image/webp"].includes(file.type)) {
        return toast.error(
          t("Allowed file formats can only be png, jpeg or webp"),
        );
      }
      setImageSrc(URL.createObjectURL(file));
      setProductImage(file);
    }
  };

  const handleCropOnChange = (newCrop: Crop) => {
    if (isCropSquared) {
      setCrop((prev) => {
        if (
          newCrop.width > newCrop.height &&
          newCrop.width + newCrop.y < imgRef.current!.height
        ) {
          return {
            ...newCrop,
            width: newCrop.width,
            height: newCrop.width,
          };
        } else if (newCrop.height + newCrop.x < imgRef.current!.width) {
          return {
            ...newCrop,
            width: newCrop.height,
            height: newCrop.height,
          };
        }
        return prev;
      });
    } else {
      setCrop({ ...newCrop, width: newCrop.width, height: newCrop.height });
    }
  };

  const cropImage = async (setter: any) => {
    const { x, y, width, height } = crop;

    if ([x, y, width, height].every((v) => v === 0)) {
      setter("product_image", productImage);
      return;
    }

    const canvas = previewCanvasRef.current;
    if (!canvas) return;
    const ctx = previewCanvasRef.current.getContext("2d");
    const image = imgRef.current;

    if (image === null) return;

    if (!ctx) return;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const pixelRatio = window.devicePixelRatio;
    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = "high";

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    const centerX = image.naturalWidth / 2;
    const centerY = image.naturalHeight / 2;

    ctx.save();

    ctx.translate(-cropX, -cropY);
    ctx.translate(centerX, centerY);
    ctx.scale(1, 1);
    ctx.translate(-centerX, -centerY);

    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
    );

    ctx.restore();

    ctx.canvas.toBlob(async (blob) => {
      if (blob === null) return;

      // form value setter
      setter("product_image", blob);
    }, "image/png");
  };

  return {
    productImage,
    setProductImage,
    show,
    handleDialogShowChange,
    imageSrc,
    handleOnChange,
    crop,
    handleCropOnChange,
    imgRef,
    previewCanvasRef,
    cropImage,
  };
};

export default useProductImageUpload;
