import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  CustomTable,
  Column,
  TableSettings,
  DynamicDialogProps,
  CellValue,
} from "components/Table/Table";
import ContentLayout from "components/layouts/ContentLayout";
import Product from "models/resources/product.model";

import useProductTable from "./useProductTable";

const ProductTable = () => {
  const { t } = useTranslation();
  const { productsData, setData, setProductsData } = useProductTable();

  const columns: Column<Product>[] = [
    {
      label: t("Name"),
      name: "name",
      getData: (data): CellValue => data.name,
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "name" },
    },
    {
      label: t("Product name"),
      name: "productName",
      getData: (data): CellValue => data.display_name,
      show: () => {
        return true;
      },
    },

    {
      label: t("Company"),
      name: "company",
      getData: (data): CellValue => (
        <Link
          to={{
            pathname: `/account/companies/${data.company_id}`,
          }}
        >
          {data.company.display_name || data.company.name}
        </Link>
      ),
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "company_id" },
      width: "15%",
      minWidth: "200px",
    },
    {
      label: t("Currency"),
      name: "currency",
      getData: (data): CellValue => data.currency,
      show: () => {
        return true;
      },
    },
    {
      label: t("Price"),
      name: "price",
      getData: (data): CellValue => Number(data.price).toFixed(2),
      show: () => {
        return true;
      },
    },
    {
      label: t("Type"),
      name: "type",
      getData: (data): CellValue => t(data.product_type),
      show: () => {
        return true;
      },
    },
    {
      label: t("Edit"),
      name: "edit",
      getData: (data): CellValue => data.id,
      show: () => {
        return true;
      },
      width: "5%",
      align: "center",
    },
  ];

  const tableOptions: TableSettings = {
    pagination: true,
    page: 0,
    rowsPerPage: 25,
    field: "id",
    order: "asc",
  };

  const dynamicDialogProps: DynamicDialogProps = {
    title: "Add product",
    resource: "product",
    call: "products",
    styles: () => "flexHalfForm",
  };

  return (
    <ContentLayout title={t("Products")}>
      <CustomTable
        title={t("Products")}
        tableData={productsData}
        fetchPaginatedData={setData}
        setTableData={setProductsData}
        columns={columns}
        resource="product"
        call="products"
        tableOptions={tableOptions}
        exportProp={"products"}
        dynamicDialogProps={dynamicDialogProps}
        isDropDown={true}
        notCSV={true}
      />
    </ContentLayout>
  );
};

export default ProductTable;
