import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { isEmpty } from "lodash";

import { Button, FormControl, Grid, Typography } from "@mui/material";

import apiClient from "services/api";

export default function ResendEmailPage() {
  const { t } = useTranslation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");

  async function resendEmail() {
    if (isEmpty(email)) {
      return toast.error(t("Email field is required!").toString());
    }

    apiClient({
      url: "password-reset",
      method: "post",
      bodyData: {
        email,
      },
    })
      .then(() =>
        toast.success(
          t("Please, check your email for password reset link!").toString(),
        ),
      )
      .catch(() => toast.error(t("Email not found!").toString()));
  }

  return (
    <Grid>
      <Grid item>
        <Typography component="span" variant="caption" mb={2}>
          {t(
            "An email has been sent to the email. Please follow the instructions in the email to change your password. If you don't see the email in your inbox within the next few minutes, please check your spam or junk folder. If you need further assistance, please don't hesitate to contact our support team at support@averato.com. Your security is our priority, and we're here to help you regain access to your account.",
          )}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Button
              variant="contained"
              onClick={resendEmail}
              sx={{ marginTop: "20px" }}
            >
              {t("Resend Email")}
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
}
