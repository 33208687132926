import { useState } from "react";
import { forwardRef } from "react";
import { useEffect } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

import { Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { bg } from "date-fns/locale/bg";
import { enGB } from "date-fns/locale/en-GB";

import {
  formatDate,
  getMinDate,
  roundTime,
  convertUTCDateToLocalDate,
} from "./utils";

registerLocale("bg", bg);
registerLocale("en", enGB);

type DateRange = Date | null;

export const initStartDate = (() => {
  const date = new Date();
  date.setUTCHours(0, 0, 0, 0);
  date.setMonth(date.getMonth() - 1);

  return date;
})();
export const initEndDate = new Date(new Date().setHours(23, 59, 59, 999));

const rangeDefault = [initStartDate, initEndDate];
const singleRangeDefault = new Date(new Date().setHours(23, 59, 59, 999));

export enum DatePickerTypes {
  DATE_RANGED = "DateRanged",
  SINGLE_RANGED = "SingleRanged",
  TIME_RANGED = "TimeRanged",
}

type DatePickerType =
  | DatePickerTypes.DATE_RANGED
  | DatePickerTypes.SINGLE_RANGED
  | DatePickerTypes.TIME_RANGED;

interface DatePickerProps {
  type?: DatePickerType;
  range?: DateRange[] | Date;
  onChange: (dates: Date[] | Date | null) => void;
  fixedStart?: boolean;
  fieldLabel?: string;
  futureDatesOnly?: boolean;
  futureDatesFromStart?: boolean;
  inlineStyle?: object;
  isCentered?: boolean;
  minDate?: Date;
}

interface CustomDatePickerProps {
  onClick?: () => void;
}

type DatePickerCommonFilter = {
  dropdownMode: "scroll" | "select" | undefined;
  showYearDropdown: boolean;
  showMonthDropdown: boolean;
  locale: string;
  selected: Date;
  minDate?: Date | null | undefined;
};

export default function DatePickerComp({
  type,
  range = type === DatePickerTypes.SINGLE_RANGED
    ? singleRangeDefault
    : rangeDefault,
  onChange,
  fixedStart,
  fieldLabel,
  futureDatesOnly,
  futureDatesFromStart,
  inlineStyle,
  isCentered = false,
  minDate,
}: DatePickerProps) {
  const { i18n } = useTranslation();

  const initStartDate = () => {
    if (Array.isArray(range)) return range[0];
    return range ?? rangeDefault[0];
  };

  const initEndDate = () => {
    if (Array.isArray(range) && range.length > 1) return range[1];
    return null;
  };

  const [startDate, setStartDate] = useState<DateRange>(initStartDate());
  const [endDate, setEndDate] = useState<DateRange>(initEndDate());
  const { t } = useTranslation();
  const theme = useTheme();
  const labelColorMUI = theme.palette.text.secondary;

  const isDate = (range: unknown): range is Date => {
    return range instanceof Date && !isNaN(range.getTime());
  };

  const isDateArray = (range: unknown): range is Date[] => {
    return Array.isArray(range) && range.every(isDate);
  };

  const isSingleDate = (range: unknown): range is Date => {
    return !isDateArray(range);
  };

  useEffect(() => {
    if (type === DatePickerTypes.TIME_RANGED && isDate(range)) {
      const roundedTime = roundTime(range);
      setStartDate(roundedTime);
      onChange([roundedTime]);
    } else if (isDateArray(range)) {
      setStartDate(range[0]);
      setEndDate(range[1]);
      onChange(range);
    } else if (isSingleDate(range)) {
      onChange([range]);
    }
  }, []);

  useEffect(() => {
    if (type === DatePickerTypes.TIME_RANGED && isDate(range)) {
      setStartDate(range);
    } else if (isDateArray(range)) {
      setStartDate(range[0]);
      setEndDate(range[1]);
    }
  }, [range]);

  const onChangeRangedDates = (dates: [Date, Date] | Date) => {
    if (fixedStart) {
      setEndDate(dates as Date);

      onChange([startDate as Date, dates as Date]);
    } else {
      const [start, end] = dates as [Date, Date];

      const currDate = new Date();

      if (start.getDay() !== currDate.getDay()) start?.setHours(0, 0, 0, 0);
      else
        start?.setHours(
          currDate.getHours(),
          currDate.getMinutes(),
          currDate.getSeconds(),
        );

      // end?.setHours(23, 59, 59, 999);

      setStartDate(start);
      setEndDate(end);

      onChange(dates as Date[]);
    }
  };

  const onChangeSingleDate = (date: Date) => {
    setStartDate(date);
    onChange([date]);
  };

  const onChangeTimeDate = (date: Date) => {
    setStartDate(date);
    onChange([date]);
  };

  const commonDateProps: DatePickerCommonFilter = {
    dropdownMode: "select",
    showYearDropdown: true,
    showMonthDropdown: true,
    minDate:
      minDate ??
      getMinDate({ futureDatesFromStart, futureDatesOnly, startDate }),
    locale: i18n.language,
    selected: startDate ?? new Date(),
  };

  const CustomRangedInput = forwardRef<
    HTMLButtonElement,
    CustomDatePickerProps
  >((props, _ref) => {
    const { onClick } = props;

    return (
      <Button onClick={onClick}>
        <Typography>
          {startDate ? formatDate(startDate) : ""}
          {" / "}
          {endDate ? formatDate(endDate) : ""}
        </Typography>
      </Button>
    );
  });

  const CustomSingleInput = forwardRef<
    HTMLButtonElement,
    CustomDatePickerProps
  >((props, _ref) => {
    const { onClick } = props;

    return (
      <Button onClick={onClick}>
        <Typography>{formatDate(startDate ?? new Date())}</Typography>
      </Button>
    );
  });

  const CustomTimeRange = forwardRef<HTMLButtonElement, CustomDatePickerProps>(
    (props, _ref) => {
      const { onClick } = props;
      return (
        <Button onClick={onClick}>
          <Typography>
            {startDate?.getHours()}:
            {startDate?.getMinutes().toLocaleString().padStart(2, "0")}
          </Typography>
        </Button>
      );
    },
  );
  const getDatePicker = () => {
    if (type === DatePickerTypes.DATE_RANGED) {
      return (
        <DatePicker
          selectsRange={!fixedStart}
          startDate={startDate}
          endDate={endDate}
          customInput={<CustomRangedInput />}
          onChange={onChangeRangedDates}
          {...commonDateProps}
        />
      );
    } else if (type === DatePickerTypes.SINGLE_RANGED) {
      return (
        <DatePicker
          onChange={onChangeSingleDate}
          selectsRange={false}
          customInput={<CustomSingleInput />}
          {...commonDateProps}
        />
      );
    } else if (type === DatePickerTypes.TIME_RANGED) {
      return (
        <DatePicker
          selected={startDate}
          onChange={onChangeTimeDate}
          locale={i18n.language}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={5}
          showTimeInput={false}
          customInput={<CustomTimeRange />}
          timeFormat="HH:mm"
          dateFormat="HH:mm"
        />
      );
    }
  };

  return (
    <div style={{ position: "static" }}>
      <div style={{ position: "relative" }}>
        <Typography
          className="label"
          style={{
            color: labelColorMUI,
            transform: "translate(14px, -9px) scale(0.75)",
            top: "-9px",
            transformOrigin: "top left",
            position: "absolute",
          }}
        >
          {t(fieldLabel ?? "")}
        </Typography>
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.06)",
            borderRadius: "4px",
            height: "36px",
            display: "flex",
            justifyContent: isCentered ? "center" : "left",
            alignItems: "center",
            ...inlineStyle,
          }}
        >
          {getDatePicker()}
        </div>
      </div>
    </div>
  );
}
