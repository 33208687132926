import moment from "moment";

import Invoice from "models/resources/invoice.model";
import Order from "models/resources/order.model";
import Transaction from "models/resources/transaction.model";

export const fixCurrencyAmount = (amount: number, currency: string) =>
  currency?.toUpperCase() !== "AVAX"
    ? Number(amount).toFixed(2)
    : Number(amount).toFixed(9);

export const ordersPdfHeaders = () => {
  return [
    "Date",
    "Time",
    "Product Number",
    "Order total",
    "Status",
    "Company",
    "Payment point",
  ];
};
export const ordersFields = (rowData: Order[]) => {
  let totalAmount: { [value: string]: number } = {
    BGN: 0.0,
    EUR: 0.0,
    USD: 0.0,
    AVAX: 0.0,
    CHF: 0.0,
  };

  const fields = rowData.reduce(
    (acc: Array<Array<number | string | undefined>>, value) => {
      const amount =
        value.product_price && value.quantity
          ? (+value.quantity * +value.product_price).toFixed(2)
          : 0.0;
      totalAmount[value.currency] += Number(amount);

      acc.push([
        moment(value.inserted_at).format("DD/MM/YYYY"),
        moment(value.inserted_at).format("HH:mm:ss"),
        value.product_number ? value.product_number : 0.0,
        `${fixCurrencyAmount(Number(amount), value.currency)} ${
          value.currency
        }`,
        value.status,
        value.company.display_name,
        value.object_entity?.name,
      ]);
      return acc;
    },
    [],
  );

  return { totalAmount, fields };
};

export const transactionsPdfHeaders = () => {
  return [
    "Date",
    "Time",
    "Amount",
    "POS Amount",
    "Status",
    "Company",
    "Payment point",
    "Payment type",
  ];
};
export const transactionsFields = (rowData: Transaction[]) => {
  let totalAmount: { [value: string]: number } = {
    BGN: 0.0,
    EUR: 0.0,
    USD: 0.0,
    AVAX: 0.0,
    CHF: 0.0,
  };

  const fields = rowData.reduce(
    (acc: Array<Array<number | string | undefined>>, value) => {
      const amount = (+value.rate * +value.amount).toFixed(2);
      totalAmount[value.currency_at_transaction] += Number(amount);

      acc.push([
        moment(value.inserted_at).format("DD/MM/YYYY"),
        moment(value.inserted_at).format("HH:mm:ss"),
        `${fixCurrencyAmount(+value.amount * +value.rate, value.currency)} ${
          value.currency
        }`,
        `${fixCurrencyAmount(+value.amount, value.currency_at_transaction)} ${
          value.currency
        }`,
        value.status,
        value.company.display_name,
        value.object_entity?.name,
        value.payment_type,
      ]);
      return acc;
    },
    [],
  );

  return { totalAmount, fields };
};

export const invoicesPdfHeaders = (typeInvoice: string) => {
  return [
    "Invoice number",
    "Invoice date",
    `${typeInvoice === "outgoing" ? "Issuing company" : ""}`,
    `${typeInvoice === "incoming" ? "Receiving company" : ""}`,
    "Total amount",
    "VAT %",
    "Cancelled",
  ];
};
export const invoicesFields = (rowData: Invoice[], typeInvoice: string) => {
  let totalAmount: { [value: string]: number } = {
    BGN: 0.0,
    EUR: 0.0,
    USD: 0.0,
    AVAX: 0.0,
    CHF: 0.0,
  };

  const fields = rowData.reduce(
    (acc: Array<Array<number | string | undefined>>, value) => {
      totalAmount[value.currency || 0] += Number(value.amount);

      acc.push([
        value.number,
        moment(value.date).format("DD/MM/YYYY"),
        `${
          typeInvoice === "incoming"
            ? ""
            : value?.issuer_company?.display_name ?? ""
        }`,
        `${
          typeInvoice === "outgoing"
            ? ""
            : value?.receiver_company?.display_name ?? ""
        }`,
        `${fixCurrencyAmount(Number(value.amount), value.currency as string)} ${
          value.currency
        }`,
        `${value.vat_percent} %`,
        value.cancelled ? "cancelled" : "",
      ]);
      return acc;
    },
    [],
  );

  return { totalAmount, fields };
};

export const invoiceOrdersPdfHeaders = () => {
  return [
    "Order id",
    "Order date",
    "Issuing company",
    "Order total",
    "Invoice status",
    "Reason to decline",
  ];
};
export const invoiceOrdersFields = (rowData: Order[]) => {
  let totalAmount: { [value: string]: number } = {
    BGN: 0.0,
    EUR: 0.0,
    USD: 0.0,
    AVAX: 0.0,
    CHF: 0.0,
  };

  const fields = rowData.reduce(
    (acc: Array<Array<number | string | undefined>>, value) => {
      const amount =
        value.product_price && value.quantity
          ? (+value.quantity * +value.product_price).toFixed(2)
          : 0.0;
      totalAmount[value.currency] += Number(amount);

      acc.push([
        value.id,
        moment(value.inserted_at).format("DD/MM/YYYY"),
        value.invoice_company?.display_name,
        `${fixCurrencyAmount(Number(amount), value.currency)} ${
          value.currency
        }`,
        value.request_invoice === "null"
          ? "Not requested"
          : value.request_invoice ?? undefined,
        value.invoice_reason ?? undefined,
      ]);
      return acc;
    },
    [],
  );

  return { totalAmount, fields };
};
