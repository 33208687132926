import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
} from "@mui/material";

import useFilterContext from "context/FilterContext";
import Company from "models/resources/company.model";

const GlobalCompanyFilter = () => {
  const { t } = useTranslation();
  const { options, handleDefaultCompanyIdChange } = useFilterContext();
  const [selectedCompany, setSelectedCompany] = useState<Company>();
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const handleCompanyOnChange = (
    event: ChangeEvent<any>,
    currentCompany: Company,
  ) => {
    setSelectedCompany(currentCompany);
    setInputValue(event.target.value);
  };

  useEffect(() => {
    handleDefaultCompanyIdChange(
      typeof selectedCompany?.id !== "undefined" ? selectedCompany.id : null,
    );
  }, [selectedCompany]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.width = "auto";
      inputRef.current.style.width = `${
        inputRef.current.scrollWidth + inputRef.current.scrollWidth * 0.1
      }px`;
    }
  }, [inputValue]);

  return (
    <Autocomplete
      sx={{ paddingRight: "40px" }}
      options={options?.companies.sort(
        (a: Company, b: Company) =>
          a.display_name?.localeCompare(b.display_name!)!,
      )}
      getOptionLabel={(company: Company) =>
        (company.display_name || company?.name)!
      }
      value={selectedCompany}
      onChange={(event, value) =>
        handleCompanyOnChange(event, value as Company)
      }
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          label={t("Filter by company")}
          inputRef={inputRef}
          sx={{ minWidth: 250 }}
          InputLabelProps={{
            style: {
              textTransform: "none",
              fontWeight: "normal",
              paddingLeft: "5px",
            },
          }}
          variant="outlined"
        />
      )}
    />
  );
};

export default GlobalCompanyFilter;
