import { Fragment } from "react";

import useLoader from "components/Loader/useLoader";

const Loader = () => {
  const { loading } = useLoader();

  return loading ? (
    <div className="loader-wrapper">
      <div className="loader" />
    </div>
  ) : (
    <Fragment />
  );
};

export default Loader;
