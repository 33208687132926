export const pageProps = [
  {
    componentName: "dashboardLayout",
    urlPath: "machines",
    userRoles: ["admin", "owner", "operator"],
  },
  {
    componentName: "dashboard",
    urlPath: "machines",
    userRoles: ["admin", "owner", "operator"],
  },
  {
    componentName: "locations",
    urlPath: "locations",
    userRoles: ["admin", "owner", "operator"],
    pageFilters: [
      {
        type: "multiselect",
        placeholder: "Company",
        option: "companyIds",
        urlPath: "companies",
        sorceIndex: 9,
      },
    ],
  },
  {
    componentName: "companies",
    urlPath: "companies",
    userRoles: ["admin", "owner"],
    tabs: ["companies", "waiting companies"],
    pageFilters: [
      {
        type: "select",
        placeholder: "Status",
        option: "companyStatus",
        choices: [
          { id: "all", label: "All" },
          { id: "approved", label: "Approved" },
          { id: "declined", label: "Declined" },
          { id: "waiting", label: "Waiting" },
        ],
        urlPath: "companies",
        sorceIndex: 9,
      },
    ],
  },
  {
    componentName: "invoices",
    urlPath: "orders",
    userRoles: ["admin", "owner", "operator", "technician"],
    tabs: ["requests", "issued"],
    pageFilters: [
      {
        type: "multiselect",
        placeholder: "Issuing Company",
        option: "issuingCompanyIds",
        urlPath: "companies",
        sorceIndex: 9,
      },
      {
        type: "multiselect",
        placeholder: "Receiving Company",
        option: "receivingCompanyIds",
        urlPath: "companies",
        sorceIndex: 9,
      },
      {
        type: "select",
        placeholder: "Status",
        option: "invoiceStatus",
        choices: [
          { id: "all", label: "All" },
          { id: "requested", label: "Requested" },
          { id: "issued", label: "Issued" },
          { id: "declined", label: "Declined" },
        ],
      },
    ],
  },
  {
    componentName: "vending/machine",
    urlPath: "machines",
    userRoles: ["admin", "owner", "operator", "technician"],
    pageFilters: [
      {
        type: "multiselect",
        placeholder: "Company",
        option: "companyIds",
        urlPath: "companies",
        sorceIndex: 9,
      },
      {
        type: "multiselect",
        placeholder: "Locations",
        option: "locationIds",
        urlPath: "locations",
        sorceIndex: 1,
      },
    ],
  },
  {
    componentName: "module",
    urlPath: "modules",
    userRoles: ["admin", "owner", "operator", "technician"],
    tabs: ["active", "deactivated", "deleted"],
    pageFilters: [
      {
        type: "multiselect",
        placeholder: "Company",
        option: "companyIds",
        urlPath: "companies",
        sorceIndex: 9,
      },
    ],
  },
  {
    componentName: "orders",
    urlPath: "orders",
    userRoles: ["admin", "owner"],
    pageFilters: [
      {
        type: "multiselect",
        placeholder: "Company",
        option: "companyIds",
        urlPath: "companies",
        sorceIndex: 9,
      },
      {
        type: "select",
        placeholder: "Payment Point type",
        option: "paymentPointType",
        choices: [
          {
            id: "machine",
            urlPath: "machines",
            label: "Machine",
            sorceIndex: 2,
          },
          { id: "qr", urlPath: "qr", label: "QR Code", sorceIndex: 4 },
          { id: "vpos", urlPath: "pos", label: "VPOS", sorceIndex: 3 },
        ],
      },
      {
        type: "multiselect",
        placeholder: "Payment Point",
        option: "paymentPoint",
      },
    ],
  },
  {
    componentName: "pos",
    urlPath: "pos",
    userRoles: ["admin", "owner", "operator"],
    pageFilters: [
      {
        type: "multiselect",
        placeholder: "Company",
        option: "companyIds",
        urlPath: "companies",
        sorceIndex: 9,
      },
      {
        type: "multiselect",
        placeholder: "Locations",
        option: "locationIds",
        urlPath: "locations",
        sorceIndex: 1,
      },
    ],
  },
  {
    componentName: "promotions",
    urlPath: "promotions",
    userRoles: ["admin", "owner", "operator"],
    pageFilters: [
      {
        type: "multiselect",
        placeholder: "Company",
        option: "companyIds",
        urlPath: "companies",
        sorceIndex: 9,
      },
    ],
  },
  {
    componentName: "qr",
    urlPath: "qrs",
    userRoles: ["admin", "owner", "operator", "technician"],
    pageFilters: [
      {
        type: "multiselect",
        placeholder: "Company",
        option: "companyIds",
        urlPath: "companies",
        sorceIndex: 9,
      },
      {
        type: "multiselect",
        placeholder: "Locations",
        option: "locationIds",
        urlPath: "locations",
        sorceIndex: 1,
      },
    ],
  },
  {
    componentName: "transactions",
    urlPath: "transactions",
    userRoles: ["admin", "owner"],
    pageFilters: [
      {
        type: "multiselect",
        placeholder: "Status",
        option: "transactionStatuses",
        choices: [
          { id: "PAID", label: "Paid" },
          { id: "TIMED_OUT", label: "Timed Out" },
          { id: "FAILED", label: "Failed" },
        ],
      },
      {
        type: "multiselect",
        placeholder: "Payment Types",
        option: "transactionPaymentTypes",
        choices: [
          { id: "averato", label: "Averato" },
          { id: "gift_card", label: "Gift Card" },
        ],
      },
      {
        type: "multiselect",
        placeholder: "Company",
        option: "companyIds",
        urlPath: "companies",
        sorceIndex: 9,
      },
      {
        type: "select",
        placeholder: "Payment Point type",
        option: "paymentPointType",
        choices: [
          {
            id: "machine",
            urlPath: "machines",
            label: "Machine",
            sorceIndex: 2,
          },
          { id: "qr", urlPath: "qr", label: "QR Code", sorceIndex: 4 },
          { id: "vpos", urlPath: "pos", label: "VPOS", sorceIndex: 3 },
        ],
      },
      {
        type: "multiselect",
        placeholder: "Payment Point",
        option: "paymentPoint",
      },
    ],
  },
  {
    componentName: "users",
    urlPath: "users",
    userRoles: ["admin", "owner"],
    pageFilters: [
      {
        type: "multiselect",
        placeholder: "Company",
        option: "companyIds",
        urlPath: "companies",
        sorceIndex: 9,
      },
      {
        type: "multiselect",
        placeholder: "Role",
        option: "roles",
        choices: [
          { id: "admin", label: "Administrator" },
          { id: "owner", label: "Company Owner" },
          { id: "operator", label: "Operator" },
          { id: "technician", label: "Technician" },
          { id: "end_user", label: "End User" },
        ],
      },
    ],
  },
  {
    componentName: "vending/products",
    userRoles: ["admin", "owner", "operator", "technician"],
  },
  {
    componentName: "vending/ingredients",
    userRoles: ["admin", "owner", "operator", "technician"],
  },
  {
    componentName: "vending/allergens",
    userRoles: ["admin", "owner", "operator", "technician"],
  },
  {
    componentName: "vending/blueprints",
    userRoles: ["admin", "owner", "operator", "technician"],
  },
  {
    componentName: "prepaid-cards",
    userRoles: ["admin", "owner", "operator", "technician"],
  },
  {
    componentName: "gift-cards",
    userRoles: ["admin", "owner", "operator", "technician", "end_user"],
  },
  {
    componentName: "entity-categories",
    userRoles: ["admin"],
  },
];
