import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import AccountCircle from "@mui/icons-material/AccountCircle";
import Edit from "@mui/icons-material/Edit";
import Key from "@mui/icons-material/Key";
import Logout from "@mui/icons-material/Logout";
import MailOutline from "@mui/icons-material/MailOutline";
import Phone from "@mui/icons-material/Phone";
import {
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Fade,
  Grid,
  Paper,
  Popper,
  PopperPlacementType,
  Tooltip,
  Typography,
} from "@mui/material";

import DynamicForm from "components/Forms/generic/DynamicForm";
import LanguageSelect from "components/LanguageSelect/LanguageSelect";
import ProfileEditForm from "components/ProfileEditForm/ProfileEditForm";
import DynamicDialog from "components/layouts/Dialog";
import useAuthContext from "context/AuthContext";
import { EditDialogState } from "models/components.model";

export default function UserPopper() {
  const { t } = useTranslation();
  const { user, logOut } = useAuthContext();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [placement, setPlacement] = useState<PopperPlacementType>();
  const [profileEditOpen, setProfileEditOpen] = useState<EditDialogState>({
    display: false,
  });
  const [changePasswordOpen, setChangePasswordOpen] = useState<EditDialogState>(
    {
      display: false,
    },
  );

  const changeStateToOpenOrClose =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };

  const handleProfileClose = () => setProfileEditOpen({ display: false });

  return (
    <Box>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
        //@ts-ignore
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
        sx={{ zIndex: 1200 }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Fade
              {...TransitionProps}
              timeout={350}
              style={{ marginTop: "20px" }}
            >
              <Paper sx={{ px: 2, py: 1 }}>
                <Typography variant="h6">{t("Signed as")}</Typography>
                <Divider />
                <Typography
                  variant="body2"
                  mt={1}
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <MailOutline
                    color="primary"
                    fontSize="small"
                    sx={{ marginRight: "15px" }}
                  />
                  {user?.email}
                </Typography>
                <Typography
                  variant="body2"
                  mb={1}
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Phone
                    color="primary"
                    fontSize="small"
                    sx={{ marginRight: "15px" }}
                  />
                  {user?.phone}
                </Typography>
                <Typography variant="subtitle2">
                  {user?.first_name} {user?.last_name}
                </Typography>
                <Divider />
                <Button
                  color="secondary"
                  size="small"
                  startIcon={<Edit />}
                  variant="contained"
                  onClick={() => setProfileEditOpen({ display: true })}
                  sx={{ marginTop: "10px", width: "100%" }}
                >
                  {t("Edit profile")}
                </Button>
                <Button
                  color="secondary"
                  size="small"
                  startIcon={<Key />}
                  variant="contained"
                  onClick={() => setChangePasswordOpen({ display: true })}
                  sx={{ marginTop: "10px", width: "100%" }}
                >
                  {t("Change password")}
                </Button>
                <Button
                  color="secondary"
                  size="small"
                  startIcon={<Logout />}
                  variant="contained"
                  onClick={logOut}
                  sx={{ marginTop: "10px", width: "100%" }}
                >
                  {t("Log out")}
                </Button>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
      {profileEditOpen.display && (
        <DynamicDialog
          hideActions={true}
          hideButton={true}
          title=""
          isOpen={true}
          exitAction={setProfileEditOpen}
          iconButton={<p className="icon icon-add" />}
          component={<ProfileEditForm onSubmit={handleProfileClose} />}
        />
      )}
      {changePasswordOpen.display && (
        <DynamicDialog
          hideActions={true}
          hideButton={true}
          title=""
          exitAction={setChangePasswordOpen}
          isOpen={true}
          iconButton={<p className="icon icon-add" />}
          component={
            <DynamicForm
              resource="change-password"
              mode="create"
              call="users/password"
              preventQuery={true}
              dynamicClass={() => "flexHalfForm nth-3 "}
            />
          }
        />
      )}
      <Grid container spacing={0} style={{ justifyContent: "flex-start" }}>
        <Grid item xs={0}>
          <Box sx={{ justifyContent: "flex-start" }}>
            <LanguageSelect />
          </Box>
        </Grid>
        <Grid item xs={0} style={{ display: "flex", alignItems: "center" }}>
          <Box alignItems="center">
            <Tooltip title={t(`User settings`)}>
              <Button onClick={changeStateToOpenOrClose("bottom-end")}>
                <AccountCircle />
              </Button>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
