import { CompanyBase } from "./company.model";
import GiftCard from "./gitfCard.model";
import Product from "./product.model";

export enum winConditionTypes {
  TotalSum = "total_sum",
  TotalRewards = "total_rewards",
  RewardsPerUser = "rewards_per_user",
  RewardsPerPayment = "rewards_per_payment",
  TimeOfDay = "time_of_day",
}

export type winConditions =
  | {
      type: "total_sum";
      currency: string;
      required_sum: string | undefined;
    }
  | {
      type: "time_of_day";
      from: string;
      to: string;
    }
  | { type: "total_rewards"; total_rewards: number | undefined }
  | { type: "rewards_per_user"; rewards_per_user: number | undefined }
  | {
      type: "rewards_per_payment";
      rewards_per_payment: number | undefined;
    };

interface Promotion {
  id: number;
  name: string;
  display_name: string;
  description: string | null;
  active: boolean;
  company_id: number;
  company: CompanyBase;
  gift_card_id: number;
  gift_card: GiftCard;
  valid_from: string;
  valid_to: string;
  win_conditions: winConditions[];
  products: Product[];
  won_promotion_counts: number;
}

export default Promotion;
