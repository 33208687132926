import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { type Crop } from "react-image-crop";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { companyImageTypes } from "utils/constants";

const useUploadLogo = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(false);
  const [image, setImage] = useState<string | null>(null);
  const [imageSrc, setImageSrc] = useState<string>("");
  const [crop, setCrop] = useState<Crop>({
    unit: "%", // Can be 'px' or '%'
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const [refetchFlag, setRefetchFlag] = useState<boolean>(false);
  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);

  const handleDialogShowChange = () => setShow((oldState) => !oldState);

  const cropImageSquared = () => {
    if (!imgRef.current) return;
    if (imgRef.current.width > imgRef.current.height) {
      setCrop({
        unit: "px", // Can be 'px' or '%'
        x: imgRef.current.width / 2 - imgRef.current.height / 2,
        y: 0,
        width: imgRef.current.height,
        height: imgRef.current.height,
      });
    } else {
      setCrop({
        unit: "px", // Can be 'px' or '%'
        x: 0,
        y: imgRef.current.height / 2 - imgRef.current.width / 2,
        width: imgRef.current.width,
        height: imgRef.current.width,
      });
    }
  };

  const handleSelectedImageOnChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      if (!companyImageTypes.includes(file.type)) {
        setShow(false);
        return toast.error(
          t("Allowed file formats can only be png, jpeg, svg or webp"),
        );
      }

      if (file.size > 5242880) {
        setShow(false);
        return toast.error(t("File cannot exceed 5MB"));
      }
      setImageSrc(URL.createObjectURL(file));
    }
  };

  const handleCropOnChange = (newCrop: Crop) => {
    setCrop({
      ...newCrop,
      height: newCrop.width,
    });
  };

  const cropImage = async () => {
    const canvas = previewCanvasRef.current;
    if (!canvas) return;
    const ctx = previewCanvasRef.current.getContext("2d");
    const image = imgRef.current;

    if (image === null) return;

    if (!ctx) return;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const pixelRatio = window.devicePixelRatio;
    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = "high";

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    const centerX = image.naturalWidth / 2;
    const centerY = image.naturalHeight / 2;
    // TODO: Check algorithm around diffrence between h and w.
    ctx.canvas.width = ctx.canvas.height;

    ctx.save();

    ctx.translate(-cropX, -cropY);
    ctx.translate(centerX, centerY);
    ctx.scale(1, 1);
    ctx.translate(-centerX, -centerY);

    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
    );

    ctx.restore();

    ctx.canvas.toBlob(async (blob) => {
      if (blob === null) return;

      const formData = new FormData();
      formData.append("image", blob, "logo.png");

      await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/companies/${id}/logo`,
        {
          method: "PUT",
          credentials: "include",
          body: formData,
        },
      ).then(() => setRefetchFlag((old) => !old));
    }, "image/png");
  };

  useEffect(() => {
    (async () => {
      await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/companies/${id}/logo`,
        {
          method: "GET",
          credentials: "include",
        },
      ).then(async (response) => {
        if (response.status === 404) {
          setImage(null);
          return;
        }
        setImage(URL.createObjectURL(await response.blob()));
      });
    })();
  }, [refetchFlag]);

  return {
    show,
    handleDialogShowChange,
    image,
    imageSrc,
    handleSelectedImageOnChange,
    crop,
    handleCropOnChange,
    imgRef,
    previewCanvasRef,
    cropImage,
    cropImageSquared,
  };
};

export default useUploadLogo;
