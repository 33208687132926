import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import IngredientForm from "components/IngredientForm";
import DynamicDialog from "components/layouts/Dialog";
import useDialogShowState from "hooks/useDialogShowState";

import IngredientsTable from "./IngredientsTable";
import useRecipeProductIngredients from "./useRecipeProductIngredients";
import { ProductIngredient } from "./utils";

const RecipeProductIngredients = ({
  companyId,
  editIngredients = null,
}: {
  companyId: number /* Used for filtering ingredients list by selected company. */;
  editIngredients?:
    | ProductIngredient[]
    | null /* Used for ingredients table for edit form. */;
}) => {
  const { t } = useTranslation();
  const {
    ingredientData,
    ingredientList,
    addIngredient,
    updateIngredient,
    removeIngredient,
  } = useRecipeProductIngredients(companyId, editIngredients);
  const { show, handleShowStateChange } = useDialogShowState();

  return (
    <Fragment>
      <DynamicDialog
        isOpen={show}
        hideActions={true}
        title={t("Add ingredient")}
        onCancel={handleShowStateChange}
        onClick={handleShowStateChange}
        component={
          <IngredientForm
            defaultOptions={ingredientList}
            submitBtnLabel="Add"
            onSubmit={(i) => {
              addIngredient(i);
              handleShowStateChange();
            }}
          />
        }
        openMessage={t("Add ingredient")}
      />
      {ingredientData.length > 0 && (
        <IngredientsTable
          ingredients={ingredientData}
          editIngredient={updateIngredient}
          deleteIngredient={removeIngredient}
        />
      )}
    </Fragment>
  );
};

export default RecipeProductIngredients;
