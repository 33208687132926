import { Dispatch, SetStateAction } from "react";
import { toast, ToastPosition } from "react-toastify";

import Company from "models/resources/company.model";
import Invoice from "models/resources/invoice.model";
import Order from "models/resources/order.model";
import PrepaidCard from "models/resources/prepaidCard.model";
import Transaction from "models/resources/transaction.model";
import { DatabaseEntity } from "models/responseData.model";
import apiClient from "services/api";

export const getOrderDataForInvoice = async (data: Invoice) => {
  return await apiClient<Order | undefined>({
    url: `/orders/${data.order_id}`,
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

export const getOrderDataForTransaction = async (data: {
  order_id: number;
}) => {
  const queryParams = {
    filter: {
      orderIds: [data.order_id],
    },
    pagination: { page: 1, perPage: 25 },
    sort: { field: "id", order: "DESC" },
  };

  return await apiClient<Transaction[]>({
    url: "transactions",
    method: "get",
    queryParams: queryParams,
  }).then((response) => {
    return response.data;
  });
};

export const RequestInvoice = async (
  data: Order | null,
  setData: (arg0: Order) => void,
  requestInvoice: any,
) => {
  await apiClient<Order>({
    url: `orders/${data?.id}/invoice`,
    bodyData: {
      invoice_company_id: requestInvoice,
      invoice_reason: data?.invoice_reason,
      request_invoice: "requested",
    },
    method: "put",
  }).then((response) => {
    if (response.statusCode === 200) {
      setData(response.data);
    }
  });
};

export const cancelInvoice = async (
  data: Invoice | undefined,
  setData: (arg0: Invoice) => void,
  t: (
    key: string | string[],
    props: { position?: ToastPosition; autoClose?: boolean },
  ) => string,
) => {
  let isCancelled = false;
  await apiClient<Invoice>({
    url: `invoices/${data?.id}`,
    method: "delete",
  }).then((response) => {
    if (response.statusCode === 204) {
      isCancelled = true;
    }
  });

  if (isCancelled) {
    await apiClient<Invoice>({
      url: `invoices/${data?.id}`,
      method: "get",
    }).then((response) => {
      if (response.statusCode === 200) {
        setData(response.data!);

        toast.success(
          t("Invoice cancelled successfully.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: false,
          }),
        );
      }
    });
  }
};

export const changeStatus = async (
  data: Company,
  t: (
    key: string | string[],
    props: { position?: ToastPosition; autoClose?: boolean },
  ) => string,
  newData: Dispatch<SetStateAction<Company>>,
  handleClose?: () => void,
) => {
  let success = false;

  await apiClient<DatabaseEntity>({
    url: `companies/${data.id}/status`,
    bodyData: {
      status: data.status === "approved" ? "declined" : "approved",
    },
    method: "put",
  })
    .then(() => {
      success = true;
      toast.success(
        t(
          `Company successfully ${
            data.status === "approved" ? "declined" : "approved"
          }.`,
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: false,
          },
        ),
      );
    })
    .catch(() =>
      toast.error(
        t("Unable to set company status.", {
          position: toast.POSITION.TOP_RIGHT,
        }),
      ),
    );
  if (success) {
    await apiClient<Company>({
      url: `companies/${data.id}`,
      method: "get",
    }).then((response) => {
      newData(response.data);
      if (handleClose) handleClose();
    });
  }
};
