import { useEffect, useRef, useState } from "react";

import {
  MultiSelectFilterProps,
  MultiSelectInputType,
  getMultiSelectFilters,
} from "components/MultiSelectFilter/utils";
import useDataContext from "context/DataContext";
import useFilterContext from "context/FilterContext";
import { FilterType } from "models/filterData.model";

const useMultiSelectFilter = ({
  fieldName,
  defaultValue,
  paymentPointChoice,
}: MultiSelectFilterProps) => {
  const { filter, handleFilterChange, options, defaultCompanyId } =
    useFilterContext();
  const { getPagePropsData } = useDataContext();
  const [multiSelectValues, setMultiSelectValues] = useState<{
    // Controlled MUI multiple autocomplete select component structure.
    values: MultiSelectInputType[]; // Handles the selected values.
    inputValue: string; // Handles the input value text in the field.
  }>({
    values: defaultValue || [],
    inputValue: "",
  });
  const initialRenderRef = useRef<boolean>(true);
  const invoiceStatusRef = useRef<string>(""); // Used for saving the last selected status on the requests tab for invoice pages.

  // TODO: Refactor logic around pageProps index when PagePropsContext is created in the future.
  const currentOptions = [
    options.machines, //0
    options.locations, //1
    options.machines, //0,2
    options.pos, //3
    options.qr, //4
    [], //5
    [], //6
    [], //7
    [], //8
    options.companies, //9
    [], //10
    [], //11
  ];

  const handleMultiSelectOnChange = (
    name: string,
    values: MultiSelectInputType[] | string,
  ) => {
    setMultiSelectValues((oldState) => ({
      ...oldState,
      [name]: values,
    }));

    if (typeof values === "string") return; // inputValue is string type. Stops triggering requests while typing in the input field.

    handleFilterChange(
      // TODO: Refactor in the future to avoid this reason.
      // Don't handle filters in useEffect hook, to avoid reseting them from the on invoice tab change handler.
      ...getMultiSelectFilters(
        fieldName,
        values,
        getPagePropsData.componentName.toLowerCase(),
        getPagePropsData.currentTab?.toLowerCase(),
        paymentPointChoice?.label.toLowerCase(),
      ),
    );
  };

  useEffect(() => {
    // Resets the input field on paymentPoint select change.
    if (fieldName !== "paymentPoint") return;

    setMultiSelectValues({ values: [], inputValue: "" });
  }, [paymentPointChoice?.sorceIndex]);

  useEffect(() => {
    // Removes selected normal company filter when global company filter is changed.
    if (
      ["companyIds", "issuingCompanyIds"].includes(fieldName) &&
      defaultCompanyId !== null
    ) {
      setMultiSelectValues({
        values: [],
        inputValue: "",
      });
    }
  }, [fieldName, defaultCompanyId]);

  useEffect(() => {
    // Handle filter management on tab change for the invoice pages.
    if (typeof getPagePropsData.currentTab === "undefined") return;

    if (getPagePropsData.currentTab === "issued") {
      invoiceStatusRef.current = filter.requestInvoice || "";
    }

    if (initialRenderRef.current) {
      // Skip initial render because it makes unnecessary api request, without implementing additional logic from this hook.
      initialRenderRef.current = false;
      return;
    }

    handleFilterChange(
      {
        type: FilterType.Company,
        value:
          defaultCompanyId !== null &&
          getPagePropsData.currentTab === "requests"
            ? [defaultCompanyId]
            : [],
      },
      { type: FilterType.ReceiverCompany, value: [] },
      { type: FilterType.InvoiceCompany, value: [] },
      {
        type: FilterType.Issuer,
        value:
          defaultCompanyId !== null && getPagePropsData.currentTab === "issued"
            ? [defaultCompanyId]
            : [],
      },
      {
        type: FilterType.RequiredInvoice,
        value:
          getPagePropsData.currentTab === "requests"
            ? invoiceStatusRef.current
            : "",
      },
    );

    if (getPagePropsData.currentTab === "issued") {
      handleFilterChange({
        type: FilterType.RequiredInvoice,
        value: "",
      });
    }

    // Reset filter input fields on tab change.
    setMultiSelectValues({
      values: [],
      inputValue: "",
    });
  }, [getPagePropsData.currentTab, defaultCompanyId]);

  return { multiSelectValues, handleMultiSelectOnChange, currentOptions };
};

export default useMultiSelectFilter;
