import { useState } from "react";

import { SelectChangeEvent } from "@mui/material";

import {
  QrDownloadFormat,
  QrSize,
  downloadQr,
} from "components/ModuleQr/utils";

interface ModuleQrFormFields {
  hasAmount: boolean;
  fixedAmount: number;
  size: QrSize;
  logo: string | null;
  downloadFormat: QrDownloadFormat;
}

const useModuleQR = () => {
  const [formValues, setFormValues] = useState<ModuleQrFormFields>({
    hasAmount: false,
    fixedAmount: 0,
    size: QrSize.x256,
    logo: null,
    downloadFormat: QrDownloadFormat.SVG,
  });

  const handleFormValuesOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const fieldName: string = event.target.name;
    let value: number | string | boolean;

    switch (fieldName) {
      case "fixedAmount":
        value = Math.abs(+event.target.value);
        break;
      case "hasAmount":
        value = event.target.checked;
        break;
      default:
        value = event.target.value;
        break;
    }

    setFormValues((oldFormValues) => ({
      ...oldFormValues,
      [fieldName]: value,
    }));
  };

  const handleSizeOnChange = (event: SelectChangeEvent<QrSize>) => {
    setFormValues((oldFormValues) => ({
      ...oldFormValues,
      size: event.target.value as QrSize,
    }));
  };

  const handleLogoOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const reader: FileReader = new FileReader();
    reader.onload = (e) => {
      setFormValues((oldFormValues) => ({
        ...oldFormValues,
        logo: e.target?.result?.toString()!, // TODO: add undefined check
      }));
    };

    if (event.target.files !== null) {
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleRemoveLogoOnClick = () => {
    setFormValues((oldFormValues) => ({
      ...oldFormValues,
      logo: null,
    }));
  };

  const handleDownloadOnClick = (name: string) => {
    downloadQr(name, formValues.downloadFormat);
  };

  return {
    formValues,
    handleFormValuesOnChange,
    handleSizeOnChange,
    handleLogoOnChange,
    handleRemoveLogoOnClick,
    handleDownloadOnClick,
  };
};

export default useModuleQR;
