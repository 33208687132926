export const createSchemeMatrix = (
  rowLength: number,
  colLength: number,
  buttonMappers: { row: number; column: number; value: string }[],
) => {
  let matrix: { value: string }[][] = [];

  for (let i = 0; i < rowLength; i++) {
    matrix[i] = [];
    for (let j = 0; j < colLength; j++) {
      matrix[i][j] = { value: "-" };

      const currentValue = buttonMappers.find(
        (bm) => bm.row === i + 1 && bm.column === j + 1,
      )?.value;

      if (typeof currentValue !== "undefined") {
        matrix[i][j] = { value: currentValue };
      }
    }
  }

  return matrix;
};
