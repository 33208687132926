import { useEffect, useState } from "react";

import {
  PaymentCredentialsTab,
  PaymentCredentialsTabForm,
  PaymentProvider,
  PaymentProviderCredentials,
  getSortedTabs,
} from "components/CompanyPaymentCredentials/utils";
import { CompanyPaymentCredentials } from "models/resources/paymentCredentials.model";
import apiClient from "services/api";

const usePaymentCredentials = (companyId: number) => {
  const addCredentialsTab: PaymentCredentialsTabForm = {
    tabIndex: PaymentCredentialsTab.ADD,
    paymentCredentialsForm: {
      currency: "",
    },
  };

  const [paymentProvider, setPaymentProvider] =
    useState<PaymentProvider | null>(null); // Default value is null for hiding the buttons on the first render, before setting the provider from the api request.
  const [currentTab, setCurrentTab] =
    useState<PaymentCredentialsTabForm>(addCredentialsTab);
  const [tabs, setTabs] = useState<PaymentCredentialsTabForm[]>([]);

  // Handler for creating new currency tabs or updating input values for existing ones.
  const handleTabsUpdate = (credentials: PaymentProviderCredentials) => {
    const { id, currency, ...credForm } = credentials;

    if (currentTab.tabIndex === PaymentCredentialsTab.ADD) {
      setCurrentTab(addCredentialsTab); // Remove form input values on tab create.

      setTabs((oldTabs): PaymentCredentialsTabForm[] =>
        getSortedTabs([
          {
            paymentCredentialId: id,
            tabIndex:
              PaymentCredentialsTab[
                credentials.currency as keyof typeof PaymentCredentialsTab
              ],
            paymentCredentialsForm: credForm,
          },
          ...oldTabs,
        ]),
      );

      return;
    }

    setTabs((oldTabs) =>
      oldTabs.map(
        (oldTab): PaymentCredentialsTabForm =>
          oldTab.paymentCredentialId === id
            ? {
                ...oldTab,
                paymentCredentialsForm: credForm,
              }
            : oldTab,
      ),
    );

    setCurrentTab((oldTab) => ({
      ...oldTab,
      paymentCredentialsForm: credForm,
    }));
  };

  const handleTabOnChange = (tabIndex: number) => {
    setCurrentTab(
      tabs.find((t) => t.tabIndex === tabIndex) || addCredentialsTab,
    );
  };

  const handleCurrentTabRemove = () => {
    setCurrentTab(addCredentialsTab);
    setTabs((oldTabs) =>
      oldTabs.filter((t) => t.tabIndex !== currentTab.tabIndex),
    );
  };

  const handleSelectProviderOnClick = (selectedProvider: PaymentProvider) => {
    setPaymentProvider(selectedProvider);
  };

  useEffect(() => {
    (async () => {
      await apiClient<CompanyPaymentCredentials>({
        url: `/companies/${companyId}/payment-credentials`,
        method: "get",
      }).then((response) => {
        const credentials: CompanyPaymentCredentials = response.data;
        const { paysera, sumup } = credentials;
        if (sumup.length > 0) {
          setPaymentProvider(PaymentProvider.Sumup);
        }

        if (paysera.length > 0) {
          setPaymentProvider(PaymentProvider.Paysera);
        }

        if (sumup.length === 0 && paysera.length === 0) {
          setPaymentProvider(PaymentProvider.None);
        }

        const tabsData: PaymentCredentialsTabForm[] = [
          /* Response format:
          {
            "avalanche":[
                {
                  "currency":"string",
                  "gift_card":"string"
                }
            ],
            "sumup":[
                {
                  "client_id":"string",
                  "client_secret":"string",
                  "currency":"string",
                  "id":0,
                  "merchant_code":"string"
                }
            ]
          } */
          ...Object.values(credentials)
            .map((credentialArr: PaymentProviderCredentials[]) =>
              credentialArr.map((cred): PaymentCredentialsTabForm => {
                const { id, currency, ...credForm } = cred;
                return {
                  tabIndex:
                    PaymentCredentialsTab[
                      currency as keyof typeof PaymentCredentialsTab
                    ],
                  paymentCredentialsForm: { ...credForm },
                  paymentCredentialId: id,
                };
              }),
            )
            .flat(1),
          addCredentialsTab,
        ];

        setTabs(getSortedTabs(tabsData));
      });
    })();
  }, [companyId]);

  return {
    paymentProvider,
    tabs,
    currentTab,
    handleTabsUpdate,
    handleTabOnChange,
    handleCurrentTabRemove,
    handleSelectProviderOnClick,
  };
};

export default usePaymentCredentials;
