import { Dispatch, SetStateAction } from "react";

import { Filter, RequestPagination } from "models/queryParams.model";
import { TransactionBehaviour } from "models/resources/transaction_behaviour.model";
import apiClient from "services/api";

export const getTransactionsBehaviourData = async (
  pagination: RequestPagination,
  setTransactionBehaviour: Dispatch<SetStateAction<TransactionBehaviour[]>>,
  filter: Filter,
) => {
  const { resourceDeleted, searchQuery, ...queryFilter } = filter;

  await apiClient<TransactionBehaviour[]>({
    url: "transactions/user-behaviour",
    queryParams: {
      filter: queryFilter,
      pagination: pagination,
    },
    method: "get",
  }).then((response) => setTransactionBehaviour(response.data));
};
