import { useEffect, useState } from "react";

import useFilterContext from "context/FilterContext";
import QueryParams, { RequestPagination } from "models/queryParams.model";
import Ingredient from "models/resources/ingredient.model";
import { ResponseDataTable } from "models/responseData.model";
import { getData } from "services/requests/listData";

const useIngredientTable = () => {
  const { filter } = useFilterContext();
  const [ingredientsData, setIngredientsData] =
    useState<ResponseDataTable<Ingredient[]>>();

  const setData = (queryParams: QueryParams, setDataLoading: any) =>
    getData(
      setIngredientsData,
      "ingredients",
      { ...queryParams, filter: { ...filter, productType: "recipe" } },
      setDataLoading,
    );

  return { ingredientsData, setData, setIngredientsData };
};

export default useIngredientTable;
