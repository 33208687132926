import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  AppBar,
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import ChangeCompanyStatus from "components/Actions/ChangeCompanyStatus";
import CompanyActions from "components/Actions/CompanyActions";
import InvoiceActions from "components/Actions/InvoiceActions";
import CompanyPaymentCredentials from "components/CompanyPaymentCredentials";
import {
  cancelInvoice,
  getOrderDataForTransaction,
  RequestInvoice,
} from "components/DetailsPage/requests";
import ConfirmDenyDialog from "components/Forms/custom/Dialogs/ConfirmDenyDialog";
import MdbSettingsForm from "components/Forms/custom/MdbSettingsForm";
import MapContainer from "components/MapContainer";
import ModuleActionsBar from "components/ModuleActions/ModuleActionsBar";
import ModuleQR from "components/ModuleQr";
import ExportSinglePDF from "components/PdfExport/invoiceExport";
import ProductTable from "components/Table/ItemsTable";
import TransactionsTable from "components/Table/TransactionsTable";
import DynamicDialog from "components/layouts/Dialog";
import useAuthContext from "context/AuthContext";
import useFilterContext from "context/FilterContext";
import { FieldDescriptor } from "models/fieldsEntities.model";
import Company from "models/resources/company.model";
import Module from "models/resources/module.model";
import Order from "models/resources/order.model";
import Transaction from "models/resources/transaction.model";
import User from "models/resources/user.model";
import { userCompanies } from "utils/UserCompanies";
import { findCompany } from "utils/findEntity";
import { parseLocations } from "utils/parseMapLocations";

export const FieldGenerator = ({
  data,
  setData,
  call,
  fields,
  orderForInvoice,
  inlineStyle = {},
}: {
  data: any;
  setData: any;
  call: string;
  fields: FieldDescriptor[];
  orderForInvoice?: Order;
  inlineStyle?: object;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [updateStatus, setUpdateStatus] = useState(data?.active);
  const { user } = useAuthContext();
  const { options } = useFilterContext();
  const { companies } = options;
  const [transactionForInvoice, setTransactionForInvoice] =
    useState<Transaction[]>();

  useEffect(() => {
    if (call === "invoices" && data?.cancelled === false) {
      (async () => {
        setTransactionForInvoice(await getOrderDataForTransaction(data));
      })();
    }
  }, []);

  const getAppBarActions = (resource: string) => {
    switch (resource) {
      case "invoice":
        return <InvoiceActions t={t} setData={setData} id={data?.id} />;
      case "company":
        return <CompanyActions t={t} setData={setData} data={data} />;
      default:
        break;
    }
  };

  return (
    <Box p={"20px"} sx={{ width: "100% !important" }}>
      {fields
        ?.filter((e: FieldDescriptor) => e.show)
        .map((e: FieldDescriptor, index: number) => {
          const globalStyle = {
            paddingRight: "48px",
            paddingBottom: "10px",
            paddingTop: "10px",
          };
          const labelStyle = {
            color: "grey",
            display: "inline",
            fontSize: "14px",
          };
          switch (e.type) {
            case "DescriptiveInfoText":
            case "InfoText":
              const descriptiveStyle =
                e.type == "DescriptiveInfoText"
                  ? {
                      display: "block",
                    }
                  : {};
              return (
                <Box
                  className="flex-row"
                  style={{
                    display: "inline-flex",
                    position: "relative",
                    flexDirection: "column",
                    ...globalStyle,
                    ...descriptiveStyle,
                  }}
                >
                  <label className="" style={labelStyle}>
                    {t(e.label)}
                  </label>
                  <Typography>
                    {e.value === null || e.value === "" ? "---" : e.value}
                  </Typography>
                </Box>
              );
            case "AppBar":
              return (
                <AppBar position="static" sx={{ marginBottom: "30px" }}>
                  <MuiAlert
                    elevation={6}
                    variant="filled"
                    severity="info"
                    action={getAppBarActions(e.value)}
                  >
                    {t(e.label)}
                  </MuiAlert>
                </AppBar>
              );
            case "Link":
              return (
                <Box
                  key={`${index}-${e.label}-${e.value}`}
                  sx={{
                    display: "inline-flex",
                    flexDirection: "column",
                    ...globalStyle,
                  }}
                >
                  <label style={labelStyle}>{t(e.label)}</label>
                  {e.value ? (
                    <Link
                      to={{
                        pathname: `/account/${e.route}/${e.id}`,
                      }}
                    >
                      {e.value}
                    </Link>
                  ) : (
                    "---"
                  )}
                </Box>
              );
            case "QR":
              return (
                <Box
                  key={`${index}-${e.label}`}
                  sx={{
                    display: "inline-flex",
                    flexDirection: "column",
                    ...globalStyle,
                  }}
                >
                  <label style={labelStyle}>{t(e.label)}</label>
                  <DynamicDialog
                    title={`${"QR code for module"} ${e.value}`}
                    component={
                      <ModuleQR
                        currency={data.currency}
                        moduleSerial={e.value}
                      />
                    }
                    buttonSx={{
                      padding: 0,
                      justifyContent: "left !important",
                    }}
                    iconButton={<VisibilityIcon />}
                    openMessage={"QR"}
                    hideActions
                  />
                </Box>
              );
            case "Icon":
              return (
                <div
                  key={`${index}-${e.label}`}
                  style={{
                    display: "inline-flex",
                    width: "231px!important",
                    flexDirection: "column",
                    ...globalStyle,
                  }}
                >
                  <label style={labelStyle}>{t(e.label)}</label>
                  {e.value}
                </div>
              );
            case "Table":
              return (
                <Box key={`${index}-${e.label}`} mb={4} style={{}}>
                  <Typography
                    sx={{
                      color: "rgba(0, 0, 0, 0.38)",
                      fontSize: "12px",
                    }}
                  >
                    {t(e.label)}
                  </Typography>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {e.headers?.map((e: string, index: number) => (
                          <TableCell
                            key={`${index}-${e}`}
                            sx={{
                              padding: "5px 0 5px 5px",
                            }}
                          >
                            {e}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>{e.columns}</TableBody>
                  </Table>
                </Box>
              );
            case "LinkTable":
              return (
                data?.transactions.length !== 0 && (
                  <Box mb={3}>
                    <TransactionsTable
                      orderIds={data.id}
                      findCompany={findCompany(data?.company?.id, companies)}
                    />
                  </Box>
                )
              );

            case "ProductsTable":
              return (
                data?.items?.length !== 0 && (
                  <Box mb={3}>
                    <ProductTable items={data.items} />
                  </Box>
                )
              );
            case "Button":
              const [isOpen, setOpen] = useState<boolean>(false);

              return (
                <Box>
                  <DynamicDialog
                    key={`${index}`}
                    hideActions={true}
                    openState={[isOpen, setOpen]}
                    title={e.text ?? ""}
                    openMessage={e.value}
                    titleSx={{ p: { fontSize: "30px" } }}
                    component={
                      <ConfirmDenyDialog
                        onConfirm={() => {
                          setOpen(false);
                          e.onClick?.();
                        }}
                        onDeny={() => {
                          setOpen(false);
                        }}
                      />
                    }
                  />
                </Box>
              );
            case "ChangeCompanyStatus":
              return (
                <Box style={globalStyle}>
                  <DynamicDialog
                    key={`${index}`}
                    hideActions={true}
                    title={""}
                    openMessage={e.value}
                    component={
                      <ChangeCompanyStatus
                        e={e}
                        t={t}
                        data={data}
                        newData={setData}
                      />
                    }
                  />
                </Box>
              );
            case "PaymentCredentials":
              return (
                <DynamicDialog
                  key={`${index}-${data}`}
                  title={t("Payment credentials")}
                  openMessage={t("Payment credentials")}
                  isOpen={false}
                  hideActions
                  component={<CompanyPaymentCredentials companyId={data?.id} />}
                />
              );
            case "MdbSettingsForm":
              return (
                <Box sx={{ ...globalStyle }} display={"inline-block"}>
                  <MdbSettingsForm
                    editId={data?.id}
                    key={`${index}-${data?.id}`}
                  />
                </Box>
              );
            // case "RequestInvoice":
            //   return (
            //     <DynamicDialog
            //       key={`${+data.id}`}
            //       hideActions={true}
            //       openMessage={t(e.label)}
            //       title={t(e.label)}
            //       component={
            //         <Box>
            //           <Select onChange={handleChange} sx={{ width: "80%" }}>
            //             {companies
            //               ?.sort((a: any, b: Company) =>
            //                 a?.display_name?.localeCompare(b?.display_name),
            //               )
            //               ?.filter((e) =>
            //                 userCompanies(user as User).includes(e.id),
            //               )
            //               ?.map((num: Company, index: number) => {
            //                 return (
            //                   <MenuItem
            //                     value={num.id}
            //                     key={`${index}-${e.label}-${e.value || "--"}`}
            //                   >
            //                     {num.display_name}
            //                   </MenuItem>
            //                 );
            //               })}
            //           </Select>
            //           <Button
            //             onClick={(event: any) =>
            //               RequestInvoice(data, setData, event.target.value)
            //             }
            //           >
            //             {t("Submit")}
            //           </Button>
            //         </Box>
            //       }
            //     />
            //   );
            case "ModuleActions":
              return (
                <ModuleActionsBar
                  key={`${+data.id}`}
                  moduleId={+data.id}
                  isActive={updateStatus}
                  hasCompany={data.company_id !== null}
                  setUpdateStatus={setUpdateStatus}
                  setData={setData as Dispatch<SetStateAction<Module>>}
                  tableData={data}
                />
              );
            case "LocationMap":
              return (
                <MapContainer
                  key={`${+data.id}`}
                  locations={
                    Array.isArray(data)
                      ? parseLocations(data)
                      : parseLocations([data])
                  }
                />
              );
            case "PDFExport":
              return (
                <ExportSinglePDF
                  invoiceData={data}
                  transactionData={transactionForInvoice}
                  orderData={orderForInvoice}
                />
              );
            case "ApiCredentials":
              return (
                <Button
                  variant="outlined"
                  onClick={() =>
                    navigate(`/account/companies/${data.id}/api-credentials`)
                  }
                >
                  {t("API Credentials")}
                </Button>
              );
            case "CustomComponent":
              return <Fragment key={index}>{e.value}</Fragment>;
            case "Wrap":
              return (
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>{e.value}</Box>
              );
            case "ImageFile":
              return e.value !== null ? (
                <Box
                  sx={{
                    display: "inline-block",
                  }}
                >
                  <InputLabel
                    sx={{
                      color: "rgba(0, 0, 0, 0.38)",
                      fontSize: "12px",
                      marginLeft: "7px",
                      maxHeight: "13px!important",
                    }}
                    variant="standard"
                  >
                    {t(e.label)}
                  </InputLabel>
                  <img src={e.value} style={e.inlineStyle}></img>
                </Box>
              ) : (
                <Fragment />
              );
            case "BlankSpace":
              return <div style={e.inlineStyle}></div>;
          }
        })}
    </Box>
  );
};
