import { useTranslation } from "react-i18next";

import {
  CustomTable,
  Column,
  TableSettings,
  DynamicDialogProps,
  CellValue,
} from "components/Table/Table";
import ContentLayout from "components/layouts/ContentLayout";
import EntityCategory from "models/resources/entityCategory.model";

import useEntityTable from "./useEntityTable";

const EntityTable = () => {
  const { t } = useTranslation();
  const {
    entityData,
    fetchPaginatedData,
    setEntityData,
    handleEntityOnDelete,
  } = useEntityTable();

  const columns: Column<EntityCategory>[] = [
    {
      label: t("Name"),
      name: "name",
      getData: (data): CellValue => data.name,
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "name" },
    },
    {
      label: t("Actions"),
      name: "actions",
      getData: (data): CellValue => data.id,
      show: () => {
        return true;
      },
      width: "5%",
      align: "center",
    },
  ];

  const tableOptions: TableSettings = {
    pagination: true,
    page: 0,
    rowsPerPage: 25,
    field: "name",
    order: "asc",
  };

  const dynamicDialogProps: DynamicDialogProps = {
    title: "Add Entity Category",
    resource: "entity-categories",
    call: "entity-categories",
    styles: () => "flexHalfForm",
  };

  return (
    <ContentLayout title={`${t("Entity Categories")}`}>
      <CustomTable
        title={t("Entity Categories")}
        tableData={entityData}
        fetchPaginatedData={fetchPaginatedData}
        setTableData={setEntityData}
        columns={columns}
        resource="entity-categories"
        call="entity-categories"
        tableOptions={tableOptions}
        exportProp={"entity-categories"}
        dynamicDialogProps={dynamicDialogProps}
        isDynamic={true}
        notCSV={true}
        clickableRow={false}
        onDelete={handleEntityOnDelete}
      />
    </ContentLayout>
  );
};

export default EntityTable;
