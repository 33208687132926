import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { TableCell, TableRow } from "@mui/material";

import { CustomDetailsPage } from "components/DetailsPage/DetailsPage";
import ContentLayout from "components/layouts/ContentLayout";
import { FieldDescriptor } from "models/fieldsEntities.model";
import POSModel from "models/resources/pos.model";
import { getDataById } from "services/requests/listData";

const PosDetails = () => {
  const [posData, setPOSData] = useState<POSModel | null>(null);
  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getDataById(setPOSData, `pos/${id}`);
    }
  }, [id]);

  const fields: FieldDescriptor[] = [
    {
      value: posData?.name,
      label: "POS name",
      type: "InfoText",
      show: true,
    },
    {
      value: posData?.currency,
      label: "Currency",
      type: "InfoText",
      show: true,
    },
    {
      value: posData?.entity_category?.name,
      label: "Entity Category",
      type: "InfoText",
      show: true,
    },
    {
      value:
        posData?.location !== null
          ? `${posData?.location.name}, ${posData?.location.address}`
          : "---",
      id: posData?.location_id,
      label: "Location",
      type: posData?.location !== null ? "Link" : "TextField",
      route: "locations",
      show: true,
    },
    {
      value:
        posData?.client?.state === "up" ? (
          <FiberManualRecordIcon sx={{ color: "#4CAF50" }} />
        ) : (
          <FiberManualRecordIcon sx={{ color: "#FF6347" }} />
        ),
      label: "VPOS State",
      type: "Icon",
      show: true,
    },
    {
      value: posData?.company.display_name || posData?.company.name,
      id: posData?.company?.id,
      label: "Company",
      type: "Link",
      route: "companies",
      show: true,
    },
    {
      value: posData?.users_pos,
      label: "Associated users",
      type: "Table",
      show: true,
      headers: [t("First name"), t("Last name")],
      columns: posData?.users_pos?.map((e: any, i: number) => {
        return (
          <TableRow key={`${i}-${e.email}`}>
            <TableCell sx={{ padding: "5px 0 0 5px" }}>
              {e.first_name}
            </TableCell>
            <TableCell sx={{ padding: "5px 0 0 5px" }}>{e.last_name}</TableCell>
          </TableRow>
        );
      }),
    },
    {
      value: posData?.comments,
      label: "Comments",
      type: "InfoText",
      show: true,
    },
  ];
  return (
    <ContentLayout
      title={`${t("Virtual POS")} > ${posData?.name ?? ""}`}
      filters={false}
    >
      <CustomDetailsPage
        data={posData}
        setData={setPOSData}
        resource={"pos"}
        call={"pos"}
        fields={fields}
      />
    </ContentLayout>
  );
};

export default PosDetails;
