import { Card } from "@mui/material";

import Instructions from "components/Advertisement/Instructions";
import MachineActions from "components/Advertisement/MachineActions";
import MachinesOnMap from "components/Advertisement/MachinesOnMap";

interface AdvertisementProps {
  moduleSerial: string;
}
// TODO: Change design for advertisement.
const Advertisement = ({ moduleSerial }: AdvertisementProps) => {
  return (
    <Card variant="outlined" sx={{ padding: "20px", position: "relative" }}>
      <Instructions />
      <MachineActions moduleSerial={moduleSerial} />
      <MachinesOnMap />
    </Card>
  );
};

export default Advertisement;
