import { View, StyleSheet } from "@react-pdf/renderer";

import TableBody from "components/PdfExport/invoiceExport/TableBody";
import TableHeader from "components/PdfExport/invoiceExport/TableHeader";
import Invoice from "models/resources/invoice.model";
import Order from "models/resources/order.model";
import Transaction from "models/resources/transaction.model";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
});

interface Props {
  invoiceData: Invoice;
  transactionData: Transaction[] | undefined;
  orderData: Order | undefined;
}

const Table = ({ invoiceData, transactionData, orderData }: Props) => {
  return (
    <View style={styles.tableContainer}>
      <TableHeader />
      <TableBody
        invoiceData={invoiceData}
        orderData={orderData}
        transactionData={transactionData}
      />
    </View>
  );
};

export default Table;
