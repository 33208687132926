import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { CustomDetailsPage } from "components/DetailsPage/DetailsPage";
import ContentLayout from "components/layouts/ContentLayout";
import LocationModel from "models/resources/location.model";
import { getDataById } from "services/requests/listData";

const LocationDetails = () => {
  const [locationData, setLocationData] = useState<LocationModel>();
  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getDataById(setLocationData, `locations/${id}`);
    }
  }, [id]);

  const fields = [
    {
      value: locationData?.name,
      label: "Location name",
      type: "InfoText",
      show: true,
    },
    {
      value: locationData?.address,
      label: "Address",
      type: "InfoText",
      show: true,
    },
    {
      value: locationData?.company.display_name || locationData?.company.name,
      id: locationData?.company.id,
      label: "Company",
      type: "Link",
      route: "companies",
      show: true,
    },
    {
      value: "",
      label: "",
      type: "LocationMap",
      show: true,
    },
    {
      value: locationData?.latitude,
      label: "Latitude",
      type: "InfoText",
      show: true,
    },
    {
      value: locationData?.longitude,
      label: "Longitude",
      type: "InfoText",
      show: true,
    },
  ];

  return (
    <ContentLayout
      title={`${t("Locations")} > ${locationData?.name ?? ""}`}
      filters={false}
    >
      <CustomDetailsPage
        data={locationData}
        setData={setLocationData}
        resource={"location"}
        call={"locations"}
        fields={fields}
        hideEditByRole={["operator"]}
      />
    </ContentLayout>
  );
};

export default LocationDetails;
