import { useTranslation } from "react-i18next";

import { CustomDetailsPage } from "components/DetailsPage/DetailsPage";
import ContentLayout from "components/layouts/ContentLayout";

import useIngredeientDetails from "./useIngredientDetails";

const IngredientDetails = () => {
  const { t } = useTranslation();
  const { ingredientData, setIngredientData } = useIngredeientDetails();

  const fields = [
    {
      value: ingredientData?.name,
      label: "Name",
      type: "InfoText",
      show: true,
    },
    {
      //@ts-ignore
      value: t(ingredientData?.unit_name),
      label: "Measurement unit",
      type: "InfoText",
      show: true,
    },
    {
      value:
        ingredientData?.company.display_name || ingredientData?.company.name,
      id: ingredientData?.company_id,
      label: "Company",
      type: "Link",
      route: "companies",
      show: true,
    },
    {
      value:
        ingredientData?.allergens?.length === 0
          ? "---"
          : ingredientData?.allergens
              ?.map((a: { name: string }) => a.name)
              .join(", "),
      label: "Allergens",
      type: "InfoText",
      show: true,
    },
  ];

  return (
    <ContentLayout
      title={`${t("Ingredients")} > ${ingredientData?.name ?? ""}`}
      filters={false}
    >
      <CustomDetailsPage
        data={ingredientData}
        setData={setIngredientData}
        resource={"ingredient"}
        call={"ingredients"}
        fields={fields}
      />
    </ContentLayout>
  );
};

export default IngredientDetails;
