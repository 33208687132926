import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import LineChart from "components/Chart/LineChart";
import {
  CellValue,
  Column,
  CustomTable,
  TableSettings,
} from "components/Table/Table";
import ContentLayout from "components/layouts/ContentLayout";
import useFilterContext from "context/FilterContext";
import QueryParams, { RequestPagination } from "models/queryParams.model";
import {
  default as Order,
  default as OrderModel,
} from "models/resources/order.model";
import { TransactionBehaviour } from "models/resources/transaction_behaviour.model";
import { ResponseDataTable } from "models/responseData.model";
import apiClient from "services/api";
import { getData } from "services/requests/listData";
import { getLocalizedDate, getLocalizedTime } from "utils/lozalizeDateTime";
import { getTransactionsBehaviourData } from "utils/transactionBehaviour";

const OrderTable = () => {
  const [getOrdersData, setOrdersData] =
    useState<ResponseDataTable<OrderModel[]>>();
  const [getTransactionsBehaviour, setTransactionBehaviour] = useState<
    TransactionBehaviour[]
  >([]);
  const { filter, handleFilterChange } = useFilterContext();
  const { t } = useTranslation();

  const setData = (queryParams: QueryParams, setDataLoading: any) => {
    getData(setOrdersData, "orders", queryParams, setDataLoading);
    getTransactionsBehaviourData(
      queryParams.pagination!,
      setTransactionBehaviour,
      filter,
    );
  };
  const columns: Column<Order>[] = [
    {
      label: t("#"),
      name: "#",
      getData: (data): CellValue => data.id,
      show: () => {
        return true;
      },
    },
    {
      label: t("Order Date"),
      name: "transactionsDate",
      getData: (data): CellValue => getLocalizedDate(data.inserted_at),
      show: () => {
        return true;
      },
    },
    {
      label: t("Order Time"),
      name: "transactionsTime",
      getData: (data): CellValue => getLocalizedTime(data.inserted_at),
      show: () => {
        return true;
      },
    },
    {
      label: t("Order items"),
      name: "productItems",

      getData: (data): CellValue =>
        //@ts-ignore
        data.items.map((x) => x?.product?.name).join(", ") ?? "---",
      show: () => {
        return true;
      },
      sort: { isSortable: false, field: "" },
      align: "left",
    },

    {
      label: t("Order total"),
      name: "orderTotal",
      getData: (data): CellValue =>
        data.items
          ? `${data.items
              ?.map((x) => parseFloat(x.price))
              ?.reduce((a, b) => a + b, 0)
              .toFixed(2)}  ${t(data.currency)}`
          : "",
      show: () => {
        return true;
      },
    },
    {
      label: t("Status"),
      name: "status",
      getData: (data): CellValue => (data.status ? t(data.status) : "---"),
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "status" },
    },
    {
      label: t("Origin"),
      name: "origin",
      //@ts-ignore
      getData: (data): CellValue => (data.origin ? t(data.origin) : "---"),
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "origin" },
    },
    {
      label: t("Company"),
      name: "company",
      getData: (data): CellValue => (
        <Link
          to={{
            pathname: `/account/companies/${data.company_id}`,
          }}
        >
          {data.company.display_name || data.company.name}
        </Link>
      ),
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "company_id" },
    },
    {
      label: t("Payment point"),
      name: "paymentPoint",
      getData: (data): CellValue => data.object_entity?.name,
      show: () => {
        return true;
      },
      sort: {
        isSortable: true,
        field: "object_entity.name",
      },
    },
  ];

  const tableOptions: TableSettings = {
    pagination: true,
    page: 0,
    rowsPerPage: 25,
    field: "id",
    order: "asc",
  };

  return (
    <ContentLayout title={t("Orders")} isDateRanged>
      <LineChart
        dataArray={getTransactionsBehaviour || []}
        dateRange={filter.dateRange}
      />
      <CustomTable
        isSearchable={false}
        title={t("Orders")}
        tableData={getOrdersData}
        fetchPaginatedData={setData}
        setTableData={setOrdersData}
        columns={columns}
        tableOptions={tableOptions}
        call="orders"
        resource={"Order"}
        exportProp={"orders"}
        hadPDF={true}
      />
    </ContentLayout>
  );
};

export default OrderTable;
