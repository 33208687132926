import React from "react";
import { Chart } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ArcElement,
  Title,
  PieController,
  ChartTypeRegistry,
} from "chart.js";

import { Box, Card, Typography } from "@mui/material";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ArcElement,
  Title,
  PieController,
);

interface DynamicChart {
  type?: string;
  data?: any;
  options?: any;
  mainHeader?: boolean;
  total?: number;
  currency?: string;
  revenueByCurrency?: string;
  totalPaid?: number;
  totalFailed?: number;
}

function DynamicChart({
  type,
  data,
  options,
  mainHeader,
  total,
  currency,
  revenueByCurrency,
  totalPaid,
  totalFailed,
}: DynamicChart) {
  const { t } = useTranslation();

  return (
    <Card
      variant="outlined"
      sx={{ height: "40vh", padding: "20px", position: "relative" }}
    >
      {mainHeader && (
        <Box sx={{ position: "absolute", top: "30px", left: "40px" }}>
          {typeof total !== "undefined" && (
            <Typography variant="h4" sx={{ fontSize: "1.5rem" }}>
              {t("Total Sales")}
              <Typography
                variant="h3"
                component="span"
                color="primary"
                ml={1}
                sx={{ fontSize: "2rem" }}
              >
                {total.toFixed(2)} {t(`${currency}`)}
              </Typography>
            </Typography>
          )}
          {totalPaid! > 0 && (
            <Typography variant="h4" sx={{ fontSize: "1rem" }}>
              {`${t("Paid transactions for period selected")} :`}
              <Typography
                variant="h3"
                component="span"
                color="#4CAF50"
                ml={1}
                sx={{ fontSize: "1rem" }}
              >
                {totalPaid}
              </Typography>
            </Typography>
          )}

          {totalFailed! > 0 && (
            <Typography variant="h4" sx={{ fontSize: "1rem" }}>
              {`${t("Failed / timed-out transactions")} :`}
              <Typography
                variant="h3"
                component="span"
                color="#FF6347"
                ml={1}
                sx={{ fontSize: "1rem" }}
              >
                {totalFailed}
              </Typography>
            </Typography>
          )}
          {total! > 0 && (
            <Typography sx={{ fontSize: "0.8em" }}>
              {revenueByCurrency && t("Revenue by currency")}:{" "}
              {revenueByCurrency}
            </Typography>
          )}
        </Box>
      )}

      <Chart
        type={type as keyof ChartTypeRegistry}
        options={options}
        data={data}
      />
    </Card>
  );
}

export default DynamicChart;
