import { useState } from "react";

import useFilterContext from "context/FilterContext";
import QueryParams from "models/queryParams.model";
import Blueprint from "models/resources/blueprint.model";
import { ResponseDataTable } from "models/responseData.model";
import { getData } from "services/requests/listData";

const useBlueprintTable = () => {
  const { filter } = useFilterContext();
  const [blueprintData, setBlueprintData] =
    useState<ResponseDataTable<Blueprint[]>>();

  const setData = (queryParams: QueryParams, setDataLoading: any) =>
    getData(
      setBlueprintData,
      "machine-blueprints",
      { ...queryParams, filter },
      setDataLoading,
    );

  return { blueprintData, setBlueprintData, setData };
};

export default useBlueprintTable;
