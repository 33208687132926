import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import CircleIcon from "@mui/icons-material/Circle";
import { TableRow, TableCell } from "@mui/material";

import { CustomDetailsPage } from "components/DetailsPage/DetailsPage";
import ContentLayout from "components/layouts/ContentLayout";
import { FieldDescriptor } from "models/fieldsEntities.model";
import { winConditionTypes } from "models/resources/promotion.model";
import { getLocalizedDate } from "utils/lozalizeDateTime";

import usePromotionDetails from "./usePromotionDetails";

const PromotionDetails = () => {
  const { promotionData, setPromotionData } = usePromotionDetails();
  const { t } = useTranslation();

  if (!promotionData) return <Fragment />;
  const fields: FieldDescriptor[] = [
    {
      value: promotionData?.name,
      label: t("Name"),
      type: "InfoText",
      show: true,
    },
    {
      value: promotionData?.display_name,
      label: t("Display Name"),
      type: "InfoText",
      show: true,
    },
    {
      value: promotionData.company.display_name || promotionData.company.name,
      id: promotionData?.company_id,
      label: "Company",
      type: "Link",
      route: "companies",
      show: true,
    },
    {
      value: getLocalizedDate(promotionData?.valid_from),
      label: "Valid from",
      type: "InfoText",
      show: true,
    },
    {
      value: getLocalizedDate(promotionData?.valid_to),
      label: t("Valid to"),
      type: "InfoText",
      show: true,
    },
    {
      value: (
        <CircleIcon
          sx={{ color: promotionData.active ? "#4CAF50" : "#FF6347" }}
        />
      ),
      label: t("Public"),
      type: "Icon",

      show: true,
    },
    {
      value: promotionData?.gift_card.name,
      route: "gift-cards",
      id: promotionData?.gift_card_id,
      label: "Prize",
      show: true,
      type: "Link",
    },
    {
      value: promotionData?.description,
      label: "Description",
      type: "DescriptiveInfoText",
      show: true,
    },
    {
      value: "",
      label: "",
      type: "BlankSpace",
      show: true,
      inlineStyle: {
        margin: "20px",
      },
    },
    {
      label: t("Win conditions - total sum"),
      value: "win_conditions",
      type: "Table",
      show: true,
      headers: [t("Currency"), t("Required sum")],
      columns: promotionData?.win_conditions
        .filter(
          (win_condition) => win_condition.type === winConditionTypes.TotalSum,
        )
        .map((e: any, i: any) => {
          return (
            <TableRow key={`${i}-${e.id}`}>
              <TableCell sx={{ padding: "5px 0 0 5px", width: "10%" }}>
                {t(e.currency)}
              </TableCell>
              <TableCell sx={{ padding: "5px 0 0 5px" }}>
                {e.required_sum}
              </TableCell>
            </TableRow>
          );
        }),
    },
    {
      label: t("Win conditions - total rewards"),
      value: "win_conditions",
      type: "Table",
      show: true,
      headers: [t("Total rewards")],
      columns: promotionData?.win_conditions
        .filter(
          (win_condition) =>
            win_condition.type === winConditionTypes.TotalRewards,
        )
        .map((e: any, i: any) => {
          return (
            <TableRow key={`${i}-${e.id}`}>
              <TableCell sx={{ padding: "5px 0 0 5px", width: "10%" }}>
                {t(e.total_rewards)}
              </TableCell>
            </TableRow>
          );
        }),
    },
    {
      label: t("Win conditions - rewards per user"),
      value: "win_conditions",
      type: "Table",
      show: true,
      headers: [t("Rewards per user")],
      columns: promotionData?.win_conditions
        .filter(
          (win_condition) =>
            win_condition.type === winConditionTypes.RewardsPerUser,
        )
        .map((e: any, i: any) => {
          return (
            <TableRow key={`${i}-${e.id}`}>
              <TableCell sx={{ padding: "5px 0 0 5px", width: "10%" }}>
                {t(e.rewards_per_user)}
              </TableCell>
            </TableRow>
          );
        }),
    },
    {
      label: t("Win conditions - rewards per payment"),
      value: "win_conditions",
      type: "Table",
      show: true,
      headers: [t("Rewards per payment")],
      columns: promotionData?.win_conditions
        .filter(
          (win_condition) =>
            win_condition.type === winConditionTypes.RewardsPerPayment,
        )
        .map((e: any, i: any) => {
          return (
            <TableRow key={`${i}-${e.id}`}>
              <TableCell sx={{ padding: "5px 0 0 5px", width: "10%" }}>
                {t(e.rewards_per_payment)}
              </TableCell>
            </TableRow>
          );
        }),
    },
  ];

  return (
    <ContentLayout
      title={`${t("Promotions")} > ${promotionData?.name ?? ""}`}
      filters={false}
    >
      <CustomDetailsPage
        isDeletable={true}
        data={promotionData}
        setData={setPromotionData}
        resource={"promotion"}
        call={"promotions"}
        fields={fields}
      />
    </ContentLayout>
  );
};

export default PromotionDetails;
