import { Dispatch, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastPosition, toast } from "react-toastify";

import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import DynamicDialog from "components/layouts/Dialog";
import ConfirmActionDialog from "components/layouts/Dialog/ConfirmActionDialog";
import Invoice from "models/resources/invoice.model";
import Order from "models/resources/order.model";
import apiClient from "services/api";

type InvoiceRequestResolution = "issue" | "reject";

const resolveInvoiceRequest = async (
  action: InvoiceRequestResolution,
  setData: Dispatch<SetStateAction<Invoice>>,
  id: number,
  t: (
    key: string | string[],
    options?: { position?: ToastPosition; autoClose?: boolean },
  ) => string,
  declineRequestReason: string | undefined = undefined,
) => {
  const navigate = useNavigate();
  if (action === "issue") {
    await apiClient({
      url: "/invoices",
      bodyData: {
        order_id: id,
      },
      method: "post",
    })
      .then(() => {
        navigate("/account/orders");
        toast.success(
          t("Invoice issued successfully.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: false,
          }),
        );
      })
      .catch(() =>
        toast.error(
          t("Invoice issuing failed.", { position: toast.POSITION.TOP_RIGHT }),
        ),
      );
  } else {
    await apiClient<Order>({
      url: `orders/${id}/invoice`,
      bodyData: {
        request_invoice: "declined",
        invoice_reason: declineRequestReason,
      },
      method: "put",
    })
      .then((response) => {
        setData(response.data);
        navigate("/account/orders");
        toast.success(
          t("Invoice request declined successfully.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: false,
          }),
        );
      })
      .catch(() =>
        toast.error(
          t("Unable to decline invoice request.", {
            position: toast.POSITION.TOP_RIGHT,
          }),
        ),
      );
  }
};

interface InvoiceActionsProps {
  t: (
    key: string | string[],
    options?: { position?: ToastPosition; autoClose?: boolean },
  ) => string;
  setData: any;
  id: number;
}

const InvoiceActions = ({ t, setData, id }: InvoiceActionsProps) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [declineRequestReason, setDeclineRequestReason] = useState("");

  return (
    <>
      {showConfirmation && (
        <DynamicDialog
          isOpen={true}
          hideActions={true}
          openMessage={""}
          title={"Why would you like to decline this request?"}
          component={
            <ConfirmActionDialog
              confirm={() =>
                resolveInvoiceRequest(
                  "reject",
                  setData,
                  id,
                  t,
                  declineRequestReason,
                )
              }
              decline={setShowConfirmation}
              useInput={true}
              inputValueSetter={setDeclineRequestReason}
              t={t}
            />
          }
        />
      )}
      <IconButton
        onClick={() => resolveInvoiceRequest("issue", setData, id, t)}
        size="small"
        sx={{ color: "black" }}
      >
        <DoneIcon></DoneIcon>
        <Typography variant="button" display="block">
          {t("Issue")}
        </Typography>
      </IconButton>
      <IconButton
        onClick={() => setShowConfirmation(true)}
        size="small"
        sx={{ color: "black" }}
      >
        <ClearIcon></ClearIcon>
        <Typography variant="button" display="block">
          {t("Decline")}
        </Typography>
      </IconButton>
    </>
  );
};

export default InvoiceActions;
