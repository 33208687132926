import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  CellValue,
  Column,
  CustomTable,
  DynamicDialogProps,
  TableSettings,
} from "components/Table/Table";
import ContentLayout from "components/layouts/ContentLayout";
import QueryParams from "models/queryParams.model";
import Location from "models/resources/location.model";
import User from "models/resources/user.model";
import { getData } from "services/requests/listData";

import useLocationTable from "./useLocationTable";

const LocationTable = () => {
  const { t } = useTranslation();
  const { locationData, setLocationsData } = useLocationTable();

  const setData = (queryParams: QueryParams, setLoadingData: any) =>
    getData(setLocationsData, "locations", queryParams, setLoadingData);

  const columns: Column<Location>[] = [
    {
      label: t("Location Name"),
      name: "locationName",
      getData: (data): CellValue => data.name,
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "name" },
      width: "20%",
      minWidth: "200px",
    },
    {
      label: t("Address"),
      name: "address",
      getData: (data): CellValue => data.address,
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "address" },
      width: "20%",
      minWidth: "200px",
    },
    {
      label: t("Company"),
      name: "company",
      getData: (data): CellValue => (
        <Link
          to={{
            pathname: `/account/companies/${data.company.id}`,
          }}
        >
          {data.company.display_name || data.company.name}
        </Link>
      ),
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "company_id" },
      width: "15%",
      minWidth: "200px",
    },
    {
      label: t("Latitude"),
      name: "latitude",
      getData: (data): CellValue => data.latitude,
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "latitude" },
      width: "20%",
      minWidth: "200px",
    },
    {
      label: t("Longitude"),
      name: "longitude",
      getData: (data): CellValue => data.longitude,
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "longitude" },
      width: "20%",
      minWidth: "200px",
    },
    {
      label: t("Edit"),
      name: "edit",
      getData: (data): CellValue => data.id,
      show: (user: User | undefined) => {
        return user?.role?.type === "admin" || user?.role?.type === "owner";
      },
      width: "5%",
    },
  ];

  const tableOptions: TableSettings = {
    pagination: true,
    page: 0,
    rowsPerPage: 25,
    field: "id",
    order: "asc",
  };

  const dynamicDialogProps: DynamicDialogProps = {
    title: "Add location",
    resource: "location",
    call: "locations",
    styles: () => "locationCreateForm nth-3 nth-4 nth-5",
  };

  return (
    <ContentLayout title={t("Locations")}>
      <CustomTable
        title={t("Locations")}
        resource="location"
        call="locations"
        tableData={locationData}
        fetchPaginatedData={setData}
        setTableData={setLocationsData}
        columns={columns}
        tableOptions={tableOptions}
        dynamicDialogProps={dynamicDialogProps}
        isDynamic={true}
        exportProp="locations"
        locations={true}
      />
    </ContentLayout>
  );
};

export default LocationTable;
