import { useState } from "react";

import useFilterContext from "context/FilterContext";
import QueryParams from "models/queryParams.model";
import GiftCard from "models/resources/gitfCard.model";
import { ResponseDataTable } from "models/responseData.model";
import { getData } from "services/requests/listData";

const useGiftCardTable = () => {
  const { filter, options } = useFilterContext();
  const [giftCardsData, setGiftCardsData] = useState<
    ResponseDataTable<GiftCard[]>
  >({
    data: [],
  });

  const companyIds = options.companies.map(({ id }) => id);
  const defaultQueryParams = {
    sort: {
      field: "name",
      order: "ASC",
    },
  };

  const fetchPaginatedData = async (
    queryParams: QueryParams,
    setDataLoading: any,
  ) => {
    getData(
      setGiftCardsData,
      "gift-cards",
      {
        ...queryParams,
        ...defaultQueryParams,
        filter: {
          companyIds: filter.companyIds ? filter.companyIds : companyIds,
        },
      },
      setDataLoading,
    );
  };

  return { giftCardsData, setGiftCardsData, fetchPaginatedData };
};

export default useGiftCardTable;
