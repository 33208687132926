import { useState } from "react";
import { Outlet } from "react-router-dom";
import { toast } from "react-toastify";

import { Box, CssBaseline } from "@mui/material";

import SideBar from "components/Sidebar";
import { DataContextProvider } from "context/DataContext";

// TODO: Refactor component.
const Layout = () => {
  // Clear notifications on page change.
  toast.dismiss();
  toast.clearWaitingQueue();
  const [isDrawerOppened, setIsDrawerOppened] = useState(true);
  const [drawerWidth, setDrawerWidth] = useState(0);
  const getDrawerWidth = (width: number) => setDrawerWidth(width);
  const checkState = (state: boolean) => setIsDrawerOppened(state);

  return (
    <DataContextProvider>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <SideBar
          isOpen={isDrawerOppened}
          sendDrawerWidth={getDrawerWidth}
          sendIsOpen={checkState}
        />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: !isDrawerOppened ? `calc(100% - ${drawerWidth}px)` : "100%",
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </DataContextProvider>
  );
};
export default Layout;
