// Returns given variable name. Also supports a property of an object.
const getVariableName = <TObject extends object>(
  nameObject: TObject,
  propExpression?: (prop: { [Property in keyof TObject]: string }) => string,
): string => {
  if (typeof propExpression === "undefined") return Object.keys(nameObject)[0];

  const res = {} as { [Property in keyof TObject]: string };
  Object.keys(nameObject).map((key) => (res[key as keyof TObject] = key));

  return propExpression(res);
};

export default getVariableName;
