import { Text, View, StyleSheet } from "@react-pdf/renderer";

import Invoice from "models/resources/invoice.model";
import Order from "models/resources/order.model";

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: "row",
    marginTop: 70,
    marginBottom: 50,
    display: "flex",
    justifyContent: "center",
  },
});

export const formatDate = (date: string) => {
  const year = date?.substr(0, 4);
  const month = date?.substr(5, 2);
  const day = date?.substr(8, 2);

  return date ? `${day}.${month}.${year}` : "";
};

interface Props {
  invoiceData: Invoice;
  orderData: Order | undefined;
}

const Title = ({ invoiceData, orderData }: Props) => {
  return (
    <div style={styles.titleContainer}>
      <View>
        <Text>{`INVOICE`}</Text>
        <Text>{`Invoice Number: ${invoiceData?.number}`}</Text>
        <Text>{`Invoice Date: ${formatDate(
          invoiceData?.date as string,
        )}`}</Text>
        <Text>{`Order Date ${formatDate(
          orderData?.inserted_at as string,
        )}`}</Text>
      </View>
    </div>
  );
};

export default Title;
