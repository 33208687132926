import { toPng, toJpeg, toSvg } from "html-to-image";
import jsPDF from "jspdf";

export const downloadAsSVG = (name: string) => {
  const qrCode = document.getElementById("QRCodeId");
  if (qrCode) {
    toSvg(qrCode).then(function (dataUrl) {
      let a = document.createElement("a");
      a.href = dataUrl;
      a.download = `${name}.svg`;
      a.click();
    });
  }
};

export const downloadAsPNG = (name: string) => {
  const qrCode = document.getElementById("QRCodeId");
  if (qrCode) {
    toPng(qrCode).then(function (dataUrl) {
      let a = document.createElement("a");
      a.href = dataUrl;
      a.download = `${name}.png`;
      a.click();
    });
  }
};

export const downloadAsJPEG = (name: string) => {
  const qrCode = document.getElementById("QRCodeId");
  if (qrCode) {
    toJpeg(qrCode).then(function (dataUrl) {
      let a = document.createElement("a");
      a.href = dataUrl;
      a.download = `${name}.jpeg`;
      a.click();
    });
  }
};

export const downloadAsPDF = (name: string) => {
  const qrCode = document.getElementById("QRCodeId");
  if (qrCode) {
    toPng(qrCode).then(function (dataUrl) {
      let doc = new jsPDF();
      doc.addImage(dataUrl, "PNG", 65, 80, 80, 80);
      doc.save(`${name}.pdf`);
    });
  }
};
