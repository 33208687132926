import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { TableCell, TableRow } from "@mui/material";

import { CustomDetailsPage } from "components/DetailsPage/DetailsPage";
import ContentLayout from "components/layouts/ContentLayout";
import useAuthContext from "context/AuthContext";
import { FieldDescriptor } from "models/fieldsEntities.model";
import TransactionModel, {
  TransactionLog,
} from "models/resources/transaction.model";
import { getDataById } from "services/requests/listData";
import { getLocalizedDate, getLocalizedTime } from "utils/lozalizeDateTime";

const TransactionDetails = () => {
  const { user } = useAuthContext();
  const [transactionData, setTransactionData] =
    useState<TransactionModel | null>(null);
  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getDataById(setTransactionData, `transactions/${id}`);
    }
  }, [id]);

  const fields: FieldDescriptor[] = [
    {
      value: transactionData?.checkout_id,
      label: "Transaction ID",
      type: "InfoText",
      show: true,
    },
    {
      value: transactionData?.object_entity?.name,
      label: "Payment point",
      type: "InfoText",
      show: true,
    },
    {
      value: transactionData?.payment_type,
      label: "Payment type",
      type: "InfoText",
      show: true,
    },
    {
      value: transactionData?.entity_serial,
      label: "Entity Serial",
      type: "InfoText",
      show: true,
    },
    {
      value:
        transactionData?.company?.display_name ||
        transactionData?.company?.name,
      id: transactionData?.company_id,
      label: "Company",
      route: "companies",
      type: "Link",
      show: true,
    },
    {
      value: t(transactionData?.status ?? ""),
      label: "Status",
      type: "InfoText",
      show: true,
    },
    {
      value: transactionData?.reason,
      label: "Reason",
      type: "InfoText",
      show: true,
    },
    {
      value: t(transactionData?.funded_entity ? "Yes" : "No"),
      label: "Is entity funded?",
      type: "InfoText",
      show: true,
    },

    {
      value: getLocalizedDate(transactionData?.inserted_at),
      label: "Transaction Date",
      type: "InfoText",
      show: true,
    },
    {
      value: getLocalizedTime(transactionData?.inserted_at as string),
      label: "Transaction Time",
      type: "InfoText",
      show: true,
    },
    {
      value: `${(+transactionData?.amount! * +transactionData?.rate!).toFixed(
        transactionData?.currency?.toUpperCase() === "AVAX" ? 9 : 2,
      )}  ${transactionData?.currency?.toUpperCase()}`,
      label: "Amount",
      type: "InfoText",
      show: true,
    },
    {
      value: transactionData?.order_id,
      id: transactionData?.order_id,
      label: "Order",
      route: "orders",
      type: "Link",
      show: true,
    },
    {
      value: transactionData?.logs,
      label: t("Logs"),
      type: "Table",
      show: user?.role?.type === "admin",
      headers: [t("Status"), t("Date"), t("Time")],
      columns: transactionData?.logs?.map((e: TransactionLog, i: number) => {
        return (
          <TableRow key={`${i}-${e.entry.checkout_id}`}>
            <TableCell sx={{ padding: "5px 0 0 5px" }}>
              {e?.entry?.status}
            </TableCell>
            <TableCell sx={{ padding: "5px 0 0 5px" }}>
              {getLocalizedDate(e.entry?.inserted_at)}
            </TableCell>
            <TableCell sx={{ padding: "5px 0 0 5px" }}>
              {getLocalizedTime(e.entry?.inserted_at)}
            </TableCell>
          </TableRow>
        );
      }),
    },
  ];

  return (
    <ContentLayout
      title={`${t("Transactions")} > ${transactionData?.id ?? ""}`}
      filters={false}
    >
      <CustomDetailsPage
        isEditableAndDeletable={false}
        data={transactionData}
        setData={setTransactionData}
        resource={"transaction"}
        call={"transactions"}
        fields={fields}
      />
    </ContentLayout>
  );
};

export default TransactionDetails;
