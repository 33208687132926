import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { IngredientBase } from "models/resources/ingredient.model";
import Machine from "models/resources/machine.model";
import Product, { ProductBase } from "models/resources/product.model";
import apiClient from "services/api";
import { getDataById } from "services/requests/listData";

export interface ButtonProductMapper {
  id: number;
  row: number;
  column: number;
  button_signal: string;
  machine_id: number;
  product_id: number;
  product: Product;
}

const useMachineDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [machineData, setMachineData] = useState<Machine>();
  const [machineProducts, setMachineProducts] = useState<ProductBase[] | null>(
    null,
  );
  const [machineIngredients, setMachineIngredients] = useState<
    IngredientBase[] | null
  >(null);
  const [productType, setProductType] = useState<string | null>(null);
  const [showEditDialog, setShowEditDialog] = useState({
    display: false,
    editId: -1,
    type: "",
  });
  const [refetchFlag, setRefechtFlag] = useState<boolean>(false);
  const [refetchMapper, setRefechMapper] = useState<boolean>(false);
  const [image, setImage] = useState<string | null>(null);
  const [selectedEditIngredient, setEditIngredient] =
    useState<IngredientBase | null>(null);
  const [buttonProductMappers, setButtonProductMappers] = useState<
    ButtonProductMapper[]
  >([]);

  const handleEditIngredientChange = (i: IngredientBase | null) => {
    setEditIngredient(i);
  };

  const loadImage = async () => {
    await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/machines/${id}/screen-saver`,
      {
        method: "GET",
        credentials: "include",
      },
    ).then(async (response) => {
      if (response.status === 404) {
        setImage(null);
        return;
      }
      setImage(URL.createObjectURL(await response.blob()));
    });
  };

  const attachProduct = async (productIds: number[]) => {
    await apiClient({
      url: `machines/${id}/products`,
      method: "post",
      bodyData: {
        product_ids: productIds,
      },
    })
      .then(() => setRefechtFlag((flag) => !flag))
      .catch((error) => toast.error(error.message));
  };

  const detachProduct = async (
    productId: number,
    setOuterMachineProducts?: Dispatch<SetStateAction<ProductBase[] | null>>,
  ) => {
    await apiClient({
      url: `machines/${id}/products/${productId}`,
      method: "delete",
    }).then(() => {
      const setter = setOuterMachineProducts ?? setMachineProducts;
      setter((oldState) => oldState?.filter((s) => s.id !== productId) ?? null);
      setRefechtFlag((oldFlag) => !oldFlag);
    });
  };

  const attachIngredient = (i: {
    id: number;
    quantity: number;
    recharge_quantity: number;
    capacity?: number;
  }) => attachIngredients([i]);

  const attachIngredients = async (
    ingredients: [
      {
        id: number;
        quantity: number;
        recharge_quantity: number;
        capacity?: number;
      },
    ],
  ) => {
    return await apiClient({
      url: `machines/${id}/ingredients`,
      method: "post",
      bodyData: {
        ingredients: [
          ...ingredients.map((ingredient) => ({
            ingredient_id: ingredient.id,
            quantity: ingredient.quantity,
            recharge_quantity: ingredient.recharge_quantity,
            capacity: ingredient.capacity,
          })),
        ],
      },
    })
      .then(() => {
        setRefechtFlag((flag) => !flag);
        return true;
      })
      .catch((error) => {
        toast.error(t(error.message));
        return false;
      });
  };

  const detachIngredients = async (
    ingredientId: number,
    setOuterMachineIngredients?: Dispatch<
      SetStateAction<IngredientBase[] | null>
    >,
  ) => {
    await apiClient({
      url: `machines/${id}/ingredients/${ingredientId}`,
      method: "delete",
    }).then(() => {
      const setter = setOuterMachineIngredients ?? setMachineIngredients;

      setter(
        (oldState) => oldState?.filter((s) => s.id !== ingredientId) ?? null,
      );
    });
  };

  const createSignalMapping = async ({
    row,
    column,
    value,
  }: {
    row: number;
    column: number;
    value: string;
  }) => {
    const buttonMapperId = buttonProductMappers.find(
      (bpm) => bpm.row === row && bpm.column === column,
    )?.id;

    const schemeId =
      machineData?.machine_blueprint?.machine_blueprint_signal_mappings.find(
        (mb) => mb.column === column && mb.row === row,
      )?.id;

    let bodyData = {
      id: buttonMapperId,
      machine_blueprint_signal_mapping_id: schemeId,
      product_id: value,
    };

    if (typeof buttonMapperId === "undefined") delete bodyData.id;

    await apiClient<{
      id: number;
      machine_blueprint_signal_mapping_id: number;
      object_entity_id: number;
      product_id: number;
    }>({
      url: `machines/${id}/button-product-mapper`,
      method: "post",
      bodyData: {
        mappings: [bodyData],
      },
    })
      .then(() => setRefechMapper((old) => !old))
      .catch(() =>
        toast.error(t("Machine blueprint signal mapping can't be blank")),
      );
  };

  const copyMachineScheme = async (machineId: number) => {
    await apiClient<Machine>({
      url: `machines/${machineId}`,
      method: "get",
    }).then(async (machineResponse) => {
      await apiClient({
        url: `machines/${id}`,
        method: "patch",
        bodyData: {
          machine_blueprint_id: machineResponse.data.machine_blueprint.id,
        },
      });

      await apiClient<ButtonProductMapper[]>({
        url: `machines/${machineId}/button-product-mapper`,
        method: "get",
      }).then(
        async (response) =>
          await apiClient({
            url: `machines/${id}/button-product-mapper`,
            method: "post",
            bodyData: {
              mappings: response.data.map((bpm: ButtonProductMapper) => ({
                machine_blueprint_signal_mapping_id:
                  machineResponse.data.machine_blueprint.machine_blueprint_signal_mappings.find(
                    (sm: any) => sm.column === bpm.column && sm.row === bpm.row,
                  )?.id,
                product_id: bpm.product_id,
              })),
            },
          }).then(() => setRefechtFlag((old) => !old)),
      );
    });
  };
  const rechargeQuantities = () => {
    apiClient({
      method: "PUT",
      url: `machines/${machineData?.id}/recharge`,
    }).then(() => {
      getDataById(setMachineData, `machines/${id}`);
    });
  };
  const cancelPreorders = () => {
    apiClient({
      method: "POST",
      url: `machines/${machineData?.id}/cancel-preorders`,
    }).then(() => {
      getDataById(setMachineData, `machines/${id}`);
    });
  };

  return {
    loadImage,
    machineData,
    refetchFlag,
    refetchMapper,
    setProductType,
    setMachineData,
    machineProducts,
    setMachineProducts,
    setButtonProductMappers,
    machineIngredients,
    setMachineIngredients,
    rechargeQuantities,
    productType,
    showEditDialog,
    selectedEditIngredient,
    handleEditIngredientChange,
    buttonProductMappers,
    cancelPreorders,
    setShowEditDialog,
    attachProduct,
    detachProduct,
    attachIngredient,
    attachIngredients,
    detachIngredients,
    createSignalMapping,
    copyMachineScheme,
    image,
    setImage,
    setRefechtFlag,
  };
};

export default useMachineDetails;
