export const createSchemeMatrix = (
  rowLength: number,
  colLength: number,
  buttonMappers: {
    row: number;
    column: number;
    value: string;
    product_id?: number;
    product_name?: string;
  }[],
) => {
  let matrix: { id?: number; value: string; name?: string }[][] = [];

  for (let i = 0; i < rowLength; i++) {
    matrix[i] = [];
    for (let j = 0; j < colLength; j++) {
      matrix[i][j] = { value: "-" };

      const currentBtn = buttonMappers.find(
        (bm) => bm.row === i + 1 && bm.column === j + 1,
      );

      if (typeof currentBtn !== "undefined")
        matrix[i][j] = {
          value: currentBtn.product_id?.toString() ?? "-",
          id: currentBtn.product_id,
          name: currentBtn.product_name,
        };
    }
  }

  return matrix;
};
