import React from "react";

import {
  Page,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";

import { Button } from "@mui/material";

import Header from "components/PdfExport/invoiceExport/Header";
import Table from "components/PdfExport/invoiceExport/Table";
import Title from "components/PdfExport/invoiceExport/Title";
import Invoice from "models/resources/invoice.model";
import Order from "models/resources/order.model";
import Transaction from "models/resources/transaction.model";

//TODO cyrilic
const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 10,
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    lineHeight: 1.5,
    flexDirection: "column",
  },
});
interface Props {
  invoiceData: Invoice;
  transactionData: Transaction[] | undefined;
  orderData: Order | undefined;
}

const ExportSinglePDF = ({
  invoiceData,
  transactionData,
  orderData,
}: Props) => {
  return (
    <div className="app">
      <PDFDownloadLink
        document={
          <Document>
            <Page size="A4" style={styles.page}>
              <Header invoiceData={invoiceData} />
              <Title invoiceData={invoiceData} orderData={orderData} />
              <Table
                invoiceData={invoiceData}
                orderData={orderData}
                transactionData={transactionData}
              />
            </Page>
          </Document>
        }
        fileName={`invoice_${invoiceData?.number}.pdf`}
      >
        <Button variant="outlined">EXPORT PDF</Button>
      </PDFDownloadLink>
    </div>
  );
};

export default ExportSinglePDF;
