import { ReactNode } from "react";

import { Box, Card, CardContent, Typography } from "@mui/material";

import constants from "styles/scss/constants.module.scss";

export interface AuthLayoutProps {
  component: ReactNode;
}
export default function AuthLayout({ component }: AuthLayoutProps) {
  return (
    <Box sx={{ padding: "40px" }}>
      <Box
        height="160px"
        sx={{
          position: "relative",
          margin: "auto",
          padding: "10px",
          width: "90%",
          top: "80px",
          zIndex: 300,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "4px",
          boxShadow: constants.boxShadow,
          backgroundColor: constants.bgGrey,
          fontWeight: "bold",
        }}
      >
        <Typography
          sx={{
            fontSize: "3em",
            backgroundColor: "#4b535a",
            color: "transparent",
            textTransform: "uppercase",
            textShadow: constants.textShadow,
            fontFamily: constants.bold,
            backgroundClip: "text",
          }}
        >
          averato
        </Typography>
      </Box>
      <Card sx={{ maxWidth: 375, paddingTop: "100px", position: "relative" }}>
        <CardContent>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>{component}</Box>
        </CardContent>
      </Card>
    </Box>
  );
}
