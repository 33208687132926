import { Dispatch, SetStateAction } from "react";
import { ToastPosition, toast } from "react-toastify";

import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import Company from "models/resources/company.model";
import { DatabaseEntity } from "models/responseData.model";
import apiClient from "services/api";

export const changeStatus = async (
  toggleStatus: boolean,
  data: Company,
  t: (
    key: string | string[],
    props: { position?: ToastPosition; autoClose?: boolean },
  ) => string,
  setData: Dispatch<SetStateAction<Company>>,
  status?: string,
  handleClose?: () => void,
) => {
  let success = false;

  await apiClient<DatabaseEntity>({
    url: `companies/${data.id}/status`,
    bodyData: {
      status: toggleStatus
        ? data.status === "approved"
          ? "declined"
          : "approved"
        : status,
    },
    method: "put",
  })
    .then(() => {
      success = true;

      const statusChange = toggleStatus
        ? data.status === "approved"
          ? "declined"
          : "approved"
        : status;

      toast.success(
        t(`Company successfully ${statusChange}.`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: false,
        }),
      );
    })
    .catch(() =>
      toast.error(
        t("Unable to set company status.", {
          position: toast.POSITION.TOP_RIGHT,
        }),
      ),
    );
  if (success) {
    await apiClient<Company>({
      url: `companies/${data.id}`,
      method: "get",
    }).then((response) => {
      setData(response.data);
      if (handleClose) handleClose();
    });
  }
};

interface CompanyActionsProps {
  t: (key: string | string[]) => string;
  setData: Dispatch<SetStateAction<Company>>;
  data: Company;
}

const CompanyActions = ({ t, setData, data }: CompanyActionsProps) => {
  return (
    <>
      <IconButton
        onClick={() => changeStatus(false, data, t, setData, "approved")}
        size="small"
        sx={{ color: "black" }}
      >
        <DoneIcon></DoneIcon>
        <Typography variant="button" display="block">
          {t("Approve")}
        </Typography>
      </IconButton>
      <IconButton
        onClick={() => changeStatus(false, data, t, setData, "declined")}
        size="small"
        sx={{ color: "black" }}
      >
        <ClearIcon></ClearIcon>
        <Typography variant="button" display="block">
          {t("Reject")}
        </Typography>
      </IconButton>
    </>
  );
};

export default CompanyActions;
