import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import * as _ from "lodash";

const useCustomSearch = (
  showAlways: boolean,
  defaultValue: string,
  onChange: (value: string) => void,
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchInput, setSearchInput] = useState<string>(defaultValue);
  const [showSearchBar, setShowSeachBar] = useState<boolean>(showAlways);
  const searchInputRef = useRef<HTMLElement | null>(null);

  const handleSearchInputOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    // State value trim not working for visualizing the trimmed result in the input because of the debouncer.
    // Trimming the value that is being sent with the request is working correctly.
    const value = event.target.value.trim();
    setSearchInput(value);
    setSearchParams({ ...searchParams, search: value });
  };

  const handleSearchInputOnBlur = () => {
    if (!showAlways) setShowSeachBar(false);
  };

  const handleSearchOnClick = () => {
    setShowSeachBar(true);
    searchInputRef.current?.focus();
  };

  const debounceResults = useMemo(() => {
    return _.debounce(handleSearchInputOnChange, 300);
  }, []);

  useEffect(() => {
    onChange(searchInput);

    return () => {
      debounceResults.cancel();
    };
  }, [searchInput]);

  return {
    searchInput,
    debounceResults,
    handleSearchInputOnBlur,
    showSearchBar,
    handleSearchOnClick,
    searchInputRef,
  };
};

export default useCustomSearch;
