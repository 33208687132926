import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { CustomDetailsPage } from "components/DetailsPage/DetailsPage";
import ContentLayout from "components/layouts/ContentLayout";
import useFilterContext from "context/FilterContext";
import { FieldDescriptor } from "models/fieldsEntities.model";
import QR from "models/resources/qr.model";
import { getDataById } from "services/requests/listData";
import { findCompany } from "utils/findEntity";

const QrPaymentCodeDetails = () => {
  const { options } = useFilterContext();
  const { locations, companies } = options;
  const [QRData, setQRData] = useState<QR | null>(null);
  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getDataById(setQRData, `qr/${id}`);
    }
  }, [id]);

  const fields: FieldDescriptor[] = [
    {
      value: QRData?.name,
      label: "QR code name",
      type: "InfoText",
      show: true,
    },
    {
      value: QRData?.serial,
      label: "Serial",
      type: "InfoText",
      show: true,
    },
    {
      value: QRData?.currency,
      label: "Currency",
      type: "InfoText",
      show: true,
    },
    {
      value: QRData?.entity_category?.name,
      label: "Entity Category",
      type: "InfoText",
      show: true,
    },
    {
      value: QRData?.company.display_name || QRData?.company.name,
      id: QRData?.company_id,
      label: "Company",
      route: "companies",
      type: "Link",
      show: true,
    },
    {
      value: QRData?.serial ? QRData?.serial : "---",
      label: "QR code",
      type: QRData?.serial ? "QR" : "TextField",
      show: true,
    },
    {
      value:
        QRData?.location !== null
          ? `${QRData?.location.name}, ${QRData?.location.address}`
          : "---",
      id: QRData?.location_id,
      label: "Location",
      type: QRData?.location !== null ? "Link" : "TextField",
      route: "locations",
      show: true,
    },
    {
      value: QRData?.comments,
      label: "Comments",
      type: "InfoText",
      show: true,
    },
  ];

  return (
    <ContentLayout
      title={`${t("QR Payment Codes")} > ${QRData?.name ?? ""}`}
      filters={false}
    >
      <CustomDetailsPage
        data={QRData}
        setData={setQRData}
        resource={"qr"}
        call={"qr"}
        fields={fields}
      />
    </ContentLayout>
  );
};

export default QrPaymentCodeDetails;
