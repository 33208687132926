import { Box, Stack } from "@mui/material";

import { DatabaseEntity } from "models/responseData.model";

import SchemeButton from "./SchemeButton";

interface MappingSchemeProps {
  rowsCount: number;
  columnsCount: number;
  signalMatrix: { id?: number; value: string; name?: string }[][];
  label: string /* Mapping form input label. */;
  mappingOptions?: DatabaseEntity[] /* Optional mapping list for form dropdown. The default field is a number input. */;
  onSubmit?(selectedBtnValue: {
    row: number;
    column: number;
    value: string;
  }): void /* Executes after submiting the mapping form for the selected row and column button with the inputed value.*/;
  customComponent?: JSX.Element;
}

const MappingScheme = ({
  rowsCount,
  columnsCount,
  label,
  signalMatrix,
  mappingOptions,
  onSubmit = () => {},
  customComponent,
}: MappingSchemeProps) => {
  return (
    <Box>
      {customComponent}
      {signalMatrix.map((row, indexRow) => (
        <Stack
          key={indexRow}
          direction="row"
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          {row.map((signal, indexCol) => (
            <SchemeButton
              indexRow={indexRow}
              indexColumn={indexCol}
              signal={signal}
              label={label}
              mappingOptions={mappingOptions}
              onSubmit={onSubmit}
            />
          ))}
        </Stack>
      ))}
    </Box>
  );
};

export default MappingScheme;
