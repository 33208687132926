import { useTranslation } from "react-i18next";

import { Box, CardContent } from "@mui/material";

import Payment from "components/Payment";
import DynamicDialog from "components/layouts/Dialog";
import useModuleInfo from "hooks/useModuleInfo";

interface MachineActionsProps {
  moduleSerial: string;
  buttonText?: string; // Used from CompleteWebPayment component. Remove on design change.
}

const MachineActions = ({
  moduleSerial,
  buttonText = "Pay without installing the app",
}: MachineActionsProps) => {
  const { t } = useTranslation();
  const machineInfo = useModuleInfo(moduleSerial);

  return (
    <Box sx={{ margin: "0 auto", width: "30%" }}>
      {typeof machineInfo.data === "undefined" &&
      typeof machineInfo.errorMessage === "undefined" ? (
        <CardContent>{t("Loading machine data...")}</CardContent>
      ) : typeof machineInfo.errorMessage !== "undefined" ? (
        <CardContent>{t(machineInfo.errorMessage)}</CardContent>
      ) : typeof machineInfo.data !== "undefined" &&
        machineInfo.data.payment_methods.length ? (
        <CardContent>
          <DynamicDialog
            title={t("Pay without installing the app")}
            component={
              <Payment
                machineInfo={{ ...machineInfo.data }}
                moduleSerial={moduleSerial}
              />
            }
            openMessage={t(buttonText)}
            hideActions
          />
        </CardContent>
      ) : (
        <CardContent style={{ fontSize: "18px", fontWeight: "bold" }}>
          {t("Payments linked to this QR code are not accepted at the moment.")}
        </CardContent>
      )}
    </Box>
  );
};

export default MachineActions;
