import React from "react";

import { Box } from "@mui/material";

const NoSsr = (props: {
  children:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactPortal
    | null
    | undefined;
}) => <Box>{props.children}</Box>;

export default NoSsr;
