import {
  createContext,
  useEffect,
  useRef,
  useState,
  ReactNode,
  useContext,
} from "react";

import { CurrentPageprops } from "models/pageProps.model";
import { getPageProps } from "services/pageconfig/getPageProps";

export interface DataContext {
  getPagePropsData: CurrentPageprops;
  setPagePropsData: (props: CurrentPageprops) => void;
  isFilterSearch: boolean;
  setIsFilterSearch: (el: boolean) => void;
  setMarkerPosition: (
    e: {
      lat: null | number | undefined;
      lng: null | number | undefined;
    } | null,
  ) => void;
  markerPosition: any;
  setMarkerClicked: (e: boolean) => void;
  markerClicked: boolean;
  selectedCompanyEditId: number;
  setSelectedCompanyEditId: (e: number) => void;
  selectedModuleEditId: number;
  setSelectedModuleEditId: (e: number) => void;
  recipeProductIngredients: {
    ingredient_id: number;
    quantity: number;
  }[];
  setProductIngredients: any;
}

const DataContext = createContext({} as DataContext);

const useDataContext = () => useContext(DataContext);

export const DataContextProvider = ({ children }: { children: ReactNode }) => {
  const [getPagePropsData, setPagePropsData] = useState({} as CurrentPageprops);
  const [isFilterSearch, setIsFilterSearch] = useState(false);
  const [markerPosition, setMarkerPosition] = useState<{
    lat: null | number | undefined;
    lng: null | number | undefined;
  } | null>({
    lat: null,
    lng: null,
  });
  const [markerClicked, setMarkerClicked] = useState<boolean>(false);
  const [selectedCompanyEditId, setSelectedCompanyEditId] =
    useState<number>(-1);
  const [selectedModuleEditId, setSelectedModuleEditId] = useState<number>(-1);
  // TODO: clean up in future.
  // Used for setting ingredientsQuantity in request body for recipe product creation.
  const [recipeProductIngredients, setProductIngredients] = useState<
    {
      ingredient_id: number;
      quantity: number;
    }[]
  >([]);
  const prevPagePropsRef = useRef<CurrentPageprops>();
  let pageProps = getPageProps() as CurrentPageprops;

  useEffect(() => {
    pageProps?.pageFilters?.map(() => {
      if (pageProps) setPagePropsData(pageProps);
    });

    prevPagePropsRef.current = getPagePropsData;
  }, [pageProps]);

  return (
    <DataContext.Provider
      value={{
        getPagePropsData,
        setPagePropsData,
        isFilterSearch,
        setIsFilterSearch,
        setMarkerClicked,
        setMarkerPosition,
        markerPosition,
        markerClicked,
        selectedCompanyEditId,
        setSelectedCompanyEditId,
        selectedModuleEditId,
        setSelectedModuleEditId,
        recipeProductIngredients,
        setProductIngredients,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default useDataContext;
