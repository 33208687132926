import { useState } from "react";

import useFilterContext from "context/FilterContext";
import QueryParams from "models/queryParams.model";
import Promotion from "models/resources/promotion.model";
import { ResponseDataTable } from "models/responseData.model";
import { getData } from "services/requests/listData";

const usePromotion = () => {
  const [getPromotionsData, setPromotionsData] =
    useState<ResponseDataTable<Promotion[]>>();

  const { options } = useFilterContext();
  const { companies } = options;

  const setData = (queryParams: QueryParams, setDataLoading: any) => {
    const myCompanies = companies.map((company) => company.id);
    const filteredCompanies = queryParams?.filter?.companyIds;

    const isGivenFilter =
      queryParams?.filter?.companyIds &&
      Object.keys(queryParams?.filter?.companyIds).length != 0;
    getData(
      setPromotionsData,
      "promotions",
      {
        ...queryParams,
        filter: {
          ...queryParams.filter,
          companyIds: !isGivenFilter ? myCompanies : filteredCompanies,
        },
      },
      setDataLoading,
    );
  };

  return { getPromotionsData, setPromotionsData, setData };
};

export default usePromotion;
