import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";

import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";

import useProfileEditForm from "components/ProfileEditForm/useProfileEditForm";
import { Currency } from "models/resources/user.model";

interface ProfileEditFormProps {
  onSubmit(): void;
}

const ProfileEditForm = ({ onSubmit }: ProfileEditFormProps) => {
  const { t } = useTranslation();
  const {
    firstName,
    handleFirstNameOnChange,
    lastName,
    handleLastNameOnChange,
    phone,
    handlePhoneOnChange,
    receiveSMS,
    handleReceiveSmsOnChange,
    currency,
    handleCurrencyOnChange,
    errors,
    onSaveClick,
  } = useProfileEditForm();

  return (
    <Fragment>
      <Grid container spacing={4}>
        <Grid item md={6}>
          <TextField
            fullWidth
            label={t("First Name")}
            error={errors.firstName !== ""}
            helperText={t(errors.firstName)}
            value={firstName}
            onChange={handleFirstNameOnChange}
          />
        </Grid>

        <Grid item md={6}>
          <TextField
            fullWidth
            label={t("Last Name")}
            error={errors.lastName !== ""}
            helperText={t(errors.lastName)}
            value={lastName}
            onChange={handleLastNameOnChange}
          />
        </Grid>

        <Grid item md={6}>
          <InputLabel
            sx={{
              fontSize: "12px",
              paddingLeft: "12px",
            }}
          >
            {t("Phone Number")}
          </InputLabel>
          <PhoneInput
            inputStyle={{
              color: "black",
              backgroundColor: "#E6E3E1",
            }}
            country="bg"
            value={phone}
            onChange={handlePhoneOnChange}
          />
        </Grid>

        <Grid item md={6}>
          <InputLabel
            sx={{
              fontSize: "12px",
              paddingLeft: "12px",
            }}
          >
            {t("Do you wish to receive SMS?")}
          </InputLabel>
          <RadioGroup
            sx={{ marginLeft: "5px", display: "flex", flexFlow: "row wrap" }}
            value={receiveSMS}
            onChange={handleReceiveSmsOnChange}
          >
            <FormControlLabel
              control={<Radio />}
              label={t("Yes")}
              value={true}
            />

            <FormControlLabel
              control={<Radio />}
              label={t("No")}
              value={false}
            />
          </RadioGroup>
        </Grid>

        <Grid item md={12}>
          <FormControl fullWidth>
            <InputLabel
              sx={{
                fontSize: "12px",
                paddingLeft: "12px",
              }}
            >
              {t("Currency")}
            </InputLabel>
            <Select value={currency} onChange={handleCurrencyOnChange}>
              {Object.values(Currency).map(
                (currentCurrency: Currency, index: number) => (
                  <MenuItem key={index} value={currentCurrency}>
                    {t(currentCurrency)}
                  </MenuItem>
                ),
              )}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Button
        sx={{
          marginTop: " 40px",
          boxShadow: "none",
          width: "24%",
        }}
        variant="contained"
        onClick={() => onSaveClick(onSubmit)}
      >
        {t("Save")}
      </Button>
    </Fragment>
  );
};

export default ProfileEditForm;
