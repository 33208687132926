import Company from "models/resources/company.model";
import Location from "models/resources/location.model";
import Machine from "models/resources/machine.model";

export const findCompany = (id: number | undefined, data: Company[]) =>
  data.find((e) => e.id === id);

export const findLocation = (id: number | undefined, data: Location[]) =>
  data.find((e) => e.id === id);

export const findMachine = (id: number | undefined, data: Machine[]) =>
  data?.find((e) => e.id === id);
