import { useEffect, useState } from "react";

import useFilterContext from "context/FilterContext";
import QueryParams from "models/queryParams.model";
import Product from "models/resources/product.model";
import { ResponseDataTable } from "models/responseData.model";
import { getData } from "services/requests/listData";

const useProductTable = () => {
  const { filter } = useFilterContext();
  const [productsData, setProductsData] =
    useState<ResponseDataTable<Product[]>>();

  const setData = (queryParams: QueryParams, setDataLoading: any) =>
    getData(setProductsData, "products", queryParams, setDataLoading);

  return { productsData, setData, setProductsData };
};

export default useProductTable;
