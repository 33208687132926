import {
  AvalancheCredentials,
  PayseraCredentials,
  SumupCredentials,
} from "models/resources/paymentCredentials.model";

export enum PaymentCredentialsTab {
  // Tabs order based on enum values order.
  ADD = 0,
  BGN = 1,
  EUR = 2,
  USD = 3,
  CHF = 4,
}

export enum PaymentProvider {
  None = "None",
  Sumup = "Sumup",
  Paysera = "Paysera",
  Avalanche = "Avalanche",
}

export interface PaymentCredentialsTabForm {
  paymentCredentialId?: number; // Used for deleting selected payment credentials.
  tabIndex: PaymentCredentialsTab; // Information for the current tab selected.
  paymentCredentialsForm: PaymentProviderCredentials; // Stores the input fields for generating the current tab form.
}

export type PaymentProviderCredentials =
  | PayseraCredentials
  | SumupCredentials
  | AvalancheCredentials;

// Generates form fields for the 'Add' payment credentials tab based on the given providerName.
export const getFieldsForProvider = (
  providerName: string,
): PaymentProviderCredentials => {
  return Object.assign(
    {},
    ...Object.entries({
      ...addFormFields[providerName as keyof typeof addFormFields],
    }).map(([a, b]) => ({ [b as string]: "" })),
  );
};

// Check if given credentials are shallow equal. Used for disabling the submit button on unchanged form data.
export const equalPaymentCredentials = (
  firstCredentials: PaymentProviderCredentials,
  secondCredentials: PaymentProviderCredentials,
): boolean => {
  return !Object.keys(firstCredentials).some(
    (key) =>
      firstCredentials[key as keyof typeof firstCredentials] !==
      secondCredentials[key as keyof typeof secondCredentials],
  );
};

// Sort current tabs based on the `PaymentCredentialsTab` enum values order.
export const getSortedTabs = (
  currentTabs: PaymentCredentialsTabForm[],
): PaymentCredentialsTabForm[] => {
  const tabsOrder = Object.values(PaymentCredentialsTab);

  return currentTabs.sort(
    (a, b) => tabsOrder.indexOf(b.tabIndex) - tabsOrder.indexOf(a.tabIndex),
  );
};

// Fields for diffrent payment providers. Used in the `Add` form tab generating process.
const addFormFields = {
  avalanche: ["currency", "wallet"],
  sumup: ["currency", "client_id", "client_secret", "merchant_code"],
  paysera: ["currency", "project_id", "password"],
};

// Parse input labels for the field generation process.
export const fields = [
  {
    name: "currency",
    label: "Currency",
  },
  {
    name: "wallet",
    label: "Avalanche Wallet Address (X-Chain)",
  },
  {
    name: "client_id",
    label: "Client ID",
  },
  {
    name: "client_secret",
    label: "Client secret",
  },
  {
    name: "merchant_code",
    label: "Merchant code",
  },
  {
    name: "project_id",
    label: "Project ID",
  },
  {
    name: "password",
    label: "Password",
  },
];
