import { useEffect, useState } from "react";

import { fetchEditEntity } from "components/Forms/formUtils";
import DynamicForm from "components/Forms/generic/DynamicForm";
import DynamicDialog from "components/layouts/Dialog";
import useDataContext from "context/DataContext";
import Module, { MDB } from "models/resources/module.model";

const MdbSettingsForm = ({ editId }: { editId?: number }) => {
  const [editData, setEditData] = useState<MDB>();
  const { selectedModuleEditId } = useDataContext();

  useEffect(() => {
    (async () => {
      if (
        (typeof editId !== "undefined" && editId !== -1) ||
        selectedModuleEditId !== -1
      ) {
        const id =
          typeof editId !== "undefined" && editId !== -1
            ? editId
            : selectedModuleEditId;

        const response = (await fetchEditEntity(
          "module",
          `modules/${id}`,
        )) as Module;

        setEditData(response!?.mdb!);
      }
    })();
  }, [selectedModuleEditId]);

  return (
    <DynamicDialog
      hideActions={true}
      title={"MDB Settings"}
      component={
        <DynamicForm
          mode="edit"
          call="modules"
          callEndPoint="mdb"
          resource="mdb-settings"
          preventQuery={true}
          editData={editData}
          dynamicClass={() => "flexHalfForm nth-9"}
        />
      }
      openMessage="MDB Settings"
      isOpen={false}
    />
  );
};

export default MdbSettingsForm;
