import { ChangeEvent, useState } from "react";

import { SelectChangeEvent } from "@mui/material";

import useAuthContext from "context/AuthContext";
import { Currency } from "models/resources/user.model";

const useProfileEditForm = () => {
  const { user, userSettings, updateProfile } = useAuthContext();
  const [firstName, setFirstName] = useState<string>(user?.first_name!);
  const [lastName, setLastName] = useState<string>(user?.last_name!);
  const [phone, setPhone] = useState<string | null>(user?.phone!);
  const [receiveSMS, setReceiveSMS] = useState<boolean>(user?.receive_sms!);
  const [currency, setCurrency] = useState<Currency>(userSettings.currency);
  const [errors, setErrors] = useState<{ firstName: string; lastName: string }>(
    {
      firstName: "",
      lastName: "",
    },
  );

  const handleFirstNameOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setErrors((oldErrors) => ({ ...oldErrors, firstName: "" }));

    setFirstName(event.target.value);
  };

  const handleLastNameOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setErrors((oldErrors) => ({ ...oldErrors, lastName: "" }));

    setLastName(event.target.value);
  };

  const handlePhoneOnChange = (phoneInput: string) => {
    setPhone(phoneInput === "" ? null : phoneInput);
  };

  const handleReceiveSmsOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setReceiveSMS((oldState) => !oldState);
  };

  const handleCurrencyOnChange = (event: SelectChangeEvent<Currency>) => {
    setCurrency(event.target.value as Currency);
  };

  const onSaveClick = async (onSubmit: () => void) => {
    if (firstName === "") {
      setErrors((oldErrors) => ({
        ...oldErrors,
        firstName: "This field is required",
      }));
    }

    if (lastName === "") {
      setErrors((oldErrors) => ({
        ...oldErrors,
        lastName: "This field is required",
      }));
    }

    if ([firstName, lastName].some((v: string) => v === "")) return;

    await updateProfile({
      firstName,
      lastName,
      phone,
      receiveSMS,
      settings: JSON.stringify({ currency }),
    });

    onSubmit();
  };

  return {
    firstName,
    handleFirstNameOnChange,
    lastName,
    handleLastNameOnChange,
    phone,
    handlePhoneOnChange,
    receiveSMS,
    handleReceiveSmsOnChange,
    currency,
    handleCurrencyOnChange,
    errors,
    onSaveClick,
  };
};

export default useProfileEditForm;
