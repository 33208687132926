import { useTranslation } from "react-i18next";

import { Box, Button, Input } from "@mui/material";

import usePayment from "components/Payment/usePayment";
import SumUpForm from "components/SumUp";
import { ObjectEntityInfo } from "models/resources/objectEntity.model";

interface PaymentProps {
  machineInfo: ObjectEntityInfo;
  moduleSerial: string;
}

const Payment = ({ machineInfo, moduleSerial }: PaymentProps) => {
  const { t } = useTranslation();
  const {
    amount,
    checkoutId,
    handleChange,
    handleClickUp,
    handleClickDown,
    handleOnClickSubmit,
  } = usePayment();

  return (
    <Box>
      <p>{t("Please, select the desired amount to pay.")}</p>

      <Button onClick={handleClickDown} variant="outlined" color="primary">
        - 0.10
      </Button>
      <Input
        type="number"
        value={amount}
        // label={`t("Please, specify amount to pay") {}`}
        onChange={handleChange}
        // className={classes.marginLR}
      />
      <Button onClick={handleClickUp} variant="outlined" color="primary">
        + 0.10
      </Button>
      <div>
        <Button
          onClick={() =>
            handleOnClickSubmit(machineInfo.currency, moduleSerial)
          }
          variant="outlined"
          color="secondary"
        >
          {`${t("Pay")} ${amount} ${t(machineInfo.currency)} ${t("now")}`}
        </Button>
      </div>
      <SumUpForm checkoutId={checkoutId} />
    </Box>
  );
};

export default Payment;
