import { useRef, useState } from "react";

import MapLocation from "models/map-location.model";

const useMapContainer = (locations: MapLocation[]) => {
  const [selectedLocation, setSelectedLocation] = useState<MapLocation | null>(
    null,
  );

  const mapRef = useRef<any>(null);

  const onLoad = (map: google.maps.Map): void => {
    // Comments from previous developer:
    // test data has an instance of latitude 100
    // this breaks the map. this function is here to prevent that
    // delete when fixed
    const areCoordinatesValid = (lat: number, lng: number): boolean => {
      return -90.0 < lat && lat < 90.0 && -180.0 < lng && lng < 180.0;
    };

    mapRef.current = map;
    const bounds = new google.maps.LatLngBounds();

    locations.forEach(({ position }: MapLocation) => {
      if (areCoordinatesValid(position.lat, position.lng)) {
        bounds.extend(position);
      }
    });

    if (locations.length === 1) {
      map.setCenter(locations[0].position);
      map.setZoom(15);
      return;
    }

    map.fitBounds(bounds);
  };

  const markerOnOpen = (locationId: number): void => {
    setSelectedLocation(
      locations.find((location: MapLocation) => location.id === locationId)!,
    );
  };

  const markerOnClose = () => {
    setSelectedLocation(null);
  };

  return {
    selectedLocation,
    onLoad,
    markerOnOpen,
    markerOnClose,
  };
};

export default useMapContainer;
