type City = {
  name: string;
  countryCode: string;
  stateCode?: string;
  latitude: string;
  longitude: string;
  isoCode?: string;
};

type ProvincesType = [
  {
    isoCode: string;
    name: string;
    regions: { [region: string]: City[] };
  },
];

export const provinces: ProvincesType = [
  {
    isoCode: "BG",
    name: "Bulgaria",
    regions: {
      Blagoevgrad: [
        {
          name: "Bansko",
          countryCode: "BG",
          stateCode: "01",
          latitude: "41.83830000",
          longitude: "23.48851000",
        },
        {
          name: "Belitsa",
          countryCode: "BG",
          stateCode: "01",
          latitude: "41.95694000",
          longitude: "23.57250000",
        },
        {
          name: "Blagoevgrad",
          countryCode: "BG",
          stateCode: "01",
          latitude: "42.01667000",
          longitude: "23.10000000",
        },
        {
          name: "Garmen",
          countryCode: "BG",
          stateCode: "01",
          latitude: "41.60000000",
          longitude: "23.81667000",
        },
        {
          name: "Gotse Delchev",
          countryCode: "BG",
          stateCode: "01",
          latitude: "41.56667000",
          longitude: "23.73333000",
        },
        {
          name: "Hadzhidimovo",
          countryCode: "BG",
          stateCode: "01",
          latitude: "41.52222000",
          longitude: "23.86861000",
        },
        {
          name: "Kolarovo",
          countryCode: "BG",
          stateCode: "01",
          latitude: "41.36385000",
          longitude: "23.10669000",
        },
        {
          name: "Kresna",
          countryCode: "BG",
          stateCode: "01",
          latitude: "41.73333000",
          longitude: "23.15000000",
        },
        {
          name: "Obshtina Bansko",
          countryCode: "BG",
          stateCode: "01",
          latitude: "41.80000000",
          longitude: "23.60000000",
        },
        {
          name: "Obshtina Belitsa",
          countryCode: "BG",
          stateCode: "01",
          latitude: "42.01667000",
          longitude: "23.58333000",
        },
        {
          name: "Obshtina Blagoevgrad",
          countryCode: "BG",
          stateCode: "01",
          latitude: "42.01667000",
          longitude: "23.16667000",
        },
        {
          name: "Obshtina Garmen",
          countryCode: "BG",
          stateCode: "01",
          latitude: "41.70000000",
          longitude: "23.85000000",
        },
        {
          name: "Obshtina Gotse Delchev",
          countryCode: "BG",
          stateCode: "01",
          latitude: "41.63333000",
          longitude: "23.68333000",
        },
        {
          name: "Obshtina Kresna",
          countryCode: "BG",
          stateCode: "01",
          latitude: "41.76667000",
          longitude: "23.23333000",
        },
        {
          name: "Obshtina Petrich",
          countryCode: "BG",
          stateCode: "01",
          latitude: "41.45000000",
          longitude: "23.16667000",
        },
        {
          name: "Obshtina Razlog",
          countryCode: "BG",
          stateCode: "01",
          latitude: "41.95000000",
          longitude: "23.46667000",
        },
        {
          name: "Obshtina Sandanski",
          countryCode: "BG",
          stateCode: "01",
          latitude: "41.56667000",
          longitude: "23.45000000",
        },
        {
          name: "Obshtina Satovcha",
          countryCode: "BG",
          stateCode: "01",
          latitude: "41.59848000",
          longitude: "24.00781000",
        },
        {
          name: "Obshtina Simitli",
          countryCode: "BG",
          stateCode: "01",
          latitude: "41.88333000",
          longitude: "23.16667000",
        },
        {
          name: "Obshtina Strumyani",
          countryCode: "BG",
          stateCode: "01",
          latitude: "41.63333000",
          longitude: "23.20000000",
        },
        {
          name: "Obshtina Yakoruda",
          countryCode: "BG",
          stateCode: "01",
          latitude: "42.08333000",
          longitude: "23.70000000",
        },
        {
          name: "Petrich",
          countryCode: "BG",
          stateCode: "01",
          latitude: "41.39846000",
          longitude: "23.20702000",
        },
        {
          name: "Razlog",
          countryCode: "BG",
          stateCode: "01",
          latitude: "41.88630000",
          longitude: "23.46714000",
        },
        {
          name: "Sandanski",
          countryCode: "BG",
          stateCode: "01",
          latitude: "41.56667000",
          longitude: "23.28333000",
        },
        {
          name: "Satovcha",
          countryCode: "BG",
          stateCode: "01",
          latitude: "41.61667000",
          longitude: "23.98333000",
        },
        {
          name: "Simitli",
          countryCode: "BG",
          stateCode: "01",
          latitude: "41.88333000",
          longitude: "23.11667000",
        },
        {
          name: "Stara Kresna",
          countryCode: "BG",
          stateCode: "01",
          latitude: "41.80000000",
          longitude: "23.18333000",
        },
        {
          name: "Strumyani",
          countryCode: "BG",
          stateCode: "01",
          latitude: "41.63333000",
          longitude: "23.20000000",
        },
        {
          name: "Yakoruda",
          countryCode: "BG",
          stateCode: "01",
          latitude: "42.02528000",
          longitude: "23.68417000",
        },
      ],
      Burgas: [
        {
          name: "Aheloy",
          countryCode: "BG",
          stateCode: "02",
          latitude: "42.64987000",
          longitude: "27.64838000",
        },
        {
          name: "Ahtopol",
          countryCode: "BG",
          stateCode: "02",
          latitude: "42.09768000",
          longitude: "27.93961000",
        },
        {
          name: "Aytos",
          countryCode: "BG",
          stateCode: "02",
          latitude: "42.70000000",
          longitude: "27.25000000",
        },
        {
          name: "Bata",
          countryCode: "BG",
          stateCode: "02",
          latitude: "42.73802000",
          longitude: "27.49643000",
        },
        {
          name: "Burgas",
          countryCode: "BG",
          stateCode: "02",
          latitude: "42.50606000",
          longitude: "27.46781000",
        },
        {
          name: "Chernomorets",
          countryCode: "BG",
          stateCode: "02",
          latitude: "42.44408000",
          longitude: "27.63902000",
        },
        {
          name: "Kameno",
          countryCode: "BG",
          stateCode: "02",
          latitude: "42.57084000",
          longitude: "27.29875000",
        },
        {
          name: "Karnobat",
          countryCode: "BG",
          stateCode: "02",
          latitude: "42.65000000",
          longitude: "26.98333000",
        },
        {
          name: "Kiten",
          countryCode: "BG",
          stateCode: "02",
          latitude: "42.23424000",
          longitude: "27.77490000",
        },
        {
          name: "Malko Tarnovo",
          countryCode: "BG",
          stateCode: "02",
          latitude: "41.97958000",
          longitude: "27.52477000",
        },
        {
          name: "Nesebar",
          countryCode: "BG",
          stateCode: "02",
          latitude: "42.65921000",
          longitude: "27.73602000",
        },
        {
          name: "Obshtina Aytos",
          countryCode: "BG",
          stateCode: "02",
          latitude: "42.70000000",
          longitude: "27.25000000",
        },
        {
          name: "Obshtina Burgas",
          countryCode: "BG",
          stateCode: "02",
          latitude: "42.50000000",
          longitude: "27.46667000",
        },
        {
          name: "Obshtina Kameno",
          countryCode: "BG",
          stateCode: "02",
          latitude: "42.51667000",
          longitude: "27.18333000",
        },
        {
          name: "Obshtina Karnobat",
          countryCode: "BG",
          stateCode: "02",
          latitude: "42.65000000",
          longitude: "26.98333000",
        },
        {
          name: "Obshtina Malko Tarnovo",
          countryCode: "BG",
          stateCode: "02",
          latitude: "42.08333000",
          longitude: "27.53333000",
        },
        {
          name: "Obshtina Nesebar",
          countryCode: "BG",
          stateCode: "02",
          latitude: "42.78333000",
          longitude: "27.73333000",
        },
        {
          name: "Obshtina Pomorie",
          countryCode: "BG",
          stateCode: "02",
          latitude: "42.73333000",
          longitude: "27.55000000",
        },
        {
          name: "Obshtina Primorsko",
          countryCode: "BG",
          stateCode: "02",
          latitude: "42.25000000",
          longitude: "27.63333000",
        },
        {
          name: "Obshtina Sozopol",
          countryCode: "BG",
          stateCode: "02",
          latitude: "42.31123000",
          longitude: "27.51733000",
        },
        {
          name: "Obshtina Sungurlare",
          countryCode: "BG",
          stateCode: "02",
          latitude: "42.83333000",
          longitude: "26.83333000",
        },
        {
          name: "Obzor",
          countryCode: "BG",
          stateCode: "02",
          latitude: "42.81998000",
          longitude: "27.88007000",
        },
        {
          name: "Pomorie",
          countryCode: "BG",
          stateCode: "02",
          latitude: "42.56326000",
          longitude: "27.62986000",
        },
        {
          name: "Primorsko",
          countryCode: "BG",
          stateCode: "02",
          latitude: "42.26791000",
          longitude: "27.75611000",
        },
        {
          name: "Ravda",
          countryCode: "BG",
          stateCode: "02",
          latitude: "42.64185000",
          longitude: "27.67713000",
        },
        {
          name: "Ruen",
          countryCode: "BG",
          stateCode: "02",
          latitude: "42.80000000",
          longitude: "27.28333000",
        },
        {
          name: "Sarafovo",
          countryCode: "BG",
          stateCode: "02",
          latitude: "42.56079000",
          longitude: "27.52195000",
        },
        {
          name: "Sozopol",
          countryCode: "BG",
          stateCode: "02",
          latitude: "42.41801000",
          longitude: "27.69560000",
        },
        {
          name: "Sredets",
          countryCode: "BG",
          stateCode: "02",
          latitude: "42.34747000",
          longitude: "27.17898000",
        },
        {
          name: "Sungurlare",
          countryCode: "BG",
          stateCode: "02",
          latitude: "42.76667000",
          longitude: "26.78333000",
        },
        {
          name: "Sveti Vlas",
          countryCode: "BG",
          stateCode: "02",
          latitude: "42.71360000",
          longitude: "27.75867000",
        },
        {
          name: "Tsarevo",
          countryCode: "BG",
          stateCode: "02",
          latitude: "42.16955000",
          longitude: "27.84541000",
        },
      ],
      Dobrich: [
        {
          name: "Balchik",
          countryCode: "BG",
          stateCode: "08",
          latitude: "43.42166000",
          longitude: "28.15848000",
        },
        {
          name: "Dobrich",
          countryCode: "BG",
          stateCode: "08",
          latitude: "43.56667000",
          longitude: "27.83333000",
        },
        {
          name: "General Toshevo",
          countryCode: "BG",
          stateCode: "08",
          latitude: "43.70123000",
          longitude: "28.03787000",
        },
        {
          name: "Kavarna",
          countryCode: "BG",
          stateCode: "08",
          latitude: "43.43601000",
          longitude: "28.33953000",
        },
        {
          name: "Krushari",
          countryCode: "BG",
          stateCode: "08",
          latitude: "43.81675000",
          longitude: "27.75552000",
        },
        {
          name: "Obshtina Balchik",
          countryCode: "BG",
          stateCode: "08",
          latitude: "43.50000000",
          longitude: "28.13333000",
        },
        {
          name: "Obshtina Dobrich",
          countryCode: "BG",
          stateCode: "08",
          latitude: "43.57763000",
          longitude: "27.80628000",
        },
        {
          name: "Obshtina Dobrich-Selska",
          countryCode: "BG",
          stateCode: "08",
          latitude: "43.63333000",
          longitude: "27.73333000",
        },
        {
          name: "Obshtina General Toshevo",
          countryCode: "BG",
          stateCode: "08",
          latitude: "43.73333000",
          longitude: "28.08333000",
        },
        {
          name: "Obshtina Kavarna",
          countryCode: "BG",
          stateCode: "08",
          latitude: "43.50000000",
          longitude: "28.38333000",
        },
        {
          name: "Obshtina Krushari",
          countryCode: "BG",
          stateCode: "08",
          latitude: "43.88333000",
          longitude: "27.75000000",
        },
        {
          name: "Obshtina Shabla",
          countryCode: "BG",
          stateCode: "08",
          latitude: "43.61667000",
          longitude: "28.51667000",
        },
        {
          name: "Obshtina Tervel",
          countryCode: "BG",
          stateCode: "08",
          latitude: "43.75000000",
          longitude: "27.40000000",
        },
        {
          name: "Shabla",
          countryCode: "BG",
          stateCode: "08",
          latitude: "43.53983000",
          longitude: "28.53429000",
        },
        {
          name: "Tervel",
          countryCode: "BG",
          stateCode: "08",
          latitude: "43.74789000",
          longitude: "27.40911000",
        },
      ],
      Gabrovo: [
        {
          name: "Dryanovo",
          countryCode: "BG",
          stateCode: "07",
          latitude: "42.97897000",
          longitude: "25.47850000",
        },
        {
          name: "Gabrovo",
          countryCode: "BG",
          stateCode: "07",
          latitude: "42.87472000",
          longitude: "25.33417000",
        },
        {
          name: "Obshtina Dryanovo",
          countryCode: "BG",
          stateCode: "07",
          latitude: "43.00000000",
          longitude: "25.46667000",
        },
        {
          name: "Obshtina Gabrovo",
          countryCode: "BG",
          stateCode: "07",
          latitude: "42.90000000",
          longitude: "25.30000000",
        },
        {
          name: "Obshtina Sevlievo",
          countryCode: "BG",
          stateCode: "07",
          latitude: "43.01667000",
          longitude: "25.10000000",
        },
        {
          name: "Obshtina Tryavna",
          countryCode: "BG",
          stateCode: "07",
          latitude: "42.85000000",
          longitude: "25.55000000",
        },
        {
          name: "Sevlievo",
          countryCode: "BG",
          stateCode: "07",
          latitude: "43.02583000",
          longitude: "25.11361000",
        },
        {
          name: "Tryavna",
          countryCode: "BG",
          stateCode: "07",
          latitude: "42.86667000",
          longitude: "25.50000000",
        },
      ],
      Haskovo: [
        {
          name: "Dimitrovgrad",
          countryCode: "BG",
          stateCode: "26",
          latitude: "42.05000000",
          longitude: "25.60000000",
        },
        {
          name: "Harmanli",
          countryCode: "BG",
          stateCode: "26",
          latitude: "41.93333000",
          longitude: "25.90000000",
        },
        {
          name: "Haskovo",
          countryCode: "BG",
          stateCode: "26",
          latitude: "41.93415000",
          longitude: "25.55557000",
        },
        {
          name: "Ivaylovgrad",
          countryCode: "BG",
          stateCode: "26",
          latitude: "41.52672000",
          longitude: "26.12490000",
        },
        {
          name: "Lyubimets",
          countryCode: "BG",
          stateCode: "26",
          latitude: "41.83333000",
          longitude: "26.08333000",
        },
        {
          name: "Madzharovo",
          countryCode: "BG",
          stateCode: "26",
          latitude: "41.63461000",
          longitude: "25.86439000",
        },
        {
          name: "Mineralni Bani",
          countryCode: "BG",
          stateCode: "26",
          latitude: "41.91667000",
          longitude: "25.35000000",
        },
        {
          name: "Obshtina Dimitrovgrad",
          countryCode: "BG",
          stateCode: "26",
          latitude: "42.05000000",
          longitude: "25.60000000",
        },
        {
          name: "Obshtina Harmanli",
          countryCode: "BG",
          stateCode: "26",
          latitude: "41.93333000",
          longitude: "25.98333000",
        },
        {
          name: "Obshtina Haskovo",
          countryCode: "BG",
          stateCode: "26",
          latitude: "41.88333000",
          longitude: "25.61667000",
        },
        {
          name: "Obshtina Ivaylovgrad",
          countryCode: "BG",
          stateCode: "26",
          latitude: "41.48333000",
          longitude: "26.00000000",
        },
        {
          name: "Obshtina Madzharovo",
          countryCode: "BG",
          stateCode: "26",
          latitude: "41.68333000",
          longitude: "25.90000000",
        },
        {
          name: "Obshtina Mineralni Bani",
          countryCode: "BG",
          stateCode: "26",
          latitude: "41.90000000",
          longitude: "25.33333000",
        },
        {
          name: "Obshtina Stambolovo",
          countryCode: "BG",
          stateCode: "26",
          latitude: "41.73333000",
          longitude: "25.70000000",
        },
        {
          name: "Obshtina Svilengrad",
          countryCode: "BG",
          stateCode: "26",
          latitude: "41.86667000",
          longitude: "26.31667000",
        },
        {
          name: "Obshtina Topolovgrad",
          countryCode: "BG",
          stateCode: "26",
          latitude: "42.08333000",
          longitude: "26.33333000",
        },
        {
          name: "Simeonovgrad",
          countryCode: "BG",
          stateCode: "26",
          latitude: "42.03333000",
          longitude: "25.83333000",
        },
        {
          name: "Svilengrad",
          countryCode: "BG",
          stateCode: "26",
          latitude: "41.76667000",
          longitude: "26.20000000",
        },
        {
          name: "Topolovgrad",
          countryCode: "BG",
          stateCode: "26",
          latitude: "42.08333000",
          longitude: "26.33333000",
        },
      ],
      Kardzhali: [
        {
          name: "Ardino",
          countryCode: "BG",
          stateCode: "09",
          latitude: "41.58333000",
          longitude: "25.13333000",
        },
        {
          name: "Dzhebel",
          countryCode: "BG",
          stateCode: "09",
          latitude: "41.49566000",
          longitude: "25.30363000",
        },
        {
          name: "Kardzhali",
          countryCode: "BG",
          stateCode: "09",
          latitude: "41.65000000",
          longitude: "25.36667000",
        },
        {
          name: "Kirkovo",
          countryCode: "BG",
          stateCode: "09",
          latitude: "41.32715000",
          longitude: "25.36379000",
        },
        {
          name: "Krumovgrad",
          countryCode: "BG",
          stateCode: "09",
          latitude: "41.47127000",
          longitude: "25.65485000",
        },
        {
          name: "Obshtina Ardino",
          countryCode: "BG",
          stateCode: "09",
          latitude: "41.60000000",
          longitude: "25.15000000",
        },
        {
          name: "Obshtina Chernoochene",
          countryCode: "BG",
          stateCode: "09",
          latitude: "41.78333000",
          longitude: "25.25000000",
        },
        {
          name: "Obshtina Dzhebel",
          countryCode: "BG",
          stateCode: "09",
          latitude: "41.50000000",
          longitude: "25.26667000",
        },
        {
          name: "Obshtina Kardzhali",
          countryCode: "BG",
          stateCode: "09",
          latitude: "41.68333000",
          longitude: "25.43333000",
        },
        {
          name: "Obshtina Kirkovo",
          countryCode: "BG",
          stateCode: "09",
          latitude: "41.35816000",
          longitude: "25.33731000",
        },
        {
          name: "Obshtina Momchilgrad",
          countryCode: "BG",
          stateCode: "09",
          latitude: "41.53333000",
          longitude: "25.50000000",
        },
      ],
      Kyustendil: [
        {
          name: "Boboshevo",
          countryCode: "BG",
          stateCode: "10",
          latitude: "42.15296000",
          longitude: "23.00146000",
        },
        {
          name: "Bobov Dol",
          countryCode: "BG",
          stateCode: "10",
          latitude: "42.36256000",
          longitude: "23.00324000",
        },
        {
          name: "Dupnitsa",
          countryCode: "BG",
          stateCode: "10",
          latitude: "42.26667000",
          longitude: "23.11667000",
        },
        {
          name: "Kocherinovo",
          countryCode: "BG",
          stateCode: "10",
          latitude: "42.08439000",
          longitude: "23.05710000",
        },
        {
          name: "Kyustendil",
          countryCode: "BG",
          stateCode: "10",
          latitude: "42.28389000",
          longitude: "22.69111000",
        },
        {
          name: "Nevestino",
          countryCode: "BG",
          stateCode: "10",
          latitude: "42.25551000",
          longitude: "22.85175000",
        },
        {
          name: "Obshtina Boboshevo",
          countryCode: "BG",
          stateCode: "10",
          latitude: "42.18333000",
          longitude: "23.02731000",
        },
        {
          name: "Obshtina Bobov Dol",
          countryCode: "BG",
          stateCode: "10",
          latitude: "42.31667000",
          longitude: "22.98333000",
        },
        {
          name: "Obshtina Dupnitsa",
          countryCode: "BG",
          stateCode: "10",
          latitude: "42.28333000",
          longitude: "23.15000000",
        },
        {
          name: "Obshtina Kocherinovo",
          countryCode: "BG",
          stateCode: "10",
          latitude: "42.09909000",
          longitude: "23.02942000",
        },
        {
          name: "Obshtina Kyustendil",
          countryCode: "BG",
          stateCode: "10",
          latitude: "42.28333000",
          longitude: "22.68333000",
        },
        {
          name: "Obshtina Nevestino",
          countryCode: "BG",
          stateCode: "10",
          latitude: "42.16667000",
          longitude: "22.83333000",
        },
        {
          name: "Obshtina Rila",
          countryCode: "BG",
          stateCode: "10",
          latitude: "42.15000000",
          longitude: "23.31667000",
        },
        {
          name: "Obshtina Sapareva Banya",
          countryCode: "BG",
          stateCode: "10",
          latitude: "42.28333000",
          longitude: "23.31667000",
        },
        {
          name: "Obshtina Treklyano",
          countryCode: "BG",
          stateCode: "10",
          latitude: "42.55000000",
          longitude: "22.60000000",
        },
        {
          name: "Rila",
          countryCode: "BG",
          stateCode: "10",
          latitude: "42.13333000",
          longitude: "23.13333000",
        },
        {
          name: "Sapareva Banya",
          countryCode: "BG",
          stateCode: "10",
          latitude: "42.28333000",
          longitude: "23.26667000",
        },
      ],
      Lovech: [
        {
          name: "Apriltsi",
          countryCode: "BG",
          stateCode: "11",
          latitude: "42.84142000",
          longitude: "24.91759000",
        },
        {
          name: "Letnitsa",
          countryCode: "BG",
          stateCode: "11",
          latitude: "43.31167000",
          longitude: "25.07333000",
        },
        {
          name: "Lovech",
          countryCode: "BG",
          stateCode: "11",
          latitude: "43.13333000",
          longitude: "24.71667000",
        },
        {
          name: "Lukovit",
          countryCode: "BG",
          stateCode: "11",
          latitude: "43.20000000",
          longitude: "24.16667000",
        },
        {
          name: "Obshtina Lovech",
          countryCode: "BG",
          stateCode: "11",
          latitude: "43.16667000",
          longitude: "24.80000000",
        },
        {
          name: "Obshtina Teteven",
          countryCode: "BG",
          stateCode: "11",
          latitude: "42.91667000",
          longitude: "24.26667000",
        },
        {
          name: "Obshtina Ugarchin",
          countryCode: "BG",
          stateCode: "11",
          latitude: "43.10000000",
          longitude: "24.41667000",
        },
        {
          name: "Teteven",
          countryCode: "BG",
          stateCode: "11",
          latitude: "42.91667000",
          longitude: "24.26667000",
        },
        {
          name: "Troyan",
          countryCode: "BG",
          stateCode: "11",
          latitude: "42.89427000",
          longitude: "24.71589000",
        },
        {
          name: "Ugarchin",
          countryCode: "BG",
          stateCode: "11",
          latitude: "43.10000000",
          longitude: "24.41667000",
        },
        {
          name: "Yablanitsa",
          countryCode: "BG",
          stateCode: "11",
          latitude: "43.03139000",
          longitude: "24.11278000",
        },
      ],
      Montana: [
        {
          name: "Berkovitsa",
          countryCode: "BG",
          stateCode: "12",
          latitude: "43.23581000",
          longitude: "23.12738000",
        },
        {
          name: "Boychinovtsi",
          countryCode: "BG",
          stateCode: "12",
          latitude: "43.47222000",
          longitude: "23.33583000",
        },
        {
          name: "Brusartsi",
          countryCode: "BG",
          stateCode: "12",
          latitude: "43.66075000",
          longitude: "23.06732000",
        },
        {
          name: "Chiprovtsi",
          countryCode: "BG",
          stateCode: "12",
          latitude: "43.38417000",
          longitude: "22.88083000",
        },
        {
          name: "Lom",
          countryCode: "BG",
          stateCode: "12",
          latitude: "43.82106000",
          longitude: "23.23677000",
        },
        {
          name: "Medkovets",
          countryCode: "BG",
          stateCode: "12",
          latitude: "43.62403000",
          longitude: "23.16876000",
        },
        {
          name: "Montana",
          countryCode: "BG",
          stateCode: "12",
          latitude: "43.41250000",
          longitude: "23.22500000",
        },
        {
          name: "Obshtina Boychinovtsi",
          countryCode: "BG",
          stateCode: "12",
          latitude: "43.53333000",
          longitude: "23.43333000",
        },
        {
          name: "Obshtina Chiprovtsi",
          countryCode: "BG",
          stateCode: "12",
          latitude: "43.41667000",
          longitude: "22.90000000",
        },
        {
          name: "Obshtina Georgi Damyanovo",
          countryCode: "BG",
          stateCode: "12",
          latitude: "43.35000000",
          longitude: "23.00000000",
        },
        {
          name: "Obshtina Lom",
          countryCode: "BG",
          stateCode: "12",
          latitude: "43.81667000",
          longitude: "23.23333000",
        },
        {
          name: "Obshtina Montana",
          countryCode: "BG",
          stateCode: "12",
          latitude: "43.46667000",
          longitude: "23.20000000",
        },
        {
          name: "Obshtina Valchedram",
          countryCode: "BG",
          stateCode: "12",
          latitude: "43.71667000",
          longitude: "23.51667000",
        },
        {
          name: "Obshtina Varshets",
          countryCode: "BG",
          stateCode: "12",
          latitude: "43.21667000",
          longitude: "23.33333000",
        },
        {
          name: "Obshtina Yakimovo",
          countryCode: "BG",
          stateCode: "12",
          latitude: "43.63333000",
          longitude: "23.33333000",
        },
        {
          name: "Valchedram",
          countryCode: "BG",
          stateCode: "12",
          latitude: "43.69281000",
          longitude: "23.44518000",
        },
        {
          name: "Varshets",
          countryCode: "BG",
          stateCode: "12",
          latitude: "43.19356000",
          longitude: "23.28680000",
        },
        {
          name: "Yakimovo",
          countryCode: "BG",
          stateCode: "12",
          latitude: "43.63472000",
          longitude: "23.35350000",
        },
      ],
      Pazardzhik: [
        {
          name: "Batak",
          countryCode: "BG",
          stateCode: "13",
          latitude: "41.94225000",
          longitude: "24.21843000",
        },
        {
          name: "Belovo",
          countryCode: "BG",
          stateCode: "13",
          latitude: "42.21239000",
          longitude: "24.02081000",
        },
        {
          name: "Bratsigovo",
          countryCode: "BG",
          stateCode: "13",
          latitude: "42.01667000",
          longitude: "24.36667000",
        },
        {
          name: "Lesichovo",
          countryCode: "BG",
          stateCode: "13",
          latitude: "42.35626000",
          longitude: "24.11190000",
        },
        {
          name: "Obshtina Batak",
          countryCode: "BG",
          stateCode: "13",
          latitude: "41.86667000",
          longitude: "24.21667000",
        },
        {
          name: "Obshtina Belovo",
          countryCode: "BG",
          stateCode: "13",
          latitude: "42.20000000",
          longitude: "23.93333000",
        },
        {
          name: "Obshtina Bratsigovo",
          countryCode: "BG",
          stateCode: "13",
          latitude: "42.00000000",
          longitude: "24.40000000",
        },
        {
          name: "Obshtina Lesichovo",
          countryCode: "BG",
          stateCode: "13",
          latitude: "42.36667000",
          longitude: "24.15000000",
        },
        {
          name: "Obshtina Panagyurishte",
          countryCode: "BG",
          stateCode: "13",
          latitude: "42.50000000",
          longitude: "24.16667000",
        },
        {
          name: "Obshtina Pazardzhik",
          countryCode: "BG",
          stateCode: "13",
          latitude: "42.20000000",
          longitude: "24.33333000",
        },
        {
          name: "Obshtina Peshtera",
          countryCode: "BG",
          stateCode: "13",
          latitude: "42.03333000",
          longitude: "24.30000000",
        },
        {
          name: "Obshtina Rakitovo",
          countryCode: "BG",
          stateCode: "13",
          latitude: "41.99965000",
          longitude: "24.10877000",
        },
        {
          name: "Obshtina Septemvri",
          countryCode: "BG",
          stateCode: "13",
          latitude: "42.26667000",
          longitude: "24.13333000",
        },
        {
          name: "Obshtina Strelcha",
          countryCode: "BG",
          stateCode: "13",
          latitude: "42.46667000",
          longitude: "24.36667000",
        },
        {
          name: "Obshtina Velingrad",
          countryCode: "BG",
          stateCode: "13",
          latitude: "41.96667000",
          longitude: "23.93333000",
        },
        {
          name: "Panagyurishte",
          countryCode: "BG",
          stateCode: "13",
          latitude: "42.49518000",
          longitude: "24.19021000",
        },
        {
          name: "Pazardzhik",
          countryCode: "BG",
          stateCode: "13",
          latitude: "42.20000000",
          longitude: "24.33333000",
        },
        {
          name: "Peshtera",
          countryCode: "BG",
          stateCode: "13",
          latitude: "42.03372000",
          longitude: "24.29995000",
        },
        {
          name: "Rakitovo",
          countryCode: "BG",
          stateCode: "13",
          latitude: "41.99012000",
          longitude: "24.08730000",
        },
        {
          name: "Sarnitsa",
          countryCode: "BG",
          stateCode: "13",
          latitude: "41.73835000",
          longitude: "24.02435000",
        },
        {
          name: "Sarnitsa Obshtina",
          countryCode: "BG",
          stateCode: "13",
          latitude: "41.73333000",
          longitude: "24.03333000",
        },
        {
          name: "Septemvri",
          countryCode: "BG",
          stateCode: "13",
          latitude: "42.21138000",
          longitude: "24.12946000",
        },
        {
          name: "Strelcha",
          countryCode: "BG",
          stateCode: "13",
          latitude: "42.50000000",
          longitude: "24.31667000",
        },
        {
          name: "Velingrad",
          countryCode: "BG",
          stateCode: "13",
          latitude: "42.02754000",
          longitude: "23.99155000",
        },
      ],
      Pernik: [
        {
          name: "Batanovtsi",
          countryCode: "BG",
          stateCode: "14",
          latitude: "42.59692000",
          longitude: "22.95634000",
        },
        {
          name: "Breznik",
          countryCode: "BG",
          stateCode: "14",
          latitude: "42.74085000",
          longitude: "22.90723000",
        },
        {
          name: "Obshtina Kovachevtsi",
          countryCode: "BG",
          stateCode: "14",
          latitude: "42.55000000",
          longitude: "22.83333000",
        },
        {
          name: "Obshtina Pernik",
          countryCode: "BG",
          stateCode: "14",
          latitude: "42.61667000",
          longitude: "23.08333000",
        },
        {
          name: "Obshtina Radomir",
          countryCode: "BG",
          stateCode: "14",
          latitude: "42.46667000",
          longitude: "23.01667000",
        },
        {
          name: "Obshtina Zemen",
          countryCode: "BG",
          stateCode: "14",
          latitude: "42.55067000",
          longitude: "22.71440000",
        },
        {
          name: "Pernik",
          countryCode: "BG",
          stateCode: "14",
          latitude: "42.60000000",
          longitude: "23.03333000",
        },
        {
          name: "Radomir",
          countryCode: "BG",
          stateCode: "14",
          latitude: "42.54565000",
          longitude: "22.96553000",
        },
        {
          name: "Tran",
          countryCode: "BG",
          stateCode: "14",
          latitude: "42.83528000",
          longitude: "22.65167000",
        },
        {
          name: "Zemen",
          countryCode: "BG",
          stateCode: "14",
          latitude: "42.47889000",
          longitude: "22.74917000",
        },
      ],
      Pleven: [
        {
          name: "Belene",
          countryCode: "BG",
          stateCode: "15",
          latitude: "43.64594000",
          longitude: "25.12627000",
        },
        {
          name: "Cherven Bryag",
          countryCode: "BG",
          stateCode: "15",
          latitude: "43.26667000",
          longitude: "24.10000000",
        },
        {
          name: "Dolna Mitropolia",
          countryCode: "BG",
          stateCode: "15",
          latitude: "43.46667000",
          longitude: "24.53333000",
        },
        {
          name: "Dolni Dabnik",
          countryCode: "BG",
          stateCode: "15",
          latitude: "43.40000000",
          longitude: "24.43333000",
        },
        {
          name: "Gulyantsi",
          countryCode: "BG",
          stateCode: "15",
          latitude: "43.64109000",
          longitude: "24.69368000",
        },
        {
          name: "Iskar",
          countryCode: "BG",
          stateCode: "15",
          latitude: "43.45000000",
          longitude: "24.26667000",
        },
        {
          name: "Knezha",
          countryCode: "BG",
          stateCode: "15",
          latitude: "43.50000000",
          longitude: "24.08333000",
        },
        {
          name: "Koynare",
          countryCode: "BG",
          stateCode: "15",
          latitude: "43.35000000",
          longitude: "24.13333000",
        },
        {
          name: "Levski",
          countryCode: "BG",
          stateCode: "15",
          latitude: "43.36667000",
          longitude: "25.13333000",
        },
        {
          name: "Nikopol",
          countryCode: "BG",
          stateCode: "15",
          latitude: "43.70528000",
          longitude: "24.89521000",
        },
        {
          name: "Obshtina Belene",
          countryCode: "BG",
          stateCode: "15",
          latitude: "43.63333000",
          longitude: "25.13333000",
        },
        {
          name: "Obshtina Cherven Bryag",
          countryCode: "BG",
          stateCode: "15",
          latitude: "43.33333000",
          longitude: "24.13333000",
        },
        {
          name: "Obshtina Dolna Mitropolia",
          countryCode: "BG",
          stateCode: "15",
          latitude: "43.58333000",
          longitude: "24.41667000",
        },
        {
          name: "Obshtina Dolni Dabnik",
          countryCode: "BG",
          stateCode: "15",
          latitude: "43.36667000",
          longitude: "24.41667000",
        },
        {
          name: "Obshtina Gulyantsi",
          countryCode: "BG",
          stateCode: "15",
          latitude: "43.68333000",
          longitude: "24.65000000",
        },
        {
          name: "Obshtina Iskar",
          countryCode: "BG",
          stateCode: "15",
          latitude: "43.50000000",
          longitude: "24.28333000",
        },
        {
          name: "Obshtina Knezha",
          countryCode: "BG",
          stateCode: "15",
          latitude: "43.50000000",
          longitude: "24.11667000",
        },
        {
          name: "Obshtina Levski",
          countryCode: "BG",
          stateCode: "15",
          latitude: "43.43333000",
          longitude: "25.10000000",
        },
        {
          name: "Obshtina Nikopol",
          countryCode: "BG",
          stateCode: "15",
          latitude: "43.60000000",
          longitude: "24.93333000",
        },
        {
          name: "Obshtina Pleven",
          countryCode: "BG",
          stateCode: "15",
          latitude: "43.40000000",
          longitude: "24.70000000",
        },
        {
          name: "Obshtina Pordim",
          countryCode: "BG",
          stateCode: "15",
          latitude: "43.38333000",
          longitude: "24.90000000",
        },
        {
          name: "Pleven",
          countryCode: "BG",
          stateCode: "15",
          latitude: "43.41667000",
          longitude: "24.61667000",
        },
        {
          name: "Pordim",
          countryCode: "BG",
          stateCode: "15",
          latitude: "43.38333000",
          longitude: "24.85000000",
        },
        {
          name: "Slavyanovo",
          countryCode: "BG",
          stateCode: "15",
          latitude: "43.46667000",
          longitude: "24.86667000",
        },
      ],
      Plovdiv: [
        {
          name: "Asenovgrad",
          countryCode: "BG",
          stateCode: "16",
          latitude: "42.01667000",
          longitude: "24.86667000",
        },
        {
          name: "Brezovo",
          countryCode: "BG",
          stateCode: "16",
          latitude: "42.35000000",
          longitude: "25.08333000",
        },
        {
          name: "Hisarya",
          countryCode: "BG",
          stateCode: "16",
          latitude: "42.50000000",
          longitude: "24.70000000",
        },
        {
          name: "Kalofer",
          countryCode: "BG",
          stateCode: "16",
          latitude: "42.61667000",
          longitude: "24.98333000",
        },
        {
          name: "Kaloyanovo",
          countryCode: "BG",
          stateCode: "16",
          latitude: "42.35000000",
          longitude: "24.73333000",
        },
        {
          name: "Karlovo",
          countryCode: "BG",
          stateCode: "16",
          latitude: "42.63333000",
          longitude: "24.80000000",
        },
        {
          name: "Klisura",
          countryCode: "BG",
          stateCode: "16",
          latitude: "42.70000000",
          longitude: "24.45000000",
        },
        {
          name: "Krichim",
          countryCode: "BG",
          stateCode: "16",
          latitude: "42.05000000",
          longitude: "24.46667000",
        },
        {
          name: "Laki",
          countryCode: "BG",
          stateCode: "16",
          latitude: "41.85000000",
          longitude: "24.81667000",
        },
        {
          name: "Obshtina Asenovgrad",
          countryCode: "BG",
          stateCode: "16",
          latitude: "41.95000000",
          longitude: "24.96667000",
        },
        {
          name: "Obshtina Hisarya",
          countryCode: "BG",
          stateCode: "16",
          latitude: "42.50000000",
          longitude: "24.60000000",
        },
        {
          name: "Obshtina Kaloyanovo",
          countryCode: "BG",
          stateCode: "16",
          latitude: "42.38333000",
          longitude: "24.81667000",
        },
        {
          name: "Obshtina Karlovo",
          countryCode: "BG",
          stateCode: "16",
          latitude: "42.68333000",
          longitude: "24.66667000",
        },
        {
          name: "Obshtina Krichim",
          countryCode: "BG",
          stateCode: "16",
          latitude: "42.00439000",
          longitude: "24.47235000",
        },
        {
          name: "Obshtina Kuklen",
          countryCode: "BG",
          stateCode: "16",
          latitude: "42.03725000",
          longitude: "24.77949000",
        },
        {
          name: "Obshtina Laki",
          countryCode: "BG",
          stateCode: "16",
          latitude: "41.81667000",
          longitude: "24.85000000",
        },
        {
          name: "Obshtina Maritsa",
          countryCode: "BG",
          stateCode: "16",
          latitude: "42.23333000",
          longitude: "24.80000000",
        },
        {
          name: "Obshtina Parvomay",
          countryCode: "BG",
          stateCode: "16",
          latitude: "42.03333000",
          longitude: "25.20000000",
        },
        {
          name: "Obshtina Perushtitsa",
          countryCode: "BG",
          stateCode: "16",
          latitude: "42.06667000",
          longitude: "24.55000000",
        },
        {
          name: "Obshtina Plovdiv",
          countryCode: "BG",
          stateCode: "16",
          latitude: "42.15000000",
          longitude: "24.75000000",
        },
        {
          name: "Obshtina Rakovski",
          countryCode: "BG",
          stateCode: "16",
          latitude: "42.26667000",
          longitude: "24.98333000",
        },
        {
          name: "Obshtina Rodopi",
          countryCode: "BG",
          stateCode: "16",
          latitude: "42.03333000",
          longitude: "24.68333000",
        },
        {
          name: "Obshtina Sadovo",
          countryCode: "BG",
          stateCode: "16",
          latitude: "42.13333000",
          longitude: "25.00000000",
        },
        {
          name: "Obshtina Saedinenie",
          countryCode: "BG",
          stateCode: "16",
          latitude: "42.31667000",
          longitude: "24.58333000",
        },
        {
          name: "Obshtina Sopot",
          countryCode: "BG",
          stateCode: "16",
          latitude: "42.65214000",
          longitude: "24.74310000",
        },
        {
          name: "Obshtina Stamboliyski",
          countryCode: "BG",
          stateCode: "16",
          latitude: "42.11667000",
          longitude: "24.51667000",
        },
        {
          name: "Parvomay",
          countryCode: "BG",
          stateCode: "16",
          latitude: "42.10000000",
          longitude: "25.21667000",
        },
        {
          name: "Perushtitsa",
          countryCode: "BG",
          stateCode: "16",
          latitude: "42.05000000",
          longitude: "24.55000000",
        },
        {
          name: "Plovdiv",
          countryCode: "BG",
          stateCode: "16",
          latitude: "42.15000000",
          longitude: "24.75000000",
        },
        {
          name: "Rakovski",
          countryCode: "BG",
          stateCode: "16",
          latitude: "42.27408000",
          longitude: "24.94083000",
        },
        {
          name: "Sadovo",
          countryCode: "BG",
          stateCode: "16",
          latitude: "42.13178000",
          longitude: "24.93999000",
        },
        {
          name: "Saedinenie",
          countryCode: "BG",
          stateCode: "16",
          latitude: "42.26667000",
          longitude: "24.55000000",
        },
        {
          name: "Stamboliyski",
          countryCode: "BG",
          stateCode: "16",
          latitude: "42.13333000",
          longitude: "24.53333000",
        },
        {
          name: "Topolovo",
          countryCode: "BG",
          stateCode: "16",
          latitude: "41.90000000",
          longitude: "25.00000000",
        },
      ],
      Razgrad: [
        {
          name: "Isperih",
          countryCode: "BG",
          stateCode: "17",
          latitude: "43.71667000",
          longitude: "26.83333000",
        },
        {
          name: "Kubrat",
          countryCode: "BG",
          stateCode: "17",
          latitude: "43.79658000",
          longitude: "26.50063000",
        },
        {
          name: "Loznitsa",
          countryCode: "BG",
          stateCode: "17",
          latitude: "43.36667000",
          longitude: "26.60000000",
        },
        {
          name: "Medovene",
          countryCode: "BG",
          stateCode: "17",
          latitude: "43.76667000",
          longitude: "26.51667000",
        },
        {
          name: "Obshtina Isperih",
          countryCode: "BG",
          stateCode: "17",
          latitude: "43.71667000",
          longitude: "26.83333000",
        },
        {
          name: "Obshtina Kubrat",
          countryCode: "BG",
          stateCode: "17",
          latitude: "43.86667000",
          longitude: "26.48333000",
        },
        {
          name: "Obshtina Loznitsa",
          countryCode: "BG",
          stateCode: "17",
          latitude: "43.41667000",
          longitude: "26.58333000",
        },
        {
          name: "Obshtina Razgrad",
          countryCode: "BG",
          stateCode: "17",
          latitude: "43.53333000",
          longitude: "26.51667000",
        },
        {
          name: "Obshtina Samuil",
          countryCode: "BG",
          stateCode: "17",
          latitude: "43.60000000",
          longitude: "26.80000000",
        },
        {
          name: "Obshtina Tsar Kaloyan",
          countryCode: "BG",
          stateCode: "17",
          latitude: "43.60000000",
          longitude: "26.21667000",
        },
        {
          name: "Obshtina Zavet",
          countryCode: "BG",
          stateCode: "17",
          latitude: "43.76667000",
          longitude: "26.68333000",
        },
        {
          name: "Razgrad",
          countryCode: "BG",
          stateCode: "17",
          latitude: "43.53333000",
          longitude: "26.51667000",
        },
        {
          name: "Samuil",
          countryCode: "BG",
          stateCode: "17",
          latitude: "43.51667000",
          longitude: "26.75000000",
        },
        {
          name: "Tsar Kaloyan",
          countryCode: "BG",
          stateCode: "17",
          latitude: "43.61667000",
          longitude: "26.25000000",
        },
        {
          name: "Zavet",
          countryCode: "BG",
          stateCode: "17",
          latitude: "43.76036000",
          longitude: "26.68063000",
        },
      ],
      Ruse: [
        {
          name: "Borovo",
          countryCode: "BG",
          stateCode: "18",
          latitude: "43.50086000",
          longitude: "25.80992000",
        },
        {
          name: "Dve Mogili",
          countryCode: "BG",
          stateCode: "18",
          latitude: "43.59258000",
          longitude: "25.87486000",
        },
        {
          name: "Ivanovo",
          countryCode: "BG",
          stateCode: "18",
          latitude: "43.68568000",
          longitude: "25.95565000",
        },
        {
          name: "Obshtina Borovo",
          countryCode: "BG",
          stateCode: "18",
          latitude: "43.60000000",
          longitude: "25.80000000",
        },
        {
          name: "Obshtina Byala",
          countryCode: "BG",
          stateCode: "18",
          latitude: "43.41667000",
          longitude: "25.83333000",
        },
        {
          name: "Obshtina Dve Mogili",
          countryCode: "BG",
          stateCode: "18",
          latitude: "43.51667000",
          longitude: "25.96667000",
        },
        {
          name: "Obshtina Ivanovo",
          countryCode: "BG",
          stateCode: "18",
          latitude: "43.70000000",
          longitude: "25.98333000",
        },
        {
          name: "Obshtina Ruse",
          countryCode: "BG",
          stateCode: "18",
          latitude: "43.85000000",
          longitude: "26.08333000",
        },
        {
          name: "Obshtina Slivo Pole",
          countryCode: "BG",
          stateCode: "18",
          latitude: "43.96667000",
          longitude: "26.30000000",
        },
        {
          name: "Obshtina Tsenovo",
          countryCode: "BG",
          stateCode: "18",
          latitude: "43.58333000",
          longitude: "25.61667000",
        },
        {
          name: "Obshtina Vetovo",
          countryCode: "BG",
          stateCode: "18",
          latitude: "43.71667000",
          longitude: "26.33333000",
        },
        {
          name: "Ruse",
          countryCode: "BG",
          stateCode: "18",
          latitude: "43.84872000",
          longitude: "25.95340000",
        },
        {
          name: "Senovo",
          countryCode: "BG",
          stateCode: "18",
          latitude: "43.65000000",
          longitude: "26.36667000",
        },
        {
          name: "Slivo Pole",
          countryCode: "BG",
          stateCode: "18",
          latitude: "43.94248000",
          longitude: "26.20464000",
        },
        {
          name: "Tsenovo",
          countryCode: "BG",
          stateCode: "18",
          latitude: "43.53588000",
          longitude: "25.65764000",
        },
        {
          name: "Vetovo",
          countryCode: "BG",
          stateCode: "18",
          latitude: "43.70000000",
          longitude: "26.26667000",
        },
      ],
      Shumen: [
        {
          name: "Shumen",
          countryCode: "BG",
          stateCode: "27",
          latitude: "43.28333300",
          longitude: "26.93333300",
        },
      ],
      Silistra: [
        {
          name: "Alfatar",
          countryCode: "BG",
          stateCode: "19",
          latitude: "43.94525000",
          longitude: "27.28751000",
        },
        {
          name: "Dulovo",
          countryCode: "BG",
          stateCode: "19",
          latitude: "43.81667000",
          longitude: "27.15000000",
        },
        {
          name: "Glavinitsa",
          countryCode: "BG",
          stateCode: "19",
          latitude: "43.91667000",
          longitude: "26.83333000",
        },
        {
          name: "Kaynardzha",
          countryCode: "BG",
          stateCode: "19",
          latitude: "43.99278000",
          longitude: "27.50713000",
        },
        {
          name: "Obshtina Alfatar",
          countryCode: "BG",
          stateCode: "19",
          latitude: "43.90000000",
          longitude: "27.33333000",
        },
        {
          name: "Obshtina Dulovo",
          countryCode: "BG",
          stateCode: "19",
          latitude: "43.81667000",
          longitude: "27.08333000",
        },
        {
          name: "Obshtina Glavinitsa",
          countryCode: "BG",
          stateCode: "19",
          latitude: "43.95000000",
          longitude: "26.85000000",
        },
        {
          name: "Obshtina Kaynardzha",
          countryCode: "BG",
          stateCode: "19",
          latitude: "43.95000000",
          longitude: "27.53333000",
        },
        {
          name: "Obshtina Silistra",
          countryCode: "BG",
          stateCode: "19",
          latitude: "44.03333000",
          longitude: "27.20000000",
        },
        {
          name: "Obshtina Sitovo",
          countryCode: "BG",
          stateCode: "19",
          latitude: "44.01667000",
          longitude: "27.00000000",
        },
        {
          name: "Obshtina Tutrakan",
          countryCode: "BG",
          stateCode: "19",
          latitude: "44.00000000",
          longitude: "26.60000000",
        },
        {
          name: "Silistra",
          countryCode: "BG",
          stateCode: "19",
          latitude: "44.11710000",
          longitude: "27.26056000",
        },
        {
          name: "Sitovo",
          countryCode: "BG",
          stateCode: "19",
          latitude: "44.02730000",
          longitude: "27.01982000",
        },
        {
          name: "Tutrakan",
          countryCode: "BG",
          stateCode: "19",
          latitude: "44.04906000",
          longitude: "26.61206000",
        },
      ],
      Sliven: [
        {
          name: "Kermen",
          countryCode: "BG",
          stateCode: "20",
          latitude: "42.50000000",
          longitude: "26.25000000",
        },
        {
          name: "Kotel",
          countryCode: "BG",
          stateCode: "20",
          latitude: "42.88333000",
          longitude: "26.45000000",
        },
        {
          name: "Nova Zagora",
          countryCode: "BG",
          stateCode: "20",
          latitude: "42.48333000",
          longitude: "26.01667000",
        },
        {
          name: "Obshtina Kotel",
          countryCode: "BG",
          stateCode: "20",
          latitude: "42.88333000",
          longitude: "26.45000000",
        },
        {
          name: "Obshtina Nova Zagora",
          countryCode: "BG",
          stateCode: "20",
          latitude: "42.45000000",
          longitude: "26.05000000",
        },
        {
          name: "Obshtina Sliven",
          countryCode: "BG",
          stateCode: "20",
          latitude: "42.66667000",
          longitude: "26.31667000",
        },
        {
          name: "Obshtina Tvarditsa",
          countryCode: "BG",
          stateCode: "20",
          latitude: "42.73333000",
          longitude: "26.00000000",
        },
        {
          name: "Sliven",
          countryCode: "BG",
          stateCode: "20",
          latitude: "42.68583000",
          longitude: "26.32917000",
        },
        {
          name: "Tvarditsa",
          countryCode: "BG",
          stateCode: "20",
          latitude: "42.70000000",
          longitude: "25.90000000",
        },
      ],
      Smolyan: [
        {
          name: "Banite",
          countryCode: "BG",
          stateCode: "21",
          latitude: "41.61667000",
          longitude: "25.01667000",
        },
        {
          name: "Borino",
          countryCode: "BG",
          stateCode: "21",
          latitude: "41.68408000",
          longitude: "24.29300000",
        },
        {
          name: "Chepelare",
          countryCode: "BG",
          stateCode: "21",
          latitude: "41.73333000",
          longitude: "24.68333000",
        },
        {
          name: "Devin",
          countryCode: "BG",
          stateCode: "21",
          latitude: "41.74327000",
          longitude: "24.40003000",
        },
        {
          name: "Dospat",
          countryCode: "BG",
          stateCode: "21",
          latitude: "41.64462000",
          longitude: "24.15857000",
        },
        {
          name: "Gyovren",
          countryCode: "BG",
          stateCode: "21",
          latitude: "41.66326000",
          longitude: "24.37557000",
        },
        {
          name: "Madan",
          countryCode: "BG",
          stateCode: "21",
          latitude: "41.49869000",
          longitude: "24.93973000",
        },
        {
          name: "Nedelino",
          countryCode: "BG",
          stateCode: "21",
          latitude: "41.45602000",
          longitude: "25.08008000",
        },
        {
          name: "Obshtina Banite",
          countryCode: "BG",
          stateCode: "21",
          latitude: "41.70000000",
          longitude: "25.00000000",
        },
        {
          name: "Obshtina Borino",
          countryCode: "BG",
          stateCode: "21",
          latitude: "41.64834000",
          longitude: "24.31694000",
        },
        {
          name: "Obshtina Chepelare",
          countryCode: "BG",
          stateCode: "21",
          latitude: "41.80000000",
          longitude: "24.68333000",
        },
        {
          name: "Obshtina Devin",
          countryCode: "BG",
          stateCode: "21",
          latitude: "41.74346000",
          longitude: "24.40192000",
        },
        {
          name: "Obshtina Dospat",
          countryCode: "BG",
          stateCode: "21",
          latitude: "41.63228000",
          longitude: "24.18765000",
        },
        {
          name: "Obshtina Madan",
          countryCode: "BG",
          stateCode: "21",
          latitude: "41.53333000",
          longitude: "24.96667000",
        },
        {
          name: "Obshtina Nedelino",
          countryCode: "BG",
          stateCode: "21",
          latitude: "41.46894000",
          longitude: "25.09481000",
        },
        {
          name: "Obshtina Rudozem",
          countryCode: "BG",
          stateCode: "21",
          latitude: "41.45439000",
          longitude: "24.83690000",
        },
        {
          name: "Obshtina Smolyan",
          countryCode: "BG",
          stateCode: "21",
          latitude: "41.57516000",
          longitude: "24.71302000",
        },
        {
          name: "Obshtina Zlatograd",
          countryCode: "BG",
          stateCode: "21",
          latitude: "41.40028000",
          longitude: "25.04591000",
        },
        {
          name: "Rudozem",
          countryCode: "BG",
          stateCode: "21",
          latitude: "41.48751000",
          longitude: "24.84945000",
        },
        {
          name: "Smolyan",
          countryCode: "BG",
          stateCode: "21",
          latitude: "41.57439000",
          longitude: "24.71204000",
        },
        {
          name: "Zlatograd",
          countryCode: "BG",
          stateCode: "21",
          latitude: "41.37950000",
          longitude: "25.09605000",
        },
      ],
      Sofia_City: [
        {
          name: "Buhovo",
          countryCode: "BG",
          stateCode: "22",
          latitude: "42.76667000",
          longitude: "23.56667000",
        },
        {
          name: "Sofia",
          countryCode: "BG",
          stateCode: "22",
          latitude: "42.69751000",
          longitude: "23.32415000",
        },
        {
          name: "Stolichna Obshtina",
          countryCode: "BG",
          stateCode: "22",
          latitude: "42.68647000",
          longitude: "23.30561000",
        },
      ],
      Sofia: [
        {
          name: "Anton",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.75000000",
          longitude: "24.28333000",
        },
        {
          name: "Botevgrad",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.90000000",
          longitude: "23.78333000",
        },
        {
          name: "Bozhurishte",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.75000000",
          longitude: "23.20000000",
        },
        {
          name: "Chavdar",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.65000000",
          longitude: "24.05000000",
        },
        {
          name: "Chelopech",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.70000000",
          longitude: "24.08333000",
        },
        {
          name: "Dolna Banya",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.30000000",
          longitude: "23.76667000",
        },
        {
          name: "Dragoman",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.92191000",
          longitude: "22.93109000",
        },
        {
          name: "Elin Pelin",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.66667000",
          longitude: "23.60000000",
        },
        {
          name: "Etropole",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.83333000",
          longitude: "24.00000000",
        },
        {
          name: "Godech",
          countryCode: "BG",
          stateCode: "23",
          latitude: "43.01682000",
          longitude: "23.04852000",
        },
        {
          name: "Gorna Malina",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.68333000",
          longitude: "23.70000000",
        },
        {
          name: "Ihtiman",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.43333000",
          longitude: "23.81667000",
        },
        {
          name: "Koprivshtitsa",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.63333000",
          longitude: "24.35000000",
        },
        {
          name: "Kostinbrod",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.81667000",
          longitude: "23.21667000",
        },
        {
          name: "Lakatnik",
          countryCode: "BG",
          stateCode: "23",
          latitude: "43.05000000",
          longitude: "23.40000000",
        },
        {
          name: "Mirkovo",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.70000000",
          longitude: "23.98333000",
        },
        {
          name: "Obshtina Anton",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.76667000",
          longitude: "24.30000000",
        },
        {
          name: "Obshtina Botevgrad",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.95000000",
          longitude: "23.75000000",
        },
        {
          name: "Obshtina Bozhurishte",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.78333000",
          longitude: "23.11667000",
        },
        {
          name: "Obshtina Chavdar",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.61667000",
          longitude: "24.08333000",
        },
        {
          name: "Obshtina Chelopech",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.71667000",
          longitude: "24.06667000",
        },
        {
          name: "Obshtina Dolna Banya",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.30000000",
          longitude: "23.76667000",
        },
        {
          name: "Obshtina Dragoman",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.93333000",
          longitude: "22.93333000",
        },
        {
          name: "Obshtina Elin Pelin",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.66667000",
          longitude: "23.60000000",
        },
        {
          name: "Obshtina Etropole",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.85000000",
          longitude: "24.03333000",
        },
        {
          name: "Obshtina Gorna Malina",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.71667000",
          longitude: "23.80000000",
        },
        {
          name: "Obshtina Koprivshtitsa",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.61667000",
          longitude: "24.38333000",
        },
        {
          name: "Obshtina Kostenets",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.30000000",
          longitude: "23.86667000",
        },
        {
          name: "Obshtina Kostinbrod",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.88333000",
          longitude: "23.20000000",
        },
        {
          name: "Obshtina Mirkovo",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.66667000",
          longitude: "23.96667000",
        },
        {
          name: "Obshtina Pirdop",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.70000000",
          longitude: "24.23333000",
        },
        {
          name: "Obshtina Pravets",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.96667000",
          longitude: "23.98333000",
        },
        {
          name: "Obshtina Samokov",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.33333000",
          longitude: "23.55000000",
        },
        {
          name: "Obshtina Slivnitsa",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.85000000",
          longitude: "22.98333000",
        },
        {
          name: "Obshtina Svoge",
          countryCode: "BG",
          stateCode: "23",
          latitude: "43.00000000",
          longitude: "23.40000000",
        },
        {
          name: "Obshtina Zlatitsa",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.70000000",
          longitude: "24.13333000",
        },
        {
          name: "Pirdop",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.70000000",
          longitude: "24.18333000",
        },
        {
          name: "Pravets",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.88333000",
          longitude: "23.91667000",
        },
        {
          name: "Samokov",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.33700000",
          longitude: "23.55280000",
        },
        {
          name: "Slivnitsa",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.85182000",
          longitude: "23.03792000",
        },
        {
          name: "Svoge",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.96667000",
          longitude: "23.35000000",
        },
        {
          name: "Zlatitsa",
          countryCode: "BG",
          stateCode: "23",
          latitude: "42.71667000",
          longitude: "24.13333000",
        },
      ],
      Stara_Zagora: [
        {
          name: "Asen",
          countryCode: "BG",
          stateCode: "24",
          latitude: "42.65000000",
          longitude: "25.20000000",
        },
        {
          name: "Chirpan",
          countryCode: "BG",
          stateCode: "24",
          latitude: "42.20000000",
          longitude: "25.33333000",
        },
        {
          name: "Gurkovo",
          countryCode: "BG",
          stateCode: "24",
          latitude: "42.66667000",
          longitude: "25.80000000",
        },
        {
          name: "Gŭlŭbovo",
          countryCode: "BG",
          stateCode: "24",
          latitude: "42.13333000",
          longitude: "25.85000000",
        },
        {
          name: "Kazanlak",
          countryCode: "BG",
          stateCode: "24",
          latitude: "42.61667000",
          longitude: "25.40000000",
        },
        {
          name: "Maglizh",
          countryCode: "BG",
          stateCode: "24",
          latitude: "42.60000000",
          longitude: "25.55000000",
        },
        {
          name: "Nikolaevo",
          countryCode: "BG",
          stateCode: "24",
          latitude: "42.63333000",
          longitude: "25.80000000",
        },
        {
          name: "Obshtina Bratya Daskalovi",
          countryCode: "BG",
          stateCode: "24",
          latitude: "42.30000000",
          longitude: "25.21667000",
        },
        {
          name: "Obshtina Chirpan",
          countryCode: "BG",
          stateCode: "24",
          latitude: "42.23333000",
          longitude: "25.38333000",
        },
        {
          name: "Obshtina Galabovo",
          countryCode: "BG",
          stateCode: "24",
          latitude: "42.15000000",
          longitude: "25.96667000",
        },
        {
          name: "Obshtina Gurkovo",
          countryCode: "BG",
          stateCode: "24",
          latitude: "42.71667000",
          longitude: "25.80000000",
        },
        {
          name: "Obshtina Kazanlŭk",
          countryCode: "BG",
          stateCode: "24",
          latitude: "42.61943000",
          longitude: "25.39885000",
        },
        {
          name: "Obshtina Maglizh",
          countryCode: "BG",
          stateCode: "24",
          latitude: "42.63333000",
          longitude: "25.61667000",
        },
        {
          name: "Obshtina Nikolaevo",
          countryCode: "BG",
          stateCode: "24",
          latitude: "42.60000000",
          longitude: "25.78333000",
        },
        {
          name: "Obshtina Opan",
          countryCode: "BG",
          stateCode: "24",
          latitude: "42.21667000",
          longitude: "25.70000000",
        },
        {
          name: "Obshtina Pavel Banya",
          countryCode: "BG",
          stateCode: "24",
          latitude: "42.63333000",
          longitude: "25.13333000",
        },
        {
          name: "Obshtina Radnevo",
          countryCode: "BG",
          stateCode: "24",
          latitude: "42.30000000",
          longitude: "25.93333000",
        },
        {
          name: "Obshtina Stara Zagora",
          countryCode: "BG",
          stateCode: "24",
          latitude: "42.41667000",
          longitude: "25.63333000",
        },
        {
          name: "Pavel Banya",
          countryCode: "BG",
          stateCode: "24",
          latitude: "42.60000000",
          longitude: "25.20000000",
        },
        {
          name: "Radnevo",
          countryCode: "BG",
          stateCode: "24",
          latitude: "42.30000000",
          longitude: "25.93333000",
        },
        {
          name: "Shipka",
          countryCode: "BG",
          stateCode: "24",
          latitude: "42.71667000",
          longitude: "25.33333000",
        },
        {
          name: "Stara Zagora",
          countryCode: "BG",
          stateCode: "24",
          latitude: "42.43278000",
          longitude: "25.64194000",
        },
      ],
      Targovishte: [
        {
          name: "Antonovo",
          countryCode: "BG",
          stateCode: "25",
          latitude: "43.15000000",
          longitude: "26.16667000",
        },
        {
          name: "Obshtina Antonovo",
          countryCode: "BG",
          stateCode: "25",
          latitude: "43.11667000",
          longitude: "26.20000000",
        },
        {
          name: "Obshtina Omurtag",
          countryCode: "BG",
          stateCode: "25",
          latitude: "43.08333000",
          longitude: "26.48333000",
        },
        {
          name: "Obshtina Opaka",
          countryCode: "BG",
          stateCode: "25",
          latitude: "43.46667000",
          longitude: "26.15000000",
        },
        {
          name: "Obshtina Popovo",
          countryCode: "BG",
          stateCode: "25",
          latitude: "43.35000000",
          longitude: "26.23333000",
        },
        {
          name: "Obshtina Targovishte",
          countryCode: "BG",
          stateCode: "25",
          latitude: "43.25000000",
          longitude: "26.56667000",
        },
        {
          name: "Omurtag",
          countryCode: "BG",
          stateCode: "25",
          latitude: "43.10000000",
          longitude: "26.41667000",
        },
        {
          name: "Opaka",
          countryCode: "BG",
          stateCode: "25",
          latitude: "43.45000000",
          longitude: "26.16667000",
        },
        {
          name: "Popovo",
          countryCode: "BG",
          stateCode: "25",
          latitude: "43.35000000",
          longitude: "26.23333000",
        },
        {
          name: "Targovishte",
          countryCode: "BG",
          stateCode: "25",
          latitude: "43.25120000",
          longitude: "26.57215000",
        },
      ],
      Varna: [
        {
          name: "Aksakovo",
          countryCode: "BG",
          stateCode: "03",
          latitude: "43.25615000",
          longitude: "27.82105000",
        },
        {
          name: "Asparuhovo",
          countryCode: "BG",
          stateCode: "03",
          latitude: "43.18067000",
          longitude: "27.88823000",
        },
        {
          name: "Balgarevo",
          countryCode: "BG",
          stateCode: "03",
          latitude: "43.40296000",
          longitude: "28.41189000",
        },
        {
          name: "Beloslav",
          countryCode: "BG",
          stateCode: "03",
          latitude: "43.18960000",
          longitude: "27.70429000",
        },
        {
          name: "Byala",
          countryCode: "BG",
          stateCode: "03",
          latitude: "42.87426000",
          longitude: "27.88865000",
        },
        {
          name: "Dalgopol",
          countryCode: "BG",
          stateCode: "03",
          latitude: "43.05000000",
          longitude: "27.35000000",
        },
        {
          name: "Devnya",
          countryCode: "BG",
          stateCode: "03",
          latitude: "43.22222000",
          longitude: "27.56944000",
        },
        {
          name: "Dolni Chiflik",
          countryCode: "BG",
          stateCode: "03",
          latitude: "42.99296000",
          longitude: "27.71596000",
        },
        {
          name: "Kiten",
          countryCode: "BG",
          stateCode: "03",
          latitude: "43.08333000",
          longitude: "27.31667000",
        },
        {
          name: "Obshtina Aksakovo",
          countryCode: "BG",
          stateCode: "03",
          latitude: "43.31667000",
          longitude: "27.85000000",
        },
        {
          name: "Obshtina Avren",
          countryCode: "BG",
          stateCode: "03",
          latitude: "43.10000000",
          longitude: "27.71667000",
        },
        {
          name: "Obshtina Beloslav",
          countryCode: "BG",
          stateCode: "03",
          latitude: "43.20000000",
          longitude: "27.73333000",
        },
        {
          name: "Obshtina Byala",
          countryCode: "BG",
          stateCode: "03",
          latitude: "42.88333000",
          longitude: "27.81667000",
        },
        {
          name: "Obshtina Dalgopol",
          countryCode: "BG",
          stateCode: "03",
          latitude: "43.05000000",
          longitude: "27.35000000",
        },
        {
          name: "Obshtina Devnya",
          countryCode: "BG",
          stateCode: "03",
          latitude: "43.22056000",
          longitude: "27.60052000",
        },
        {
          name: "Obshtina Dolni Chiflik",
          countryCode: "BG",
          stateCode: "03",
          latitude: "42.96667000",
          longitude: "27.68333000",
        },
        {
          name: "Obshtina Provadia",
          countryCode: "BG",
          stateCode: "03",
          latitude: "43.18333000",
          longitude: "27.40000000",
        },
        {
          name: "Obshtina Suvorovo",
          countryCode: "BG",
          stateCode: "03",
          latitude: "43.33333000",
          longitude: "27.65000000",
        },
        {
          name: "Obshtina Valchidol",
          countryCode: "BG",
          stateCode: "03",
          latitude: "43.45000000",
          longitude: "27.50000000",
        },
        {
          name: "Obshtina Varna",
          countryCode: "BG",
          stateCode: "03",
          latitude: "43.21667000",
          longitude: "27.91667000",
        },
        {
          name: "Obshtina Vetrino",
          countryCode: "BG",
          stateCode: "03",
          latitude: "43.31667000",
          longitude: "27.43333000",
        },
        {
          name: "Provadia",
          countryCode: "BG",
          stateCode: "03",
          latitude: "43.18333000",
          longitude: "27.43333000",
        },
        {
          name: "Suvorovo",
          countryCode: "BG",
          stateCode: "03",
          latitude: "43.33058000",
          longitude: "27.59908000",
        },
        {
          name: "Valchidol",
          countryCode: "BG",
          stateCode: "03",
          latitude: "43.40000000",
          longitude: "27.55000000",
        },
        {
          name: "Varna",
          countryCode: "BG",
          stateCode: "03",
          latitude: "43.21667000",
          longitude: "27.91667000",
        },
        {
          name: "Vetrino",
          countryCode: "BG",
          stateCode: "03",
          latitude: "43.31667000",
          longitude: "27.43333000",
        },
        {
          name: "Zlatni Pyasatsi",
          countryCode: "BG",
          stateCode: "03",
          latitude: "43.28500000",
          longitude: "28.04180000",
        },
      ],
      Veliko_Tarnovo: [
        {
          name: "Byala Cherkva",
          countryCode: "BG",
          stateCode: "04",
          latitude: "43.20000000",
          longitude: "25.30000000",
        },
        {
          name: "Debelets",
          countryCode: "BG",
          stateCode: "04",
          latitude: "43.03333000",
          longitude: "25.61667000",
        },
        {
          name: "Elena",
          countryCode: "BG",
          stateCode: "04",
          latitude: "42.93333000",
          longitude: "25.88333000",
        },
        {
          name: "Gorna Oryahovitsa",
          countryCode: "BG",
          stateCode: "04",
          latitude: "43.12778000",
          longitude: "25.70167000",
        },
        {
          name: "Kilifarevo",
          countryCode: "BG",
          stateCode: "04",
          latitude: "42.98333000",
          longitude: "25.63333000",
        },
        {
          name: "Lyaskovets",
          countryCode: "BG",
          stateCode: "04",
          latitude: "43.11111000",
          longitude: "25.72833000",
        },
        {
          name: "Obshtina Elena",
          countryCode: "BG",
          stateCode: "04",
          latitude: "42.91667000",
          longitude: "25.95000000",
        },
        {
          name: "Obshtina Gorna Oryahovitsa",
          countryCode: "BG",
          stateCode: "04",
          latitude: "43.21667000",
          longitude: "25.75000000",
        },
        {
          name: "Obshtina Lyaskovets",
          countryCode: "BG",
          stateCode: "04",
          latitude: "43.13333000",
          longitude: "25.85000000",
        },
        {
          name: "Obshtina Pavlikeni",
          countryCode: "BG",
          stateCode: "04",
          latitude: "43.28333000",
          longitude: "25.35000000",
        },
        {
          name: "Obshtina Polski Trambesh",
          countryCode: "BG",
          stateCode: "04",
          latitude: "43.35000000",
          longitude: "25.58333000",
        },
        {
          name: "Obshtina Strazhitsa",
          countryCode: "BG",
          stateCode: "04",
          latitude: "43.26667000",
          longitude: "25.98333000",
        },
        {
          name: "Obshtina Suhindol",
          countryCode: "BG",
          stateCode: "04",
          latitude: "43.16667000",
          longitude: "25.20000000",
        },
        {
          name: "Obshtina Svishtov",
          countryCode: "BG",
          stateCode: "04",
          latitude: "43.53333000",
          longitude: "25.35000000",
        },
        {
          name: "Obshtina Veliko Tŭrnovo",
          countryCode: "BG",
          stateCode: "04",
          latitude: "43.08241000",
          longitude: "25.63202000",
        },
        {
          name: "Obshtina Zlataritsa",
          countryCode: "BG",
          stateCode: "04",
          latitude: "43.06667000",
          longitude: "25.98333000",
        },
        {
          name: "Parvomaytsi",
          countryCode: "BG",
          stateCode: "04",
          latitude: "43.15000000",
          longitude: "25.65000000",
        },
        {
          name: "Pavlikeni",
          countryCode: "BG",
          stateCode: "04",
          latitude: "43.24278000",
          longitude: "25.32194000",
        },
        {
          name: "Polski Trambesh",
          countryCode: "BG",
          stateCode: "04",
          latitude: "43.37233000",
          longitude: "25.63525000",
        },
        {
          name: "Strazhitsa",
          countryCode: "BG",
          stateCode: "04",
          latitude: "43.23333000",
          longitude: "25.96667000",
        },
        {
          name: "Suhindol",
          countryCode: "BG",
          stateCode: "04",
          latitude: "43.19167000",
          longitude: "25.18111000",
        },
        {
          name: "Svishtov",
          countryCode: "BG",
          stateCode: "04",
          latitude: "43.61875000",
          longitude: "25.35033000",
        },
        {
          name: "Veliko Tŭrnovo",
          countryCode: "BG",
          stateCode: "04",
          latitude: "43.08124000",
          longitude: "25.62904000",
        },
        {
          name: "Zlataritsa",
          countryCode: "BG",
          stateCode: "04",
          latitude: "43.05000000",
          longitude: "25.90000000",
        },
      ],
      Vidin: [
        {
          name: "Belogradchik",
          countryCode: "BG",
          stateCode: "05",
          latitude: "43.62722000",
          longitude: "22.68361000",
        },
        {
          name: "Boynitsa",
          countryCode: "BG",
          stateCode: "05",
          latitude: "43.95650000",
          longitude: "22.53255000",
        },
        {
          name: "Bregovo",
          countryCode: "BG",
          stateCode: "05",
          latitude: "44.15167000",
          longitude: "22.64250000",
        },
        {
          name: "Chuprene",
          countryCode: "BG",
          stateCode: "05",
          latitude: "43.51806000",
          longitude: "22.66611000",
        },
        {
          name: "Dimovo",
          countryCode: "BG",
          stateCode: "05",
          latitude: "43.74167000",
          longitude: "22.72694000",
        },
        {
          name: "Drenovets",
          countryCode: "BG",
          stateCode: "05",
          latitude: "43.69134000",
          longitude: "22.97365000",
        },
        {
          name: "Dunavtsi",
          countryCode: "BG",
          stateCode: "05",
          latitude: "43.92111000",
          longitude: "22.82111000",
        },
        {
          name: "Gramada",
          countryCode: "BG",
          stateCode: "05",
          latitude: "43.83738000",
          longitude: "22.65279000",
        },
        {
          name: "Kula",
          countryCode: "BG",
          stateCode: "05",
          latitude: "43.88833000",
          longitude: "22.52139000",
        },
        {
          name: "Makresh",
          countryCode: "BG",
          stateCode: "05",
          latitude: "43.76794000",
          longitude: "22.66406000",
        },
        {
          name: "Novo Selo",
          countryCode: "BG",
          stateCode: "05",
          latitude: "44.16214000",
          longitude: "22.78376000",
        },
        {
          name: "Obshtina Belogradchik",
          countryCode: "BG",
          stateCode: "05",
          latitude: "43.65000000",
          longitude: "22.60000000",
        },
        {
          name: "Obshtina Boynitsa",
          countryCode: "BG",
          stateCode: "05",
          latitude: "44.00000000",
          longitude: "22.55000000",
        },
        {
          name: "Obshtina Dimovo",
          countryCode: "BG",
          stateCode: "05",
          latitude: "43.75000000",
          longitude: "22.81667000",
        },
        {
          name: "Obshtina Gramada",
          countryCode: "BG",
          stateCode: "05",
          latitude: "43.83333000",
          longitude: "22.65000000",
        },
        {
          name: "Obshtina Kula",
          countryCode: "BG",
          stateCode: "05",
          latitude: "43.90000000",
          longitude: "22.56667000",
        },
        {
          name: "Obshtina Ruzhintsi",
          countryCode: "BG",
          stateCode: "05",
          latitude: "43.65000000",
          longitude: "22.90000000",
        },
        {
          name: "Obshtina Vidin",
          countryCode: "BG",
          stateCode: "05",
          latitude: "44.00000000",
          longitude: "22.83333000",
        },
        {
          name: "Ruzhintsi",
          countryCode: "BG",
          stateCode: "05",
          latitude: "43.62179000",
          longitude: "22.83286000",
        },
        {
          name: "Vidin",
          countryCode: "BG",
          stateCode: "05",
          latitude: "43.99159000",
          longitude: "22.88236000",
        },
      ],
      Vratsa: [
        {
          name: "Borovan",
          countryCode: "BG",
          stateCode: "06",
          latitude: "43.43333000",
          longitude: "23.75000000",
        },
        {
          name: "Byala Slatina",
          countryCode: "BG",
          stateCode: "06",
          latitude: "43.46667000",
          longitude: "23.93333000",
        },
        {
          name: "Hayredin",
          countryCode: "BG",
          stateCode: "06",
          latitude: "43.60193000",
          longitude: "23.66135000",
        },
        {
          name: "Kozloduy",
          countryCode: "BG",
          stateCode: "06",
          latitude: "43.77864000",
          longitude: "23.72058000",
        },
        {
          name: "Krivodol",
          countryCode: "BG",
          stateCode: "06",
          latitude: "43.37444000",
          longitude: "23.48444000",
        },
        {
          name: "Mezdra",
          countryCode: "BG",
          stateCode: "06",
          latitude: "43.15000000",
          longitude: "23.70000000",
        },
        {
          name: "Mizia",
          countryCode: "BG",
          stateCode: "06",
          latitude: "43.68623000",
          longitude: "23.85371000",
        },
        {
          name: "Obshtina Borovan",
          countryCode: "BG",
          stateCode: "06",
          latitude: "43.43333000",
          longitude: "23.75000000",
        },
        {
          name: "Obshtina Hayredin",
          countryCode: "BG",
          stateCode: "06",
          latitude: "43.58333000",
          longitude: "23.66667000",
        },
        {
          name: "Obshtina Kozloduy",
          countryCode: "BG",
          stateCode: "06",
          latitude: "43.71667000",
          longitude: "23.73333000",
        },
        {
          name: "Obshtina Krivodol",
          countryCode: "BG",
          stateCode: "06",
          latitude: "43.38333000",
          longitude: "23.48333000",
        },
        {
          name: "Obshtina Mezdra",
          countryCode: "BG",
          stateCode: "06",
          latitude: "43.13333000",
          longitude: "23.73333000",
        },
        {
          name: "Obshtina Mizia",
          countryCode: "BG",
          stateCode: "06",
          latitude: "43.63333000",
          longitude: "23.85000000",
        },
        {
          name: "Obshtina Oryahovo",
          countryCode: "BG",
          stateCode: "06",
          latitude: "43.69038000",
          longitude: "23.98643000",
        },
        {
          name: "Obshtina Roman",
          countryCode: "BG",
          stateCode: "06",
          latitude: "43.15000000",
          longitude: "23.91667000",
        },
        {
          name: "Obshtina Vratsa",
          countryCode: "BG",
          stateCode: "06",
          latitude: "43.28333000",
          longitude: "23.60000000",
        },
        {
          name: "Oryahovo",
          countryCode: "BG",
          stateCode: "06",
          latitude: "43.73618000",
          longitude: "23.96052000",
        },
        {
          name: "Roman",
          countryCode: "BG",
          stateCode: "06",
          latitude: "43.15000000",
          longitude: "23.91667000",
        },
        {
          name: "Vratsa",
          countryCode: "BG",
          stateCode: "06",
          latitude: "43.21000000",
          longitude: "23.56250000",
        },
      ],
      Yambol: [
        {
          name: "Blagoevgrad Province",
          isoCode: "01",
          countryCode: "BG",
          latitude: "42.02086140",
          longitude: "23.09433560",
        },
        {
          name: "Burgas Province",
          isoCode: "02",
          countryCode: "BG",
          latitude: "42.50480000",
          longitude: "27.46260790",
        },
        {
          name: "Varna Province",
          isoCode: "03",
          countryCode: "BG",
          latitude: "43.20464770",
          longitude: "27.91054880",
        },
        {
          name: "Veliko Tarnovo Province",
          isoCode: "04",
          countryCode: "BG",
          latitude: "43.07565390",
          longitude: "25.61715000",
        },
        {
          name: "Vidin Province",
          isoCode: "05",
          countryCode: "BG",
          latitude: "43.99617390",
          longitude: "22.86795150",
        },
        {
          name: "Vratsa Province",
          isoCode: "06",
          countryCode: "BG",
          latitude: "43.21018060",
          longitude: "23.55292100",
        },
        {
          name: "Gabrovo Province",
          isoCode: "07",
          countryCode: "BG",
          latitude: "42.86847000",
          longitude: "25.31688900",
        },
        {
          name: "Dobrich Province",
          isoCode: "08",
          countryCode: "BG",
          latitude: "43.57278600",
          longitude: "27.82728020",
        },
        {
          name: "Kardzhali Province",
          isoCode: "09",
          countryCode: "BG",
          latitude: "41.63384160",
          longitude: "25.37766870",
        },
        {
          name: "Kyustendil Province",
          isoCode: "10",
          countryCode: "BG",
          latitude: "42.28687990",
          longitude: "22.69396350",
        },
        {
          name: "Lovech Province",
          isoCode: "11",
          countryCode: "BG",
          latitude: "43.13677980",
          longitude: "24.71393350",
        },
        {
          name: "Montana Province",
          isoCode: "12",
          countryCode: "BG",
          latitude: "43.40851480",
          longitude: "23.22575890",
        },
        {
          name: "Pazardzhik Province",
          isoCode: "13",
          countryCode: "BG",
          latitude: "42.19275670",
          longitude: "24.33362260",
        },
        {
          name: "Pernik Province",
          isoCode: "14",
          countryCode: "BG",
          latitude: "42.60519900",
          longitude: "23.03779160",
        },
        {
          name: "Pleven Province",
          isoCode: "15",
          countryCode: "BG",
          latitude: "43.41701690",
          longitude: "24.60667080",
        },
        {
          name: "Plovdiv Province",
          isoCode: "16",
          countryCode: "BG",
          latitude: "42.13540790",
          longitude: "24.74529040",
        },
        {
          name: "Razgrad Province",
          isoCode: "17",
          countryCode: "BG",
          latitude: "43.52717050",
          longitude: "26.52412280",
        },
        {
          name: "Ruse Province",
          isoCode: "18",
          countryCode: "BG",
          latitude: "43.83559640",
          longitude: "25.96561440",
        },
        {
          name: "Silistra Province",
          isoCode: "19",
          countryCode: "BG",
          latitude: "44.11471010",
          longitude: "27.26714540",
        },
        {
          name: "Sliven Province",
          isoCode: "20",
          countryCode: "BG",
          latitude: "42.68167020",
          longitude: "26.32285690",
        },
        {
          name: "Smolyan Province",
          isoCode: "21",
          countryCode: "BG",
          latitude: "41.57741480",
          longitude: "24.70108710",
        },
        {
          name: "Sofia City Province",
          isoCode: "22",
          countryCode: "BG",
          latitude: "42.75701090",
          longitude: "23.45046830",
        },
        {
          name: "Sofia Province",
          isoCode: "23",
          countryCode: "BG",
          latitude: "42.67344000",
          longitude: "23.83349370",
        },
        {
          name: "Stara Zagora Province",
          isoCode: "24",
          countryCode: "BG",
          latitude: "42.42577090",
          longitude: "25.63448550",
        },
        {
          name: "Targovishte Province",
          isoCode: "25",
          countryCode: "BG",
          latitude: "43.24623490",
          longitude: "26.56912510",
        },
        {
          name: "Haskovo Province",
          isoCode: "26",
          countryCode: "BG",
          latitude: "41.93441780",
          longitude: "25.55546720",
        },
        {
          name: "Shumen",
          isoCode: "27",
          countryCode: "BG",
          latitude: "43.27123980",
          longitude: "26.93612860",
        },
        {
          name: "Yambol Province",
          isoCode: "28",
          countryCode: "BG",
          latitude: "42.48414940",
          longitude: "26.50352960",
        },
      ],
    },
  },
];
