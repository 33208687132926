import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { Button, DialogActions } from "@mui/material";

import PaymentCredentialsForm from "components/CompanyPaymentCredentials/PaymentCredentialsForm";
import usePaymentCredentials from "components/CompanyPaymentCredentials/usePaymentCredentials";
import {
  PaymentCredentialsTab,
  PaymentProvider,
  getFieldsForProvider,
} from "components/CompanyPaymentCredentials/utils";
import CustomTabs from "components/CustomTabs";

interface CompanyPaymentCredentialsProps {
  companyId: number;
}

const CompanyPaymentCredentials = ({
  companyId,
}: CompanyPaymentCredentialsProps) => {
  const { t } = useTranslation();
  const {
    paymentProvider,
    tabs,
    currentTab,
    handleTabsUpdate,
    handleTabOnChange,
    handleCurrentTabRemove,
    handleSelectProviderOnClick,
  } = usePaymentCredentials(companyId);

  return (
    <Fragment>
      {paymentProvider !== null &&
        (paymentProvider !== PaymentProvider.None ? (
          //Show form tabs on selected payment provider or existing payment credentials for current company.
          <Fragment>
            <CustomTabs
              tabs={tabs.map((tab) => ({
                index: tab.tabIndex,
                label: t(PaymentCredentialsTab[tab.tabIndex].toString()),
              }))}
              defaultValue={currentTab.tabIndex}
              onCustomChange={handleTabOnChange}
            />
            {tabs.map(
              // Dynamic generation of payment credentials form for the current selected tab.
              (t) =>
                t.tabIndex === currentTab.tabIndex && (
                  <PaymentCredentialsForm
                    key={t.tabIndex}
                    companyId={companyId}
                    paymentProvider={paymentProvider}
                    credentialsTabForm={{
                      ...currentTab,
                      paymentCredentialsForm:
                        currentTab.tabIndex !== PaymentCredentialsTab.ADD
                          ? currentTab.paymentCredentialsForm // Use input fields with values from response for existing currency tabs.
                          : getFieldsForProvider(
                              // Generate empty input fields for the `Add` tab based on current provider.
                              PaymentProvider[paymentProvider].toLowerCase(),
                            ),
                    }}
                    handleTabsUpdate={handleTabsUpdate}
                    handleCurrentTabRemove={handleCurrentTabRemove}
                    selectCurrencyNames={Object.keys(PaymentCredentialsTab)
                      .filter(
                        // Remove existing currency tabs array for current company from all currencies array.
                        (t) => !tabs.map((tab) => tab.tabIndex).includes(+t),
                      )
                      .map((t) => PaymentCredentialsTab[+t])
                      // Remove undefined values left from the enum parsing.
                      .filter((t) => typeof t !== "undefined")}
                  />
                ),
            )}
          </Fragment>
        ) : (
          <DialogActions>
            {Object.values(PaymentProvider).map(
              // Generates buttons for selecting a payment provider from the enum values.
              (currentProvider, index) =>
                ![PaymentProvider.None, PaymentProvider.Avalanche].includes(
                  currentProvider,
                ) && (
                  <Button
                    key={index}
                    sx={{
                      boxShadow: "none",
                    }}
                    variant="contained"
                    onClick={() =>
                      handleSelectProviderOnClick(
                        PaymentProvider[
                          currentProvider as keyof typeof PaymentProvider
                        ],
                      )
                    }
                  >
                    {currentProvider}
                  </Button>
                ),
            )}
          </DialogActions>
        ))}
    </Fragment>
  );
};

export default CompanyPaymentCredentials;
