import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton } from "@mui/material";

import ModuleQR from "components/ModuleQr";
import {
  CustomTable,
  Column,
  TableSettings,
  CellValue,
  DynamicDialogProps,
} from "components/Table/Table";
import ContentLayout from "components/layouts/ContentLayout";
import DynamicDialog from "components/layouts/Dialog";
import QueryParams from "models/queryParams.model";
import PosModel from "models/resources/pos.model";
import POS from "models/resources/pos.model";
import { ResponseDataTable } from "models/responseData.model";
import { getData } from "services/requests/listData";

const PosTable = () => {
  const { t } = useTranslation();
  const [getPOSsData, setPOSsData] = useState<ResponseDataTable<PosModel[]>>();

  const setData = (queryParams: QueryParams, setDataLoading: any) => {
    getData(setPOSsData, "pos", queryParams, setDataLoading);
  };

  const columns: Column<POS>[] = [
    {
      label: t("POS Name"),
      name: "posName",
      getData: (data): CellValue => data.name,
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "name" },
      width: "15%",
      minWidth: "200px",
    },
    {
      label: t("Currency"),
      name: "currency",
      getData: (data): CellValue => t(data.currency),
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "currency" },
      align: "center",
      width: "15%",
      minWidth: "150px",
    },
    {
      label: t("Location"),
      name: "location",
      getData: (data): CellValue =>
        data.location !== null ? (
          <Link
            to={{
              pathname: `/account/locations/${data.location.id}`,
            }}
          >
            {`${data.location.name}, ${data.location.address}`}
          </Link>
        ) : (
          "---"
        ),
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "location_id" },
      width: "20%",
      minWidth: "250px",
    },
    {
      label: t("Company"),
      name: "company",
      getData: (data): CellValue => (
        <Link
          to={{
            pathname: `/account/companies/${data.company_id}`,
          }}
        >
          {data.company.display_name || data.company.name}
        </Link>
      ),
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "company_id" },
      width: "15%",
      minWidth: "200px",
    },
    {
      label: t("POS Serial"),
      name: "company",
      getData: (data): CellValue => data.client?.serial,
      show: () => {
        return true;
      },
      sort: {
        isSortable: true,
        field: "client.serial",
      },
      width: "15%",
    },
    {
      label: t("VPOS State"),
      name: "company",
      getData: (data): CellValue =>
        `${data.client?.state}` === "up" ? (
          <IconButton>
            <FiberManualRecordIcon sx={{ color: "#4CAF50" }} />
          </IconButton>
        ) : (
          <IconButton>
            <FiberManualRecordIcon sx={{ color: "#FF6347" }} />
          </IconButton>
        ),
      show: () => {
        return true;
      },
      width: "10%",
      align: "center",
    },
    {
      label: t("QR"),
      name: "qr",
      getData: (data): CellValue =>
        data.client?.serial ? (
          <DynamicDialog
            title={`${t("QR code for module")} ${data.client?.serial}`}
            component={
              <ModuleQR
                currency={data.currency}
                moduleSerial={data.client?.serial}
              />
            }
            iconButton={<VisibilityIcon />}
            openMessage={t("QR")}
            hideActions
          />
        ) : (
          "---"
        ),
      show: () => {
        return true;
      },
      width: "5%",
      align: "center",
    },
    {
      label: t("Edit"),
      name: "edit",
      getData: (data): CellValue => data.id,
      show: () => {
        return true;
      },
      width: "5%",
      align: "center",
    },
  ];

  const tableOptions: TableSettings = {
    pagination: true,
    page: 0,
    rowsPerPage: 25,
    field: "name",
    order: "asc",
  };

  const dynamicDialogProps: DynamicDialogProps = {
    title: "Add virtual POS",
    resource: "pos",
    call: "pos",
    styles: () => "flex90Form first-100 nth-2 nth-4",
  };

  return (
    <ContentLayout title={t("Virtual POS")}>
      <CustomTable
        title={t("Virtual POS")}
        resource="pos"
        call="pos"
        tableData={getPOSsData}
        fetchPaginatedData={setData}
        setTableData={setPOSsData}
        columns={columns}
        tableOptions={tableOptions}
        dynamicDialogProps={dynamicDialogProps}
        isDynamic={true}
        exportProp="pos"
      />
    </ContentLayout>
  );
};

export default PosTable;
