import { I18nextProvider } from "react-i18next";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import theme from "theme";

import { Box } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";

import { ThemeProvider } from "@emotion/react";
import { AuthProvider } from "context/AuthContext";
import i18n from "i18n/i18next";
import "styles/averato.scss";
import "styles/fonts.scss";
import "styles/globals.scss";

const Root = () => {
  return (
    <Box>
      <ToastContainer limit={1} position="top-right" />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthProvider>
          <I18nextProvider i18n={i18n}>
            <Outlet />
          </I18nextProvider>
        </AuthProvider>
      </ThemeProvider>
    </Box>
  );
};

export default Root;
