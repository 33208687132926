import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import CircleIcon from "@mui/icons-material/Circle";

import usePromotion from "../usePromotion";
import {
  CustomTable,
  Column,
  TableSettings,
  CellValue,
  DynamicDialogProps,
} from "components/Table/Table";
import ContentLayout from "components/layouts/ContentLayout";
import Promotion from "models/resources/promotion.model";
import { getLocalizedDate } from "utils/lozalizeDateTime";

const PromotionTable = () => {
  const { t } = useTranslation();
  const { getPromotionsData, setPromotionsData, setData } = usePromotion();

  const columns: Column<Promotion>[] = [
    {
      label: t("Name"),
      name: "name",
      getData: (data): CellValue => data.name,
      show: () => {
        return true;
      },
    },
    {
      label: t("Display name"),
      name: "display_name",
      getData: (data): CellValue => data.display_name,
      show: () => true,
    },
    {
      label: t("Public"),
      name: "active",
      getData: (data): CellValue => (
        <CircleIcon sx={{ color: data.active ? "#4CAF50" : "#FF6347" }} />
      ),
      show: () => true,
    },
    {
      label: t("Validity period"),
      name: "period",
      getData: (data): CellValue => {
        return (
          <div>
            {getLocalizedDate(data.valid_from) +
              " / " +
              getLocalizedDate(data.valid_to)}
          </div>
        );
      },
      show: () => true,

      sort: { isSortable: true, field: "from" },
    },
    {
      label: t("Company"),
      name: "company",
      getData: (data): CellValue => (
        <Link to={{ pathname: `/account/companies/${data.company_id}` }}>
          {data.company.display_name || data.company.name}
        </Link>
      ),
      show: () => true,
    },
    {
      label: t("Prize"),
      name: "gift-card",
      getData: (data): CellValue => (
        <Link to={{ pathname: `/account/gift-cards/${data.gift_card_id}` }}>
          {data.gift_card.name}
        </Link>
      ),
      show: () => true,
    },
    {
      label: t("Edit"),
      name: "edit",
      getData: (data): CellValue => data.id,
      show: () => true,

      align: "center",
      width: "5%",
    },
  ];

  const tableOptions: TableSettings = {
    pagination: true,
    page: 0,
    rowsPerPage: 25,
    field: "from",
    order: "desc",
  };

  const dynamicDialogProps: DynamicDialogProps = {
    title: "Add promotion",
    resource: "promotion",
    call: "promotions",
    styles: () => "promotions",
  };

  return (
    <ContentLayout title={t("Promotions")}>
      <CustomTable
        isSearchable={false}
        title={t("Promotions")}
        resource="promotion"
        call="promotions"
        tableData={getPromotionsData}
        fetchPaginatedData={setData}
        setTableData={setPromotionsData}
        columns={columns}
        tableOptions={tableOptions}
        exportProp={"promotions"}
        dynamicDialogProps={dynamicDialogProps}
        isDynamic={true}
        notCSV={true}
      />
    </ContentLayout>
  );
};

export default PromotionTable;
