import { useTranslation } from "react-i18next";

import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  TextField,
} from "@mui/material";

import Product from "models/resources/product.model";
import { DatabaseEntity } from "models/responseData.model";

import useMappingForm from "./useMappingForm";

interface MappingFormProps {
  label: string;
  buttonSignal: number;
  dropdown?: { options: DatabaseEntity[]; selected?: DatabaseEntity };
  onSubmit?(selectedValue: string): void;
}

const MappingForm = ({
  label,
  buttonSignal,
  dropdown,
  onSubmit = () => {},
}: MappingFormProps) => {
  const { t } = useTranslation();
  const {
    optionInput,
    handleSignalValueOnChange,
    selectedOption,
    handleOptionOnChange,
  } = useMappingForm(buttonSignal.toString(), dropdown?.selected ?? null);

  return (
    <Box>
      {typeof dropdown !== "undefined" ? (
        <Autocomplete
          options={dropdown.options}
          getOptionLabel={(option) => (option as Product).display_name} // TODO: Find a more generic solution.
          value={selectedOption}
          onChange={(e, ingredient) => handleOptionOnChange(ingredient!)}
          inputValue={optionInput}
          onInputChange={(e, ingredientName) =>
            handleOptionOnChange(ingredientName)
          }
          // Remove 'InputLabelProps' that causes error in TextField component.
          renderInput={({ InputLabelProps, ...params }) => (
            <TextField {...params} label={t(label).concat("*")} />
          )}
        />
      ) : (
        <TextField
          fullWidth
          required
          type="number"
          label={t(label)}
          value={optionInput}
          onChange={handleSignalValueOnChange}
        />
      )}

      <DialogActions>
        <Button
          sx={{
            boxShadow: "none",
            marginTop: "10px",
          }}
          variant="contained"
          type="submit"
          //@ts-ignore
          onClick={() => onSubmit(selectedOption?.id ?? optionInput)}
        >
          {t("Save")}
        </Button>
      </DialogActions>
    </Box>
  );
};

export default MappingForm;
