import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { isEmpty } from "lodash";

import EmailIcon from "@mui/icons-material/Email";
import {
  Button,
  FilledInput,
  FormControl,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";

import LanguageSelect from "components/LanguageSelect";
import apiClient from "services/api";

export interface State {
  email: string;
}

export default function ForgotForm() {
  const { t } = useTranslation();
  const [values, setValues] = useState<State>({
    email: "",
  });
  const navigate = useNavigate();

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  async function resetPassword({ email }: State) {
    if (isEmpty(email)) {
      return toast.error(t("Email field is required!").toString());
    }

    apiClient({
      url: "password-reset",
      method: "post",
      bodyData: {
        email,
      },
    })
      .then(() =>
        navigate(
          `/resend-email-forgotten-password?email=${encodeURIComponent(email)}`,
        ),
      )
      .catch(() => toast.error(t("Email not found!").toString()));
  }
  async function handleSubmit(event: FormEvent) {
    event.preventDefault();
    resetPassword(values);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography component="span" variant="caption" mb={2}>
          {t("Please enter the Email associated with your account")}
        </Typography>
        <FormControl
          fullWidth
          sx={{ m: 1, margin: "0px", marginTop: "20px" }}
          variant="filled"
        >
          <FilledInput
            id="filled-adornment-weight-1"
            value={values.email}
            onChange={handleChange("email")}
            disableUnderline={true}
            autoFocus={false}
            placeholder={t("Email")}
            endAdornment={
              <InputAdornment position="end">
                <EmailIcon />
              </InputAdornment>
            }
            aria-describedby="filled-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{ marginTop: "20px" }}
            >
              {t("Reset password")}
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
}
