import { provinces } from "./country-state-city";

const findCountry = (isoCode: string) => {
  return provinces.find((country) => country.name === isoCode);
};

const getAllCountries = () => {
  return provinces.map((country) => {
    return { name: country.name, isoCode: country.isoCode };
  });
};

const getStatesOfCountry = (isoCode: string) => {
  return Object.keys(findCountry(isoCode)?.regions ?? {});
};

const getCitiesOfState = (isoCode: string, stateName: string) => {
  return findCountry(isoCode)?.regions[stateName];
};

export default {
  getAllCountries,
  getStatesOfCountry,
  getCitiesOfState,
};
