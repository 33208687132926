import { useTranslation } from "react-i18next";

import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import GetAppIcon from "@mui/icons-material/GetApp";
import { Tooltip, Typography } from "@mui/material";
import Button from "@mui/material/Button";

import {
  ordersPdfHeaders,
  transactionsPdfHeaders,
  ordersFields,
  transactionsFields,
  invoicesPdfHeaders,
  invoiceOrdersPdfHeaders,
  invoicesFields,
  invoiceOrdersFields,
} from "components/PdfExport/fields";
import constants from "styles/scss/constants.module.scss";

interface Props {
  rowData: any;
  type: string;
  invoiceType?: string;
  typeInvoice?: string;
}

const PDFExport = ({ rowData, type, invoiceType, typeInvoice }: Props) => {
  const { t } = useTranslation();
  const types: any = {
    orders: {
      header: ordersPdfHeaders,
      body: ordersFields,
    },
    transactions: {
      header: transactionsPdfHeaders,
      body: transactionsFields,
    },
    invoices: {
      header:
        invoiceType === "invoice"
          ? invoicesPdfHeaders
          : invoiceOrdersPdfHeaders,
      body: invoiceType === "invoice" ? invoicesFields : invoiceOrdersFields,
    },
  };

  function exportHandler() {
    const generateFooter = (totalAmount: { [value: string]: number }) => {
      return [
        "Total:",
        Object.entries(totalAmount)
          .filter((e) => e[1] !== 0.0)
          .map(
            (el) =>
              `${el[0] === "AVAX" ? el[1].toFixed(9) : el[1].toFixed(2)} ${
                el[0]
              }`,
          )
          .join("\n"),
      ];
    };

    try {
      let doc = new jsPDF("landscape");

      autoTable(doc, {
        head: [types[type].header(typeInvoice)],
        body: types[type].body(rowData, typeInvoice).fields.reverse(),
        foot: [generateFooter(types[type].body(rowData).totalAmount)],
        showFoot: "lastPage",
        styles: {
          fontSize: 9,
        },
        footStyles: {
          fontStyle: "bold",
          fontSize: 12,
          cellWidth: "wrap",
        },
      });

      doc.save(`${type}.pdf`);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Tooltip title={t("Export as PDF")}>
      <Button onClick={() => exportHandler()}>
        <Typography
          sx={{
            color: constants.grey,
            textShadow: constants.boxShadowLight,
            fontSize: "24px",
            display: "flex",
          }}
          component="p"
        >
          <GetAppIcon />
          <Typography
            sx={{
              color: constants.textColor,
              fontSize: "12px",
              lineHeight: "21px",
            }}
            component="span"
          >
            PDF
          </Typography>
        </Typography>
      </Button>
    </Tooltip>
  );
};

export default PDFExport;
