import MapLocation from "models/map-location.model";
import Location from "models/resources/location.model";
import Machine from "models/resources/machine.model";

// Converts machine array to locations for map markers.
export const parseMachineLocations = (
  machines: Machine[],
  locations: Location[],
): MapLocation[] =>
  machines
    .map(
      // Parse locations from machines.
      (m: Machine) => {
        const machineLocation: Location | undefined = locations.find(
          (l) => l.id === m.location_id,
        );

        if (typeof machineLocation === "undefined") return;

        return {
          ...parseMapLocation(machineLocation),
          machineName: m.name,
        };
      },
    )
    .filter(
      (
        currentLocation: MapLocation | undefined,
      ): currentLocation is Required<MapLocation> =>
        // Removes machines with no existing location.
        typeof currentLocation?.id !== "undefined",
    )
    .filter(
      // Remove duplicate locations.
      (location: MapLocation, index: number, self: MapLocation[]) =>
        self.findIndex((l: MapLocation) => l.id === location.id) === index,
    );

// Convert locations for map parsing.
export const parseLocations = (locations: Location[]) =>
  locations?.map((location: Location) => parseMapLocation(location));

const parseMapLocation = (location: Location): MapLocation => ({
  id: location.id,
  address: location.address,
  name: location.name,
  position: {
    lat: +parseFloat(location.latitude),
    lng: +parseFloat(location.longitude),
  },
});
