import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import QueryParams, { RequestPagination } from "models/queryParams.model";
import {
  CompanyApiCredentials as ApiCredentials,
  CredentialsToken,
} from "models/resources/company.model";
import { ResponseDataTable } from "models/responseData.model";
import apiClient from "services/api";

const useCompanyApiCredentials = () => {
  const { id } = useParams();
  const [projectId, setProjectId] = useState<number | null>(null);
  const [projectKey, setProjectKey] = useState<string | null>("");
  const [tokens, setTokens] = useState<ResponseDataTable<CredentialsToken[]>>({
    data: [],
  });

  // Get project id after the first successfully created token for current company. Used for token delete request.
  const handleProjectIdChange = (newProjectId: number) => {
    setProjectId(newProjectId);
  };

  // Get project key after the first successfully created token for current company.
  const handleProjectKeyChange = (newProjectKey: string) => {
    setProjectKey(newProjectKey);
  };

  // Update tokens array in table after successfully created/updated token.
  // Updated projectKey after creating the first token for a company via 'createdProjectKey' param.
  const updateTokens = (updatedToken: CredentialsToken) => {
    setTokens((oldTokens) => {
      const existingToken = oldTokens.data.filter(
        (t) => t.id === updatedToken.id,
      );

      return {
        ...tokens,
        data:
          existingToken.length !== 0
            ? oldTokens.data.map((t) =>
                t.id === updatedToken.id ? updatedToken : t,
              )
            : [...oldTokens.data, updatedToken],
      };
    });
  };

  const deleteToken = async (tokenId: number) => {
    await apiClient({
      url: `companies/${id}/integration-credentials/${projectId}/tokens/${tokenId}`,
      method: "delete",
    }).then(() => {
      setTokens((oldTokens) => {
        return {
          ...tokens,
          data: [...oldTokens.data.filter((t) => t.id !== tokenId)],
        };
      });
    });
  };

  const setData = async (queryParams: QueryParams, setDataLoading: any) => {
    setDataLoading(true);
    await apiClient<ApiCredentials>({
      url: `companies/${id}/integration-credentials`,
      method: "get",
      queryParams,
    })
      .then((response) => {
        const { id, project_key, tokens } = response.data;

        setProjectId(id);
        setProjectKey(project_key);
        setTokens({ data: tokens });

        setDataLoading(false);
      })
      .catch((error) => {
        if (error.statusCode === 404) setProjectKey(null);
        setDataLoading(false);
      });
  };

  return {
    projectId,
    projectKey,
    tokens,
    handleProjectIdChange,
    handleProjectKeyChange,
    updateTokens,
    deleteToken,
    setData,
  };
};

export default useCompanyApiCredentials;
