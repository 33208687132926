import { CompanyBase } from "./company.model";

export enum GiftCardTypes {
  Preferential = "preferential",
  Promotional = "promotional",
  Conventional = "conventional",
}
export type GiftCardType =
  | GiftCardTypes.Preferential
  | GiftCardTypes.Promotional
  | GiftCardTypes.Conventional;

interface GiftCard {
  id: number;
  name: string;
  description: string | null;
  type: GiftCardType;
  active: boolean;
  price: string | null;
  currency_type: string;
  currency: string;
  discount_type: "amount" | "percent";
  discount_value: string;
  serial: string;
  deleted: boolean;
  company_id: number;
  company: CompanyBase &
    { id: number; name: string; code: "sumup" | "paysera"; type: string }[];
  usage_conditions: [
    {
      type: "valid_period";
      validity_amount: number;
      validity_type: "minute" | "hour" | "day" | "week" | "month" | "year";
    },
  ];
}

export interface GiftCardConventional extends GiftCard {
  purchase_conditions: [
    { type: "total_purchases"; total_purchase_amount: number },
  ];
  purchased_gift_cards_count: number;
}

export default GiftCard;
