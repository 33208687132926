export const dateFormatTypes = (date: string): string => {
  const localDate: string = localStorage.getItem("dateFormat") ?? "D.MM.YYYY";
  const newDay: number = Date.parse(date);
  const currDate: Date = new Date(newDay);
  let resDate: string = "";

  switch (localDate) {
    case "DD/MM/YYYY":
      resDate = currDate.toLocaleString("en-GB");
      resDate = resDate.split(",")[0];
      break;
    case "M/D/YYYY":
      resDate = currDate.toLocaleString("en-US");
      resDate = resDate.split(",")[0];
      break;
    case "D.MM.YYYY":
      resDate = currDate.toLocaleString("bg-BG");
      resDate = resDate.split(" ")[0];
      break;
    default:
      break;
  }

  return resDate;
};
