import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import useFilterContext from "context/FilterContext";
import QueryParams, { RequestPagination } from "models/queryParams.model";
import Allergen from "models/resources/allergen.model";
import { ResponseDataTable } from "models/responseData.model";
import apiClient from "services/api";
import { getData } from "services/requests/listData";

const useAllergenTable = () => {
  const { filter } = useFilterContext();
  const [allergensData, setAllergensData] = useState<
    ResponseDataTable<Allergen[]>
  >({ data: [] });
  const defaultQueryParams = {
    sort: {
      field: "name",
      order: "ASC",
    },
  };

  const handleAllergenOnDelete = async (id: number) => {
    await apiClient({
      url: `allergens/${id}`,
      method: "delete",
    })
      .then(() =>
        setAllergensData((oldData) => {
          return {
            ...allergensData,
            data: oldData.data.filter((a) => a.id !== id),
          };
        }),
      )
      .catch((error) => toast.error(error.message));
  };

  const fetchPaginatedData = async (
    queryParams: QueryParams,
    setDataLoading: any,
  ) =>
    getData(
      setAllergensData,
      "allergens",
      { ...defaultQueryParams, ...queryParams },
      setDataLoading,
    );

  return {
    allergensData,
    fetchPaginatedData,
    handleAllergenOnDelete,
    setAllergensData,
  };
};

export default useAllergenTable;
