import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import { Box, Typography } from "@mui/material";

import ProductAtachForm from "../../ProductAtachForm";
import { CustomDetailsPage } from "components/DetailsPage/DetailsPage";
import DynamicDialog from "components/layouts/Dialog";
import { FieldDescriptor } from "models/fieldsEntities.model";
import Machine from "models/resources/machine.model";
import { ProductBase } from "models/resources/product.model";
import constants from "styles/scss/constants.module.scss";

import ProductsCard from "./ProductsCard";

interface ProductsBoardProps {
  show: boolean;
  productType: string | null;
  handleShowStateChange: () => void;
  machineData: Machine | undefined;
  attachProduct: (productIds: number[]) => Promise<void>;
  machineProducts: ProductBase[] | null;
  setMachineProducts: Dispatch<SetStateAction<ProductBase[] | null>>;
  setMachineData: Dispatch<SetStateAction<Machine | undefined>>;
  setRefechtFlag: (value: SetStateAction<boolean>) => void;
}

const ProductsBoard = ({
  show,
  handleShowStateChange,
  machineData,
  attachProduct,
  machineProducts,
  setMachineProducts,
  productType,
  setMachineData,
  setRefechtFlag,
}: ProductsBoardProps) => {
  const { t } = useTranslation();
  const productFields: FieldDescriptor[] = [
    {
      type: "Wrap",
      show: true,
      label: "",
      value: (machineProducts ?? [])
        .sort((machineProductA, machineProductB) =>
          machineProductA.name.localeCompare(machineProductB.name),
        )
        .map((product, idx) => {
          return (
            <ProductsCard
              id={product.id}
              key={idx}
              setMachineProducts={setMachineProducts}
              name={product.name}
              display_name={product.display_name}
              price={product.price}
            />
          );
        }),
    },
  ];
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h3"
          component="header"
          sx={{
            fontFamily: constants.semiBold,
            fontSize: "24px",
            lineHeight: "36px",
            marginBottom: "5px",
          }}
        >
          {t("Products")}
        </Typography>
        <DynamicDialog
          buttonSx={{
            margin: "20px 0 20px 0",
            display: "flex",
          }}
          title={t("Attach products")}
          openMessage={t("Attach products")}
          isOpen={show}
          hideActions
          onClick={handleShowStateChange}
          onCancel={handleShowStateChange}
          component={
            <ProductAtachForm
              productType={productType || "packaged"}
              companyId={machineData?.company_id ? +machineData.company_id : 0}
              attachedList={machineProducts ?? []}
              attachProducts={attachProduct}
              onSubmit={handleShowStateChange}
            />
          }
        />
      </div>
      {machineProducts?.length != 0 ? (
        <CustomDetailsPage
          data={machineData}
          setData={(v) => {
            setMachineData(v);
            setRefechtFlag((old) => !old);
          }}
          resource={""}
          call={""}
          fields={productFields}
          isEditableAndDeletable={false}
        />
      ) : (
        <Box
          sx={{
            backgroundColor: "white",
            boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
            padding: "50px",
          }}
        >
          <h1>{t("There aren't any products")}</h1>
        </Box>
      )}
    </>
  );
};

export default ProductsBoard;
