import Module from "models/resources/module.model";

export const moduleRequestFilter = (data: Module[], tab?: string) => {
  switch (tab) {
    case "active":
      return data?.filter(
        ({ active, deleted }) => active === true && deleted === false,
      );
    case "deactivated":
      return data?.filter(
        ({ active, deleted }) => active === false && deleted === false,
      );
    case "deleted":
      return data?.filter(({ deleted }) => deleted === true);
  }
};
