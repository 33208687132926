import { useState, useEffect } from "react";

import { PaymentStatus } from "models/resources/payment.model";
import { validatePaymentStatus } from "utils/validatePaymentStatus";

const usePaymentStatus = (moduleSerial: string, checkoutId: string) => {
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>({
    funded_entity: false,
    status: "",
  });

  useEffect(() => {
    setTimeout(() => {
      // remove sensitive information (checkout_id as query param) after transaction finish
      window.history.pushState(
        "",
        "",
        `/machine-payment/${moduleSerial}/complete-web-payment`,
      );
    }, 2000);

    checkoutId &&
      (async () =>
        await validatePaymentStatus(checkoutId).then(
          (paymentStatus: PaymentStatus) => setPaymentStatus(paymentStatus),
        ))();
  }, [checkoutId]);

  return { paymentStatus };
};

export default usePaymentStatus;
