import { useTranslation } from "react-i18next";

import { Box, Tab, Tabs, TabsProps } from "@mui/material";

import useTabs from "components/CustomTabs/useTabs";

interface CustomTabsProps extends TabsProps {
  tabs: {
    index?: number;
    label: string;
  }[] /* Generates tabs from the given label with custom indexes. If there is no index, it uses the index from mapping which is the default order. */;
  defaultValue?: number /* Defines the starting tab index. Default is 0. */;
  onCustomChange?(
    tabIndex: number,
  ): void /* Executes given function on tab change. It contains the index of the new tab. */;
}

// TODO: Fix React MUI error on dynamic tab removing that occurs in the payment credentials functionallity.
// Error: MUI: The `value` provided to the Tabs component is invalid. None of the Tabs' children match with "3". You can provide one of the following values: 2, 1, 0.
const CustomTabs = ({
  tabs,
  defaultValue = 0,
  onCustomChange = () => {},
  onChange, // Remove the default onChange from TabsProps to not cause conflicts with the custom one.
  ...props
}: CustomTabsProps) => {
  const { t } = useTranslation();
  const { tabIndex, handleTabIndexOnChange } = useTabs(
    defaultValue,
    onCustomChange,
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        sx={{ paddingBottom: "30px" }}
        value={tabIndex}
        onChange={handleTabIndexOnChange}
        {...props}
      >
        {tabs.map((tab, i) => (
          <Tab
            key={tab.index ?? i}
            sx={{
              fontWeight: "bold",
              fontSize: "0.8em",
            }}
            label={t(tab.label)}
            value={tab.index ?? i}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default CustomTabs;
