import { useEffect, useState } from "react";

import { SumupMounter } from "components/SumUp/sumupHelpers";

const useSumupForm = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [sumupMounter, setSumupMounter] = useState<SumupMounter | null>(null);
  const [responseStatus, setResponseStatus] = useState<{
    success: boolean;
    message?: string;
  } | null>(null);

  const handleSumupMounterChange = (mounter: SumupMounter) => {
    setSumupMounter(mounter);
  };

  const handleResponseStatusChange = (status: {
    success: boolean;
    message?: string;
  }) => {
    setResponseStatus(status);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => {
      // Unmount current sumup card on dialog window close to prevent the form of staying on the next opened window after submition.
      // TODO: Refactor because it is currently not working as expected.
      if (sumupMounter !== null) sumupMounter.unmount();
    };
  }, []);

  return {
    loading,
    responseStatus,
    handleSumupMounterChange,
    handleResponseStatusChange,
  };
};

export default useSumupForm;
