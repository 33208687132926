import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  CustomTable,
  Column,
  TableSettings,
  DynamicDialogProps,
  CellValue,
} from "components/Table/Table";
import ContentLayout from "components/layouts/ContentLayout";
import Allergen from "models/resources/allergen.model";

import useAllergenTable from "./useAllergenTable";

const AllergenTable = () => {
  const { t } = useTranslation();
  const {
    allergensData,
    fetchPaginatedData,
    handleAllergenOnDelete,
    setAllergensData,
  } = useAllergenTable();

  const columns: Column<Allergen>[] = [
    {
      label: t("Name"),
      name: "name",
      getData: (data): CellValue => data.name,
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "name" },
    },
    {
      label: t("Company"),
      name: "company",
      getData: (data): CellValue => (
        <Link
          to={{
            pathname: `/account/companies/${data.company_id}`,
          }}
        >
          {data.company.display_name || data.company.name}
        </Link>
      ),
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "company_id" },
    },
    {
      label: t("Actions"),
      name: "actions",
      getData: (data): CellValue => data.id,
      show: () => {
        return true;
      },
      width: "5%",
      align: "center",
    },
  ];

  const tableOptions: TableSettings = {
    pagination: true,
    page: 0,
    rowsPerPage: 25,
    field: "name",
    order: "asc",
  };

  const dynamicDialogProps: DynamicDialogProps = {
    title: "Add allergen",
    resource: "allergen",
    call: "allergens",
    styles: () => "flexHalfForm",
  };

  return (
    <ContentLayout title={`${t("Products")} > ${t("Allergens")}`}>
      <CustomTable
        title={t("Allergens")}
        tableData={allergensData}
        fetchPaginatedData={fetchPaginatedData}
        setTableData={setAllergensData}
        columns={columns}
        resource="allergen"
        call="allergens"
        tableOptions={tableOptions}
        exportProp={"allergens"}
        dynamicDialogProps={dynamicDialogProps}
        isDynamic={true}
        notCSV={true}
        clickableRow={false}
        onDelete={handleAllergenOnDelete}
      />
    </ContentLayout>
  );
};

export default AllergenTable;
