import { Fragment, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Grid, Typography } from "@mui/material";

import { DatePickerTypes } from "../../components/DatePicker/DatePicker";
import DatePicker from "components/DatePicker/DatePicker";
import HeaderFiltersActions from "components/HeaderFilters/HeaderFiltersActions";
import Loader from "components/Loader";
import useAuthContext from "context/AuthContext";
import useDataContext from "context/DataContext";
import useFilterContext from "context/FilterContext";
import { FilterType } from "models/filterData.model";
import constants from "styles/scss/constants.module.scss";

interface ContentLayoutProps {
  title: string;
  children: ReactNode;
  filters?: boolean;
  isDateRanged?: boolean;
}
const ContentLayout = ({
  children,
  title,
  filters,
  isDateRanged,
}: ContentLayoutProps) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { getPagePropsData } = useDataContext();
  const location = useLocation();
  const { id } = useParams();
  const { filter, handleFilterChange } = useFilterContext();

  const getDateRange = (dates: any) => {
    const [start, end] = dates;
    if (end === null) return;
    handleFilterChange({
      type: FilterType.DateRange,
      value: {
        startDate: start.toISOString(),
        endDate: end.toISOString(),
      },
    });
  };

  return (
    <Fragment>
      <Loader />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {typeof id !== "undefined" &&
            location.pathname.startsWith("/account") && (
              <Button
                className="backButton"
                sx={{
                  padding: "0!important",
                  marginBottom: "5px",
                  color: constants.primary,
                  textTransform: "none",
                }}
                onClick={() => window.history.back()}
              >
                <ArrowBackIcon />
                {t("Back")}
              </Button>
            )}
          <Box display="flex" justifyContent="space-between" alignItems="end">
            <Box>
              <Typography
                variant="h3"
                component="header"
                sx={{
                  fontFamily: constants.semiBold,
                  fontSize: "24px",
                  lineHeight: "36px",
                  marginBottom: "5px",
                }}
              >
                {title}
              </Typography>
              {typeof user !== "undefined" &&
                getPagePropsData.componentName === "dashboard" && (
                  <Typography
                    variant="subtitle1"
                    component="p"
                    sx={{
                      fontFamily: constants.textRegular,
                      fontSize: "14px",
                      lineHeight: "17px",
                    }}
                  >
                    {t("Welcome").concat(
                      ` ${user.first_name} ${user.last_name}!`,
                    )}
                  </Typography>
                )}
            </Box>
            <HeaderFiltersActions filters={filters} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          {isDateRanged && (
            <Box style={{ maxWidth: "200px" }}>
              <DatePicker
                onChange={getDateRange}
                type={DatePickerTypes.DATE_RANGED}
                isCentered
              />
            </Box>
          )}
          <Box>{children}</Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ContentLayout;
