import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { TableCell, TableRow } from "@mui/material";

import {
  convertUTCDateToLocalDate,
  formatDate,
} from "components/DatePicker/utils";
import { CustomDetailsPage } from "components/DetailsPage/DetailsPage";
import ContentLayout from "components/layouts/ContentLayout";
import Promotion, { winConditionTypes } from "models/resources/promotion.model";

import useProductDetails from "./useProductDetails";

const ProductDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { productData, setProductData, image, setRefetchFlag } =
    useProductDetails();

  if (productData === null) return <Fragment />;

  const fields = [
    {
      value: productData.name,
      label: "Name",
      type: "InfoText",
      show: true,
    },
    {
      value: productData.display_name,
      label: "Product name",
      type: "InfoText",
      show: true,
    },
    {
      value: productData.currency,
      label: "Currency",
      type: "InfoText",
      show: true,
    },
    {
      value: productData.company.display_name || productData.company.name,
      id: productData.company_id,
      label: "Company",
      type: "Link",
      route: "companies",
      show: true,
    },
    {
      value: Number(productData.price).toFixed(2),
      label: "Price",
      type: "InfoText",
      show: true,
    },
    {
      value: Number(productData.current_price).toFixed(2),
      label: "Current price",
      type: "InfoText",
      show: true,
    },
    {
      value: productData.description,
      label: "Description",
      type: "DescriptiveInfoText",
      show: true,
    },
    {
      value: t(productData.product_type),
      label: "Product type",
      type: "InfoText",
      show: true,
    },
    {
      value:
        productData?.machines?.length === 0
          ? "---"
          : productData?.machines?.map((m) => m.name).join(", "),
      label: "Machines",
      type: "InfoText",
      show: true,
    },
    {
      value: image,
      label: "Image",
      type: "ImageFile",
      inlineStyle: { width: "400px", height: "200px" },
      show: true,
    },
    {
      value: productData.ingredients,
      label: "Ingredients",
      type: "Table",
      show: true,
      headers: [t("Name"), t("Quantity"), t("Measurement"), t("Allergens")],
      columns: productData.ingredients?.map((e, i) => {
        return (
          <TableRow
            key={`${i}-${e.name}`}
            hover
            sx={{ cursor: "pointer" }}
            onClick={(event: any) => {
              if (event.target.nodeName === "TD")
                navigate(`/account/vending/ingredients/${e.id}`);
            }}
          >
            <TableCell sx={{ width: "20%" }}>{e.name}</TableCell>
            <TableCell sx={{ width: "20%" }}>{e.quantity}</TableCell>
            <TableCell sx={{ width: "20%" }}>{t(e.unit_name)}</TableCell>
            <TableCell>
              <p style={{ margin: 0 }}>
                {e.allergens.map((allergen) => allergen.name).join(", ")}
              </p>
            </TableCell>
          </TableRow>
        );
      }),
    },
    {
      value: productData.promotions,
      label: "Price Adjustment",
      type: "Table",
      show: true,
      headers: [
        t("Name"),
        t("Valid from"),
        t("Valid to"),
        t("From"),
        t("To"),
        t("Discount value"),
      ],
      columns: productData.promotions?.map((promotion: Promotion, i: any) => {
        const { win_conditions } = promotion;

        if (win_conditions[0].type != winConditionTypes.TimeOfDay) return;
        const dateFrom = win_conditions[0].from.split(":");
        const dateTo = win_conditions[0].to.split(":");

        const [hoursFrom, minutesFrom, _secondsFrom] = dateFrom;
        const [hoursTo, minutesTo, _secondsto] = dateTo;

        const from = new Date();
        from.setHours(Number(hoursFrom));
        from.setMinutes(Number(minutesFrom));

        const to = new Date();
        to.setHours(Number(hoursTo));
        to.setMinutes(Number(minutesTo));

        const localeFrom = convertUTCDateToLocalDate(from);
        const localeTo = convertUTCDateToLocalDate(to);

        return (
          <TableRow>
            <TableCell sx={{ width: "10%" }}>
              {promotion.display_name}
            </TableCell>
            <TableCell sx={{ width: "10%" }}>
              {formatDate(promotion.valid_from)}
            </TableCell>
            <TableCell sx={{ width: "10%" }}>
              {formatDate(promotion.valid_to)}
            </TableCell>
            <TableCell sx={{ width: "10%" }}>
              {localeFrom.getHours().toString().padStart(2, "0")}:
              {localeFrom.getMinutes().toString().padStart(2, "0")}
            </TableCell>
            <TableCell sx={{ width: "10%" }}>
              {localeTo.getHours().toString().padStart(2, "0")}:
              {localeTo.getMinutes().toString().padStart(2, "0")}
            </TableCell>
            <TableCell sx={{ width: "10%" }}>
              {Number(promotion.gift_card.discount_value).toFixed(2)}
            </TableCell>
          </TableRow>
        );
      }),
    },
  ];

  return (
    <ContentLayout
      title={`${t("Products")} > ${productData?.name ?? ""}`}
      filters={false}
    >
      <CustomDetailsPage
        data={productData}
        setData={(v) => {
          setProductData(v);
          setRefetchFlag((old) => !old);
        }}
        resource={"product"}
        call={"products"}
        fields={fields}
        isDeletable={productData.machines.length === 0}
      />
    </ContentLayout>
  );
};

export default ProductDetails;
