import { useTranslation } from "react-i18next";

import CircleIcon from "@mui/icons-material/Circle";

import {
  CellValue,
  Column,
  CustomTable,
  DynamicDialogProps,
  TableSettings,
} from "components/Table/Table";
import ContentLayout from "components/layouts/ContentLayout";
import GiftCard from "models/resources/gitfCard.model";

import useGiftCardTable from "./useGiftCardTable";

const GiftCardTable = () => {
  const { t } = useTranslation();
  const { giftCardsData, setGiftCardsData, fetchPaginatedData } =
    useGiftCardTable();

  const columns: Column<GiftCard>[] = [
    {
      label: t("Name"),
      name: "name",
      getData: (data): CellValue => {
        return data.name;
      },
      show: () => true,
    },
    {
      label: t("Type"),
      name: "type",
      getData: (data): CellValue => t(data.type),
      show: () => true,
    },
    {
      label: t("Active "),
      name: "active",
      getData: (data): CellValue => (
        <CircleIcon sx={{ color: data.active ? "#4CAF50" : "#FF6347" }} />
      ),
      show: () => true,
    },
    {
      label: t("Value"),
      name: "discount",
      getData: (data): CellValue => Number(data.discount_value).toFixed(2),
      show: () => true,
    },
    {
      label: t("Purchase price"),
      name: "price",
      getData: (data): CellValue =>
        data.price !== null ? Number(data.price).toFixed(2) : "---",
      show: () => true,
    },
    {
      label: t("Currency"),
      name: "currency",
      getData: (data): CellValue => data.currency,
      show: () => true,
    },
    {
      label: t("Valid for"),
      name: "valid",
      getData: (data): CellValue =>
        data.usage_conditions[0] &&
        `${data.usage_conditions[0]?.validity_amount} ${t(
          data.usage_conditions[0]?.validity_type,
        )}`,
      show: () => true,
    },
    {
      label: t("Edit"),
      name: "edit",
      getData: (data): CellValue => data.id,
      show: () => {
        return true;
      },
      align: "center",
      width: "5%",
    },
  ];
  const tableOptions: TableSettings = {
    pagination: true,
    page: 0,
    rowsPerPage: 25,
    field: "from",
    order: "desc",
  };
  const dynamicDialogProps: DynamicDialogProps = {
    title: "Add gift card",
    resource: "gift-cards",
    call: "gift-cards",
    styles: () => "flexHalfForm",
  };
  return (
    <ContentLayout title={t("Gift Cards")}>
      <CustomTable
        fetchPaginatedData={fetchPaginatedData}
        title={t("Gift Cards")}
        resource="gift-cards"
        call="gift-cards"
        tableData={giftCardsData}
        setTableData={setGiftCardsData}
        columns={columns}
        tableOptions={tableOptions}
        exportProp={"giftCards"}
        dynamicDialogProps={dynamicDialogProps}
        isDynamic={true}
        isSearchable={false}
        notCSV={true}
      />
    </ContentLayout>
  );
};

export default GiftCardTable;
