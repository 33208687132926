import { useState } from "react";

import { IngredientBase } from "models/resources/ingredient.model";

import { IngredientFormFields } from "./utils";

const useIngredientForm = (
  defaultValues: IngredientFormFields,
  defaultIngredientList: IngredientBase[] | null,
) => {
  const [formValues, setFormValues] =
    useState<IngredientFormFields>(defaultValues);
  const [ingredientList, setIngredientList] = useState<IngredientBase[]>(
    defaultIngredientList ?? [],
  );
  const [selectedIngredient, setSelectedIngredient] =
    useState<IngredientBase | null>(null);

  // Handles ingredient select component onChange.
  const handleIngredientOnChange = (ingredient: IngredientBase | string) => {
    if (typeof ingredient === "string") {
      // Handles the component's input string value change.
      setFormValues((oldFormValues) => ({
        ...oldFormValues,
        name: ingredient,
      }));
      return;
    }

    setFormValues((oldFormValues) => ({
      ...oldFormValues,
      allergens: ingredient.allergens.map((allergen) => allergen.name),
      measurement_unit: ingredient.unit_name,
    }));

    setSelectedIngredient(ingredient);
  };

  const handleQuantityOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;

    if (+value < 0) return;

    setFormValues((oldFormValues) => ({
      ...oldFormValues,
      quantity: value,
    }));
  };

  const handleCapacityOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;

    if (+value < 0) return;

    setFormValues((oldFormValues) => ({
      ...oldFormValues,
      capacity: value,
    }));
  };

  return {
    formValues,
    ingredientList,
    selectedIngredient,
    handleIngredientOnChange,
    handleQuantityOnChange,
    handleCapacityOnChange,
  };
};

export default useIngredientForm;
