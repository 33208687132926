import { getCountryStateCity } from "components/Forms/formUtils";

class CountryList {
  countries = () => getCountryStateCity("countries");
  states = (country: string) => getCountryStateCity("states", country);
  cities = (country: string, state: string) =>
    getCountryStateCity("cities", country, state);
}

export default CountryList;
