import { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Id, toast } from "react-toastify";

import AveratoAdvertisement from "components/Advertisement/Advertisement";
import usePaymentStatus from "pages/payment/CompleteWebPayment/usePaymentStatus";

const CompleteWebPayment = () => {
  const { t } = useTranslation();
  const { moduleSerial, checkoutId } = useParams();
  const { paymentStatus } = usePaymentStatus(
    moduleSerial || "",
    checkoutId || "",
  );
  const toastId = useRef<Id | null>(null);

  useEffect(() => {
    if (paymentStatus.status === "") {
      toastId.current = toast.loading(t("Payment is being processed..."));
      return;
    }

    toast.update(toastId.current as Id, {
      render: t(paymentStatus.status),
      type:
        paymentStatus.status === "Payment finished successfully." // TODO: Add constant for success message.
          ? toast.TYPE.SUCCESS
          : toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 8000,
    });
  }, [paymentStatus]);

  return <AveratoAdvertisement moduleSerial={moduleSerial || ""} />;
};

export default CompleteWebPayment;
