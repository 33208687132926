import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import * as _ from "lodash";

import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Box,
  Button,
  FilledInput,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
  List,
  ListItem,
  SwipeableDrawer,
  Tooltip,
} from "@mui/material";

import DateRangePicker from "components/DatePicker/DatePicker";
import MultiSelectFilter from "components/MultiSelectFilter";
import useDataContext from "context/DataContext";
import useFilterContext from "context/FilterContext";
import { FilterType } from "models/filterData.model";
import { Choice, PageProps } from "models/pageProps.model";

// configuration of filter component rendering
type Anchor = "bottom" | "right";

interface selectedOptions {
  field: string;
  value: any;
}

export default function SideBarFilters() {
  const location = useLocation();
  const { getPagePropsData } = useDataContext();
  const { filter, handleFilterChange, options } = useFilterContext();
  const [sideBarState, setSideBarState] = React.useState({ right: false });
  const [isDataSubmited, setIsDataSubmitttes] = useState(true);
  const [selectedOption, setSelectedOption] = useState<selectedOptions>();
  const { t } = useTranslation();

  // show/hide the sidebar filters component
  const toggleFilters =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setSideBarState({ ...sideBarState, [anchor]: open });
    };

  // map submitted values with filter object
  function handleChange(_event: React.ChangeEvent<HTMLInputElement>) {
    setIsDataSubmitttes(true);
  }

  const getDate = (
    dateRange: Date[],
    type: FilterType.DateFrom | FilterType.DateTo,
  ) => {
    if (dateRange === null) {
      handleFilterChange({
        type: FilterType.DateFrom,
        value: null,
      });
      return;
    }

    handleFilterChange({
      type,
      value: {
        startDate: dateRange[0].toISOString(),
        endDate: dateRange[1].toISOString(),
      },
    });
  };

  useEffect(() => {
    if (
      Object.values(getPagePropsData).length === 0 ||
      !location.pathname.includes(getPagePropsData.componentName)
    ) {
      return;
    }

    if (typeof selectedOption === "undefined") return;

    const filterValue = selectedOption.value.id.toLowerCase();
    switch (selectedOption.field.toLowerCase()) {
      case "companystatus":
        handleFilterChange({
          type: FilterType.CompanyStatus,
          value: filterValue === "all" ? [] : filterValue,
        });
        break;
      case "invoicestatus":
        handleFilterChange({
          type: FilterType.RequiredInvoice,
          value: filterValue === "all" ? "" : filterValue,
        });
        break;
      case "paymentpointtype":
        const { objectEntityIds, machineIds, qrIds, vposIds } = filter;

        if (
          [objectEntityIds, machineIds, qrIds, vposIds].every((f) =>
            _.isEmpty(f),
          )
        ) {
          return;
        }

        handleFilterChange(
          { type: FilterType.Machine, value: [] },
          { type: FilterType.QR, value: [] },
          { type: FilterType.VPOS, value: [] },
          { type: FilterType.ObjectEntity, value: [] },
        );
        break;
    }
  }, [selectedOption]);

  //show the filters list
  const filtersList = (anchor: Anchor) => {
    return (
      <Box
        sx={{
          width: anchor === "bottom" ? "auto" : "30vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
        role="presentation"
      >
        <Box>
          <Typography
            gutterBottom
            variant="h5"
            component="header"
            sx={{
              fontFamily: "Poppins-Medium",
              fontSize: "2rem",
              margin: "6px 0px",
            }}
            style={{
              color: "#313838",
              textTransform: "uppercase",
              fontFamily: "Poppins-Bold",
            }}
          >
            {t("Filter by:")}
          </Typography>
          <List>
            {getPagePropsData?.pageFilters?.map((el: PageProps) => {
              if (
                Object.values(getPagePropsData).length === 0 ||
                !location.pathname.includes(getPagePropsData.componentName)
              ) {
                return;
              }

              return (
                <ListItem
                  key={`${el.type}-${JSON.stringify(el)}`}
                  disablePadding
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    marginBottom: "20px",
                  }}
                >
                  {el.type !== "switch" && (
                    <Typography component="p" variant="body2">
                      {t(el.placeholder)}:
                    </Typography>
                  )}
                  {el.type === "dateRange" && el.option === "dateRangeFrom" && (
                    <Box>
                      <DateRangePicker
                        range={
                          typeof filter.from !== "undefined" &&
                          filter.from?.startDate !== null
                            ? [
                                new Date(filter.from?.startDate as string),
                                new Date(filter.from?.endDate as string),
                              ]
                            : undefined
                        }
                        onChange={(data: Date | Date[] | null) =>
                          getDate(data as Date[], FilterType.DateFrom)
                        }
                      />
                    </Box>
                  )}

                  {el.type === "dateRange" && el.option === "dateRangeTo" && (
                    <Box>
                      <DateRangePicker
                        range={
                          typeof filter.to !== "undefined" &&
                          filter.to?.startDate !== null
                            ? [
                                new Date(filter.to?.startDate as string),
                                new Date(filter.to?.endDate as string),
                              ]
                            : undefined
                        }
                        onChange={(data: Date | Date[] | null) =>
                          getDate(data as Date[], FilterType.DateTo)
                        }
                      />
                    </Box>
                  )}
                  {(el.type === "search" || el.type === "text") && (
                    <Box>
                      <FormControl
                        fullWidth
                        sx={{ margin: "0px" }}
                        variant="filled"
                      >
                        <FilledInput
                          id="filled-adornment-weight-7"
                          // value={_.find(filterValues, [el.option])}
                          disableUnderline={true}
                          autoFocus={false}
                          type={el.type}
                          placeholder={el.placeholder}
                          aria-describedby="filled-weight-helper-text"
                          inputProps={{ "aria-label": "weight" }}
                        />
                      </FormControl>
                    </Box>
                  )}
                  {el.type === "switch" && (
                    <FormControlLabel
                      control={
                        <Switch
                          name={el.option}
                          //checked={_.find(filterValues, [el.option])}
                          onChange={(event) => handleChange(event)}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label={t(el.placeholder)}
                    />
                  )}
                  {el.type === "select" && (
                    <FormControl fullWidth sx={{ m: 1 }}>
                      <Select
                        disabled={
                          el.option === "invoiceStatus" &&
                          (getPagePropsData.currentTab === "issued" ||
                            getPagePropsData.currentTab === "incoming" ||
                            getPagePropsData.currentTab === "invoices")
                        }
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        name={el.option}
                        value={
                          typeof selectedOption !== "undefined"
                            ? selectedOption.value
                            : setSelectedOption({
                                field: el.option,
                                value: el.choices?.[0],
                              })
                        }
                        onChange={(event) => {
                          return setSelectedOption({
                            field: el.option,
                            value: event.target.value,
                          });
                        }}
                        autoWidth
                      >
                        {el.choices?.map((choice: Choice) => (
                          <MenuItem
                            key={`${el.type}-${el.urlPath}`}
                            value={choice as any}
                          >
                            {t(choice.label)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {el.type === "multiselect" && (
                    <MultiSelectFilter
                      fieldName={el.option}
                      defaultValue={
                        // TODO: Clean logic with pageProps refactor in future.
                        ["companyIds", "issuingCompanyIds"].includes(el.option)
                          ? options.companies.filter((c) =>
                              filter.companyIds?.includes(c.id),
                            )
                          : el.option === "locationIds"
                            ? options.locations.filter((l) =>
                                filter.locationIds?.includes(l.id),
                              )
                            : el.option === "transactionStatuses"
                              ? el.choices?.filter((c) =>
                                  filter.transactionStatuses?.includes(c.id),
                                )
                              : el.option === "transactionPaymentTypes"
                                ? el.choices?.filter((c) =>
                                    filter.transactionPaymentTypes?.includes(
                                      c.id,
                                    ),
                                  )
                                : el.option === "roles"
                                  ? el.choices?.filter((c) =>
                                      filter.userRoleNames?.includes(c.id),
                                    )
                                  : undefined
                      }
                      paymentPointChoice={selectedOption?.value}
                      sorceIndex={el.sorceIndex!}
                      choices={el.choices}
                    />
                  )}
                </ListItem>
              );
            })}
          </List>
        </Box>

        <Typography
          sx={{
            fontSize: "3em",
            backgroundColor: "#4b535a",
            color: "transparent",
            textTransform: "uppercase",
            textShadow: "0px 3px 3px rgba(255,255,255,0.5)",
            fontFamily: "Poppins-Bold",
            backgroundClip: "text",
            textAlign: "center",
          }}
        >
          averato
        </Typography>
      </Box>
    );
  };

  return (
    <Box>
      <Button onClick={toggleFilters("right", true)}>
        <Tooltip title={t("Table filters")}>
          <FilterListIcon />
        </Tooltip>
      </Button>
      <SwipeableDrawer
        anchor={"right"}
        open={sideBarState["right"]}
        onClose={toggleFilters("right", false)}
        onOpen={toggleFilters("right", true)}
      >
        {filtersList("right")}
      </SwipeableDrawer>
    </Box>
  );
}
