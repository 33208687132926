import { useEffect, useState } from "react";

import Product from "models/resources/product.model";
import apiClient from "services/api";

const useProductAttachForm = (productType: string, companyId: number) => {
  const [productList, setProductList] = useState<Product[]>([]);
  const [multiSelectValues, setMultiSelectValues] = useState<{
    // Controlled MUI multiple autocomplete select component structure.
    products: Product[]; // Handles the selected values.
    productNames: string; // Handles the input value text in the field.
  }>({
    products: [],
    productNames: "",
  });

  const handleMultiSelectOnChange = (
    name: string,
    values: Product[] | string,
  ) => {
    setMultiSelectValues((oldState) => ({
      ...oldState,
      [name]: values,
    }));
  };

  useEffect(() => {
    (async () => {
      await apiClient<Product[]>({
        url: "products",
        method: "get",
        queryParams: {
          filter: {
            productType,
            companyIds: [companyId],
          },
        },
      }).then((response) => setProductList(response.data));
    })();
  }, [productType]);

  return {
    productList,
    multiSelectValues,
    handleMultiSelectOnChange,
  };
};

export default useProductAttachForm;
