import { RouteObject } from "react-router-dom";

import { FilterProvider } from "context/FilterContext";
import AllergenTable from "pages/account/Allergen/Table";
import BlueprintDetails from "pages/account/Blueprint/Details";
import BlueprintTable from "pages/account/Blueprint/Table";
import CompanyApiCredentials from "pages/account/Company/ApiCredentials";
import CompanyDetails from "pages/account/Company/Details";
import CompanyTable from "pages/account/Company/Table";
import Dashboard from "pages/account/Dashboard";
import EntityTable from "pages/account/EntityCategory/EntityTable";
import GiftCardsDetails from "pages/account/GiftCards/Details/GiftCardsDetails";
import GiftCardTable from "pages/account/GiftCards/Table/GiftCardTable";
import IngredientDetails from "pages/account/Ingredient/Details";
import IngredientTable from "pages/account/Ingredient/Table";
import InvoiceDetails from "pages/account/Invoice/Details";
import IncomingTable from "pages/account/Invoice/IncommingTable";
import OutgoingTable from "pages/account/Invoice/OutgoingTable";
import LocationDetails from "pages/account/Location/Details";
import LocationTable from "pages/account/Location/Table";
import MachineDetails from "pages/account/Machine/Details";
import MachineTable from "pages/account/Machine/Table";
import ModuleDetails from "pages/account/Module/Details";
import ModuleTable from "pages/account/Module/Table";
import OrderDetails from "pages/account/Order/Details";
import OrderTable from "pages/account/Order/Table";
import PosDetails from "pages/account/Pos/Details";
import PosTable from "pages/account/Pos/Table";
import PrepaidCards from "pages/account/PrepaidCards";
import PriceAdjustmentDetails from "pages/account/PriceAdjustment/Details/PriceAdjustmentDetails";
import PriceAdjustment from "pages/account/PriceAdjustment/PriceAdjustmentTable";
import ProductDetails from "pages/account/Product/Details";
import ProductTable from "pages/account/Product/Table";
import PromotionDetails from "pages/account/Promotion/Details";
import PromotionTable from "pages/account/Promotion/Table";
import QrPaymentCodeDetails from "pages/account/QrPaymentCode/Details";
import QrPaymentCodeTable from "pages/account/QrPaymentCode/Table";
import TransactionDetails from "pages/account/Transaction/Details";
import TransactionTable from "pages/account/Transaction/Table";
import UserDetails from "pages/account/User/Details";
import UserTable from "pages/account/User/Table";
import Login from "pages/auth/Login";
import PasswordForgot from "pages/auth/PasswordForgot";
import PasswordResend from "pages/auth/PasswordResend";
import PasswordReset from "pages/auth/PasswordReset";
import Register from "pages/auth/Register";
import Layout from "pages/layouts/Layout";
import NotFound from "pages/layouts/NotFound";
import CompleteWebPayment from "pages/payment/CompleteWebPayment";
import VM from "pages/payment/VM";
import Root from "routes/Root";

// This file is 'tsx' format because of the JSX Elements that are used in the RouteObject array.
export const routesConfig: RouteObject[] = [
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/password-forgot",
        element: <PasswordForgot />,
      },
      {
        path: "/resend-email-forgotten-password",
        element: <PasswordResend />,
      },
      {
        path: "/password-reset/:token",
        element: <PasswordReset />,
      },
      {
        path: "/vm/:moduleSerial",
        element: <VM />,
      },
      {
        path: "/complete-web-payment/:moduleSerial/:checkoutId",
        element: <CompleteWebPayment />,
      },
      {
        path: "/account",
        element: (
          <FilterProvider>
            <Layout />
          </FilterProvider>
        ),
        children: [
          { index: true, element: <Dashboard /> },
          {
            path: "/account/dashboard",
            element: <Dashboard />,
          },
          {
            path: "/account/locations",
            element: <LocationTable />,
          },
          {
            path: "/account/locations/:id",
            element: <LocationDetails />,
          },
          {
            path: "/account/vending/machines",
            element: <MachineTable />,
          },
          {
            path: "/account/vending/machines/:id",
            element: <MachineDetails />,
          },
          {
            path: "/account/vending/blueprints",
            element: <BlueprintTable />,
          },
          {
            path: "/account/vending/blueprints/:id",
            element: <BlueprintDetails />,
          },
          {
            path: "/account/pos",
            element: <PosTable />,
          },
          {
            path: "/account/pos/:id",
            element: <PosDetails />,
          },
          {
            path: "/account/qr-payment-codes",
            element: <QrPaymentCodeTable />,
          },
          {
            path: "/account/qr-payment-codes/:id",
            element: <QrPaymentCodeDetails />,
          },
          {
            path: "/account/transactions",
            element: <TransactionTable />,
          },
          {
            path: "/account/transactions/:id",
            element: <TransactionDetails />,
          },
          {
            path: "/account/orders",
            element: <OrderTable />,
          },
          {
            path: "/account/orders/:id",
            element: <OrderDetails />,
          },
          // Removed while there is no way for invoices to be created atm.
          // {
          //   path: "/account/invoices/incoming",
          //   element: <IncomingTable />,
          // },
          // {
          //   path: "/account/invoices/outgoing",
          //   element: <OutgoingTable />,
          // },
          // {
          //   path: "/account/invoices/:id",
          //   element: <InvoiceDetails />,
          // },
          {
            path: "/account/users",
            element: <UserTable />,
          },
          {
            path: "/account/users/:id",
            element: <UserDetails />,
          },
          {
            path: "/account/companies",
            element: <CompanyTable />,
          },
          {
            path: "/account/companies/:id",
            element: <CompanyDetails />,
          },
          {
            path: "/account/companies/:id/api-credentials",
            element: <CompanyApiCredentials />,
          },
          {
            path: "/account/modules",
            element: <ModuleTable />,
          },
          {
            path: "/account/modules/:id",
            element: <ModuleDetails />,
          },
          {
            path: "/account/vending/products",
            element: <ProductTable />,
          },
          {
            path: "/account/vending/products/:id",
            element: <ProductDetails />,
          },
          {
            path: "/account/vending/ingredients",
            element: <IngredientTable />,
          },
          {
            path: "/account/vending/ingredients/:id",
            element: <IngredientDetails />,
          },
          {
            path: "/account/vending/allergens",
            element: <AllergenTable />,
          },
          {
            path: "/account/promotions",
            element: <PromotionTable />,
          },
          {
            path: "/account/promotions/:id",
            element: <PromotionDetails />,
          },
          {
            path: "/account/price_adjustments/:id",
            element: <PriceAdjustmentDetails />,
          },
          // {
          //   path: "/account/prepaid-cards",
          //   element: <PrepaidCards />,
          // },
          {
            path: "/account/gift-cards",
            element: <GiftCardTable />,
          },
          {
            path: "/account/gift-cards/:id",
            element: <GiftCardsDetails />,
          },
          {
            path: "/account/entity-categories",
            element: <EntityTable />,
          },
          {
            path: "/account/price_adjustments",
            element: <PriceAdjustment />,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
];
