import {
  CSSProperties,
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  DialogActions,
  DialogContent,
  Link,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";

import BootstrapDialogTitle from "components/layouts/Dialog/DialogHeader";
import useDataContext from "context/DataContext";
import { DatabaseEntity } from "models/responseData.model";
import apiClient from "services/api";
import constants from "styles/scss/constants.module.scss";
import { appendPropsToComponent } from "utils/component";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: "unset!important",
  },
}));
interface FormDataProps {
  submittedData: any;
  setSubmittedData: any;
}

interface DynamicDialogProps {
  openMessage?: string;
  title: string;
  component: JSX.Element;
  isOpen?: boolean;
  openState?: [boolean, Dispatch<SetStateAction<boolean>>];
  onClick?: Function; // Execute function on button click.
  onConfirm?: Function; // Execute custom function on confirm click.
  hideActions?: boolean; // disable submit and cancel buttons
  hideButton?: boolean; // displays the dialog directly without a button prompting to open it
  submitFormData?: (data: FormDataProps) => any; // submit form function
  exitAction?: any; // hide dialog in parent (usually state setter)
  exitActionType?: "boolean" | "object"; // some components use a simple boolean state and others use a complex object one
  iconButton?: JSX.Element; // Display passed icon as button for opening the dialog.
  openButtonType?: string; // Some forms require a submit type whereas most operate with button type.
  showLink?: boolean;
  data?: any;
  resource?: string | undefined;
  showEdit?: boolean;
  showDelete?: boolean;
  setData?: any;
  getData?: any;
  fullWidth?: boolean;
  handleCheckoutIdChange?: Function;
  onCancel?(): void; // Handle function on dialog cancel without confirm/submit.
  onCustomCancel?(): void;
  disabled?: boolean;
  confirmDisabled?: boolean;
  buttonSx?: any;
  titleSx?: object;
  divSx?: any;
  divClasses?: string;
  hide?: boolean;
  style?: CSSProperties;
  buttonStyle?: CSSProperties;
}

export const FormContext = createContext({} as FormDataProps);

export default function DynamicDialog({
  fullWidth,
  openMessage,
  title,
  component,
  isOpen = false,
  openState,
  onClick,
  onConfirm,
  hideActions = false,
  hideButton = false,
  submitFormData,
  exitAction,
  exitActionType = "object",
  iconButton,
  openButtonType = "button",
  showLink = false,
  data,
  resource,
  showEdit,
  showDelete,
  setData,
  getData,
  handleCheckoutIdChange,
  onCancel = () => {},
  disabled = false,
  confirmDisabled = false,
  buttonSx,
  titleSx,
  onCustomCancel = () => {},
  divClasses,
  style = {},
  buttonStyle = {},
  hide = false,
}: DynamicDialogProps) {
  const [open, setOpen] = openState ? openState : useState(false);
  const [submittedData, setSubmittedData] = useState({} as FormDataProps);
  const { t } = useTranslation();
  const { setMarkerPosition } = useDataContext();

  const handleClose = () => {
    onCancel();
    if (handleCheckoutIdChange) handleCheckoutIdChange("");

    setOpen(false);
    if (exitAction) {
      //BugFix: if we open edit location, close the form and open create locations,
      //the marker remains at the same position. This clears the position.
      setMarkerPosition(null);

      exitAction(
        exitActionType === "object" ? { display: false, editId: -1 } : false,
      );
    }
  };

  const handleOpen = () => {
    setOpen(true);
    if (typeof onClick !== "undefined") onClick();
  };

  const editHandler = (data: any) => {
    // TODO: state for getting id because nested dialog doesnt receive id | data e.t.c
    if (exitAction) exitAction({ display: true, editId: data?.id });
  };

  const deleteHandler = async (data: any, resource?: string) => {
    const url: string = `${resource}/${data.id}`;

    await apiClient<DatabaseEntity[]>({
      url: url,
      method: "delete",
    }).then((response) => {
      if (response.statusCode === 204) {
        const removeDeletedItem = getData.filter((e: any) => e.id !== data.id);
        if (setData) setData(removeDeletedItem);
        handleClose();
      }
    });
  };
  // Open dialog window and execute onClick function from parent.

  function submitData(data: any) {
    setSubmittedData({ ...data });
    if (submitFormData) submitFormData(submittedData);
    handleClose();
  }

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Box
      position="relative"
      component="div"
      className={divClasses}
      style={{
        ...(hide ? { display: "none" } : {}),
        overflow: "visible !important",
        ...style,
      }}
    >
      {typeof iconButton === "undefined"
        ? !hideButton && (
            <Button
              sx={buttonSx}
              style={buttonStyle}
              type={openButtonType as "button" | "submit"}
              variant="outlined"
              onClick={handleOpen}
              disabled={disabled}
            >
              {openMessage ? t(openMessage?.toString()) : ""}
            </Button>
          )
        : !hideButton && (
            <Button
              color="primary"
              onClick={handleOpen}
              className="iconButton Buttons"
            >
              {iconButton}
            </Button>
          )}
      {showLink && (
        <Link
          sx={{
            cursor: "pointer",
            textDecoration: "none",
            fontSize: "16px",
          }}
          onClick={handleOpen}
        >
          {openMessage ? t(openMessage.toString()) : ""}
        </Link>
      )}
      <FormContext.Provider value={{ ...submittedData, setSubmittedData }}>
        <BootstrapDialog
          onClose={() => {
            handleClose();
            onCustomCancel();
          }}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth={fullWidth}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={() => {
              handleClose();
              onCustomCancel();
            }}
          >
            <Typography
              sx={{
                fontSize: "3em",
                backgroundColor: constants.bgTextShadow,
                color: "transparent",
                textTransform: "uppercase",
                textShadow: `0px 3px 3px ${constants.textShadow}`,
                fontFamily: constants.bold,
                backgroundClip: "text",
                textAlign: "center",
                paddingBottom: "10px!important",
                ...titleSx,
              }}
            >
              {t(title)}
            </Typography>
          </BootstrapDialogTitle>
          {component && (
            <DialogContent
              sx={{
                marginBottom: "40px",
              }}
            >
              {appendPropsToComponent(component, {
                setDialogOpen: setOpen,
                handleClose: handleClose,
                exitAction: exitAction,
                setData,
                getData,
              })}
            </DialogContent>
          )}
          {typeof onConfirm !== "undefined" && (
            <DialogActions>
              <Button
                onClick={() => {
                  handleClose();
                  onCustomCancel();
                }}
                sx={{
                  boxShadow: "none",
                  backgroundColor: constants.bgGrey,
                  color: constants.textColor,
                }}
              >
                {t("Cancel")}
              </Button>
              <Button
                variant="contained"
                disabled={confirmDisabled}
                onClick={() => {
                  onConfirm();
                  handleClose();
                }}
                sx={{
                  boxShadow: "none",
                }}
              >
                {t("OK")}
              </Button>
            </DialogActions>
          )}
          {typeof onConfirm === "undefined" && !hideActions && (
            <DialogActions>
              <Button
                onClick={() => {
                  handleClose();
                }}
                sx={{
                  boxShadow: "none",
                  backgroundColor: constants.bgGrey,
                  color: constants.textColor,
                  // width: "24%",
                }}
              >
                {t("Cancel")}
              </Button>
              <Button
                variant="contained"
                type="submit"
                onClick={submitData}
                sx={{
                  boxShadow: "none",
                  // width: "24%",
                }}
              >
                {t("Submit")}
              </Button>
            </DialogActions>
          )}
          <DialogActions>
            {showEdit && (
              <Button
                onClick={() => editHandler(data)}
                sx={{
                  boxShadow: "none",
                  backgroundColor: constants.bgGrey,
                  color: constants.textColor,
                }}
              >
                {t("Edit")}
              </Button>
            )}
            {showDelete && (
              <Button
                variant="contained"
                type="submit"
                onClick={() => deleteHandler(data, resource)}
                sx={{
                  boxShadow: "none",
                  backgroundColor: constants.bgRed,
                  color: constants.bgColor,
                }}
              >
                {t("Delete")}
              </Button>
            )}
          </DialogActions>
        </BootstrapDialog>
      </FormContext.Provider>
    </Box>
  );
}
