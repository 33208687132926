import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { CustomDetailsPage } from "components/DetailsPage/DetailsPage";
import { RequestInvoice } from "components/DetailsPage/requests";
import ContentLayout from "components/layouts/ContentLayout";
import useAuthContext from "context/AuthContext";
import { FieldDescriptor } from "models/fieldsEntities.model";
import OrderModel from "models/resources/order.model";
import User from "models/resources/user.model";
import { getDataById } from "services/requests/listData";
import { userCompanies } from "utils/UserCompanies";
import { getLocalizedDate, getLocalizedTime } from "utils/lozalizeDateTime";

const OrderDetails = () => {
  const { user } = useAuthContext();
  const [orderData, setOrderData] = useState<OrderModel | null>(null);
  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getDataById(setOrderData, `orders/${id}`);
    }
  }, [id]);

  const fields: FieldDescriptor[] = [
    {
      value: "invoice",
      label: "This invoice request is still pending.",
      type: "AppBar",
      show:
        userCompanies(user as User)?.includes(
          orderData?.company_id as number,
        ) && orderData?.request_invoice === "requested",
    },
    {
      value: orderData?.id,
      label: "Order id",
      type: "InfoText",
      show: true,
    },
    {
      value: getLocalizedDate(orderData?.inserted_at),
      label: "Order Date",
      type: "InfoText",
      show: true,
    },
    {
      value: getLocalizedTime(orderData?.inserted_at as string),
      label: "Order Time",
      type: "InfoText",
      show: true,
    },

    {
      value: orderData?.items
        ? `${orderData.items
            ?.map((x) => parseFloat(x.price))
            ?.reduce((a, b) => a + b, 0)
            .toFixed(2)}  ${t(orderData.currency)}`
        : "",
      label: "Order total",
      type: "InfoText",
      show: true,
    },

    {
      value: t(orderData?.status || "N/A"),
      label: "Status",
      type: "InfoText",
      show: true,
    },
    {
      value: t(orderData?.reason || "N/A"),
      label: "Reason",
      type: "InfoText",
      show: orderData?.reason ? true : false,
    },
    {
      //@ts-ignore
      value: t(orderData?.origin || "N/A"),
      label: "Origin",
      type: "InfoText",
      show: true,
    },

    {
      value: orderData?.company?.display_name || orderData?.company?.name,
      id: orderData?.company_id,
      label: "Company",
      route: "companies",
      type: "Link",
      show: true,
    },
    {
      //@ts-ignore
      value: t(orderData?.object_entity.name || "N/A"),
      label: "Machine",
      route: "vending/machines",
      id: orderData?.object_entity_id,
      type: "Link",
      show: true,
    },
    {
      value: orderData?.entity_serial,
      label: "Entity Serial",
      type: "InfoText",
      show: true,
    },
    {
      value: "transaction",
      label: "Transaction",
      type: "LinkTable",
      show: true,
    },
    {
      value: "products",
      label: "Products",
      type: "ProductsTable",
      show: true,
    },
  ];

  return (
    <ContentLayout
      title={`${t("Orders")} > ${orderData?.id ?? ""}`}
      filters={false}
    >
      <CustomDetailsPage
        isEditableAndDeletable={false}
        data={orderData}
        setData={setOrderData}
        resource={"order"}
        call={"orders"}
        fields={fields}
      />
    </ContentLayout>
  );
};

export default OrderDetails;
