import { useState } from "react";

import { DatabaseEntity } from "models/responseData.model";

const useMappingForm = (
  defaultSignal: string,
  defaultOption: DatabaseEntity | null,
) => {
  const [optionInput, setOptionInput] = useState<string>(defaultSignal);
  const [selectedOption, setSelectedOption] = useState<DatabaseEntity | null>(
    defaultOption,
  );

  const handleSignalValueOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setOptionInput(event.target.value);
  };

  // Handles option select component onChange.
  const handleOptionOnChange = (currentOption: DatabaseEntity | string) => {
    if (typeof currentOption === "string") {
      // Handles the component's input string value change.
      setOptionInput(currentOption);
      return;
    }

    setSelectedOption(currentOption);
  };

  return {
    optionInput,
    handleSignalValueOnChange,
    selectedOption,
    handleOptionOnChange,
  };
};

export default useMappingForm;
