import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import SignalWifiStatusbarNullIcon from "@mui/icons-material/SignalWifiStatusbarNull";

import { CustomDetailsPage } from "components/DetailsPage/DetailsPage";
import ModuleSignal from "components/ModuleSignal";
import ContentLayout from "components/layouts/ContentLayout";
import { FieldDescriptor } from "models/fieldsEntities.model";
import ModuleModel from "models/resources/module.model";
import { getDataById } from "services/requests/listData";

const ModuleDetails = () => {
  const [moduleData, setModuleData] = useState<ModuleModel>();
  const { id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (id) {
      getDataById(setModuleData, `modules/${id}`);
    }
  }, [id]);

  const fields: FieldDescriptor[] = [
    {
      value: moduleData?.serial,
      label: "Module serial",
      type: "InfoText",
      show: true,
    },
    {
      value: moduleData?.signal_strength ? (
        <ModuleSignal
          signal={moduleData?.signal_strength ?? null}
          noLabel={true}
        />
      ) : (
        <SignalWifiStatusbarNullIcon />
      ),
      label: "Signal Strength",
      type: "Icon",
      show: true,
    },
    {
      value: moduleData?.state ? moduleData?.serial : "---",
      label: "QR code",
      type: moduleData?.state ? "QR" : "InfoText",
      show: true,
    },
    {
      value: moduleData?.task,
      label: "Task",
      type: "InfoText",
      show: true,
    },
    {
      value: moduleData?.mcu_version,
      label: "MCU Version",
      type: "InfoText",
      show: true,
    },
    {
      value: moduleData?.sim_iccid,
      label: "ICCID",
      type: "InfoText",
      show: true,
    },
    {
      value: moduleData?.firmware?.current,
      label: "Current Firmware",
      type: "InfoText",
      show: true,
    },
    {
      value: moduleData?.machine?.name,
      id: moduleData?.machine_id,
      label: "Machine",
      type: "Link",
      route: "vending/machines",
      show: true,
    },
    {
      value: moduleData?.company?.display_name || moduleData?.company?.name,
      id: moduleData?.company_id,
      label: "Company",
      type: "Link",
      route: "companies",
      show: true,
    },
    {
      value:
        moduleData?.state === "up" ? (
          <FiberManualRecordIcon sx={{ color: "#4CAF50" }} />
        ) : (
          <FiberManualRecordIcon sx={{ color: "#FF6347" }} />
        ),
      label: "Module State",
      type: "Icon",
      show: true,
    },
    {
      value: moduleData?.uptime,
      label: "Uptime",
      type: "InfoText",
      show: true,
    },
    {
      value: moduleData?.last_seen,
      label: "Last Seen",
      type: "InfoText",
      show: true,
    },
    {
      value: t(moduleData?.flow != null ? moduleData.flow : "---"),
      label: "Flow",
      type: "InfoText",
      show: true,
    },
    {
      value: moduleData?.comments,
      label: "Comments",
      type: "DescriptiveInfoText",
      show: true,
    },
    {
      value: "",
      label: "",
      type: "BlankSpace",
      show: true,
      inlineStyle: {
        margin: 20,
      },
    },
    {
      value: "",
      label: "MDB SETTINGS",
      type: "MdbSettingsForm",
      show: true,
    },
    {
      value: "",
      label: "Module actions",
      type: "ModuleActions",
      show: true,
    },
  ];

  return (
    <ContentLayout
      title={`${t("Modules")} > ${moduleData?.serial ?? ""}`}
      filters={false}
    >
      <CustomDetailsPage
        data={moduleData}
        setData={setModuleData}
        resource={"module"}
        call={"modules"}
        fields={fields}
      />
    </ContentLayout>
  );
};

export default ModuleDetails;
