import { useTranslation } from "react-i18next";

import { QRCodeSVG } from "qrcode.react";

import { Box } from "@mui/material";

interface QRCodeGeneratorProps {
  size: number;
  logo: string | null;
  amount?: number | null;
  currency: string;
  moduleSerial: string;
}

const QRCodeGenerator = ({
  size,
  logo,
  amount = null,
  currency,
  moduleSerial,
}: QRCodeGeneratorProps) => {
  const { t } = useTranslation();
  const url: string = `${
    process.env.REACT_APP_AVERATO_SYSTEM_HOST_URL
  }vm/${moduleSerial}${
    amount !== null ? `?sum=${amount.toFixed(2)}&xe=${currency}` : ""
  }`;

  return (
    <Box>
      <div style={{ paddingBottom: "10px" }}>
        <span style={{ fontSize: "18px", fontWeight: "bold" }}>
          {t("URL encoded in QR code")}
        </span>
        <br></br>
        <span style={{ fontSize: "18px", fontWeight: "bold" }}>
          <a href={url} target="_blank" style={{ color: "#0000ee" }}>
            {url}
          </a>
        </span>
      </div>

      <QRCodeSVG
        value={url}
        size={size}
        id={"QRCodeId"}
        level={logo ? "H" : "Q"}
        imageSettings={
          (logo as ImageDataSettings) && {
            src: logo as string,
            excavate: true,
            height: size / 2.5,
            width: size / 2.5,
          }
        }
      />
    </Box>
  );
};

export default QRCodeGenerator;
