import SignalWifiStatusbarNullIcon from "@mui/icons-material/SignalWifiStatusbarNull";
import WifiIcon from "@mui/icons-material/Wifi";
import Wifi1BarIcon from "@mui/icons-material/Wifi1Bar";
import Wifi2BarIcon from "@mui/icons-material/Wifi2Bar";
import { Box } from "@mui/material";

interface SignalInfo {
  noLabel?: boolean;
  signal: string | null;
}

const ModuleSignal = ({ signal, noLabel }: SignalInfo) => (
  <Box>
    {signal ? (
      signal === "high" ? (
        <WifiIcon />
      ) : signal === "medium" ? (
        <Wifi2BarIcon />
      ) : signal === "low" ? (
        <Wifi1BarIcon />
      ) : signal === "no_signal" ? (
        <SignalWifiStatusbarNullIcon />
      ) : (
        ""
      )
    ) : (
      "---"
    )}
    {!noLabel ? ` ` + `${signal} signal` : ""}
  </Box>
);

export default ModuleSignal;
