import {
  useEffect,
  useState,
  useCallback,
  SetStateAction,
  Fragment,
  CSSProperties,
} from "react";
import GooglePlacesAutocomplete, {
  getLatLng,
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { useTranslation } from "react-i18next";

import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";

import { Box } from "@mui/material";

import useDataContext from "context/DataContext";

export const MapWithMarkerOnClick = ({
  setValue,
  getChangedLatLng,
}: {
  setValue: Function;
  getChangedLatLng: { lat: number; lng: number };
}) => {
  const [map, setMap] = useState<google.maps.Map>();
  const [address, setAddress] = useState<string | null>(null);
  const { setMarkerPosition, markerPosition, setMarkerClicked, markerClicked } =
    useDataContext();
  const { t } = useTranslation();

  interface Props {
    label: string;
    value: { types: string };
  }

  function handleChangeAddress(e: Props | null) {
    let zoom;
    setAddress(() => e?.label as string);
    geocodeByAddress(e!.label as string)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        map?.setCenter({ lat, lng });
        setMap(map);
        switch (e?.value.types[0]) {
          case "natural_feature":
            zoom = 4;
            break;
          case "country":
            zoom = 7;
            break;
          case "locality":
            zoom = 10;
            break;
          case "tourist_attraction":
            zoom = 15;
            break;
          case "route":
            zoom = 18;
            break;
          case "point_of_interest":
            zoom = 20;
            break;
          default:
            zoom = 20;
            break;
        }
        map?.setZoom(zoom);
        setMarkerPosition({ lat, lng });
      })
      .catch((err) => {
        map?.setZoom(9);
      });
  }

  function SearchAddress() {
    return isLoaded ? (
      <GooglePlacesAutocomplete
        selectProps={{
          defaultInputValue: address || "",
          placeholder: t("Search location"),
          onChange: handleChangeAddress,
          styles: {
            input: (provided: CSSProperties) => ({
              ...provided,
            }),
            option: (provided: CSSProperties) => ({
              ...provided,
            }),
            singleValue: (provided: CSSProperties) => ({
              ...provided,
            }),
          },
        }}
      />
    ) : (
      <>...</>
    );
  }

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY ?? "",
    libraries: ["places"],
  });
  const onLoad = useCallback(function callback(
    map: SetStateAction<google.maps.Map | undefined>,
  ) {
    setMap(map);
  }, []);

  useEffect(() => {
    if (map) {
      if (markerPosition?.lat) {
        map?.panTo(
          getChangedLatLng.lng !== 0 ? getChangedLatLng : markerPosition,
        );
        map?.getZoom() ? map?.getZoom() : map.setZoom(14);
      } else {
        map?.setCenter({ lat: 42.75, lng: 25.2 });
        map.setZoom(15);
        setMap(map);
      }
    }
  }, [markerPosition, map, getChangedLatLng]);

  useEffect(() => {
    if (markerPosition) {
      setValue("longitude", markerPosition?.lng);
      setValue("latitude", markerPosition?.lat);
    }
  }, [markerPosition]);

  return (
    <Box>
      <SearchAddress />
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: "30vh",
            marginTop: "15px",
            marginBottom: "10px",
          }}
          onLoad={onLoad}
          onClick={(event) => {
            setMarkerClicked(false);
            setMarkerPosition({
              lat: event.latLng?.lat(),
              lng: event.latLng?.lng(),
            });
          }}
        >
          {markerPosition?.lat && (
            <Marker
              position={markerPosition}
              onClick={() => {
                setMarkerClicked(true);
              }}
            />
          )}
          {markerClicked && (
            <InfoWindow
              onCloseClick={() => setMarkerClicked(false)}
              position={markerPosition}
            >
              <Fragment>
                <p>Latitude: {markerPosition?.lat}</p>
                <p>Longitude: {markerPosition?.lng}</p>
              </Fragment>
            </InfoWindow>
          )}
        </GoogleMap>
      ) : null}
    </Box>
  );
};
