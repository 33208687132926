import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Link } from "@mui/material";

import {
  CustomTable,
  Column,
  TableSettings,
  CellValue,
  DynamicDialogProps,
} from "components/Table/Table";
import ContentLayout from "components/layouts/ContentLayout";
import useAuthContext from "context/AuthContext";
import QueryParams from "models/queryParams.model";
import UserModel from "models/resources/user.model";
import User from "models/resources/user.model";
import { ResponseDataTable } from "models/responseData.model";
import { getData } from "services/requests/listData";
import { roleName } from "utils/UserRoles";

const UserTable = () => {
  const [getUsersData, setUsersData] =
    useState<ResponseDataTable<UserModel[]>>();
  const { user } = useAuthContext();
  const { t } = useTranslation();

  const dataSet = (queryParams: QueryParams, setDataLoading: any) =>
    getData(setUsersData, "users", queryParams, setDataLoading);

  const columns: Column<User>[] = [
    {
      label: t("First Name"),
      name: "firstName",
      getData: (data): CellValue => data.first_name,
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "first_name" },
    },
    {
      label: t("Last Name"),
      name: "lastName",
      getData: (data): CellValue => data.last_name,
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "last_name" },
    },
    {
      label: t("Email"),
      name: "email",
      getData: (data): CellValue => (
        <Link key="Email" component="a" href={`mailto: ${data.email}`}>
          {data.email}
        </Link>
      ),
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "email" },
    },
    {
      label: t("Phone number"),
      name: "phoneNumber",
      getData: (data): CellValue => (data.phone ? data.phone : "---"),
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "phone" },
    },
    {
      label: t("Role"),
      name: "role",
      getData: (data): CellValue => {
        return `${data?.role?.id ? t(roleName(data?.role?.type) ?? "") : ""}`;
      },

      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "role_id" },
    },
    {
      label: t("Edit"),
      name: "edit",
      getData: (data): CellValue => data.id,
      show: () => {
        return user?.role?.type === "admin";
      },
    },
  ];

  const tableOptions: TableSettings = {
    pagination: true,
    page: 0,
    rowsPerPage: 10,
    field: "first_name",
    order: "asc",
  };

  const dynamicDialogProps: DynamicDialogProps = {
    title: "Add user",
    resource: "user",
    call: "users",
    styles: () => "flexHalfForm",
  };

  return (
    <ContentLayout title={t("Users")}>
      <CustomTable
        title={t("Users")}
        resource="user"
        call="users"
        tableData={getUsersData}
        fetchPaginatedData={dataSet}
        setTableData={setUsersData}
        columns={columns}
        tableOptions={tableOptions}
        exportProp={"users"}
        dynamicDialogProps={dynamicDialogProps}
        isDynamic={true}
      />
    </ContentLayout>
  );
};

export default UserTable;
