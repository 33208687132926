import { CompanyBase } from "./company.model";
import Role from "./role.model";

export enum Currency {
  BGN = "BGN",
  EUR = "EUR",
  USD = "USD",
  CHF = "CHF",
}

export interface UserPos {
  email: string;
  first_name: string;
  id: number;
  last_name: string;
}

export interface UserSettings {
  lang?: string;
  currency: Currency;
  perPage?: string;
  dateFormat?: string;
}

interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string | null;
  receive_sms: boolean;
  role: Role;
  users_companies: CompanyBase[];
  users_pos: UserPos[];
  settings: string;
}

export default User;
