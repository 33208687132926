import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import GiftCardModel from "models/resources/gitfCard.model";
import { getDataById } from "services/requests/listData";

const useGiftCardDetails = () => {
  const { id } = useParams();
  const [giftCardData, setGiftCardData] = useState<GiftCardModel | null>(null);

  useEffect(() => {
    if (id) {
      getDataById(setGiftCardData, `gift-cards/${id}`);
    }
  }, [id]);
  return { giftCardData, setGiftCardData };
};

export default useGiftCardDetails;
