import Ingredient from "models/resources/ingredient.model";
import apiClient from "services/api";

export interface ProductIngredient {
  id: number;
  name: string;
  measurement_unit: string;
  quantity: number;
  recharge_quantity: number;
  allergens: string[];
}

export const fetchRecipeIngredients = async (
  companyId: number,
): Promise<Ingredient[]> => {
  return await apiClient<Ingredient[]>({
    url: "ingredients",
    method: "get",
    queryParams: {
      filter: {
        productType: "recipe",
        companyId,
      },
    },
  }).then((response) => response.data);
};
