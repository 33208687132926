import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import moment, { Moment } from "moment";

import {
  CompanyApiCredentials,
  CredentialsToken,
} from "models/resources/company.model";
import apiClient from "services/api";

interface ApiCredentialsFormFields {
  isActive: boolean;
  isSandbox: boolean;
  description: string;
  validUntil: Date | null;
  callbackUrl: string;
}

const useApiCredentialsForm = (
  projectId: number | null,
  tokenId: number | null,
  updateTokens: (updatedToken: CredentialsToken) => void,
  addProjectId: (projectId: number | null) => void,
  addProjectKey: (projectKey: string) => void,
  openState?: [boolean, Dispatch<SetStateAction<boolean>>],
) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState<ApiCredentialsFormFields>({
    isActive: true,
    isSandbox: false,
    description: "",
    validUntil: null,
    callbackUrl: "",
  });
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);

  const { isActive, isSandbox, description, validUntil, callbackUrl } =
    formValues;
  const isEditForm = tokenId !== null;

  const handleIsActiveOnChange = () => {
    setFormValues((oldState) => ({
      ...oldState,
      isActive: !oldState.isActive,
    }));
  };

  const handleIsSandboxOnChange = () => {
    setFormValues((oldState) => ({
      ...oldState,
      isSandbox: !oldState.isSandbox,
    }));
  };

  const handleFormValuesOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const fieldName = event.target.name;
    const value = event.target.value.trimStart();

    setFormValues((oldState) => ({
      ...oldState,
      [fieldName]: fieldName !== "callbackUrl" ? value : value.trim(),
    }));
  };

  const handleValidUntilOnChange = (endDate: Date | null) => {
    setIsDatePickerOpen(false);
    if (endDate) {
      endDate.setHours(23, 59, 59);
    }
    setFormValues((oldState) => ({
      ...oldState,
      validUntil: endDate as Date,
    }));
  };

  const handleDateRangeOnClick = () => {
    setIsDatePickerOpen((oldState) => !oldState);
  };

  const handleSubmitOnClick = async () => {
    if (validUntil === null) {
      toast.error(t("Valid until field is required!"));
      return;
    }

    if (openState) {
      const [_open, setOpen] = openState;
      setOpen(false);
    }
    const bodyForm = {
      active: isActive,
      sandbox: isSandbox,
      description: description,
      callback_url: callbackUrl,
      valid_until: Array.isArray(validUntil)
        ? new Date(validUntil[0]).toISOString()
        : new Date(validUntil).toISOString(),
    };

    // Handles both POST and PATCH requests based on the opened form.
    await apiClient<CompanyApiCredentials | CredentialsToken>({
      url: `companies/${id}/integration-credentials`.concat(
        !isEditForm ? "" : `/${projectId}/tokens/${tokenId}`,
      ),
      method: isEditForm ? "patch" : "post",
      bodyData: isEditForm ? bodyForm : { tokens: [bodyForm] },
    })
      .then((response) => {
        toast.success(t("Save successful"));
        updateTokens(isEditForm ? response.data : response.data.tokens.at(-1));

        if (isEditForm) return;

        const companyApiCredentials: CompanyApiCredentials = response.data;

        if (companyApiCredentials.tokens.length === 1) {
          addProjectId(companyApiCredentials.id);
          addProjectKey(companyApiCredentials.project_key);
        }

        setFormValues({
          isActive,
          isSandbox,
          description: "",
          validUntil: null,
          callbackUrl: "",
        });
      })
      .catch((error) => {
        toast.error(t("System error!"));
      });
  };

  useEffect(() => {
    if (tokenId === null) return;

    // Stores token values in the edit form fields.
    (async () => {
      await apiClient<CredentialsToken>({
        url: `companies/${id}/integration-credentials/${projectId}/tokens/${tokenId}`,
        method: "get",
      }).then((response) => {
        const { active, sandbox, description, callback_url, valid_until } =
          response.data;
        setFormValues({
          isActive: active,
          isSandbox: sandbox,
          description,
          callbackUrl: callback_url,
          validUntil: valid_until,
        });
      });
    })();
  }, [tokenId]);

  return {
    isActive,
    isSandbox,
    handleIsActiveOnChange,
    handleIsSandboxOnChange,
    description,
    callbackUrl,
    handleFormValuesOnChange,
    validUntil,
    handleValidUntilOnChange,
    isDatePickerOpen,
    handleDateRangeOnClick,
    handleSubmitOnClick,
  };
};

export default useApiCredentialsForm;
