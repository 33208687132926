import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { MenuItem, Select, SelectChangeEvent } from "@mui/material";

const LanguageSelect = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(
    localStorage.getItem("appLanguage") || i18n.language,
  );
  const languageOptions = [
    {
      value: "bg",
      label: "Bulgarian",
      icon: <img src="/bg.svg" id="bg" width={32} height={23} />,
    },
    {
      value: "en",
      label: "English",
      icon: <img src="/en.svg" id="en" width={32} height={23} />,
    },
  ];

  const handleLanguageOnChange = (event: SelectChangeEvent) => {
    setLanguage(event.target.value);
    localStorage.setItem("appLanguage", event.target.value);
  };

  useEffect(() => {
    setTimeout(() => {
      i18n.changeLanguage(language);
    }, 0);
  }, [language]);

  return (
    <Select
      labelId="language-select-label"
      id="language-select"
      value={language}
      onChange={handleLanguageOnChange}
      style={{ display: "inline-block" }}
      sx={{
        padding: "0px!important",
        paddingRight: "0px",
        "& svg": { display: "none" },
        "& fieldset": { display: "none" },
        "&>div:first-of-type": { paddingRight: "20px!important" },
      }}
    >
      {languageOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.icon}
        </MenuItem>
      ))}
    </Select>
  );
};

export default LanguageSelect;
