import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { t } from "i18next";
import * as _ from "lodash";

import {
  CellValue,
  Column,
  CustomTable,
  TableSettings,
} from "components/Table/Table";
import QueryParams from "models/queryParams.model";
import Company from "models/resources/company.model";
import Transaction from "models/resources/transaction.model";
import { ResponseDataTable } from "models/responseData.model";
import apiClient from "services/api";
import { getData } from "services/requests/listData";
import { getLocalizedDate, getLocalizedTime } from "utils/lozalizeDateTime";

interface Props {
  orderIds: number;
  findCompany: Company | undefined;
}

const TransactionsTable = ({ orderIds, findCompany }: Props) => {
  const [tableData, setTableData] = useState<ResponseDataTable<Transaction[]>>({
    data: [],
  });

  const getTransactions = async (
    queryParams: QueryParams,
    setDataLoading: any,
  ) => {
    getData(
      setTableData,
      "transactions",
      {
        ...queryParams,
        filter: { ...queryParams.filter, orderIds: [orderIds] },
      },
      setDataLoading,
    );
  };

  const columns: Column<Transaction>[] = [
    {
      label: "Transactions Date",
      name: "transactionsDate",
      getData: (data): CellValue => getLocalizedDate(data.inserted_at),
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "inserted_at" },
      width: "15%",
      minWidth: "200px",
    },
    {
      label: "Transactions Time",
      name: "transactionsTime",
      getData: (data): CellValue => getLocalizedTime(data.inserted_at),
      show: () => {
        return true;
      },
      width: "10%",
      minWidth: "200px",
    },
    {
      label: "Amount",
      name: "amount",
      getData: (data): CellValue =>
        `${Number(+data?.amount * +data?.rate).toFixed(
          data?.currency?.toUpperCase() === "AVAX" ? 9 : 2,
        )}  ${data?.currency?.toUpperCase()}`,
      show: () => {
        return true;
      },
      align: "right",
      width: "15%",
      minWidth: "200px",
      padding: "6px 50px",
    },
    {
      label: "Status",
      name: "status",
      getData: (data): CellValue => data.status,
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "status" },
      width: "15%",
      minWidth: "150px",
      padding: "6px 50px",
    },
    {
      label: "Reason",
      name: "reason",
      getData: (data): CellValue => data.reason ?? "",
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "reason" },
      width: "15%",
      minWidth: "150px",
      padding: "6px 50px",
    },
    {
      label: "Company",
      name: "company",
      getData: (data): CellValue => (
        <Link
          to={{
            pathname: `/account/companies/${data.company_id}`,
          }}
        >
          {findCompany?.display_name}
        </Link>
      ),
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "company.display_name" },
      width: "15%",
      minWidth: "200px",
    },
    {
      label: "Payment point",
      name: "paymentPoint",
      getData: (data): CellValue => data.object_entity.name,
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "object_entity.name" },
      width: "25%",
      minWidth: "200px",
    },
    {
      label: "Payment type",
      name: "paymentType",
      getData: (data): CellValue => t(data.payment_type),
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "payment_type" },
      align: "center",
      width: "10%",
      minWidth: "200px",
    },
  ];
  const tableOptions: TableSettings = {
    pagination: true,
    page: 0,
    rowsPerPage: 25,
    field: "id",
    order: "asc",
  };

  return (
    <CustomTable
      title={t("Transactions")}
      isNestedTable={true}
      tableData={tableData}
      fetchPaginatedData={getTransactions}
      columns={columns}
      isSearchable={false}
      tableOptions={tableOptions}
      resource={"Transaction"}
      call={"transactions"}
      notCSV={true}
    />
  );
};

export default TransactionsTable;
