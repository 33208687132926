import { useState } from "react";

const useDialogShowState = () => {
  const [show, setShow] = useState<boolean>(false);

  const closeDialog = () => {
    setShow(false);
  };

  const openDialog = () => {
    setShow(true);
  };
  const handleShowStateChange = () => {
    setShow(!show);
  };

  return {
    show,
    closeDialog,
    openDialog,
    handleShowStateChange,
  };
};

export default useDialogShowState;
