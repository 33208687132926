import { useTranslation } from "react-i18next";

import DynamicDialog from ".";

import { Box, Button, Typography } from "@mui/material";

import constants from "styles/scss/constants.module.scss";

interface ConfirmDeleteDialogProps {
  setConfirmDelete(show: boolean): void;
  deleteHandler(): void;
}

// TODO: Refactor dialog component strucutre.
const ConfirmDeleteDialog = ({
  setConfirmDelete,
  deleteHandler,
}: ConfirmDeleteDialogProps) => {
  const { t } = useTranslation();

  return (
    <DynamicDialog
      hideActions={true}
      title={""}
      isOpen={true}
      hideButton={true}
      exitAction={setConfirmDelete}
      exitActionType="boolean"
      component={
        <Box sx={{ textAlign: "center" }}>
          <Typography
            variant="h5"
            sx={{
              color: "rgba(0, 0, 0, 0.58)",
            }}
          >
            {t("Are you sure you want to delete this resource?")}
          </Typography>
          <Box mt={5} textAlign="right">
            <Button
              variant="contained"
              onClick={() => setConfirmDelete(false)}
              sx={{
                boxShadow: "none",
                backgroundColor: constants.bgGrey,
                color: constants.textColor,
              }}
            >
              {t("Decline")}
            </Button>
            <Button
              variant="contained"
              onClick={() => deleteHandler()}
              sx={{
                boxShadow: "none",
                backgroundColor: constants.bgRed,
                color: constants.bgColor,
                marginLeft: "10px",
              }}
            >
              {t("Delete")}
            </Button>
          </Box>
        </Box>
      }
    />
  );
};

export default ConfirmDeleteDialog;
