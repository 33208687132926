import { useTranslation } from "react-i18next";

import { Autocomplete, FormControl, TextField } from "@mui/material";

import useMultiSelectFilter from "components/MultiSelectFilter/useMultiSelectFilter";
import {
  MultiSelectFilterProps,
  optionsForAutocomplete,
  sortOptions,
} from "components/MultiSelectFilter/utils";
import useFilterContext from "context/FilterContext";
import getVariableName from "utils/getVariableName";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultiSelectFilter = (props: MultiSelectFilterProps) => {
  const { t } = useTranslation();
  const { defaultCompanyId } = useFilterContext();
  const { multiSelectValues, handleMultiSelectOnChange, currentOptions } =
    useMultiSelectFilter(props);

  const { fieldName, sorceIndex, choices, paymentPointChoice } = props;

  return (
    <FormControl fullWidth sx={{ m: 1 }}>
      <Autocomplete
        sx={MenuProps}
        multiple
        disabled={
          ["companyIds", "issuingCompanyIds"].includes(fieldName) &&
          defaultCompanyId !== null
        }
        options={
          sortOptions(choices!) ||
          sortOptions(currentOptions[sorceIndex]) ||
          sortOptions(currentOptions[paymentPointChoice?.sorceIndex!])
        }
        renderOption={(props, value) => (
          <li {...props} key={value.id}>
            {t(optionsForAutocomplete(value))}
          </li>
        )}
        getOptionLabel={(option) => optionsForAutocomplete(option)}
        value={multiSelectValues.values}
        // TODO: Add getVariableName function when merged.
        onChange={(e, values) =>
          handleMultiSelectOnChange(getVariableName({ values }), values)
        }
        inputValue={multiSelectValues.inputValue}
        onInputChange={(e, inputValue) =>
          // TODO: Add getVariableName function when merged.
          handleMultiSelectOnChange(getVariableName({ inputValue }), inputValue)
        }
        //@ts-ignore
        renderInput={(params) => <TextField {...params} variant="outlined" />}
      />
    </FormControl>
  );
};
export default MultiSelectFilter;
