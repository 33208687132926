import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Box, Button, Grid, Typography } from "@mui/material";

import { FieldGenerator } from "components/DetailsPage/FieldGenerator";
import DynamicForm from "components/Forms/generic/DynamicForm";
import DynamicDialog from "components/layouts/Dialog";
import ConfirmDeleteDialog from "components/layouts/Dialog/ConfirmDeleteDialog";
import useAuthContext from "context/AuthContext";
import { FieldDescriptor } from "models/fieldsEntities.model";
import Order from "models/resources/order.model";
import { deleteDataById } from "services/requests/listData";
import constants from "styles/scss/constants.module.scss";

export const CustomDetailsPage = ({
  data,
  setData,
  resource,
  fields,
  call,
  isEditable = true,
  isDeletable = true,
  isEditableAndDeletable = true,
  orderForInvoice,
  hideEditByRole,
}: {
  data: any;
  setData: Dispatch<SetStateAction<any>>;
  resource: string;
  fields: FieldDescriptor[];
  call: string;
  isEditable?: boolean;
  isDeletable?: boolean;
  isEditableAndDeletable?: boolean;
  orderForInvoice?: Order;
  hideEditByRole?: string[];
}) => {
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [showEditDialog, setShowEditDialog] = useState({
    display: false,
    editId: -1,
  });
  const { user } = useAuthContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const editHandler = (data: any) => {
    setShowEditDialog({ display: true, editId: data?.id });
  };

  const openDeleteDialog = () => {
    setConfirmDelete(true);
  };

  const deleteHandler = async () => {
    deleteDataById(call, data.id);
    navigate(
      `/account/${
        [
          "machines",
          "machine-blueprints",
          "products",
          "ingredients",
          "allergens",
        ].includes(call)
          ? "vending/"
          : ""
      }${
        call === "qr"
          ? "qr-payment-codes"
          : call === "machine-blueprints"
            ? "blueprints"
            : call
      }`,
    );
  };

  if (!data) {
    return (
      <Box
        style={{
          textAlign: "center",
          fontSize: "20px",
          paddingTop: "80px",
          backgroundColor: "white",
          minHeight: "30vh",
        }}
        key={"loading-row"}
      >
        Loading...
      </Box>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: "white",
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
      }}
    >
      {confirmDelete && (
        <ConfirmDeleteDialog
          setConfirmDelete={setConfirmDelete}
          deleteHandler={deleteHandler}
        />
      )}
      {showEditDialog?.display && (
        <DynamicDialog
          hideActions={true}
          title={t("Edit").concat(` ${t(resource)}`)}
          key={`edit-${showEditDialog?.editId}`}
          component={
            <DynamicForm
              key={showEditDialog?.editId}
              mode="edit"
              resource={
                resource === "product"
                  ? data.product_type === "packaged"
                    ? "packet-product"
                    : "recipe-product"
                  : resource
              }
              call={call}
              dynamicClass={() => `edit-form__${resource!}`}
              editId={showEditDialog?.editId}
              updateData={setData}
              tableData={data}
            />
          }
          isOpen={true}
          hideButton={true}
          exitAction={setShowEditDialog}
        />
      )}
      <Grid sx={{ minHeight: "30vh" }}>
        <FieldGenerator
          data={data}
          setData={setData}
          call={call}
          fields={fields}
          orderForInvoice={orderForInvoice}
        />
      </Grid>
      {!hideEditByRole?.includes(user?.role?.type as string) &&
        isEditableAndDeletable && (
          <Grid sx={{ padding: "20px", textAlign: "right" }}>
            {isEditable && (
              <Button
                onClick={() => editHandler(data)}
                sx={{
                  boxShadow: "none",
                  backgroundColor: constants.bgGrey,
                  color: constants.textColor,
                  marginRight: "10px",
                }}
              >
                {t("Edit")}
              </Button>
            )}
            {isDeletable && (
              <Button
                variant="contained"
                type="submit"
                onClick={() => openDeleteDialog()}
                sx={{
                  boxShadow: "none",
                  backgroundColor: constants.bgRed,
                  color: constants.bgColor,
                }}
              >
                {t("Delete")}
              </Button>
            )}
          </Grid>
        )}
    </Box>
  );
};
