import { useTranslation } from "react-i18next";

import { Box, Button } from "@mui/material";

const ConfirmDenyDialog = ({ onConfirm, onDeny }: any) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        margin: 0,
        padding: 0,
        display: "flex",
        flexDirection: "row",
        gap: "20px",
        justifyContent: "center",
      }}
    >
      <Button variant="outlined" onClick={onDeny} color="error">
        {t("Deny")}
      </Button>
      <Button variant="outlined" onClick={onConfirm}>
        {t("Confirm")}
      </Button>
    </Box>
  );
};

export default ConfirmDenyDialog;
