import { useTranslation } from "react-i18next";

import { Box, CardContent, CardHeader, Divider } from "@mui/material";

import { AppLinks } from "components/Advertisement/AppLinks";

interface InstuctionsProps {
  product?: string;
}

const Instructions = ({ product = "Averato" }: InstuctionsProps) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ margin: "0 auto", width: "30%" }}>
      <CardContent>
        <CardHeader title={t("Need to get something, but got no cash?")} />
        <CardHeader title={t("That's OK ;) All you need to do is")} />
        <Divider />
      </CardContent>
      <CardContent>
        <p>{t(`1. Download and install the free ${product} app.`)}</p>
        <div>
          <AppLinks product={product} />
        </div>
        <p>{t("2. Open the app and scan the QR code again.")}</p>
        <p>{t("3. Setup your payment method and pay the desired amount.")}</p>
      </CardContent>
      {product === "myPay.bg" && (
        <CardContent>
          <CardHeader title={t("Enjoy!")} />
        </CardContent>
      )}
    </Box>
  );
};

export default Instructions;
