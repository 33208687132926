import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { PriceAdjustment } from "models/resources/price_adjustment.model";
import { getDataById } from "services/requests/listData";

const usePriceAdjustmentDetails = () => {
  const { id } = useParams();
  const [productsData, setProductsData] = useState<PriceAdjustment | null>(
    null,
  );

  useEffect(() => {
    if (id) {
      getDataById(setProductsData, `price_adjustments/${id}`);
    }
  }, [id]);
  return { productsData, setProductsData };
};

export default usePriceAdjustmentDetails;
