import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import VisibilityIcon from "@mui/icons-material/Visibility";

import ModuleQR from "components/ModuleQr";
import {
  CustomTable,
  Column,
  TableSettings,
  CellValue,
  DynamicDialogProps,
} from "components/Table/Table";
import ContentLayout from "components/layouts/ContentLayout";
import DynamicDialog from "components/layouts/Dialog";
import useFilterContext from "context/FilterContext";
import QueryParams from "models/queryParams.model";
import QR from "models/resources/qr.model";
import { ResponseDataTable } from "models/responseData.model";
import { getData } from "services/requests/listData";
import { findCompany, findLocation } from "utils/findEntity";

const QrPaymentCodeTable = () => {
  const [getQRsData, setQRsData] = useState<ResponseDataTable<QR[]>>();
  const { t } = useTranslation();
  const { filter, options } = useFilterContext();
  const { locations, companies } = options;

  const setData = (queryParams: QueryParams, setDataLoading: any) =>
    getData(setQRsData, "qr", queryParams, setDataLoading);

  const columns: Column<QR>[] = [
    {
      label: t("QR Code Name"),
      name: "qrName",
      getData: (data): CellValue => data.name,
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "name" },
      width: "15%",
      minWidth: "200px",
    },
    {
      label: t("Currency"),
      name: "currency",
      getData: (data): CellValue => t(data.currency),
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "currency" },
      width: "15%",
      minWidth: "150px",
    },
    {
      label: t("Company"),
      name: "company",
      getData: (data): CellValue => (
        <Link
          to={{
            pathname: `/account/companies/${data.company_id}`,
          }}
        >
          {data.company.display_name || data.company.name}
        </Link>
      ),
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "company_id" },
      width: "25%",
      minWidth: "200px",
    },
    {
      label: t("Serial"),
      name: "serial",
      getData: (data): CellValue => (data.serial ? data.serial : "---"),
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "serial" },
      width: "15%",
      minWidth: "200px",
    },
    {
      label: t("Location"),
      name: "location",
      getData: (data): CellValue =>
        data.location !== null ? (
          <Link
            to={{
              pathname: `/account/locations/${data.location.id}`,
            }}
          >
            {`${data.location.name}, ${data.location.address}`}
          </Link>
        ) : (
          "---"
        ),
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "location_id" },
      width: "20%",
    },
    {
      label: t("QR"),
      name: "qr",
      getData: (data): CellValue =>
        data.serial ? (
          <DynamicDialog
            title={`${t("QR code for module")} ${data?.serial}`}
            component={
              <ModuleQR currency={data.currency} moduleSerial={data?.serial} />
            }
            iconButton={<VisibilityIcon />}
            openMessage={t("QR")}
            hideActions
          />
        ) : (
          "---"
        ),
      show: () => {
        return true;
      },
      align: "center",
      width: "5%",
    },
    {
      label: t("Edit"),
      name: "edit",
      getData: (data): CellValue => data.id,
      show: () => {
        return true;
      },
      align: "center",
      width: "5%",
    },
  ];

  const tableOptions: TableSettings = {
    pagination: true,
    page: 0,
    rowsPerPage: 25,
    field: "name",
    order: "asc",
  };

  const dynamicDialogProps: DynamicDialogProps = {
    title: "Add QR code",
    resource: "qr",
    call: "qr",
    styles: () => "flex90Form first-100 second-70 nth-5 nth-8",
  };

  return (
    <ContentLayout title={t("QR Payment Codes")}>
      <CustomTable
        title={t("QR Payment Codes")}
        resource="qr"
        call="qr"
        tableData={getQRsData}
        fetchPaginatedData={setData}
        setTableData={setQRsData}
        columns={columns}
        tableOptions={tableOptions}
        dynamicDialogProps={dynamicDialogProps}
        isDynamic={true}
        exportProp="qr"
      />
    </ContentLayout>
  );
};

export default QrPaymentCodeTable;
