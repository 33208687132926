import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import DomainDisabledIcon from "@mui/icons-material/DomainDisabled";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import UpdateIcon from "@mui/icons-material/Update";
import VideogameAssetOffIcon from "@mui/icons-material/VideogameAssetOff";
import { Button } from "@mui/material";

import RequestParams from "models/requestParams.model";
import Module from "models/resources/module.model";
import ResponseData, { DatabaseEntity } from "models/responseData.model";
import apiClient from "services/api";

export enum ModuleActions {
  ResetTask,
  UpdateFirmware,
  Disable,
  Enable,
  Detach,
  ModuleFlow,
}

const disableEnableOnClick = async (
  moduleId: number,
  isActive: boolean,
  setData?: Dispatch<SetStateAction<Module>>,
  setUpdateStatus?: Dispatch<SetStateAction<boolean>>,
) => {
  const apiParams: RequestParams = {
    url: `modules/${moduleId}`,
    method: "patch",
    bodyData: { active: isActive ? false : true },
  };
  await apiClient<Module>(apiParams).then((response) => {
    if (response.statusCode === 200) {
      if (setUpdateStatus) setUpdateStatus(response.data.active as boolean);
      if (setData) setData(response.data);
    }
  });
};

const resetTaskOnClick = async (moduleId: number) => {
  const apiParams: RequestParams = {
    url: `modules/${moduleId}`,
    method: "patch",
    bodyData: { task: "idle" },
  };
  const response: ResponseData<DatabaseEntity> =
    await apiClient<DatabaseEntity>(apiParams);
};

const detachFromCompany = async (
  moduleId: number,
  setData?: Dispatch<SetStateAction<Module>>,
) => {
  await apiClient<Module>({
    url: `modules/${moduleId}`,
    method: "patch",
    bodyData: {
      company_id: null,
    },
  }).then((response) => {
    if (setData) setData(response.data);
  });
};

// Set state in parent form which will conditionally render the form
const updateFormOnClick = (
  showFormSetter: Dispatch<SetStateAction<boolean>>,
) => {
  showFormSetter(true);
};

const generateModuleAction = (
  action: ModuleActions,
  moduleId: number,
  showFormSetter?: Dispatch<SetStateAction<boolean>>,
  setData?: Dispatch<SetStateAction<Module>>,
  setUpdateStatus?: Dispatch<SetStateAction<boolean>>,
) => {
  const { t } = useTranslation();

  const globalBtnStyle = {
    marginRight: "20px",
  };

  switch (action) {
    case ModuleActions.Disable:
      return (
        <Button
          style={globalBtnStyle}
          onClick={() =>
            disableEnableOnClick(moduleId, true, setData, setUpdateStatus)
          }
          variant="outlined"
          startIcon={<VideogameAssetOffIcon />}
        >
          {t("Disable")}
        </Button>
      );
    case ModuleActions.Enable:
      return (
        <Button
          style={globalBtnStyle}
          onClick={() =>
            disableEnableOnClick(moduleId, false, setData, setUpdateStatus)
          }
          variant="outlined"
          startIcon={<VideogameAssetOffIcon />}
        >
          {t("Enable")}
        </Button>
      );
    case ModuleActions.UpdateFirmware:
      return (
        <Button
          style={globalBtnStyle}
          onClick={() => updateFormOnClick(showFormSetter!)}
          variant="outlined"
          startIcon={<UpdateIcon />}
        >
          {t("Update firmware")}
        </Button>
      );
    case ModuleActions.ModuleFlow:
      return (
        <Button
          style={globalBtnStyle}
          onClick={() => updateFormOnClick(showFormSetter!)}
          variant="outlined"
        >
          {t("Module Flow")}
        </Button>
      );
    case ModuleActions.ResetTask:
      return (
        <Button
          style={globalBtnStyle}
          onClick={() => resetTaskOnClick(moduleId)}
          variant="outlined"
          startIcon={<RestartAltIcon />}
        >
          {t("Reset task")}
        </Button>
      );
    case ModuleActions.Detach:
      return (
        <Button
          style={globalBtnStyle}
          onClick={() => detachFromCompany(moduleId, setData)}
          variant="outlined"
          startIcon={<DomainDisabledIcon />}
        >
          {t("Detach from company")}
        </Button>
      );
  }
};

const ModuleAction = ({
  action,
  moduleId,
  showFormSetter,
  setUpdateStatus,
  setData,
}: {
  action: ModuleActions;
  moduleId: number;
  showFormSetter?: Dispatch<SetStateAction<boolean>>;
  setUpdateStatus?: Dispatch<SetStateAction<boolean>>;
  setData: Dispatch<SetStateAction<Module>>;
}) => {
  return generateModuleAction(
    action,
    moduleId,
    showFormSetter,
    setData,
    setUpdateStatus,
  );
};

export default ModuleAction;
