import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import useModuleInfo from "hooks/useModuleInfo";
import { EntityPaymentResponse } from "models/resources/payment.model";
import { ResponseDataSuccess } from "models/responseData.model";
import apiClient from "services/api";

const usePayment = () => {
  const { t } = useTranslation();
  const urlParams = new URL(window.location.href.toString().replace("#", ""));
  const defaultAmount = urlParams.searchParams.get("sum");
  const [amount, setAmount] = useState<string>(defaultAmount ?? "0.50");
  const [checkoutId, setCheckoutId] = useState<string>("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value);
  };

  const handleClickUp = () => {
    setAmount((parseFloat(amount) + 0.1).toFixed(2));
  };

  const handleClickDown = () => {
    parseFloat(amount) > 0.1
      ? setAmount((parseFloat(amount) - 0.1).toFixed(2))
      : setAmount("0.10");
  };

  const handleOnClickSubmit = (currency: string, moduleSerial: string) => {
    if (typeof currency === "undefined") {
      const machineInfo = useModuleInfo(moduleSerial);

      if (typeof machineInfo.data !== "undefined") {
        initPayment(machineInfo.data.currency, moduleSerial);
      }
    } else {
      initPayment(currency, moduleSerial);
    }
  };

  const initPayment = (currency: string, moduleSerial: string) => {
    apiClient<EntityPaymentResponse>({
      url: "payments",
      method: "post",
      bodyData: {
        entity_serial: moduleSerial,
        description: `Web payment from ${moduleSerial}`,
        amount: parseFloat(amount),
        currency: currency ? currency : "BGN",
        redirect_url: `${process.env.REACT_APP_AVERATO_SYSTEM_HOST_URL}complete-web-payment/${moduleSerial}`,
        pp_code: "sumup",
        rate: 1.0,
      },
    })
      .then((response) => {
        setCheckoutId(
          (response as ResponseDataSuccess<EntityPaymentResponse>).data
            .payments[0].checkout_id,
        );
      })
      .catch((error) => {
        switch (error.statusCode) {
          case 409:
            toast.error(
              t("This QR code is not ready to accept payments.").toString(),
            );
            break;
          default:
            console.log("Module info error message: ".concat(error.message));
            toast.error(t("System error!").toString());
            break;
        }
        // Other errors.
        // data.reason === "busy"
        //     ? t("Vending machine busy! Please, try again in a minute or two!")
        //     : data.reason === "invalid_request_params"
        //     ? t("Invalid parameters sent in request.")
        //     : data.reason === "out_of_order"
        //     ? t("Vending machine out of order. Sorry!")
        //     : data.reason === "invalid_company_status"
        //     ? t("This QR code is not ready to accept payments.")
        //     : data.reason,
        //   "error",
      });
  };

  return {
    amount,
    checkoutId,
    handleChange,
    handleClickUp,
    handleClickDown,
    handleOnClickSubmit,
  };
};

export default usePayment;
