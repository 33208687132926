import {
  downloadAsJPEG,
  downloadAsPDF,
  downloadAsPNG,
  downloadAsSVG,
} from "./downloadFunctions";

// Visualisation order based on value order.
export enum QrDownloadFormat {
  SVG = "SVG",
  PNG = "PNG",
  JPEG = "JPEG",
  PDF = "PDF",
}
// Visualisation order based on value order.
export enum QrSize {
  x70 = "70x70",
  x80 = "80x80",
  x128 = "120x120",
  x256 = "256x256",
  x512 = "512x512",
  x1024 = "1024x1024",
}

export const downloadQr = (name: string, format: QrDownloadFormat) => {
  switch (format) {
    case QrDownloadFormat.SVG:
      downloadAsSVG(name);
      break;
    case QrDownloadFormat.PNG:
      downloadAsPNG(name);
      break;
    case QrDownloadFormat.JPEG:
      downloadAsJPEG(name);
      break;
    case QrDownloadFormat.PDF:
      downloadAsPDF(name);
      break;
  }
};
