export interface SumupMounter {
  unmount(): void;
}

export const loadSumUpWidget = (callback: Function) => {
  const existingScript = document.getElementById("sumupWidget");

  if (!existingScript) {
    const script = document.createElement("script");
    script.src = "https://gateway.sumup.com/gateway/ecom/card/v2/sdk.js";
    script.id = "sumupWidget";
    document.body.appendChild(script);
    script.onload = () => callback();
  }
  if (existingScript) callback();
};

export const sumUpMountingCallback = (
  checkoutId: string,
  mountHandler: (mounter: SumupMounter) => void, // Saves current mounter sumup, to be able to unmount from outside on dialog window close. Not working as expected.
  responseStatusHandler: (status: {
    // Handles response status from the custom hook.
    success: boolean;
    message?: string;
  }) => void,
): (() => void) => {
  return () => {
    // @ts-ignore
    // Project should have global access to SumUpCard ater including the sdk script, but it does not work in React.
    var sumUpCard: SumupMounter = SumUpCard.mount({
      checkoutId: checkoutId,
      onLoad: () => {
        mountHandler(sumUpCard);
      },
      onResponse: (type: string, body: { message: string }) => {
        switch (type) {
          case "success":
            responseStatusHandler({ success: true });
            sumUpCard.unmount();
            break;
          case "error":
            responseStatusHandler({ success: false, message: body.message });
            sumUpCard.unmount();
            break;
        }
      },
    });
  };
};
