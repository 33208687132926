import Allergen from "./allergen.model";
import { CompanyBase } from "./company.model";
import { MachineBase } from "./machine.model";
import { ProductBase } from "./product.model";

interface Ingredient extends IngredientBase {
  machines: MachineBase[];
  products: ProductBase[];
}

export enum ProductType {
  packaged = "packaged",
  recipe = "recipe",
}

export interface IngredientBase {
  id: number;
  name: string;
  quantity: string;
  recharge_quantity: string;
  unit_name: string;
  product_type: ProductType.packaged | ProductType.recipe;
  company_id: number;
  company: CompanyBase;
  allergens: Allergen[];
  capacity: string;
}

export default Ingredient;
