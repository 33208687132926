import { Text, View, StyleSheet } from "@react-pdf/renderer";

import Invoice from "models/resources/invoice.model";

const styles = StyleSheet.create({
  headerContainer: { flexDirection: "row", justifyContent: "space-between" },
  field: {
    padding: "5px",
  },
});
interface Props {
  invoiceData: Invoice;
}
const Header = ({ invoiceData }: Props) => {
  return (
    <div style={styles.headerContainer}>
      <View>
        <Text
          style={styles.field}
        >{`Client: ${invoiceData?.receiver_company?.name}`}</Text>
        <Text style={styles.field}>{`Address: ${
          invoiceData?.receiver_company?.address
            ? invoiceData?.receiver_company?.address
            : "-"
        }`}</Text>
        <Text style={styles.field}>{`UIC: ${
          invoiceData?.receiver_company?.tax_number
            ? invoiceData?.receiver_company?.tax_number
            : "-"
        }`}</Text>
        <Text style={styles.field}>{`Accountable Person: ${
          invoiceData?.receiver_company?.accountable_person
            ? invoiceData?.receiver_company?.accountable_person
            : "-"
        }`}</Text>
      </View>
      <View>
        <Text
          style={styles.field}
        >{`Company: ${invoiceData?.issuer_company?.name}`}</Text>
        <Text style={styles.field}>{`Address: ${
          invoiceData?.issuer_company?.address
            ? invoiceData?.issuer_company?.address
            : "-"
        }`}</Text>
        <Text style={styles.field}>{`UIC: ${
          invoiceData?.issuer_company?.tax_number
            ? invoiceData?.issuer_company?.tax_number
            : "-"
        }`}</Text>
        <Text style={styles.field}>{`Accountable Person: ${
          invoiceData?.issuer_company?.accountable_person
            ? invoiceData?.issuer_company?.accountable_person
            : "-"
        }`}</Text>
      </View>
    </div>
  );
};

export default Header;
