import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Switch } from "@mui/material";

import LineChart from "components/Chart/LineChart";
import TransactionsBarChart from "components/Chart/TransactionsBarChart";
import {
  CellValue,
  Column,
  CustomTable,
  TableSettings,
} from "components/Table/Table";
import ContentLayout from "components/layouts/ContentLayout";
import useFilterContext from "context/FilterContext";
import QueryParams, { RequestPagination } from "models/queryParams.model";
import {
  default as Transaction,
  default as TransactionModel,
} from "models/resources/transaction.model";
import { TransactionBehaviour } from "models/resources/transaction_behaviour.model";
import { ResponseDataTable } from "models/responseData.model";
import apiClient from "services/api";
import { getData } from "services/requests/listData";
import { getLocalizedDate, getLocalizedTime } from "utils/lozalizeDateTime";
import { getTransactionsBehaviourData } from "utils/transactionBehaviour";

const TransactionTable = () => {
  const [getTransactionsData, setTransactionsData] =
    useState<ResponseDataTable<TransactionModel[]>>();
  const [getTransactionsBehaviour, setTransactionBehaviour] = useState<
    TransactionBehaviour[]
  >([]);
  const [showRevenue, setShowRevenue] = useState<boolean>(true);
  const { filter } = useFilterContext();
  const { t } = useTranslation();
  const handleChartChange = () => {
    setShowRevenue((oldState) => !oldState);
  };

  const setData = async (queryParams: QueryParams, setDataLoading: any) => {
    getData(setTransactionsData, "transactions", queryParams, setDataLoading);
    getTransactionsBehaviourData(
      queryParams?.pagination!,
      setTransactionBehaviour,
      filter,
    );
  };

  const columns: Column<Transaction>[] = [
    {
      label: t("Transactions Date"),
      name: "transactionsDate",
      getData: (data): CellValue => getLocalizedDate(data.inserted_at),
      show: () => {
        return true;
      },
    },
    {
      label: t("Transactions Time"),
      name: "transactionsTime",
      getData: (data): CellValue => getLocalizedTime(data.inserted_at),
      show: () => {
        return true;
      },
    },
    {
      label: t("Amount"),
      name: "amount",
      getData: (data): CellValue =>
        `${Number(+data.amount * +data.rate).toFixed(
          data?.currency?.toUpperCase() === "AVAX" ? 9 : 2,
        )}  ${data?.currency ? t(data?.currency?.toUpperCase()) : ""}`,
      show: () => {
        return true;
      },
      padding: "6px 50px",
    },
    {
      label: t("Status"),
      name: "status",
      getData: (data): CellValue => t(data.status),
      show: () => {
        return true;
      },
      padding: "6px 50px",
    },
    {
      label: "Reason",
      name: "reason",
      getData: (data): CellValue => data.reason ?? "",
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "reason" },
      padding: "6px 50px",
    },
    {
      label: t("Company"),
      name: "company",
      getData: (data): CellValue => (
        <Link
          to={{
            pathname: `/account/companies/${data.company_id}`,
          }}
        >
          {data.company.display_name || data.company.name}
        </Link>
      ),
      show: () => {
        return true;
      },
      sort: {
        isSortable: true,
        field: "company.name",
      },
    },
    {
      label: t("Payment point"),
      name: "paymentPoint",
      getData: (data): CellValue => data?.object_entity?.name,
      show: () => {
        return true;
      },
      sort: {
        isSortable: true,
        field: "object_entity.name",
      },
    },
    {
      label: t("Payment type"),
      name: "paymentType",
      getData: (data): CellValue => data.payment_type,
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "payment_type" },
    },
  ];

  const tableOptions: TableSettings = {
    pagination: true,
    page: 0,
    rowsPerPage: 25,
    field: "id",
    order: "asc",
  };

  return (
    <ContentLayout title={t("Transactions")} isDateRanged>
      <Switch
        checked={showRevenue}
        onChange={() => handleChartChange()}
        inputProps={{ "aria-label": "controlled" }}
      />
      {t("Show revenue")}
      {showRevenue ? (
        <LineChart
          dataArray={getTransactionsBehaviour || []}
          dateRange={filter.dateRange}
        />
      ) : (
        <TransactionsBarChart
          transactions={getTransactionsData?.data || []}
          dateRange={filter.dateRange}
        />
      )}
      <CustomTable
        isSearchable={false}
        title={t("Transactions")}
        tableData={getTransactionsData}
        fetchPaginatedData={setData}
        columns={columns}
        tableOptions={tableOptions}
        resource={"Transaction"}
        call={"transactions"}
        exportProp={"transactions"}
        hadPDF={true}
      />
    </ContentLayout>
  );
};

export default TransactionTable;
