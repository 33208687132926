import i18next from "i18next";
import LngDetector from "i18next-browser-languagedetector";

import * as bg from "i18n/bg";
import * as en from "i18n/en";

i18next.use(LngDetector).init({
  lng: "bg",
  resources: {
    en,
    bg,
  },
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
