import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { TableRow, TableCell } from "@mui/material";

import { CustomDetailsPage } from "components/DetailsPage/DetailsPage";
import { fixCurrencyAmount } from "components/PdfExport/fields";
import ContentLayout from "components/layouts/ContentLayout";
import useFilterContext from "context/FilterContext";
import { FieldDescriptor } from "models/fieldsEntities.model";
import Company from "models/resources/company.model";
import PrepaidCard from "models/resources/prepaidCard.model";
import UserModel from "models/resources/user.model";
import { getDataById } from "services/requests/listData";
import { roleName } from "utils/UserRoles";

const UserDetails = () => {
  const [userData, setUserData] = useState<UserModel>();
  const { t } = useTranslation();
  const { id } = useParams();
  const { options } = useFilterContext();
  const { companies } = options;

  useEffect(() => {
    if (id) {
      getDataById(setUserData, `users/${id}`);
    }
  }, [id]);

  const fields: FieldDescriptor[] = [
    {
      value: userData?.first_name,
      label: "First name",
      type: "InfoText",
      show: true,
    },
    {
      value: userData?.last_name,
      label: "Last name",
      type: "InfoText",
      show: true,
    },
    {
      value: userData?.email, // TODO: Add link to email.
      label: "Email",
      type: "InfoText",
      show: true,
    },
    {
      value: userData?.phone,
      label: "Phone number",
      type: "InfoText",
      show: true,
    },
    {
      value: userData?.receive_sms === true ? t("Yes") : t("No"),
      label: "Do you wish to receive SMS?",
      type: "InfoText",
      show: true,
    },
    {
      value: `${
        userData?.role?.id ? t(roleName(userData?.role?.type) ?? "") : ""
      }`,
      label: "Role",
      type: "InfoText",
      show: true,
    },
    {
      value: userData?.users_companies,
      label: "Companies",
      type: "Table",
      show: userData?.users_companies?.length !== 0,
      headers: [t("Name")],
      columns: userData?.users_companies
        ?.filter((uc) =>
          // Remove deleted companies.
          companies.map((c) => c.id).includes(uc.id),
        )
        .sort((a, b) => a.display_name?.localeCompare(b.display_name as string))
        .map((e, i) => {
          return (
            <TableRow key={`${i}-${e.id}`}>
              <TableCell sx={{ padding: "5px 0 0 5px" }}>
                {e.display_name /* TODO: Add links to companies. */}
              </TableCell>
            </TableRow>
          );
        }),
    },
  ];

  return (
    <ContentLayout
      title={`${t("Users")} > ${userData?.first_name ?? ""} ${
        userData?.last_name ?? ""
      }`}
      filters={false}
    >
      <CustomDetailsPage
        data={userData}
        setData={setUserData}
        resource={"user"}
        call={"users"}
        fields={fields}
      />
    </ContentLayout>
  );
};

export default UserDetails;
