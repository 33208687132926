import { useEffect, useState } from "react";

import { ObjectEntityInfo } from "models/resources/objectEntity.model";
import apiClient from "services/api";

const useModuleInfo = (moduleSerial: string) => {
  const [machineInfo, setMachineInfo] = useState<ObjectEntityInfo>();
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    if (typeof moduleSerial !== "undefined") {
      apiClient<ObjectEntityInfo>({
        url: `object-entities/${moduleSerial}/info`,
        method: "get",
      })
        .then((response) => {
          setMachineInfo(response.data);
          setErrorMessage(undefined);
        })
        .catch((error) => {
          switch (error.statusCode) {
            case 404:
              setErrorMessage("Expired URL or QR code.");
              break;
            case 409:
              setErrorMessage(
                "AVERATO module is currently offline. Please, try again later.",
              );
              break;
            default:
              console.log("Module info error message: ".concat(error.message));
              setErrorMessage("");
              break;
          }
        });
    }
  }, [moduleSerial]);

  return { data: machineInfo, errorMessage };
};

export default useModuleInfo;
