import React from "react";
import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import EmailIcon from "@mui/icons-material/Email";
import {
  Button,
  FilledInput,
  FormControl,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";

import apiClient from "services/api";
import constants from "styles/scss/constants.module.scss";

export interface State {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  country: string;
  company: string;
  role: string;
}

export default function RegisterForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [values, setValues] = useState<State>({
    first_name: "",
    last_name: "",
    email: "",
    role: "owner",
    phone: "",
    country: "",
    company: "",
  });

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target?.value });
    };

  async function register({
    first_name,
    last_name,
    email,
    role,
    phone,
  }: State) {
    apiClient({
      url: "users/register/company-owner",
      method: "post",
      bodyData: {
        first_name,
        last_name,
        email,
        role,
        phone,
      },
    })
      .then(() => {
        navigate({ pathname: "/login" });

        return toast.success(
          t(
            "Registration successful. A password has been sent to your E-mail address.",
          ).toString(),
        );
      })
      .catch(() =>
        toast.error(t("Email is already in use or invalid.").toString()),
      );
  }

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();
    register(values);
  }

  return (
    <Grid container spacing={2}>
      <Grid item sm={6}>
        <FormControl fullWidth sx={{ m: 1, margin: "0px" }} variant="filled">
          <FilledInput
            id="filled-adornment-weight-3"
            value={values.first_name}
            onChange={handleChange("first_name")}
            disableUnderline={true}
            autoFocus={false}
            placeholder={t("First name")}
            required={true}
            aria-describedby="filled-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
          />
        </FormControl>
      </Grid>
      <Grid item sm={6}>
        <FormControl fullWidth sx={{ m: 1, margin: "0px" }} variant="filled">
          <FilledInput
            id="filled-adornment-weight"
            value={values.last_name}
            onChange={handleChange("last_name")}
            disableUnderline={true}
            autoFocus={false}
            placeholder={t("Last name")}
            required={true}
            aria-describedby="filled-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth sx={{ m: 1, margin: "0px" }} variant="filled">
          <FilledInput
            id="filled-adornment-weight-4"
            value={values.email}
            onChange={handleChange("email")}
            disableUnderline={true}
            autoFocus={false}
            placeholder={t("Email")}
            required={true}
            endAdornment={
              <InputAdornment position="end">
                <EmailIcon />
              </InputAdornment>
            }
            aria-describedby="filled-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        {/* Why phone is displayed if we dont send it to api */}
        <FormControl fullWidth sx={{ m: 1, margin: "0px" }} variant="filled">
          <PhoneInput
            value={values.phone}
            country={"bg"}
            regions={["europe"]}
            onChange={(e) => setValues({ ...values, ["phone"]: e })}
            buttonStyle={{ border: "none" }}
            inputStyle={{
              fontSize: "1rem",
              width: "100%",
              border: "none",
              backgroundColor: constants.bgLight,
              color: "black",
            }}
            autoFormat={true}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{ marginTop: "20px" }}
            >
              {t("Register")}
            </Button>
          </FormControl>
        </Grid>
        <Typography variant="body1" component="span">
          {t("You have an account?")}{" "}
          <Link to={{ pathname: "/login" }}>
            <a
              style={{
                color: constants.primary,
                fontFamily: constants.bold,
              }}
            >
              {t("Login")}
            </a>
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
}
