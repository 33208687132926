import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import colors from "styles/scss/constants.module.scss";

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export default function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, paddingBottom: "40px" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: -67,
            top: -65,
            color: colors.secondary,
          }}
        >
          <CloseIcon sx={{ width: "3rem", height: "3rem" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
