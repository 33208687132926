import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Product from "models/resources/product.model";
import { getDataById } from "services/requests/listData";

const useProductDetails = () => {
  const { id } = useParams();
  const [productData, setProductData] = useState<Product | null>(null);
  const [image, setImage] = useState<string | null>(null);
  const [refetchFlag, setRefetchFlag] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      getDataById(setProductData, `products/${id}`);
      (async () => {
        await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/products/${id}/image`,
          {
            method: "GET",
            credentials: "include",
          },
        ).then(async (response) => {
          if (response.status === 404) {
            setImage(null);
            return;
          }
          setImage(URL.createObjectURL(await response.blob()));
        });
      })();
    }
  }, [id, refetchFlag]);

  return { productData, setProductData, image, setRefetchFlag };
};

export default useProductDetails;
