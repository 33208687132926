import { PaymentStatus as PaymentStatusModel } from "models/resources/payment.model";
import apiClient from "services/api";

// Enum string values based on response from back end.
enum PaymentStatus {
  Pending = "PENDING",
  Paid = "PAID",
  Failed = "FAILED",
  TimedOut = "TIMED_OUT",
  Refunded = "REFUNDED",
  Expired = "EXPIRED", // Depricated?
  NotFound = "transaction_not_found", // Depricated?
}

// /api/payments/{checkout_id}/status fetches the transaction status from the backend since SumUp
// docs state that a 'success' status is not a guarantee for a successful transaction. The backend
// has the correct informaton and includes scenarios of a timed out transaction.

// This function periodically checks the status of the payment since it is unpredictable
// when exactly the status will be set. It will iterate until the status is no longer pending.
// and then notify the user.

// This function is for both prepaid cards and machine/qr/vpos payments. The former uses notifactions
// and the latter renders it on the DOM that's why logic was added to differentiate.

// prepaid card payment uses notifications, machine payment message is rendered on the DOM
export const checkPaymentStatus = async (
  checkoutId: string,
): Promise<PaymentStatusModel> => {
  return apiClient<PaymentStatusModel>({
    url: `/payments/${checkoutId}/status`,
    method: "get",
  }).then((response) => response.data);
};

export const processPaymentStatus = (
  paymentStatus: PaymentStatusModel,
): { funded_entity: boolean; status: string } => {
  switch (paymentStatus.status) {
    case PaymentStatus.Paid:
      return {
        funded_entity: paymentStatus.funded_entity,
        status: "Payment finished successfully.",
      };
    case PaymentStatus.Failed:
      return {
        funded_entity: paymentStatus.funded_entity,
        status: "Payment failed.",
      };
    case PaymentStatus.TimedOut:
    case PaymentStatus.Expired:
      return {
        funded_entity: paymentStatus.funded_entity,
        status:
          "The payment request expired before being completed! Please, try again!",
      };
    case PaymentStatus.NotFound:
      return {
        funded_entity: paymentStatus.funded_entity,
        status: "Transaction not found in system!",
      };
    default:
      return {
        funded_entity: paymentStatus.funded_entity,
        status: paymentStatus.status,
      };
  }
};

export const validatePaymentStatus = async (
  checkoutId: string,
): Promise<{ funded_entity: boolean; status: string }> => {
  let paymentStatus: PaymentStatusModel = {
    funded_entity: false,
    status: PaymentStatus.Pending,
  };

  do {
    await new Promise((resolve) => setTimeout(resolve, 800));

    paymentStatus = await checkPaymentStatus(checkoutId).catch(() => ({
      funded_entity: false,
      status: PaymentStatus.Pending,
    }));
  } while (paymentStatus.status === PaymentStatus.Pending);

  return processPaymentStatus(paymentStatus);
};
