import { SetStateAction, Dispatch } from "react";
import { ToastPosition } from "react-toastify";

import { Box, Button, TextField } from "@mui/material";

interface ConfirmActionDialogProps {
  confirm: Dispatch<SetStateAction<boolean>>;
  decline: Function;
  useInput: boolean;
  inputValueSetter?: Function;
  t: (
    key: string | string[],
    options?: { position?: ToastPosition; autoClose?: boolean },
  ) => string;
}

const ConfirmActionDialog = ({
  confirm,
  decline,
  useInput,
  inputValueSetter,
  t,
}: ConfirmActionDialogProps) => {
  // not generic for now
  return (
    <Box>
      {useInput && (
        <TextField
          autoFocus
          margin="dense"
          id="reason"
          label={t("Reason")}
          type="text"
          fullWidth
          variant="standard"
          onChange={(e) => {
            if (inputValueSetter) inputValueSetter(e.target.value);
          }}
        />
      )}
      <Button onClick={() => confirm(true)}>{t("Confirm")}</Button>
      <Button onClick={() => decline(false)}>{t("Close")}</Button>
    </Box>
  );
};

export default ConfirmActionDialog;
