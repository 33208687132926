import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Promotion from "models/resources/promotion.model";
import { getDataById } from "services/requests/listData";

const usePromotionDetails = () => {
  const { id } = useParams();
  const [promotionData, setPromotionData] = useState<Promotion | null>(null);

  useEffect(() => {
    if (id) {
      getDataById(setPromotionData, `promotions/${id}`);
    }
  }, [id]);
  return { promotionData, setPromotionData };
};

export default usePromotionDetails;
