import { useTranslation } from "react-i18next";

import {
  CompanyProps,
  InvoicesProps,
  LocationsProps,
  MachineProps,
  ModulesProps,
  OrderInvoicesProps,
  OrdersProps,
  POSProps,
  PromotionsProps,
  QRProps,
  TransactionsProps,
  UsersProps,
} from "components/CsvExport/models";
import { fixCurrencyAmount } from "components/PdfExport/fields";
import useFilterContext from "context/FilterContext";
import Company from "models/resources/company.model";
import Invoice from "models/resources/invoice.model";
import Location from "models/resources/location.model";
import Machine from "models/resources/machine.model";
import Module from "models/resources/module.model";
import Order from "models/resources/order.model";
import POS from "models/resources/pos.model";
import Promotion from "models/resources/promotion.model";
import QR from "models/resources/qr.model";
import Transaction from "models/resources/transaction.model";
import User from "models/resources/user.model";
import { CompaniesForExport } from "utils/CompaniesToExport";
import { roleName } from "utils/UserRoles";
import { getLocalizedDate, getLocalizedTime } from "utils/lozalizeDateTime";

export const Fields = () => {
  const { t } = useTranslation();
  const { options } = useFilterContext();

  const findLocation = (data: Machine | POS) =>
    options?.locations.find((e) => e.id === data.location_id);

  const Companies = (rowData: Company[]) => {
    const headers = [
      { label: t("Company Name"), key: "companyName" },
      { label: t("Email"), key: "email" },
      { label: t("Company info"), key: "companyInfo" },
      { label: t("Phone number"), key: "phoneNumber" },
    ];

    const body = rowData?.reduce((acc: CompanyProps[], value) => {
      acc.push({
        companyName: value.display_name,
        email: value.email,
        companyInfo: value.info,
        phoneNumber: value.phone,
      });

      return acc;
    }, []);
    return { headers, body };
  };
  const OrderInvoices = (rowData: Order[]) => {
    const headers = [
      { label: t("Order Id"), key: "orderId" },
      { label: t("Date"), key: "date" },
      {
        label: `${t("Company issuer")}`,
        key: "companyRowName",
      },
      { label: t("Total price"), key: "totalPrice" },
      { label: t("Currency"), key: "currency" },
      { label: t("%VAT"), key: "vat" },
      { label: t("Status"), key: "status" },
      { label: t("Invoice number"), key: "invoiceNumber" },
      { label: t("Reason"), key: "reason" },
    ];

    const body = rowData?.reduce((acc: OrderInvoicesProps[], value) => {
      acc.push({
        orderId: value.id,
        date: getLocalizedDate(value.inserted_at),
        companyRowName: value?.company?.display_name,
        totalPrice:
          value.product_price && value.quantity
            ? (+value.quantity * +value.product_price).toFixed(2)
            : 0.0,
        currency: value.currency ? t(value.currency) : "",
        vat: value?.company?.vat_load_gift_cards,
        status:
          value.request_invoice === "null"
            ? "not requested"
            : value.request_invoice,
        invoiceNumber: value?.invoice?.number ? value?.invoice?.number : "",
        reason: value.invoice_reason,
      });

      return acc;
    }, []);

    return { headers, body };
  };
  const Invoices = (rowData: Invoice[], typeInvoice: string) => {
    const headers = [
      { label: t("Invoice number"), key: "invoiceNumber" },
      { label: t("Date"), key: "date" },
      { label: t("Issuing company"), key: "issuingCompany" },
      { label: t("Receiving company"), key: "receivingCompany" },
      { label: t("Total amount"), key: "totalAmount" },
      { label: t("VAT %"), key: "vat" },
      { label: t("Cancelled"), key: "cancelled" },
    ];

    const body = rowData?.reduce((acc: InvoicesProps[], value) => {
      acc.push({
        invoiceNumber: value.id,
        date: getLocalizedDate(value.date as string),
        issuingCompany: value?.issuer_company?.display_name,
        receivingCompany: value?.receiver_company?.display_name,
        totalAmount: `${value.amount} ${value.currency}`,
        vat: `${value.vat_percent} %`,
        cancelled: value.cancelled,
      });

      return acc;
    }, []);

    if (typeInvoice === "outgoing") {
      headers.splice(2, 1);
      body.splice(2, 1);
    } else if (typeInvoice === "incoming") {
      headers.splice(3, 1);
      body.splice(3, 1);
    }

    return { headers, body };
  };
  const Locations = (rowData: Location[]) => {
    const headers = [
      { label: t("Location name"), key: "locationName" },
      { label: t("Address"), key: "address" },
      { label: t("Company"), key: "company" },
      { label: t("Latitude"), key: "latitude" },
      { label: t("Longitude"), key: "longitude" },
    ];

    const body = rowData?.reduce((acc: LocationsProps[], value) => {
      acc.push({
        locationName: value.name,
        address: value.address,
        company: options?.companies?.find((e) => e.id === value.company.id)
          ?.display_name,
        latitude: value.latitude,
        longitude: value.longitude,
      });

      return acc;
    }, []);

    return { headers, body };
  };
  const Machines = (rowData: Machine[]) => {
    const headers = [
      { label: t("Machine Name"), key: "machineName" },
      { label: t("Currency"), key: "currency" },
      { label: t("Location"), key: "location" },
      { label: t("Company"), key: "company" },
      { label: t("Module Serial"), key: "moduleSerial" },
      { label: t("Module Signal Strength"), key: "moduleSignalStrength" },
      { label: t("Module State"), key: "moduleState" },
    ];

    const body = rowData?.reduce((acc: MachineProps[], value) => {
      acc.push({
        machineName: value?.name,
        currency: value?.currency,
        location: `${
          findLocation(value)?.name !== undefined
            ? findLocation(value)?.name
            : ""
        }${findLocation(value)?.name !== undefined ? "," : ""} ${
          findLocation(value)?.address !== undefined
            ? findLocation(value)?.address
            : ""
        }`,
        company: value?.company?.display_name,
        moduleSerial: value.module?.serial,
        moduleSignalStrength: value.module?.signal_strength
          ? t(`${value.module?.signal_strength} signal`)
          : "n.a.",
        moduleState: value.module?.state
          ? `${value.module?.state ? t(value.module?.state) : ""}`
          : "n.a.",
      });

      return acc;
    }, []);

    return { headers, body };
  };
  const Modules = (rowData: Module[]) => {
    const headers = [
      { label: t("ID"), key: "id" },
      { label: t("Added to cloud"), key: "addedToCloud" },
      { label: t("Module Serial"), key: "moduleSerial" },
      { label: t("MCU Version"), key: "mcuVersion" },
      { label: t("Firmware"), key: "firmware" },
      { label: t("Company"), key: "company" },
      { label: t("Machine"), key: "machine" },
      { label: t("Module State"), key: "moduleState" },
      { label: t("uptime"), key: "uptime" },
      { label: t("Task"), key: "task" },
      { label: t("Signal Strength"), key: "signalStrength" },
    ];

    const body = rowData?.reduce((acc: ModulesProps[], value) => {
      acc.push({
        id: value.id,
        addedToCloud: `${getLocalizedDate(
          value.inserted_at as string,
        )} ${getLocalizedTime(value.inserted_at as string)}`,
        moduleSerial: value.serial,
        mcuVersion: value.mcu_version,
        firmware: value.firmware?.current,
        company: value.company?.display_name
          ? value.company?.display_name
          : "---",
        machine: value.machine?.name ? value.machine?.name : "---",
        moduleState: value.state ? t(value.state) : "",
        uptime: value.uptime,
        task: value.task ? t(value.task) : "",
        signalStrength: t(`${value.signal_strength} signal`),
      });

      return acc;
    }, []);

    return { headers, body };
  };
  const Orders = (rowData: Order[]) => {
    const headers = [
      { label: t("Order Date"), key: "orderDate" },
      { label: t("Order Time"), key: "orderTime" },
      { label: t("Product Number"), key: "productNumber" },
      { label: t("Unit price"), key: "unitPrice" },
      { label: t("Quantity"), key: "quantity" },
      { label: t("Order total"), key: "orderTotal" },
      { label: t("Currency"), key: "currency" },
      { label: t("Status"), key: "status" },
      { label: t("Company"), key: "company" },
      { label: t("Payment point"), key: "paymentPoint" },
    ];

    const body = rowData?.reduce((acc: OrdersProps[], value) => {
      acc.push({
        orderDate: getLocalizedDate(value.inserted_at),
        orderTime: getLocalizedTime(value.inserted_at),
        productNumber: value.product_number ?? "---",
        unitPrice: value.product_price
          ? Number(value.product_price).toFixed(2)
          : 0.0,
        quantity: value.quantity,
        orderTotal:
          value.product_price && value.quantity
            ? (+value.quantity * +value.product_price).toFixed(2)
            : 0.0,
        currency: value.currency,
        status: t(`${value.status}${value.reason ? " " + value.reason : ""}`),
        company: value?.company?.display_name,
        paymentPoint: value.object_entity?.name,
      });

      return acc;
    }, []);

    return { headers, body };
  };
  const POS = (rowData: POS[]) => {
    const headers = [
      { label: t("POS Name"), key: "posName" },
      { label: t("Currency"), key: "currency" },
      { label: t("Location"), key: "location" },
      { label: t("Company"), key: "company" },
      { label: t("POS serial"), key: "posSerial" },
    ];

    const body = rowData?.reduce((acc: POSProps[], value) => {
      acc.push({
        posName: value?.name,
        currency: value?.currency,
        location: `${
          findLocation(value)?.name !== undefined
            ? findLocation(value)?.name
            : ""
        }${findLocation(value)?.name !== undefined ? "," : ""} ${
          findLocation(value)?.address !== undefined
            ? findLocation(value)?.address
            : ""
        }`,
        company: value?.company?.display_name,
        posSerial: value.client?.serial,
      });

      return acc;
    }, []);

    return { headers, body };
  };
  const Promotions = (rowData: Promotion[]) => {
    const headers = [
      { label: t("Id"), key: "id" },
      { label: t("From"), key: "from" },
      { label: t("To"), key: "to" },
      { label: t("Company"), key: "company" },
      { label: t("Promotion Type"), key: "promotionType" },
      { label: t("Pay Now"), key: "payNow" },
      { label: t("Get Later"), key: "getLater" },
    ];

    const body = rowData?.reduce((acc: PromotionsProps[], value) => {
      acc.push({
        id: value.id,
        from: getLocalizedDate(value.valid_from),
        to: getLocalizedTime(value.valid_to),
        company: options?.companies?.find((e) => e.id === value.company_id)
          ?.display_name,
      });

      return acc;
    }, []);

    return { headers, body };
  };
  const QR = (rowData: QR[]) => {
    const headers = [
      { label: t("QR code name"), key: "qrCodeName" },
      { label: t("Currency"), key: "currnecy" },
      { label: t("Company"), key: "company" },
      { label: t("Serial"), key: "serial" },
    ];

    const body = rowData?.reduce((acc: QRProps[], value) => {
      acc.push({
        qrCodeName: value?.name,
        currnecy: value?.currency,
        company: value?.company?.display_name,
        serial: value.serial,
      });

      return acc;
    }, []);

    return { headers, body };
  };
  const Transactions = (rowData: Transaction[]) => {
    const headers = [
      { label: t("Transaction Date"), key: "transactionDate" },
      { label: t("Transaction Time"), key: "transactionTime" },
      { label: t("Amount"), key: "amount" },
      { label: t("Currency"), key: "currency" },
      { label: t("POS Amount"), key: "posAmount" },
      { label: t("POS Currency"), key: "posCurrency" },
      { label: t("Status"), key: "status" },
      { label: t("Company"), key: "company" },
      { label: t("Payment point"), key: "paymentPoint" },
      { label: t("Payment type"), key: "paymentType" },
      { label: t("Checkout ID"), key: "checkoutId" },
    ];

    const body = rowData?.reduce((acc: TransactionsProps[], value) => {
      acc.push({
        transactionDate: getLocalizedDate(value.inserted_at),
        transactionTime: getLocalizedTime(value.inserted_at),
        amount: fixCurrencyAmount(+value.amount * +value.rate, value.currency),
        currency: value.currency,
        posAmount: fixCurrencyAmount(
          +value.amount,
          value.currency_at_transaction,
        ),
        posCurrency: value.currency_at_transaction,
        status: value.status ? t(value.status) : "",
        company: value.company?.display_name,
        paymentPoint: value.object_entity?.name,
        paymentType: value.payment_type,
        checkoutId: value.checkout_id,
      });

      return acc;
    }, []);

    return { headers, body };
  };
  const Users = (rowData: User[]) => {
    const headers = [
      { label: t("First Name"), key: "firstName" },
      { label: t("Last Name"), key: "lastName" },
      { label: t("Email"), key: "email" },
      { label: t("Phone number"), key: "phoneNumber" },
      { label: t("Role"), key: "role" },
      { label: t("Companies"), key: "companies" },
    ];

    const body = rowData?.reduce((acc: UsersProps[], value) => {
      acc.push({
        firstName: value.first_name,
        lastName: value.last_name,
        email: value.email,
        phoneNumber: value.phone !== null ? value.phone : "---",
        role: roleName(value.role?.type),
        companies: CompaniesForExport(value.users_companies),
      });

      return acc;
    }, []);

    return { headers, body };
  };

  return {
    Locations,
    Machines,
    POS,
    QR,
    Transactions,
    Orders,
    Invoices,
    OrderInvoices,
    Users,
    Companies,
    Modules,
    Promotions,
  };
};

export default Fields;
