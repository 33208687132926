export const formatDate = (date: string | Date) => {
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, "0");
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const year = d.getFullYear();
  return `${day}-${month}-${year}`;
};

export const roundTime = (date: Date) => {
  if (date.getMinutes() % 10 <= 5)
    date.setMinutes(Math.floor(date.getMinutes() / 10) * 10 + 0);
  else if (date.getHours() < 23) {
    date.setHours(date.getHours() + 1);
    date.setMinutes(5);
  } else {
    date.setMinutes(Math.floor(date.getMinutes() / 10) * 10 + 5);
  }
  return date;
};

// used for converting back end string dates (ex. 21:00:00) to JS Date
export const normalizeDate = (range: string) => {
  const [hours, minutes, seconds] = range?.split(":").map(Number);

  const date = new Date();
  date.setHours(hours, minutes, seconds, 0);

  return convertUTCDateToLocalDate(date);
};

export const isTime = (range: string) => {
  if (typeof range !== "string") return false;
  const time = range.split(":").map(Number);
  if (time.length != 3) return false;
  const [hours, minutes, seconds] = time;

  if (
    hours < 0 ||
    hours > 24 ||
    minutes < 0 ||
    minutes > 59 ||
    seconds < 0 ||
    seconds > 59
  )
    return false;
  return true;
};

export function convertUTCDateToLocalDate(date: Date) {
  var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;
}

export const getMinDate = ({
  futureDatesFromStart,
  futureDatesOnly,
  startDate,
}: any): Date | null | undefined => {
  if (futureDatesFromStart) return startDate as Date;
  else if (futureDatesOnly) return new Date();
  return null;
};
