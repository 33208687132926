import { useLocation } from "react-router";

import * as _ from "lodash";

import { pageProps } from "services/pageconfig/pagePropsData";

export function getPageProps() {
  const router = useLocation();

  return pageProps.find((page) => {
    if (
      router.pathname.toLowerCase().indexOf(page.componentName.toLowerCase()) >
      -1
    ) {
      return page;
    }
  });
}
