import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Button } from "@mui/material";

import MapContainer from "components/MapContainer";
import useAuthContext from "context/AuthContext";
import Location from "models/resources/location.model";
import Machine from "models/resources/machine.model";
import apiClient from "services/api";
import { parseMachineLocations } from "utils/parseMapLocations";

const MachinesOnMap = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuthContext();
  const [machines, setMachines] = useState<Machine[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  //TODO: The text in the button is sometimes not translated when changing language
  const [showMap, setShowMap] = useState({
    active: false,
    label: t("See where else you can pay with Averato"),
  });

  const handleButtonOnClick = () => {
    setShowMap({
      active: !showMap.active,
      label: showMap.active
        ? t("See where else you can pay with Averato")
        : t("Hide Map"),
    });
  };

  useEffect(() => {
    if (!isAuthenticated) return;

    (async () => {
      await apiClient<Machine[]>({
        url: "machines",
        method: "get",
        queryParams: {
          pagination: { page: 1, perPage: Number.MAX_SAFE_INTEGER },
        },
      }).then((response) => setMachines(response.data));

      await apiClient<Location[]>({
        url: "locations",
        method: "get",
        queryParams: {
          pagination: { page: 1, perPage: Number.MAX_SAFE_INTEGER },
        },
      }).then((response) => setLocations(response.data));
    })();
  }, []);

  return (
    <Box sx={{ margin: "0 auto", width: "30%" }}>
      <Button
        variant={"contained"}
        style={{
          fontSize: "medium",
          margin: "0.5em 0",
          color: "white",
          backgroundColor: "#233044",
        }}
        onClick={() => handleButtonOnClick()}
      >
        {`${t(showMap.label)}`}
      </Button>
      {showMap.active && (
        <MapContainer locations={parseMachineLocations(machines, locations)} />
      )}
    </Box>
  );
};

export default MachinesOnMap;
