import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import CircleIcon from "@mui/icons-material/Circle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Chip } from "@mui/material";
import { Box } from "@mui/material";

import ModuleQR from "components/ModuleQr";
import {
  CustomTable,
  Column,
  TableSettings,
  CellValue,
} from "components/Table/Table";
import ContentLayout from "components/layouts/ContentLayout";
import DynamicDialog from "components/layouts/Dialog";
import useDataContext from "context/DataContext";
import QueryParams from "models/queryParams.model";
import ModuleModel from "models/resources/module.model";
import Module from "models/resources/module.model";
import { ResponseDataTable } from "models/responseData.model";
import { getData } from "services/requests/listData";
import { getLocalizedDate, getLocalizedTime } from "utils/lozalizeDateTime";
import { moduleRequestFilter } from "utils/requestFilter";

const ModuleTable = () => {
  const [currentData, setCurrentData] =
    useState<ResponseDataTable<ModuleModel[]>>();
  const [getModulesData, setModulesData] =
    useState<ResponseDataTable<ModuleModel[]>>();
  const [currentTabIndex, setCurrentTabIndex] = useState("active");
  const { t } = useTranslation();
  const { getPagePropsData } = useDataContext();

  const setData = (queryParams: QueryParams, setDataLoading: any) => {
    getData(setModulesData, "modules", queryParams, setDataLoading);
  };

  useEffect(() => {
    const filteredData = moduleRequestFilter(
      getModulesData?.data as ModuleModel[],
      currentTabIndex,
    );
    setCurrentData({ ...getModulesData, data: filteredData! });
  }, [currentTabIndex, getModulesData]);

  const getCurrentTab = (tab: number) => {
    setCurrentTabIndex((getPagePropsData.tabs || ([] as string[]))[tab]);
  };

  const columns: Column<Module>[] = [
    {
      label: t("ID"),
      name: "id",
      getData: (data): CellValue => data.id,
      show: () => {
        return true;
      },
      sort: { isSortable: false, field: "id" },
    },
    {
      label: t("Added to cloud"),
      name: "addedToCloud",
      getData: (data): CellValue =>
        `${getLocalizedDate(data.inserted_at)} ${getLocalizedTime(
          data.inserted_at,
        )}`,
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "inserted_at" },
      minWidth: "200px",
    },
    {
      label: t("Module Serial"),
      name: "modulSerial",
      getData: (data): CellValue => data.serial,
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "serial" },
      minWidth: "200px",
    },
    {
      label: t("MCU version & Firmware"),
      name: "mcuVersion",
      getData: (data): CellValue => (
        <Box>
          <Chip sx={{ cursor: "pointer" }} label={data.mcu_version} />
          {` `}
          {data.firmware?.current ? data.firmware.current : "---"}
        </Box>
      ),
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "mcu_version" },
      minWidth: "250px",
    },
    {
      label: t("Company"),
      name: "company",
      getData: (data): CellValue =>
        data.company !== null ? (
          <Link
            to={{
              pathname: `/account/companies/${data.company.id}`,
            }}
          >
            {data.company.display_name || data.company.name}
          </Link>
        ) : (
          "---"
        ),
      show: () => {
        return true;
      },
      sort: {
        isSortable: true,
        field: "company.name",
      },
      minWidth: "200px",
    },
    {
      label: t("Machine"),
      name: "machine",
      getData: (data): CellValue =>
        data.machine !== null ? (
          <Link
            to={{
              pathname: `/account/vending/machines/${data.machine.id}`,
            }}
          >
            <a>{data.machine.name}</a>
          </Link>
        ) : (
          "---"
        ),
      show: () => {
        return true;
      },
      minWidth: "200px",
    },
    {
      label: t("Module State"),
      name: "moduleState",
      getData: (data): CellValue => (
        <CircleIcon
          sx={{ color: data.state === "up" ? "#4CAF50" : "#FF6347" }}
        />
      ),
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "state" },
      minWidth: "150px",
      align: "center",
    },
    {
      label: t("Uptime"),
      name: "uptime",
      getData: (data): CellValue => data.uptime,
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "uptime" },
      minWidth: "150px",
    },
    {
      label: t("Task"),
      name: "task",
      getData: (data): CellValue => (data.task ? t(data.task) : "---"),
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "task" },
    },
    {
      label: t("QR"),
      name: "qr",
      getData: (data): CellValue =>
        data.serial ? (
          <DynamicDialog
            title={`${t("QR code for module")} ${data.serial}`}
            component={<ModuleQR moduleSerial={data.serial} />}
            iconButton={<VisibilityIcon />}
            openMessage={t("QR")}
            hideActions
          />
        ) : (
          "---"
        ),
      align: "center",
      show: () => {
        return true;
      },
    },
    {
      label: t("Last Seen"),
      name: "lastSeen",
      getData: (data): CellValue =>
        data.last_seen
          ? `${getLocalizedDate(data.last_seen)} ${getLocalizedTime(
              data.last_seen,
            )}`
          : "---",
      show: () => {
        return true;
      },
      minWidth: "200px",
    },
    {
      label: t("Comments"),
      name: "comments",
      getData: (data): CellValue =>
        data?.comments !== null ? data?.comments : "---",
      show: () => {
        return true;
      },
      minWidth: "300px",
    },
    {
      label: t("Flow"),
      name: "flow",
      getData: (data): CellValue =>
        data?.flow !== null ? t(data?.flow) : "---",
      show: () => {
        return true;
      },
      minWidth: "300px",
    },
    {
      label: t("Edit"),
      name: "edit",
      getData: (data): CellValue => data.id,
      show: () => {
        return currentTabIndex === "deleted" ? false : true;
      },
    },
  ];

  const tableOptions: TableSettings = {
    pagination: true,
    page: 0,
    rowsPerPage: 10,
    field: "id",
    order: "desc",
  };
  return (
    <ContentLayout title={t("Modules")}>
      <CustomTable
        title={t("Modules")}
        resource="module"
        tableData={currentData}
        fetchPaginatedData={setData}
        setTableData={setModulesData}
        columns={columns}
        tableOptions={tableOptions}
        getCurrentTab={getCurrentTab}
        isTabs={true}
        call={"modules"}
        currentTabIndex={currentTabIndex}
        exportProp={"modules"}
        clickableRow={currentTabIndex === "deleted" ? false : true}
      />
    </ContentLayout>
  );
};

export default ModuleTable;
