import React, { useEffect, useState } from "react";

import { Text, View, StyleSheet } from "@react-pdf/renderer";

import Invoice from "models/resources/invoice.model";
import Order from "models/resources/order.model";
import Transaction from "models/resources/transaction.model";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
    height: 24,
  },
  description: {
    width: "35%",
    textAlign: "center",
  },
  qty: {
    width: "10%",
    textAlign: "center",
  },
  vat: {
    width: "15%",
    textAlign: "center",
  },
  price: {
    width: "20%",
    textAlign: "center",
  },
  amount: {
    width: "20%",
    textAlign: "center",
  },
  totalAmount: {
    padding: "5px",
  },
  totalContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 10,
    paddingTop: 100,
  },
});
interface PdfInfo {
  name: string;
  gross: string;
  vatPercentage: number;
  net: string;
  vatAmount: string;
  currency: string;
  quantity: number;
}
interface Props {
  invoiceData: Invoice;
  transactionData: Transaction[] | undefined;
  orderData: Order | undefined;
}

const TableBody = ({ invoiceData, transactionData, orderData }: Props) => {
  const [pdfInformation, setPdfInformation] = useState<PdfInfo[] | undefined>(
    [],
  );
  const [totalAmount, setTotalAmount] = useState({
    amount: 0,
    vat: 0,
  });
  const calculateVat = (amount: number | string) => {
    return ((Number(amount) * Number(invoiceData?.vat_percent)) / 100).toFixed(
      2,
    );
  };
  useEffect(() => {
    calculatePdfData();
  }, [orderData, transactionData]);

  const calculatePdfData = () => {
    let totalAmount = 0;
    let totalVat = 0;
    const data = transactionData
      ?.filter((e: Transaction) => e.payment_type === "gift_card")
      ?.reduce((acc: PdfInfo[], element: Transaction) => {
        totalAmount += Number(element?.amount);
        totalVat += Number(calculateVat(element?.amount));
        acc.push({
          name: element?.object_entity.name,
          gross: calculateVat(element?.amount),
          vatPercentage: Number(invoiceData?.vat_percent),
          net: Number(element?.amount).toFixed(2),
          vatAmount: (
            Number(element?.amount) - Number(calculateVat(element?.amount))
          ).toFixed(2),
          currency: element?.currency as string,
          quantity: Number(orderData?.quantity),
        });

        return acc;
      }, []);

    setPdfInformation(data);
    setTotalAmount({ amount: totalAmount, vat: totalVat });
  };

  return (
    <>
      {pdfInformation?.map((element: PdfInfo, index: number) => (
        <View style={styles.row} key={`${index}-${element.name}`}>
          <Text style={styles.description}>{element.name}</Text>
          <Text style={styles.qty}>{element?.quantity}</Text>
          <Text style={styles.vat}>{`${element.vatPercentage}%`}</Text>
          <Text
            style={styles.price}
          >{`${element?.vatAmount} ${element?.currency}`}</Text>
          <Text
            style={styles.amount}
          >{`${element?.net} ${element?.currency}`}</Text>
        </View>
      ))}
      <View style={styles.totalContainer}>
        <View>
          <Text style={styles.totalAmount}>{`Total Excl. VAT: ${(
            totalAmount.amount - totalAmount.vat
          ).toFixed(2)} ${orderData?.currency}`}</Text>
          <Text
            style={styles.totalAmount}
          >{`20% Tax: ${totalAmount.vat?.toFixed(2)} ${
            orderData?.currency
          }`}</Text>
          <Text
            style={styles.totalAmount}
          >{`Total Incl. VAT: ${totalAmount.amount?.toFixed(2)} ${
            orderData?.currency
          }`}</Text>
        </View>
      </View>
    </>
  );
};

export default TableBody;
