import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";

import { Box } from "@mui/material";

import useMapContainer from "components/MapContainer/useMapContainer";
import MapLocation from "models/map-location.model";

interface MapContainerProps {
  locations: MapLocation[];
}

const MapContainer = ({ locations }: MapContainerProps) => {
  const { t } = useTranslation();
  const { selectedLocation, onLoad, markerOnOpen, markerOnClose } =
    useMapContainer(locations);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY ?? "",
    libraries: [process.env.REACT_APP_GOOGLE_LIBRARIES as "places"],
  });

  return (
    <Box>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: "50vh",
            marginTop: "15px",
            marginBottom: "20px",
          }}
          onLoad={onLoad}
        >
          {locations.map(({ id, position }) => (
            <Marker
              key={id}
              onClick={() => markerOnOpen(id)}
              position={position}
            />
          ))}
          {selectedLocation !== null && (
            <InfoWindow
              position={selectedLocation.position}
              onCloseClick={markerOnClose}
            >
              <Fragment>
                <p>
                  {typeof selectedLocation.machineName !== "undefined" &&
                    `${t("Machine")}: ${selectedLocation?.machineName}`}
                </p>
                <p>
                  {t("Address")}: {selectedLocation?.address}
                </p>
                <p>
                  {t("Location Name")}: {selectedLocation.name}
                </p>
              </Fragment>
            </InfoWindow>
          )}
        </GoogleMap>
      ) : (
        <p>{t("Loading")}</p>
      )}
    </Box>
  );
};

export default MapContainer;
