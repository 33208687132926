import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { SelectChangeEvent } from "@mui/material";

import {
  PaymentCredentialsTab,
  PaymentCredentialsTabForm,
  PaymentProvider,
  PaymentProviderCredentials,
} from "components/CompanyPaymentCredentials/utils";
import { PaymentCredentials } from "models/fields.model";
import { ResponseDataError } from "models/responseData.model";
import apiClient from "services/api";

interface PaymentCredentialsFormFields {
  // Snake-case because of dynamic generation of form fields from API GET response props and same name usage for POST request.
  client_id: string;
  client_secret: string;
  currency: string;
  merchant_code: string;
  password: string;
  project_id: string;
}

const usePaymentCredentialsForm = (
  companyId: number,
  paymentProvider: PaymentProvider,
  credentialsTabForm: PaymentCredentialsTabForm,
  handleTabsUpdate: (credentials: PaymentProviderCredentials) => void,
  handleCurrentTabRemove: () => void,
) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState<PaymentProviderCredentials>({
    // Snake-case because of dynamic generation of form fields from API GET response props and same name usage for POST request.
    currency: "",
    client_id: "",
    client_secret: "",
    merchant_code: "",
    password: "",
    project_id: "",
  });
  const [errorMessages, setErrorMessages] =
    useState<PaymentCredentialsFormFields>({
      client_id: "",
      client_secret: "",
      currency: "",
      merchant_code: "",
      password: "",
      project_id: "",
    });

  // Dynamic handler for input values on change and MUI Select component for currencies.
  const handleFormValuesOnChange = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>,
  ) => {
    const fieldName = event.target.name;
    const value = event.target.value.trim();

    setErrorMessages((oldErrors) => ({
      ...oldErrors,
      [fieldName]: "",
    }));

    setFormValues((oldFormValues) => ({
      ...oldFormValues,
      [fieldName]: value,
    }));
  };

  // Handler for the post request method with vallidations of the entered input field values.
  const handleSubmitOnClick = async () => {
    const emptyFields = Object.entries(formValues).filter(
      // Filter empty input fields for the dynamic form state.
      ([key, value]) =>
        Object.keys(credentialsTabForm.paymentCredentialsForm).includes(key) &&
        value.length === 0,
    );

    if (emptyFields.length !== 0) {
      // Doesn't allow to continue with the reqeust when there is any none empty error message in the state.
      setErrorMessages((oldErrors) => ({
        ...oldErrors,
        ...Object.fromEntries(
          emptyFields.map((v) => [v[0], "This field is required"]),
        ),
      }));
      return;
    }

    await apiClient<PaymentCredentials>({
      url: `/companies/${companyId}/payment-credentials`,
      method: "post",
      bodyData: {
        credentials_data: {
          ...formValues,
          currency:
            credentialsTabForm.tabIndex !== PaymentCredentialsTab.ADD
              ? PaymentCredentialsTab[credentialsTabForm.tabIndex] // Used for updating an already existing currency. Get currency from tab.
              : formValues.currency,
        },
        payment_provider_code: paymentProvider.toLowerCase(),
      },
    })
      .then((response) => {
        handleTabsUpdate(response.data);

        toast.success(
          t(
            `Resource ${
              credentialsTabForm.tabIndex === PaymentCredentialsTab.ADD
                ? "created"
                : "edited"
            } successfully`,
          ),
        );
      })
      .catch((error: ResponseDataError) => toast.error(error.message));
  };

  // Handler for the delete request method.
  const handleDeleteOnClick = async () => {
    await apiClient({
      url: `/companies/${companyId}/payment-credentials/${paymentProvider.toLowerCase()}/${
        credentialsTabForm.paymentCredentialId
      }`,
      method: "delete",
    })
      .then(() => {
        handleCurrentTabRemove();
        toast.success(t("Resource deleted successfully"));
      })
      .catch((error: ResponseDataError) => toast.error(error.message));
  };

  useEffect(() => {
    setFormValues(credentialsTabForm.paymentCredentialsForm);
  }, [credentialsTabForm.paymentCredentialsForm]);

  return {
    formValues,
    handleFormValuesOnChange,
    errorMessages,
    handleSubmitOnClick,
    handleDeleteOnClick,
  };
};

export default usePaymentCredentialsForm;
