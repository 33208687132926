import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import MobileStoreButton from "react-mobile-store-button";

interface AppLinksProps {
  product: string;
}

export const AppLinks = ({ product }: AppLinksProps) => {
  const { t } = useTranslation();

  const iOSUrl: string =
    product === "myPay.bg"
      ? "https://apps.apple.com/bg/app/mypay-bg/id1563457954"
      : product === "Averato"
        ? "https://apps.apple.com/us/app/averato/id1547449307"
        : "";
  const androidUrl: string =
    product === "myPay.bg"
      ? "https://play.google.com/store/apps/details?id=com.myPay.bg"
      : product === "Averato"
        ? "https://play.google.com/store/apps/details?id=com.quanterall.vendingpayment"
        : "";

  return (
    <Fragment>
      <MobileStoreButton
        store="android"
        url={androidUrl}
        height={60}
        width={164}
        linkProps={{ title: t(`Get ${product} for Android`) }}
      />
      &nbsp;
      <MobileStoreButton
        store="ios"
        url={iOSUrl}
        height={50}
        width={145}
        linkProps={{ title: t(`Get ${product} for iOS`) }}
      />
    </Fragment>
  );
};
