import { Currency, UserSettings } from "models/resources/user.model";

// Parse userSettings string from API to UserSettings interface.
const parseUserSettings = (settings: string): UserSettings => {
  // @ts-ignore
  // More info about ts-ignore: https://stackoverflow.com/questions/49218765/typescript-and-iterator-type-iterableiteratort-is-not-an-array-type
  const matches = [...settings.matchAll(/"(\w+)":"([^"]*)"/g)];

  const userSettings: UserSettings = { currency: Currency.BGN };

  matches.map((m) => {
    const value: string = m[2];

    switch (m[1].toLowerCase()) {
      case "lang":
        userSettings.lang = value;
        break;
      case "currency":
        userSettings.currency = value as Currency;
        break;
      case "perpage":
        userSettings.perPage = value;
        break;
      case "dateformat":
        userSettings.dateFormat = value;
        break;
    }
  });

  return userSettings;
};

export default parseUserSettings;
