import { memo } from "react";
import { useTranslation } from "react-i18next";

import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  Grid,
  TextField,
} from "@mui/material";

import { IngredientBase } from "models/resources/ingredient.model";

import useIngredientForm from "./useIngredientForm";
import { IngredientFormFields } from "./utils";

interface IngredientFormProps {
  defaultValues?: IngredientFormFields /* Fills the form input fields with the passed default values. */;
  defaultOptions?: IngredientBase[] /* Default list of ingredients for the Select Dropdown input. */;
  submitBtnLabel?: string;
  hasCapacity?: boolean;
  onSubmit(ingredient: {
    id: number;
    capacity?: number;
    quantity: number;
    recharge_quantity: number;
    name: string;
    measurement_unit: string;
    allergens: string[];
  }): void /* Handles the onSubmit function of the form and passes the id of the selected ingredient and its quantity. */;
}

const IngredientForm = ({
  defaultValues = {
    name: "",
    measurement_unit: "",
    recharge_quantity: "",
    quantity: "",
  },
  defaultOptions,
  submitBtnLabel = "Submit",
  hasCapacity = false,
  onSubmit,
}: IngredientFormProps) => {
  const { t } = useTranslation();
  const {
    formValues,
    ingredientList,
    selectedIngredient,
    handleIngredientOnChange,
    handleQuantityOnChange,
    handleCapacityOnChange,
  } = useIngredientForm(defaultValues, defaultOptions ?? null);

  const hasQuantityError =
    typeof formValues.capacity !== "undefined" &&
    +formValues.quantity > +formValues.capacity;

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item md={6}>
          {defaultValues.name.length === 0 ? (
            <Autocomplete
              disabled={defaultValues?.name.length !== 0}
              options={ingredientList}
              getOptionLabel={(ingredient) => ingredient.name}
              value={selectedIngredient}
              onChange={(e, ingredient) =>
                handleIngredientOnChange(ingredient!)
              }
              inputValue={formValues.name}
              onInputChange={(e, ingredientName) =>
                handleIngredientOnChange(ingredientName)
              }
              // Remove 'InputLabelProps' that causes error in TextField component.
              renderInput={({ InputLabelProps, ...params }) => (
                <TextField {...params} label={t("Ingredient").concat("*")} />
              )}
            />
          ) : (
            <TextField
              fullWidth
              disabled
              label={t("Ingredient")}
              value={formValues.name}
            />
          )}
        </Grid>

        <Grid item md={6}>
          <TextField
            fullWidth
            disabled
            label={t("Measurement unit")}
            value={t(formValues.measurement_unit.toLowerCase())}
          />
        </Grid>

        {hasCapacity && (
          <Grid item md={6}>
            <TextField
              fullWidth
              required
              type="number"
              label={t("Capacity")}
              value={formValues.capacity}
              onChange={handleCapacityOnChange}
            />
          </Grid>
        )}

        <Grid item md={6}>
          <TextField
            fullWidth
            required
            type="number"
            label={t("Quantity")}
            error={hasQuantityError}
            helperText={
              hasQuantityError && t("Must be equal or lower than capacity")
            }
            value={formValues.quantity}
            onChange={handleQuantityOnChange}
          />
        </Grid>
      </Grid>

      <DialogActions>
        <Button
          sx={{
            boxShadow: "none",
            marginTop: "10px",
          }}
          variant="contained"
          type="submit"
          disabled={
            formValues.name.length === 0 ||
            +formValues.quantity === 0 ||
            hasQuantityError
          }
          onClick={() => {
            const {
              name,
              measurement_unit,
              quantity,
              capacity,
              id,
              allergens,
            } = formValues;
            const savedIngredient = {
              id: id ?? selectedIngredient?.id ?? 0,
              name,
              measurement_unit,
              quantity: +quantity,
              recharge_quantity: +quantity,
              capacity: capacity ? +capacity : undefined,
              allergens: allergens ?? [],
            };

            onSubmit(savedIngredient);
          }}
        >
          {t(submitBtnLabel)}
        </Button>
      </DialogActions>
    </Box>
  );
};

export default memo(IngredientForm);
