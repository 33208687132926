import { useState } from "react";

import QueryParams from "models/queryParams.model";
import { getData } from "services/requests/listData";

const usePriceAdjustment = () => {
  const [priceAdjustmentProducts, setPriceAdjustmentProducts] = useState();

  const setData = (queryParams: QueryParams, setDataLoading: any) => {
    getData(
      setPriceAdjustmentProducts,
      "price_adjustments",
      {
        ...queryParams,
        filter: queryParams.filter,
      },
      setDataLoading,
    );
  };

  return { priceAdjustmentProducts, setPriceAdjustmentProducts, setData };
};

export default usePriceAdjustment;
