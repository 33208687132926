import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Loader from "components/Loader";
import { CurrentPageprops } from "models/pageProps.model";
import User, { Currency, UserSettings } from "models/resources/user.model";
import { ResponseDataError } from "models/responseData.model";
import NotFound from "pages/layouts/NotFound";
import apiClient from "services/api";
import { getPageProps } from "services/pageconfig/getPageProps";
import parseUserSettings from "utils/parseUserSettings";

interface LogInCredentials {
  email: string;
  password: string;
  rememberMe: boolean;
}

interface Profile {
  firstName: string;
  lastName: string;
  phone: string | null;
  receiveSMS: boolean;
  settings: string;
}

interface AuthProviderProps {
  children: ReactNode;
}

interface AuthContextProps {
  user?: User | undefined;
  userSettings: UserSettings;
  logIn(credentials: LogInCredentials): Promise<void>;
  logOut(): void;
  updateProfile(profile: Profile): void;
  isAuthenticated: boolean;
  getPagePropsData: CurrentPageprops;
  setPagePropsData: (props: CurrentPageprops) => void;
}

const AuthContext = createContext({} as AuthContextProps);

const useAuthContext = () => useContext(AuthContext);

const nonAuthRoutes = [
  "vm",
  "login",
  "register",
  "password-forgot",
  "password-reset",
  "resend-email-forgotten-password",
];

export const isOnNonAuthRoute = () =>
  nonAuthRoutes.includes(window.location.pathname.split("/")[1]);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const navigate = useNavigate();
  let location = useLocation();
  const { t } = useTranslation();
  const [user, setUser] = useState<User | undefined>(undefined);
  const [userSettings, setUserSettings] = useState<UserSettings>({
    currency: Currency.BGN,
  });
  const { moduleSerial } = useParams();

  const [getPagePropsData, setPagePropsData] = useState({} as CurrentPageprops);

  const isAuthenticated: boolean = user != undefined;
  let pageProps = getPageProps() as CurrentPageprops;

  const authorize = async () => {
    return await apiClient<User>({
      url: "profile",
      method: "get",
    })
      .then((response) => {
        setUser(response.data);
        return true;
      })
      .catch(() => false);
  };

  const logIn = async ({ email, password, rememberMe }: LogInCredentials) => {
    await apiClient<{ token: string }>({
      url: "admin/login",
      method: "post",
      bodyData: {
        email,
        password,
        remember_me: rememberMe,
      },
    })
      .then((_response) => {
        navigate(`/account/dashboard`);
        if (!authorize()) logOut();
      })
      .catch(() =>
        toast.error("Invalid email or password!".toString(), {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
  };

  const logOut = () => {
    setUser(undefined);
    (async () => {
      await apiClient<User>({
        url: "logout",
        method: "delete",
      }).then((_response) => setUser(undefined));
    })();
    navigate(`/login`);
  };

  const updateProfile = async ({
    firstName,
    lastName,
    phone,
    receiveSMS,
    settings,
  }: Profile) => {
    await apiClient<User>({
      url: "profile",
      method: "patch",
      bodyData: {
        first_name: firstName,
        last_name: lastName,
        phone: phone,
        receive_sms: receiveSMS,
        settings,
      },
    })
      .then((response) => {
        setUser(response.data);
        toast.success(t("Profile updated"));
      })
      .catch((error: ResponseDataError) => {
        switch (error.statusCode) {
          case 422:
            if (error.message === "phone is invalid") {
              return toast.error(t("Phone is invalid."));
            }
            break;
          default:
            console.log(`Response error: ${error.message}`);
        }
      });
  };

  useEffect(() => {
    const maybeRedirectUser = async () => {
      if (isOnNonAuthRoute()) return;

      const isAuthorized = await authorize();
      if (!isAuthorized) {
        navigate({ pathname: "/login" });
      } else if (location.pathname === "/") {
        navigate("/account/dashboard");
      }
    };

    maybeRedirectUser();
  }, []);

  useEffect(() => {
    if (typeof user?.settings === "undefined") return;

    setUserSettings(parseUserSettings(user.settings));
  }, [user?.settings]);

  if (
    typeof user !== "undefined" &&
    pageProps != undefined &&
    !pageProps.userRoles?.includes(user?.role?.type) &&
    typeof moduleSerial === "undefined"
  ) {
    return <NotFound />;
  }

  if (typeof user === "undefined" && !isOnNonAuthRoute()) {
    return <Loader />;
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        userSettings,
        logIn,
        logOut,
        updateProfile,
        isAuthenticated,
        getPagePropsData,
        setPagePropsData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default useAuthContext;
