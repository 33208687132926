import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
} from "@mui/material";

import QRCodeGenerator from "components/ModuleQr/QRCodeGenerator";
import useModulqQR from "components/ModuleQr/useModuleQR";
import { QrDownloadFormat, QrSize } from "components/ModuleQr/utils";

interface ModuleQRProps {
  currency?: string;
  moduleSerial: string;
}

const ModuleQR = ({ currency = "EUR", moduleSerial }: ModuleQRProps) => {
  const { t } = useTranslation();
  const {
    formValues,
    handleFormValuesOnChange,
    handleSizeOnChange,
    handleLogoOnChange,
    handleRemoveLogoOnClick,
    handleDownloadOnClick,
  } = useModulqQR();

  const { hasAmount, fixedAmount, size, logo, downloadFormat } = formValues;

  return (
    <>
      <Grid container spacing={4}>
        <Grid item md={6}>
          <span>{t("Fixed amount QR code")}</span>
          <Switch
            name="hasAmount"
            checked={hasAmount}
            onChange={handleFormValuesOnChange}
          />
        </Grid>

        <Grid item md={12}>
          {hasAmount && (
            <Fragment>
              <TextField
                sx={{
                  marginRight: "10px!important",
                }}
                name="fixedAmount"
                label={t("Enter amount to be paid")}
                InputLabelProps={{
                  style: { color: "black" },
                }}
                type="number"
                value={fixedAmount || ""}
                onChange={handleFormValuesOnChange}
              />
              <span>{t(currency)}</span>
            </Fragment>
          )}
        </Grid>

        <Grid item md={12}>
          <InputLabel
            sx={{
              fontSize: "15px",
              paddingLeft: "5px",
              color: "black",
            }}
          >
            {t("QR Code Size")}
          </InputLabel>
          <Select name="size" value={size} onChange={handleSizeOnChange}>
            {
              // Render all sizes from enum.
              Object.values(QrSize).map((qrSize, index) => (
                <MenuItem key={index} value={qrSize}>
                  {qrSize}
                </MenuItem>
              ))
            }
          </Select>
        </Grid>

        <Grid item md={12}>
          <InputLabel
            sx={{
              fontSize: "16px",
              paddingLeft: "5px",
              color: "black",
            }}
          >
            {t("Logo in the middle of QR code (optional)")}
          </InputLabel>
          <Button variant="contained" component="label">
            <input
              name="logo"
              type="file"
              accept="image/svg+xml, image/png, image/jpeg"
              hidden
              onChange={handleLogoOnChange}
            />
            {t("Upload File")}
          </Button>
          {logo && (
            <Button
              variant="contained"
              component="label"
              style={{ marginLeft: "50px" }}
              onClick={handleRemoveLogoOnClick}
            >
              {t("Remove File")}
            </Button>
          )}
        </Grid>

        <Grid item md={12}>
          <QRCodeGenerator
            size={parseInt(size.split("x")[0])}
            logo={logo}
            amount={hasAmount ? fixedAmount : null}
            currency={currency}
            moduleSerial={moduleSerial}
          />
        </Grid>

        <Grid sx={{ paddingTop: "0!important" }} item md={12}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            {
              // Render all formats from enum.
              Object.values(QrDownloadFormat).map((qrFormat, index) => {
                return (
                  <Box key={index}>
                    <Radio
                      style={{
                        marginTop: "-0.6%", //probably terrible css, but the radio buttons are next to the text this way
                      }}
                      name="downloadFormat"
                      checked={qrFormat === downloadFormat}
                      value={qrFormat}
                      onChange={handleFormValuesOnChange}
                    />
                    {qrFormat}
                  </Box>
                );
              })
            }
          </RadioGroup>
        </Grid>

        <Grid
          sx={{ paddingTop: "0!important" }}
          item
          md={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="contained"
            onClick={() => handleDownloadOnClick(moduleSerial)}
          >
            {t("Download now")}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ModuleQR;
