import Invoice from "models/resources/invoice.model";
import Order from "models/resources/order.model";

export const invoiceRequestFilter = (
  data: Order[] | Invoice[],
  tab: string,
  userCompanies?: number[],
): Order[] | Invoice[] => {
  switch (tab) {
    case "requests":
      return (data as Order[])?.filter(
        ({ status, request_invoice, invoice_company, company_id }) =>
          status === "success" &&
          request_invoice === "requested" &&
          invoice_company &&
          userCompanies?.includes(company_id),
      );
    case "waiting":
      return (data as Order[])?.filter(
        ({ status, request_invoice, invoice_company, invoice }) =>
          status === "success" &&
          request_invoice === "requested" &&
          invoice_company &&
          !invoice,
      );
    case "pending":
      return (data as Order[])?.filter(
        ({ request_invoice, status }: Order) =>
          status === "success" && request_invoice === "requested",
      );
    case "issued":
      return (data as Invoice[])?.filter((invoice) =>
        userCompanies?.includes(invoice?.issuer_company_id!),
      );
    case "incoming":
      return (data as Invoice[])?.filter((invoice) =>
        userCompanies?.includes(invoice?.receiver_company_id!),
      );
    case "invoices":
      return data as Invoice[];
  }
  return [];
};
