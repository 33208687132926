import { useParams } from "react-router-dom";

import AveratoAdvertisement from "components/Advertisement/Advertisement";

const VM = () => {
  const { moduleSerial } = useParams();

  return <AveratoAdvertisement moduleSerial={moduleSerial || ""} />;
};

export default VM;
