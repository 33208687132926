import FilterData, { FilterType } from "models/filterData.model";
import { Choice } from "models/pageProps.model";
import Company from "models/resources/company.model";
import Location from "models/resources/location.model";
import Machine from "models/resources/machine.model";
import ObjectEntity from "models/resources/objectEntity.model";
import POS from "models/resources/pos.model";
import QR from "models/resources/qr.model";

export interface MultiSelectFilterProps {
  fieldName: string; // Name of the input field.
  defaultValue?: MultiSelectInputType[]; // Sets default selected values in the current input.
  sorceIndex: number; // Used for dynamic collection management for  payment point filters in transaction and order pages.
  choices?: Choice[]; // Used for status and payment types filters in transaction page.
  paymentPointChoice?: Choice; // Used for payment point filters in order and transaction pages.
}

export type MultiSelectInputType =
  | Choice
  | Company
  | Location
  | Machine
  | POS
  | QR
  | ObjectEntity;

export const getMultiSelectFilters = (
  field: string,
  values: MultiSelectInputType[],
  componentName: string,
  invoiceCurrentTab?: string,
  paymentPointChoice?: string,
): FilterData[] => {
  // TODO: Remove deleted filter when BE fix issue around machines, pos and qrs without deleted: false.
  const deletedFilter: FilterData[] = [
    "orders",
    "transactions",
    "invoices",
    "module",
  ].includes(componentName)
    ? []
    : [{ type: FilterType.ResourceDeleted, value: false }];

  return [
    ...deletedFilter,
    ...getSelectedFilter(
      field,
      values,
      componentName,
      invoiceCurrentTab,
      paymentPointChoice,
    ),
  ];
};

export const optionsForAutocomplete = (value: MultiSelectInputType) =>
  (value as Company).display_name ||
  (value as Location).address ||
  (value as Machine).name ||
  (value as Company).name ||
  (value as Choice).label;

export const sortOptions = (data: Choice[] | MultiSelectInputType[]) =>
  data?.sort(
    (a: string | MultiSelectInputType, b: string | MultiSelectInputType) =>
      optionsForAutocomplete(a as MultiSelectInputType).localeCompare(
        optionsForAutocomplete(b as MultiSelectInputType),
      ),
  );

const getSelectedFilter = (
  field: string,
  values: MultiSelectInputType[],
  componentName: string,
  invoiceCurrentTab?: string,
  paymentPointChoice?: string,
): FilterData[] => {
  switch (field.toLowerCase()) {
    case "companyids":
      return [
        {
          type: FilterType.Company,
          value: values.map((v: MultiSelectInputType) => (v as Company).id!),
        },
      ];
    case "locationids":
      return [
        {
          type: FilterType.Location,
          value: values.map((v: MultiSelectInputType) => (v as Location).id),
        },
      ];
    case "issuingcompanyids":
      switch (invoiceCurrentTab) {
        case "requests":
          return [
            { type: FilterType.Issuer, value: [] },
            {
              type: FilterType.Company,
              value: values.map(
                (v: MultiSelectInputType) => (v as Company).id!,
              ),
            },
          ];
        case "issued":
          return [
            {
              // Needed for removing the filter on invoice tab change.
              type: FilterType.RequiredInvoice,
              value: "",
            },
            { type: FilterType.Company, value: [] },
            {
              type: FilterType.Issuer,
              value: values.map(
                (v: MultiSelectInputType) => (v as Company).id!,
              ),
            },
          ];
      }
      break;
    case "receivingcompanyids":
      switch (invoiceCurrentTab) {
        case "requests":
          return [
            { type: FilterType.ReceiverCompany, value: [] },
            {
              type: FilterType.InvoiceCompany,
              value: values.map(
                (v: MultiSelectInputType) => (v as Company).id!,
              ),
            },
          ];
        case "issued":
          return [
            {
              // Needed for removing the filter on invoice tab change.
              type: FilterType.RequiredInvoice,
              value: "",
            },
            { type: FilterType.InvoiceCompany, value: [] },
            {
              type: FilterType.ReceiverCompany,
              value: values.map(
                (v: MultiSelectInputType) => (v as Company).id!,
              ),
            },
          ];
      }
      break;
    case "paymentpoint":
      return [
        {
          type: FilterType.ObjectEntity,
          value: values.map(
            (v: MultiSelectInputType) => (v as ObjectEntity).id,
          ),
        },
      ];
    case "transactionstatuses":
      return [
        {
          type: FilterType.TransactionStatus,
          value: values.map((v: MultiSelectInputType) => (v as Choice).id),
        },
      ];
    case "transactionpaymenttypes":
      return [
        {
          type: FilterType.TransactionPaymentType,
          value: values.map((v: MultiSelectInputType) => (v as Choice).id),
        },
      ];
    case "roles":
      return [
        {
          type: FilterType.UserRolenames,
          value: values.map((v: MultiSelectInputType) => (v as Choice).id),
        },
      ];
  }

  return [];
};
