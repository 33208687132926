import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastPosition, toast } from "react-toastify";

import { TextField } from "@mui/material";

import { CustomTable } from "components/Table/Table";
import ContentLayout from "components/layouts/ContentLayout";

import ApiCredentialsForm from "./ApiCredentialsForm";
import useCompanyApiCredentials from "./useCompanyApiCredentials";
import { tokenTableColumns } from "./utils";

const CompanyApiCredentials = () => {
  const { t } = useTranslation();
  const {
    projectId,
    projectKey,
    tokens,
    handleProjectIdChange,
    handleProjectKeyChange,
    updateTokens,
    deleteToken,
    setData,
  } = useCompanyApiCredentials();

  const openState = useState(false);

  useEffect(() => {
    if (projectKey != null) return;
    toast.error(
      t("There aren't any available API tokens for the current company", {
        position: toast.POSITION.TOP_RIGHT,
      }),
    );
  }, [projectKey]);

  tokenTableColumns.forEach((element) => {
    element.label = t(element.label);
  });
  return (
    <ContentLayout
      title={`${t("Companies")} > ${t("API Credentials")}`}
      filters={false}
    >
      <TextField
        fullWidth
        disabled
        name={"projectKey"}
        label={t("Project Key")}
        value={projectKey || ""}
      />
      <CustomTable
        openState={openState}
        isSearchable={false}
        title={t("API Tokens")}
        fetchPaginatedData={setData}
        tableData={tokens}
        columns={tokenTableColumns}
        clickableRow={false}
        dynamicDialogProps={{
          title: "Create API Token", // Used only for title. TODO: Refactor.
          resource: "null",
          call: "null",
          styles: () => "null",
        }}
        tableOptions={{
          pagination: false,
          page: 0,
          rowsPerPage: 25,
          field: "validUntil",
          order: "asc",
        }}
        hadPDF={false}
        notCSV={true}
        isDynamic
        onDelete={deleteToken}
        customCreate={
          <ApiCredentialsForm
            openState={openState}
            projectId={projectId}
            updateTokens={updateTokens}
            addProjectId={handleProjectIdChange}
            addProjectKey={handleProjectKeyChange}
          />
        }
        customEdit={
          <ApiCredentialsForm
            projectId={projectId}
            updateTokens={updateTokens}
            addProjectId={handleProjectIdChange}
            addProjectKey={handleProjectKeyChange}
          />
        }
      />
    </ContentLayout>
  );
};

export default CompanyApiCredentials;
