interface BooleanFilter {
  type: FilterType.ResourceDeleted;
  value: boolean;
}

interface StringFilter {
  type:
    | FilterType.SearchQuery
    | FilterType.RequiredInvoice
    | FilterType.PaymentPointType
    | FilterType.CompanyStatus;
  value: string;
}

interface StringArrayFilter {
  type:
    | FilterType.TransactionPaymentType
    | FilterType.TransactionStatus
    | FilterType.UserRolenames;
  value: string[];
}

interface NumberArrayFilter {
  type:
    | FilterType.Company
    | FilterType.Location
    | FilterType.Machine
    | FilterType.VPOS
    | FilterType.QR
    | FilterType.ObjectEntity
    | FilterType.Issuer
    | FilterType.InvoiceCompany
    | FilterType.ReceiverCompany;
  value: number[];
}

interface DateFilter {
  type: FilterType.DateRange | FilterType.DateFrom | FilterType.DateTo;
  value: {
    startDate?: string;
    endDate?: string;
  } | null;
}

type FilterData =
  | BooleanFilter
  | StringFilter
  | StringArrayFilter
  | NumberArrayFilter
  | DateFilter;

export enum FilterType {
  Company = "companyIds",
  Location = "locationIds",
  Machine = "machineIds",
  VPOS = "vposIds",
  QR = "qrIds",
  ObjectEntity = "objectEntityIds",
  Issuer = "issuerCompanyIds",
  ReceiverCompany = "receiverCompanyIds",
  InvoiceCompany = "invoiceCompanyIds",
  CompanyStatus = "companyStatus",
  RequiredInvoice = "requestInvoice",
  UserRolenames = "userRoleNames",
  TransactionStatus = "transactionStatuses",
  TransactionPaymentType = "transactionPaymentTypes",
  PaymentPointType = "paymentPointType",
  SearchQuery = "searchQuery",
  ResourceDeleted = "resourceDeleted",
  DateRange = "dateRange",
  DateFrom = "from",
  DateTo = "to",
}

export default FilterData;
