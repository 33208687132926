import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import SignalWifiStatusbarNullIcon from "@mui/icons-material/SignalWifiStatusbarNull";
import VisibilityIcon from "@mui/icons-material/Visibility";

import ModuleQR from "components/ModuleQr";
import ModuleSignal from "components/ModuleSignal";
import {
  CustomTable,
  Column,
  TableSettings,
  CellValue,
  DynamicDialogProps,
} from "components/Table/Table";
import ContentLayout from "components/layouts/ContentLayout";
import DynamicDialog from "components/layouts/Dialog";
import QueryParams from "models/queryParams.model";
import MachineModel from "models/resources/machine.model";
import Machine from "models/resources/machine.model";
import { ResponseDataTable } from "models/responseData.model";
import { getData } from "services/requests/listData";

const MachineTable = () => {
  const [getMachinesData, setMachinesData] =
    useState<ResponseDataTable<MachineModel[]>>();
  const { t } = useTranslation();

  const setData = (queryParams: QueryParams, setDataLoading: any) => {
    getData(setMachinesData, "machines", queryParams, setDataLoading);
  };

  const columns: Column<Machine>[] = [
    {
      label: t("Machine Name"),
      name: "machineName",
      getData: (data): CellValue => data.name,
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "name" },
      width: "15%",
      minWidth: "200px",
    },
    {
      label: t("Machine Type"),
      name: "machineType",
      getData: (data): CellValue => t(data.type),
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "name" },
      width: "15%",
      minWidth: "200px",
    },
    {
      label: t("Currency"),
      name: "currency",
      getData: (data): CellValue => t(data.currency),
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "currency" },
      width: "10%",
      minWidth: "150px",
    },
    {
      label: t("Location"),
      name: "location",
      getData: (data): CellValue => (
        <Link
          to={{
            pathname: `/account/locations/${data.location_id}`,
          }}
        >
          {`${data.location.name}, ${data.location.address}`}
        </Link>
      ),
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "location_id" },
      width: "20%",
      minWidth: "200px",
    },
    {
      label: t("Company"),
      name: "company",
      getData: (data): CellValue => (
        <Link
          to={{
            pathname: `/account/companies/${data.company_id}`,
          }}
        >
          {data.company.display_name || data.company.name}
        </Link>
      ),
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "company_id" },
      width: "15%",
      minWidth: "200px",
    },
    {
      label: t("Module Serial"),
      name: "modulSerial",
      getData: (data): CellValue =>
        typeof data.module?.serial !== "undefined"
          ? t(`${data.module?.serial}`)
          : "-------",

      align: "center",
      width: "15%",
      minWidth: "200px",
      show: () => {
        return true;
      },
      sort: {
        isSortable: true,
        field: "module.serial",
      },
    },
    {
      label: t("Module Signal Strength"),
      name: "moduleS",
      getData: (data): CellValue =>
        data.module?.signal_strength ? (
          <ModuleSignal
            signal={data.module?.signal_strength ?? null}
            noLabel={true}
          />
        ) : (
          <SignalWifiStatusbarNullIcon />
        ),
      show: () => {
        return true;
      },
      sort: {
        isSortable: true,
        field: "module.signal_strength",
      },
      align: "center",
      width: "15%",
      minWidth: "200px",
    },
    {
      label: t("Module State"),
      name: "moduleState",
      getData: (data): CellValue =>
        data.module?.state === "up" ? (
          <FiberManualRecordIcon sx={{ color: "#4CAF50" }} />
        ) : (
          <FiberManualRecordIcon sx={{ color: "#FF6347" }} />
        ),
      show: () => {
        return true;
      },
      sort: {
        isSortable: true,
        field: "module.state",
      },
      align: "center",
      width: "5%",
    },
    {
      label: t("QR"),
      name: "qr",
      getData: (data): CellValue =>
        data.module?.state ? (
          <DynamicDialog
            title={`${t("QR code for module")} ${data.module?.serial}`}
            component={
              <ModuleQR
                currency={data.currency}
                moduleSerial={data.module?.serial}
              />
            }
            iconButton={<VisibilityIcon />}
            openMessage={t("QR")}
            hideActions
          />
        ) : (
          "---"
        ),
      show: () => {
        return true;
      },
      width: "5%",
      align: "center",
    },
    {
      label: t("Edit"),
      name: "edit",
      getData: (data): CellValue => data.id,
      show: () => {
        return true;
      },
      align: "center",
      width: "5%",
    },
  ];

  const tableOptions: TableSettings = {
    pagination: true,
    page: 0,
    rowsPerPage: 25,
    field: "name",
    order: "asc",
  };

  const dynamicDialogProps: DynamicDialogProps = {
    title: "Add machine",
    resource: "machine",
    call: "machines",
    styles: (company_id) => {
      if (!company_id) return "flex90Form  first-100 nth-7 nth-8";
      return "flexMachines first-100 nth-7 nth-8 nth-9";
    },
  };

  return (
    <ContentLayout title={t("Machines")}>
      <CustomTable
        title={t("Machines")}
        resource="machine"
        call="machines"
        tableData={getMachinesData}
        fetchPaginatedData={setData}
        setTableData={setMachinesData}
        columns={columns}
        tableOptions={tableOptions}
        exportProp={"machines"}
        dynamicDialogProps={dynamicDialogProps}
        isDynamic={true}
      />
    </ContentLayout>
  );
};

export default MachineTable;
