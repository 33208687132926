import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import DeleteIcon from "@mui/icons-material/Delete";
import EditSharp from "@mui/icons-material/EditSharp";
import { IconButton, Stack, TableCell, TableRow, Tooltip } from "@mui/material";

import IngredientForm from "components/IngredientForm";
import DynamicDialog from "components/layouts/Dialog";
import useDialogShowState from "hooks/useDialogShowState";

import { ProductIngredient } from "./utils";

const cellStyles = { textAlign: "center", padding: "5px 0 0 5px" };

interface IngredientRowProps {
  ingredient: ProductIngredient;
  editIngredient(ingredient: ProductIngredient): void;
  deleteIngredient(ingredient: ProductIngredient): void;
}

const IngredientRow = ({
  ingredient,
  editIngredient,
  deleteIngredient,
}: IngredientRowProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { show, handleShowStateChange } = useDialogShowState();
  const { name, quantity, measurement_unit, allergens } = ingredient;

  return (
    <TableRow hover sx={{ cursor: "pointer" }}>
      <TableCell sx={cellStyles}>{name}</TableCell>
      <TableCell sx={cellStyles}>{quantity}</TableCell>
      <TableCell sx={cellStyles}>{measurement_unit}</TableCell>
      <TableCell sx={cellStyles}>
        {allergens && allergens?.length !== 0 ? allergens?.join(", ") : "---"}
      </TableCell>
      <TableCell size="small" sx={{ width: "5%" }}>
        <Stack direction="row">
          <IconButton size="small" onClick={handleShowStateChange}>
            <Tooltip title={t("Click here to edit")}>
              <EditSharp />
            </Tooltip>
          </IconButton>

          <IconButton
            size="small"
            color="secondary"
            aria-label="delete"
            onClick={() => deleteIngredient(ingredient)}
          >
            <Tooltip title={t("Click here to delete")}>
              <DeleteIcon />
            </Tooltip>
          </IconButton>
        </Stack>

        <DynamicDialog
          isOpen={show}
          hideActions={true}
          hideButton
          title={t("Add Ingredient")}
          onClick={handleShowStateChange}
          onCancel={handleShowStateChange}
          component={
            <IngredientForm
              defaultValues={ingredient}
              submitBtnLabel="Update"
              onSubmit={(i) => {
                editIngredient(i);
                handleShowStateChange();
              }}
            />
          }
        />
      </TableCell>
    </TableRow>
  );
};

export default IngredientRow;
