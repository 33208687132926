import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import CategoryIcon from "@mui/icons-material/Category";
import ClassIcon from "@mui/icons-material/Class";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DescriptionIcon from "@mui/icons-material/Description";
import DiscountIcon from "@mui/icons-material/Discount";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import GroupsIcon from "@mui/icons-material/Groups";
import InventoryIcon from "@mui/icons-material/Inventory";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MicrowaveIcon from "@mui/icons-material/Microwave";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import QrCodeIcon from "@mui/icons-material/QrCode";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";

import styles from "components/SidebarLinks/SideBarLinks.module.scss";
import { SubItemList, ItemList } from "components/SidebarLinks/types";
import useAuthContext from "context/AuthContext";
import { rolesPermission } from "utils/constants";

const itemsList = [
  {
    text: "Dashboard",
    icon: <DashboardIcon style={{ fontSize: "1.2em", margin: "4px 0" }} />,
    href: "/dashboard", // <-- add link targets
  },
  {
    text: "Locations",
    icon: <LocationOnIcon style={{ fontSize: "1.2em", margin: "4px 0" }} />,
    href: "/locations",
  },
  {
    text: "Vending",
    icon: <MicrowaveIcon style={{ fontSize: "1.2em", margin: "4px 0" }} />,
    href: "/vending",
    subItems: [
      {
        text: "Machines",
        icon: (
          <MiscellaneousServicesIcon
            style={{ fontSize: "1.2em", margin: "4px 0" }}
          />
        ),
        href: "/vending/machines",
      },
      {
        text: "Blueprints",
        icon: (
          <DescriptionIcon style={{ fontSize: "1.2em", margin: "4px 0" }} />
        ),
        href: "/vending/blueprints",
      },
      {
        text: "Products",
        icon: <InventoryIcon style={{ fontSize: "1.2em", margin: "4px 0" }} />,
        href: "/vending/products",
      },
      {
        text: "Ingredients",
        icon: <CategoryIcon style={{ fontSize: "1.2em", margin: "4px 0" }} />,
        href: "/vending/ingredients",
      },
      {
        text: "Allergens",
        icon: <WorkspacesIcon style={{ fontSize: "1.2em", margin: "4px 0" }} />,
        href: "/vending/allergens",
      },
    ],
  },
  {
    text: "Virtual POS",
    icon: (
      <AccountBalanceWalletOutlinedIcon
        style={{ fontSize: "1.2em", margin: "4px 0" }}
      />
    ),
    href: "/pos",
  },
  {
    text: "QR Payment Codes",
    icon: <QrCodeIcon style={{ fontSize: "1.2em", margin: "4px 0" }} />,
    href: "/qr-payment-codes",
  },
  {
    text: "Transactions",
    icon: (
      <PaymentOutlinedIcon style={{ fontSize: "1.2em", margin: "4px 0" }} />
    ),
    href: "/transactions",
  },
  {
    text: "Orders",
    icon: (
      <ShoppingCartOutlinedIcon
        style={{ fontSize: "1.2em", margin: "4px 0" }}
      />
    ),
    href: "/orders",
  },
  // Removed while there is no way for invoices to be created atm.
  // {
  //   text: "Invoices",
  //   icon: (
  //     <CreditScoreOutlinedIcon style={{ fontSize: "1.2em", margin: "4px 0" }} />
  //   ),
  //   href: "/invoices",
  //   subItems: [
  //     {
  //       text: "Incoming",
  //       icon: (
  //         <CreditScoreOutlinedIcon
  //           style={{ fontSize: "1.2em", margin: "4px 0" }}
  //         />
  //       ),
  //       href: "/invoices/incoming",
  //     },
  //     {
  //       text: "Outgoing",
  //       icon: (
  //         <CreditScoreOutlinedIcon
  //           style={{ fontSize: "1.2em", margin: "4px 0" }}
  //         />
  //       ),
  //       href: "/invoices/outgoing",
  //     },
  //   ],
  // },
  {
    text: "Users",
    icon: <GroupsIcon style={{ fontSize: "1.2em", margin: "4px 0" }} />,
    href: "/users",
  },
  {
    text: "Companies",
    icon: (
      <BusinessOutlinedIcon style={{ fontSize: "1.2em", margin: "4px 0" }} />
    ),
    href: "/companies",
  },
  {
    text: "Modules",
    icon: <ViewModuleIcon style={{ fontSize: "1.2em", margin: "4px 0" }} />,
    href: "/modules",
  },
  {
    text: "Promotions",
    icon: <DiscountIcon style={{ fontSize: "1.2em", margin: "4px 0" }} />,
    href: "/promotions",
  },
  {
    text: "Gift Cards",
    icon: (
      // change Icon
      <CardGiftcardIcon style={{ fontSize: "1.2em", margin: "4px 0" }} />
    ),
    href: "/gift-cards",
  },
  {
    text: "Entity Categories",
    icon: <ClassIcon style={{ fontSize: "1.2em", margin: "4px 0" }} />,
    href: "/entity-categories",
  },
  {
    text: "Price Adjustment",
    icon: <LocalOfferIcon style={{ fontSize: "1.2em", margin: "4px 0" }} />,
    href: "/price_adjustments",
  },
];

const SideBarLinks = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const role = user?.role?.type;
  const [open, setOpen] = useState(false);

  const filteredLinks = itemsList.filter((e) => {
    if (role) return !rolesPermission[role]?.includes(e.text);
  });

  const handleOpenExpand = () => {
    setOpen(!open);
  };

  const dropDown = ({ href, text, icon, subItems, i }: SubItemList) => {
    return (
      <div style={{ cursor: "pointer" }} key={`${i}-${href}`}>
        <ListItem
          onClick={handleOpenExpand}
          className={
            location.pathname.includes(href)
              ? styles.navigationButton__active
              : styles.navigationButton
          }
        >
          {icon && (
            <ListItemIcon className={styles.averatoIcon}>{icon}</ListItemIcon>
          )}
          <ListItemText primary={t(text)} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {subItems?.map(({ text, icon, href }: ItemList, i: number) => {
            return (
              <div
                onClick={() => navigate(`/account${href}`)}
                key={`${i}-${href}`}
              >
                <ListItem
                  sx={{ pl: 2, background: "#35455e" }}
                  className={
                    location.pathname.includes(href)
                      ? styles.navigationButton__active
                      : styles.navigationButton
                  }
                >
                  {icon && (
                    <ListItemIcon className={styles.averatoIcon}>
                      {icon}
                    </ListItemIcon>
                  )}
                  <ListItemText primary={t(text)} />
                </ListItem>
              </div>
            );
          })}
        </Collapse>
      </div>
    );
  };

  return (
    <List style={{ marginTop: "20px" }}>
      {filteredLinks.map(({ href, text, icon, subItems }, i) => {
        // Removed while there is no way for invoices to be created atm.
        return subItems ? (
          dropDown({ href, text, icon, subItems, i })
        ) : (
          <div key={`${i}-${href}`} onClick={() => navigate(`/account${href}`)}>
            <ListItem
              className={
                location.pathname.includes(href)
                  ? styles.navigationButton__active
                  : styles.navigationButton
              }
            >
              {icon && (
                <ListItemIcon className={styles.averatoIcon}>
                  {icon}
                </ListItemIcon>
              )}
              <ListItemText primary={t(text)} style={{ cursor: "pointer" }} />
            </ListItem>
          </div>
        );
      })}
    </List>
  );
};

export default SideBarLinks;
